import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import { Company } from 'shared/types/api'

interface CompanyState {
  list: Company[]
  fetching: boolean
}

const initialState: CompanyState = {
  list: [],
  fetching: false,
}

const name = 'companies'

export const fetchCompanies = createAction(`${name}/fetching`)

const company = createSlice({
  name,
  initialState,
  reducers: {
    fetch(state, action) {
      state.list = action.payload
      state.fetching = false
    },
  },
  extraReducers: {
    [fetchCompanies.type]: state => {
      state.fetching = true
    },
  },
})

export const { fetch } = company.actions

export default company.reducer
