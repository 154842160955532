import React, { useRef } from 'react';
import withDialog, { WrappedProps } from 'shared/hocs/withDialog';
import flowRight from 'lodash/flowRight';
import { useDispatch } from 'react-redux';
import { showDialog, hideAllDialogs } from 'shared/redux/app/reducer';
import Button from 'shared/components/Button';
import TakePhotoDialog from './TakePhotoDialog';
import ImageCropper from './ImageCropper';
import IconUploadPhoto from 'assets/icons/upload-photo.svg';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Box from 'shared/components/Box';
import SvgCameraIcon from 'assets/icons/Camera';
import { useBreakpoint } from 'shared/hooks/useBreakpoint';
import { getTranslatedWord } from 'shared/utils/tools';

export type SelectUploadProps = WrappedProps<{ title?: string }> & {
  onChangeImage?: Function;
  onSaveImage?: Function;
  cropImage?: boolean;
  takePhoto?: boolean;
  multiple?: boolean;
  accept: 'all' | string | string[];
  isRaw?: boolean;
};

function SelectUpload(props: SelectUploadProps) {
  const { onChangeImage, onSaveImage, cropImage = true, takePhoto = true, multiple = false, isRaw = false } = props;
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useBreakpoint() === 'mobile';
  const accept = props.accept !== 'all' && { accept: 'image/*' };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    minSize: 0,
    maxSize: 5242880,
    ...accept,
  });

  return (
    <SelectUploadContainer>
      <UploadOptions>
        {takePhoto && (
          <UploadOption
            onClick={() =>
              dispatch(
                showDialog({
                  component: TakePhotoDialog,
                  props: {
                    onSave: handleCropImage,
                    onCancel,
                    onBack,
                    title: getTranslatedWord('Take A Photo'),
                    dialogClassName: 'dialog--medium',
                  },
                }),
              )
            }
          >
            <SvgCameraIcon />
            <Description>Take a photo</Description>
          </UploadOption>
        )}
        <UploadOption
          {...getRootProps()}
          {...(takePhoto
            ? {}
            : {
                width: '100% !important',
                height: '200px !important',
              })}
        >
          <input {...getInputProps()} />
          <img src={IconUploadPhoto} alt="Upload a photo" />
          {!isMobile && (
            <Description>
              {getTranslatedWord('Drag and drop your image here or')}{' '}
              <span className="underline">{getTranslatedWord('upload a photo')}</span>
            </Description>
          )}
          {isMobile && <Description>{getTranslatedWord('Upload a photo')}</Description>}
        </UploadOption>
      </UploadOptions>
      <SubText>{getTranslatedWord('Single image must not exceed 5MB')}</SubText>
    </SelectUploadContainer>
  );

  function onDrop(accepted: File[]) {
    // consoleLogger(accepted, '@accepted')
    const [acceptedFile] = accepted;

    if (isRaw) return onSave(acceptedFile);

    if (!acceptedFile) return;

    const reader = new FileReader();
    reader.onload = () => {
      const data = reader.result;
      if (typeof data !== 'string') return;

      if (!cropImage) return onSave(data);

      onChangeImage && onChangeImage(data);
      dispatch(
        showDialog({
          component: ImageCropper,
          props: {
            title: getTranslatedWord('Upload Profile Image'),
            imgSrc: data,
            onSave,
            onCancel,
            onBack,
            imageRef,
            overflow: 'auto',
          },
        }),
      );
    };

    reader.readAsDataURL(acceptedFile);
  }

  function handleCropImage(data: string) {
    if (!cropImage) return onSave(data);

    dispatch(
      showDialog({
        component: ImageCropper,
        props: {
          title: getTranslatedWord('Take A Photo'),
          imgSrc: data,
          onSave,
          onCancel,
          onBack,
          imageRef,
        },
      }),
    );
  }

  function onSave(data: string | File | File[]) {
    onChangeImage && onChangeImage(data);
    onSaveImage && onSaveImage(data);
    dispatch(hideAllDialogs());
  }

  function onCancel(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    dispatch(hideAllDialogs());
  }

  function onBack() {
    dispatch(hideAllDialogs());
    dispatch(
      showDialog({
        component: Dialog,
        props: {
          onChangeImage,
          onSaveImage,
          cropImage,
          title: props?.formState?.fields?.title || getTranslatedWord('Upload Profile Image'),
        },
      }),
    );
  }
}

const Dialog = flowRight(withDialog())(SelectUpload);

Dialog.defaultProps = {
  hasContinue: false,
  dialogClassName: 'dialog--medium',
};

const SelectUploadContainer = styled(Box)`
  padding: 40px 40px 0 40px;
  height: 290px !important;

  ${({ theme }) => theme.mediaQueries.smallOnly} {
    padding: 16px;
    overflow-x: hidden;
  }
`;

const UploadOptions = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.smallOnly} {
    justify-content: space-around;
  }
`;

const UploadOption = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 250px;
  height: 150px;
  background: #f9f9f9;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.smallOnly} {
    width: 138px;
    height: 100px;

    svg,
    img {
      width: 38px;
    }
  }
`;

const Description = styled(Box)`
  font-size: 16px;
  line-height: 19px;
  width: 235px;
  color: ${({ theme }) => theme.colors.secondary};

  .underline {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediaQueries.smallOnly} {
    font-weight: bold;
    width: fit-content;
  }
`;

const SubText = styled(Box)`
  font-size: 14px;
  line-height: 16px;
  margin-top: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
`;

export default Dialog;
