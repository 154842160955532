import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import flowRight from 'lodash/flowRight';

import withDialog from 'shared/hocs/withDialog';
import { dataSelector } from 'shared/redux/selectors';
import styled from 'styled-components';

import Box from '../Box';
import DialogProgress, { DialogProgressProps } from '../Layout/Dialog/Progress';

type ConfirmDialogProps = {
  message?: string | ReactNode;
  additional_message?: string | ReactNode;
};

function Confirm(props: ConfirmDialogProps & DialogProgressProps) {
  const { message, additional_message, withProgress = false, etaInSeconds = 0 } = props;
  const { isProgressing } = useSelector(dataSelector);

  const defaultIncrement = useMemo(() => {
    return 1 / (0.1 * etaInSeconds);
  }, [etaInSeconds]);

  return (
    <ConfirmDialogContainer>
      {withProgress && <DialogProgress isProcessing={isProgressing} defaultIncrement={defaultIncrement} />}
      <ConfirmDialogBodyContainer>
        {message && typeof message === 'string' ? <div className="message">{message}</div> : message}
        {additional_message && typeof additional_message === 'string' ? (
          <div className="additional">{additional_message}</div>
        ) : (
          additional_message
        )}
      </ConfirmDialogBodyContainer>
    </ConfirmDialogContainer>
  );
}

const ConfirmDialogContainer = styled(Box)`
  display: flex;
  margin: 0;
  flex-direction: column;
`;

const ConfirmDialogBodyContainer = styled(Box)`
  display: flex;
  margin: 32px 48px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main};
  min-height: 32px;
  flex-direction: column;

  .message {
    margin: auto 0;
  }

  .additional {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.caution};
  }
`;

const ConfirmDialog = flowRight(withDialog())(Confirm);

ConfirmDialog.defaultProps = {
  titleClassName: 'dialog__title--no-capitalize',
};

export default ConfirmDialog;
