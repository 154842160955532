import styled from 'styled-components';

export const TextFieldLabel = styled.div`
  margin-bottom: 32px;
  font-size: 15px;
`;

export const FirstGroup = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SecondGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #b1b1b5;
  border-radius: 8px;
  height: calc(100% - 40px);
  overflow: hidden;
  gap: 4px;
  svg {
    width: 120px;
    height: 90px;

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      width: 100px;
      height: 70px;
    }
  }

  img {
    height: 148px;
    width: 120px;
    object-fit: contain;
  }

  p {
    font-size: 14px;
    color: #b1b1b5;
  }

  p:first-of-type {
    font-weight: bold;
    font-size: 15px;
    padding: 0;
    margin: 0;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    width: calc(100% - 140px);
    height: calc(100% - 80px);
    padding: 8px;
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 12px;
  margin-left: 12px;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    margin-top: 24px;
    margin-left: 0px;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  padding: 32px;
  padding-bottom: 0px;
`;
