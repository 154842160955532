import { useEffect, RefObject } from 'react';
import { consoleLogger } from 'shared/utils/tools';

const isClient = typeof document !== undefined;

export default function useClickOutside(
  domRef: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  callback?: Function,
  conditionCallback?: Function,
) {
  useEffect(() => {
    function handleClick(evt: MouseEvent) {
      // evt.preventDefault
      const target = evt.target as Node;

      if (domRef) {
        if (!Array.isArray(domRef) && domRef.current && !domRef.current.contains(target)) {
          if (conditionCallback) {
            conditionCallback(target);
          } else {
            callback && callback();
          }
        } else {
          if (Array.isArray(domRef)) {
            const arr: Array<boolean> = domRef.map(dom => {
              if (dom.current) {
                if (!dom.current.contains(target)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            });

            if (!arr.includes(false)) {
              if (conditionCallback) {
                conditionCallback(target);
              } else {
                callback && callback();
              }
            }
          }
        }
      }
    }
    if (isClient) {
      document.addEventListener('mousedown', handleClick, false);
    }
    return () => {
      if (isClient) {
        document.removeEventListener('mousedown', handleClick, false);
      }
    };
  }, [domRef]);
}
