import { useState } from 'react';
import omit from 'lodash/omit';
import { FormErrors } from 'shared/types';
import set from 'lodash/set';

type FormValid = {
  isValid: boolean;
  errors?: FormErrors;
};

type CustomChangeHandler<T> = {
  [key: string]: (val: any, fields: T) => Partial<T>;
};

export type UseFormParams<T> = {
  onValid?(arg: T, onClose?: any): void;
  validator?(arg0: T): FormValid;
  initialFields: T | (() => T);
  customChangeHandler?: CustomChangeHandler<T>;
};

export type FormState<T> = {
  fields: T;
  errors: FormErrors;
};

export type FormHandlers = {
  onChange: (key: string, value: any) => void;
  onElementChange: (value: any, e: any) => void | undefined;
  onValidate: (data?: any) => void;
  onReset: () => void;
  onSetError: (errors: FormErrors) => void;
  onSetForm: (data?: any) => void;
};

export default function useForm<S>(params: UseFormParams<S>) {
  const {
    initialFields,
    validator = (): FormValid => ({ isValid: true, errors: {} }),
    customChangeHandler = {},
    onValid = () => {},
  } = params;
  const [fields, setFields] = useState<S>(initialFields);
  const [errors, setErrors] = useState<FormErrors>({});
  function onElementChange(value: any, e: any): void | undefined {
    let id = '';
    if (typeof e === 'string') {
      id = e;
    } else if (e.target) {
      id = e.target.id || '';
      if (e.target.type === 'number' && !Number.isNaN(value)) {
        value = Number(value);
      }
    }
    if (id) {
      onChange(id, value);
    }
  }

  function onSetForm(data: S) {
    setFields(data);
  }

  function onSetError(errors: FormErrors) {
    setErrors(errors);
  }

  return [
    {
      fields,
      errors,
    },
    {
      onElementChange,
      onChange,
      onValidate,
      onReset,
      onSetForm,
      onSetError,
    },
  ] as const;
  function onChange(key: string, value: any): void {
    const customHandler = customChangeHandler[key];

    if (customHandler) {
      setFields(oldFields => {
        const changes = customHandler(value, oldFields);
        if (changes) {
          return { ...oldFields, ...changes };
        }
        return oldFields;
      });
      return;
    }
    setErrors(omit(errors, key));
    setFields((oldFields: any) => {
      return set({ ...oldFields }, key, value);
    });
  }

  function onValidate(data?: S): void {
    const { isValid, errors: validationErrors = {} } = validator({ ...fields, ...(data || {}) });
    if (isValid) {
      setErrors({});
      onValid({ ...fields, ...(data || {}) });
      return;
    }
    setErrors(validationErrors);
  }
  function onReset() {
    setErrors({});
    setFields(initialFields);
  }
}
