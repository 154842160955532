import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Worker } from 'shared/types/api';

export interface TimeZone {
  timezone_type: number;
  timezone: string;
}

export interface Role {
  id: number;
  name: string;
}

export interface User extends Worker {
  permission_ids?: string[];
  role_level?: number;
  roles: Role[];
  permissions: { id: number; name: string }[];
}

export interface AuthState {
  user: User | null;
  loginLoading: boolean;
  sessionLoading: boolean;
  sessionRequested: boolean;
  csrf: string;
}

export interface Address {
  city: string;
  unit: string;
  state: string;
  address: string;
  postal_code: string;
  street_address: string;
}

export interface Permission {
  id: string;
  status: string;
  created_date: string;
  updated_date: string;
  name: string;
}

export interface Rolescheme {
  main: string;
  black: string;
  label: string;
  white: string;
  border: string;
  darkMain: string;
  textLink: string;
  lightGrey: string;
  secondary: string;
  focusShadow: string;
  inputBorder: string;
  tableAlternate: string;
}

export interface LoginPayload {
  email: string;
  password: string;
  remember_me: boolean;
}

const initialState: AuthState = {
  user: null,
  sessionLoading: false,
  sessionRequested: false,
  loginLoading: false,
  csrf: '',
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authorize(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.sessionLoading = false;
    },
    unauthorize(state) {
      state.user = null;
      state.sessionLoading = false;
    },
    authRequest(state, action?: PayloadAction) {
      if (!action?.payload) {
        state.sessionRequested = true;
        state.sessionLoading = true;
      }
    },
    setLoginLoading(state, action: PayloadAction<boolean>) {
      state.loginLoading = action.payload;
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setCsrf(state, action: PayloadAction<string>) {
      state.csrf = action.payload;
    },
    setUserOptions(state, action) {
      if (state.user?.workhours_options) {
        state.user.workhours_options = action.payload;
      }
    },
    setScheduleOptions(state, action) {
      if (state.user?.schedule_options) {
        state.user.schedule_options = action.payload;
      }
    },
    setWorkerDataOptions(state, action) {
      if (state.user?.worker_data_options) {
        state.user.worker_data_options = action.payload;
      }
    },
    setNotificationSettings(state, action) {
      if (state.user?.notification_settings) {
        state.user.notification_settings = action.payload;
      }
    },
    setIsDecimal(state, action) {
      if (state.user?.user_settings) {
        state.user.user_settings.show_time_decimal = action.payload;
      }
    },
  },
});

export const logoutRequest = createAction('auth/logoutRequest');
export const loginRequest = createAction<LoginPayload>('auth/loginRequest');
export const authRequest = createAction<boolean | undefined>('auth/authRequest');

export const {
  authorize,
  unauthorize,
  setLoginLoading,
  setCsrf,
  setUserOptions,
  setScheduleOptions,
  setWorkerDataOptions,
  setNotificationSettings,
  setIsDecimal,
} = auth.actions;

export default auth.reducer;
