import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import RmdTextField, { TextFieldProps as RmdTextFieldProps } from 'react-md/lib/TextFields/TextField';
import classnames from 'classnames';
import omit from 'lodash/omit';
import styled from 'styled-components';
import Box from './Box';
import { Tooltip } from 'react-tooltip';
import SvgCloseRemoveIcon from 'assets/icons/CloseRemove';

interface TextFieldProps extends RmdTextFieldProps {
  icon?: string | ReactNode;
  isClearable?: boolean;
  clearAction?: Function;
  marginBottom?: string | number;
  caution?: boolean;
  disabled?: boolean;
  placeholderPrefix?: string;
  tooltip?: string | ReactNode;
  isPasswordSecured?: boolean;
  labelId?: string;
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const [focus, setFocus] = useState(false);

  const ommitedProps = omit(props, [
    'className',
    'icon',
    'label',
    'required',
    'marginBottom',
    'placeholder',
    'placeholderPrefix',
  ]);
  // const { placeholder, ...restProps } = ommitedProps

  const onFocus = (event: any) => {
    setFocus(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const onBlur = (event: any) => {
    setFocus(false);

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onClearValue = () => {
    props.clearAction && props.clearAction();
  };

  const fieldLabel = useMemo(() => {
    return props.label ? (
      <FieldLabel
        id={props?.labelId}
        className={classnames(props.className, `${props.className}__input-label`)}
        rows={props.rows}
        caution={props.caution}
      >
        {props.label}
      </FieldLabel>
    ) : null;
  }, [props]);

  const inputField = (
    <InputContainer>
      <StyledTextField
        {...ommitedProps}
        placeholder={`${props?.placeholderPrefix ? `${props?.placeholderPrefix} ` : ''}${
          props?.placeholder ? props.placeholder : props?.label ? props.label : ''
        }`}
        $hasIcon={props.icon !== undefined}
        data-tooltip-id={props.id}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {props.tooltip ? (
        <Tooltip
          id={props.id as string}
          isOpen={focus && !props?.isPasswordSecured}
          className="text-input__tooltip"
          classNameArrow="text-input__tooltip-arrow"
        >
          {props.tooltip}
        </Tooltip>
      ) : null}
    </InputContainer>
  );

  if (props.icon) {
    return (
      <StyledTextFieldContainer
        disabled={props.disabled}
        className={classnames(props.className, { multiline: props.rows })}
        mb={props.marginBottom}
      >
        {fieldLabel}
        <Box>
          {props.isClearable ? (
            <ClearContainer>
              {inputField}
              <i className={'remove-icon'} onClick={onClearValue}>
                <SvgCloseRemoveIcon />
              </i>
            </ClearContainer>
          ) : (
            inputField
          )}
          <Icon>{typeof props.icon === 'string' ? <img src={props.icon} alt="" /> : props.icon}</Icon>
        </Box>
      </StyledTextFieldContainer>
    );
  }

  if (props.isClearable) {
    return (
      <StyledTextFieldContainer
        disabled={props.disabled}
        className={classnames(props.className, { multiline: props.rows })}
        mb={props.marginBottom}
        clearable={props.isClearable}
      >
        {fieldLabel}
        <ClearContainer>
          {inputField}
          <i className={'remove-icon'} onClick={onClearValue}>
            <SvgCloseRemoveIcon />
          </i>
        </ClearContainer>
      </StyledTextFieldContainer>
    );
  }

  return (
    <StyledTextFieldContainer
      disabled={props.disabled}
      className={classnames(props.className, { multiline: props.rows })}
      mb={props.marginBottom}
    >
      {fieldLabel}
      {inputField}
    </StyledTextFieldContainer>
  );
};

const InputContainer = styled.div`
  .text-input__tooltip {
    z-index: 9999;
    padding: 16px;
    opacity: 1;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 5px 3px ${({ theme }) => theme.colors.gray.medium};
  }

  .text-input__tooltip-arrow {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 0px 0px ${({ theme }) => theme.colors.gray.medium};
  }
`;

const ClearContainer = styled(Box)`
  .remove-icon {
    box-sizing: border-box;
    min-width: 0;
    width: 19px;
    height: 19px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: revert;
    opacity: 0.6 !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }
`;

const StyledTextFieldContainer = styled(Box)<{ disabled?: boolean; clearable?: boolean }>`
  position: relative;
  &.multiline {
    height: unset;
  }

  .md-text-field-multiline-container {
    width: 100%;

    textarea:hover {
      border: ${({ theme }) => `1px solid ${theme.colors.main} !important`};
      box-shadow: 0px 0px 5px 1px rgba(149, 53, 143, 0.2);
      border-radius: 4px;
    }

    &.md-text-field--margin {
      margin-top: 3px;
    }
  }

  .md-text-field.md-text {
    ${({ clearable }) => `
    padding-right: ${clearable ? '40px !important' : '16px !important'};
  `}
  }

  ${({ disabled }) => `
    opacity: ${disabled ? '0.8' : '1'};
  `}
`;

const StyledTextField = styled(RmdTextField)<{ disabled?: boolean; $hasIcon?: boolean; caution?: boolean }>`
  &.md-text-field-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    input:hover {
      border: ${({ theme }) => `1px solid ${theme.colors.main} !important`};
      box-shadow: 0px 0px 5px 1px rgba(149, 53, 143, 0.2);
      border-radius: 4px;
    }

    .md-text-field {
      ${({ theme, $hasIcon, caution, disabled }) => `
        background: ${disabled ? theme.colors.disabled : theme.colors.white};
        border: 1px solid  ${caution ? theme.colors.caution : theme.colors.inputBorder};
        padding: ${$hasIcon ? '8px 16px 8px 48px' : '8px 16px'};
        color: ${theme.colors.gray.dark};
      `}

      height: 48px;
      border-radius: 6px;
      margin-top: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;

      &[type='password'] {
        width: 100%;
        padding-right: 45px;
      }

      &::placeholder {
        ${({ theme }) => `
          color: ${theme.colors.gray.medium_dark};
          font-family: ${theme.fonts.regular};
        `}
      }

      &.md-text--disabled {
        color: ${({ theme }) => theme.colors.gray.medium_dark};
        background: ${({ theme }) => theme.colors.gray.light};
        border: 1px solid ${({ theme }) => theme.colors.gray.medium_dark};
        cursor: not-allowed;

        &:hover {
          border: 1px solid ${({ theme }) => theme.colors.gray.medium_dark};
        }
      }

      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }

      &--inline-indicator {
        width: 100%;
      }

      &--multiline {
        padding: 8px 14px;
      }

      &:focus {
        ${({ theme, caution }) => `
          border: 1px solid  ${caution ? theme.colors.caution : theme.colors.main};
        `}
      }

      &::disabled {
        ${({ theme }) => `
          color: ${theme.colors.gray.medium_dark};
        `}
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.label};
        border-radius: 50px;
      }
    }

    .md-text-field-icon {
      transform: translateY(-50%);
      margin-right: 16px;
      margin-left: 16px;
      position: absolute;
      right: 0;
      top: 25px;
    }

    .md-divider {
      display: none;
    }

    &--multiline {
      height: fit-content;
    }

    .md-password-btn {
      top: 25px;
      transform: translateY(-50%);
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  position: absolute;
  top: 25px;
  left: 14px;
  transform: translateY(-50%);
`;

export const FieldLabel = styled(Box)<{ caution?: boolean; rows?: number }>`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;

  margin-bottom: 10px;
  width: auto;
  position: absolute;
  z-index: 1;
  padding: 0 8px;
  background: #fff;
  margin-left: 8px;

  ${({ theme, caution, rows }) => `
    color: ${caution ? theme.colors.caution : theme.colors.gray.dark};
    top: ${rows ? '-13px' : '-16px'};
  `}
`;

export default TextField;
