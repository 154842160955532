import React, { useRef, useEffect, useContext } from 'react';
import Button from 'shared/components/Button';
import 'react-image-crop/dist/ReactCrop.css';
import styled, { ThemeContext } from 'styled-components';
import Box from '../Box';
import SvgCameraIcon from 'assets/icons/Camera';
import SvgBackIcon from 'assets/icons/Back';

type ImageUploaderProps = {
  onCancel: Function;
  onSave: Function;
  onBack: () => void;
};

function ImageUploader(props: ImageUploaderProps) {
  const theme = useContext(ThemeContext);

  const { onSave, onBack } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    if (hasGetUserMedia()) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then(stream => {
          if (currentVideoRef) {
            currentVideoRef.srcObject = stream;
          }
        });
    }
    return () => {
      if (currentVideoRef) {
        currentVideoRef.pause();
        currentVideoRef.srcObject = null;
        currentVideoRef.load();
      }
    };
  }, []);
  return (
    <>
      <Box>
        <CameraContainer>
          <video autoPlay playsInline muted ref={videoRef} />
        </CameraContainer>
        <TakeAPhotoButton onClick={takeScreenshot}>
          <SvgCameraIcon fill={theme.colors.white} width="43px" />
        </TakeAPhotoButton>
      </Box>
      <DialogBackToContainer onClick={onBack}>
        <SvgBackIcon fill={theme.colors.main} />
        <DialogBackToText>Back</DialogBackToText>
      </DialogBackToContainer>
    </>
  );

  function takeScreenshot() {
    // event.preventDefault();
    const canvas: any = document.createElement('canvas');
    if (videoRef.current) {
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
      onSave(canvas.toDataURL('image/webp'));
    }
  }

  function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }
}

const CameraContainer = styled(Box)`
  width: 550px;
  height: 412.5px;
  margin: 40px auto;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};

  video {
    width: 100%;
  }
`;

const TakeAPhotoButton = styled(Button)`
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.colors.main};
  border-radius: 100%;
  margin: 0 auto;
`;

export const DialogBackToText = styled(Box)`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
`;

export const DialogBackToContainer = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
  position: absolute;
  top: 44px;
  left: 40px;
`;

export default ImageUploader;
