import LeftToRightArrow from 'assets/icons/LeftToRightArrow';
import React, { useContext, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, dataSelector } from 'shared/redux/selectors';
import { ADMIN, MANAGER, WORKER } from 'shared/types';
import { getTranslatedWord, serverUploadPath } from 'shared/utils/tools';
import styled, { ThemeContext } from 'styled-components';
import Box from './Box';
import Avatar from './DataTable/Avatar';

const SwitchAccountLoader = () => {
  const theme = useContext(ThemeContext);
  const {
    persona,
    isSwitching,
    workSpace: { selected },
  } = useSelector(dataSelector);
  const { auth } = useSelector(authSelector);

  const avatar = useMemo(() => {
    switch (persona) {
      case WORKER:
        return (
          <Fragment>
            <Avatar
              name={auth?.default_company?.name}
              imageUrl={serverUploadPath(
                auth?.default_company?.thumb_nail_path || auth?.default_company?.photo || auth?.default_company?.logo,
              )}
            />
            <LeftToRightArrow fill={theme.colors.inputBorder} />
            <Avatar name={auth?.name} imageUrl={serverUploadPath(auth?.thumb_nail_path || auth?.photo)} />
          </Fragment>
        );
      case ADMIN:
        return (
          <Fragment>
            <Avatar
              name={selected?.name}
              imageUrl={serverUploadPath(selected?.thumb_nail_path || selected?.photo || selected?.logo)}
            />
            <LeftToRightArrow fill={theme.colors.inputBorder} />
            <Avatar name={auth?.name} imageUrl={serverUploadPath(auth?.thumb_nail_path || auth?.photo)} />
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Avatar name={auth?.name} imageUrl={serverUploadPath(auth?.thumb_nail_path || auth?.photo)} />
            <LeftToRightArrow fill={theme.colors.inputBorder} />
            <Avatar
              name={auth?.default_company?.name || selected?.name}
              imageUrl={serverUploadPath(
                selected?.thumb_nail_path || selected?.logo || auth?.default_company?.thumb_nail_path || auth?.photo,
              )}
            />
          </Fragment>
        );
    }
  }, [persona, auth, selected]);
  return (
    <BoxContainer isSwitching={isSwitching}>
      <div className="header">{getTranslatedWord('Switching Account')}</div>
      <AvatarContainer>{avatar}</AvatarContainer>
    </BoxContainer>
  );
};

const AvatarContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 48px;
  height: 48px;
  div {
    position: absolute;

    :first-child {
      animation-name: leftToRight;
      animation-duration: 1s;
    }

    :last-child {
      animation-name: rightToLeft;
      animation-duration: 1s;
    }
  }

  @keyframes leftToRight {
    from {
      left: -100px;
    }

    to {
      left: 0;
    }
  }

  @keyframes rightToLeft {
    from {
      left: 100px;
    }

    to {
      left: 0;
    }
  }
`;

const BoxContainer = styled(Box)<{ isSwitching?: Boolean }>`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000010 !important;
  height: 100vh;
  width: 100vw;
  display: ${({ isSwitching }) => (isSwitching ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header {
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 600;
  }
`;

export default React.memo(SwitchAccountLoader);
