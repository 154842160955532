import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatNetvisorWorkReport } from 'shared/utils/tools';
import { Worker, GroupByDate, Work } from 'shared/types/api';
import { omit } from 'lodash';

export type DateSelectItem = {
  worker_id: number;
  work_nr: string;
  random_id: string;
  work_id?: number;
  hours: number;
  formatted_readable_hours: string;
  formatted_decimal_hours: number;
  date: string;
  dimensions: string[];
};

type SelectWorkersPayload = {
  workers: Worker[];
  selectedWorkerIds: string[];
};

type SelectDatePayload = {
  date: string;
  workerId: number;
  works: Work[];
  identifier: string;
};

export type SelectedDate = {
  [key: string]: DateSelectItem[];
};

export type SelectedWorkers = {
  [key: string]: SelectedDate;
};

export type WorkerExportResponse = {
  errors: string[];
  error_ids: string[];
  success_ids: string[];
};

export type ExportResponse = {
  [key: string]: WorkerExportResponse;
};

export type ExportResponsePayload = {
  data: WorkerExportResponse;
  workerId: number;
};

export type NetvisorReportType = {
  workers: SelectedWorkers;
  exportResponse: ExportResponse;
};

export type ReportStateType = {
  shouldExport: boolean;
  loading: boolean;
  netvisor: NetvisorReportType;
};

const initialState: ReportStateType = {
  shouldExport: false,
  loading: false,
  netvisor: {
    workers: {},
    exportResponse: {},
  },
};

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setSelectedWorkers(state, action: PayloadAction<SelectWorkersPayload>) {
      if (state?.loading) return state;

      const { workers, selectedWorkerIds } = action.payload;
      const group = workers.reduce((selectedWorkers: SelectedWorkers, worker: Worker) => {
        if (!worker?.visma?.id) return selectedWorkers;

        if (worker?.work_group?.hasUnConfirmed) return selectedWorkers;

        const workerKey = `worker-${worker.id}`;
        if (!selectedWorkerIds.includes(String(worker.id))) return selectedWorkers;

        const work = (worker?.work_group?.group_by_date || []).reduce((selected: SelectedDate, byDate: GroupByDate) => {
          const identifier = `worker-${worker?.id}--date-${byDate?.date}`;
          const works = formatNetvisorWorkReport(byDate?.items, byDate?.date, worker?.id) as DateSelectItem[];
          return { ...selected, [identifier]: works };
        }, {}) as SelectedDate;

        return { ...selectedWorkers, [workerKey]: work };
      }, {} as SelectedWorkers);

      state.netvisor.workers = group;
    },
    setSelectedWorker(state, action: PayloadAction<Omit<SelectDatePayload, 'identifier'>>) {
      if (state?.loading) return state;

      const { works, date, workerId } = action.payload;
      const workerKey = `worker-${workerId}`;
      const worker = state.netvisor.workers[workerKey] ?? {};

      const identifier = `worker-${workerId}--date-${date}`;
      const formatted = formatNetvisorWorkReport(works, date, workerId) as DateSelectItem[];

      state.netvisor.workers = {
        ...state.netvisor.workers,
        [workerKey]: { ...worker, [identifier]: formatted },
      };
    },
    removeSelectedWorkerDate(state, action: PayloadAction<Pick<SelectDatePayload, 'identifier' | 'workerId'>>) {
      const { identifier, workerId } = action.payload;
      const workerKey = `worker-${workerId}`;
      const worker = state.netvisor.workers[workerKey] ?? {};

      state.netvisor.workers = {
        ...state.netvisor.workers,
        [workerKey]: omit(worker, [identifier]),
      };
    },
    resetSelectedWorkersDate(state) {
      state.netvisor.workers = initialState.netvisor.workers;
    },
    setExportResponse(state, action: PayloadAction<ExportResponsePayload>) {
      const { data, workerId } = action.payload;
      state.netvisor.exportResponse = {
        ...state.netvisor.exportResponse,
        [workerId]: data,
      };
    },
    removeExportResponse(state, action: PayloadAction<Pick<ExportResponsePayload, 'workerId'>>) {
      const { workerId } = action.payload;

      state.netvisor.exportResponse = {
        ...omit(state.netvisor.exportResponse, [workerId]),
      };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetReportState(state) {
      state.loading = false;
      state.netvisor.workers = {};
      state.netvisor.exportResponse = {};
    },
    resetExportResponse(state) {
      state.netvisor.exportResponse = {};
    },
    setShouldExport(state, action: PayloadAction<boolean>) {
      state.shouldExport = action.payload;
    },
  },
});

export const {
  setSelectedWorkers,
  setSelectedWorker,
  resetSelectedWorkersDate,
  removeSelectedWorkerDate,
  removeExportResponse,
  setExportResponse,
  setLoading,
  resetReportState,
  resetExportResponse,
  setShouldExport,
} = report.actions;

export default report.reducer;
