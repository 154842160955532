import ImageCropper from 'shared/components/ImageCropper'
import flowRight from 'lodash/flowRight'
import withDialog from 'shared/hocs/withDialog'

const Dialog = flowRight(withDialog())(ImageCropper)

Dialog.defaultProps = {
  hasContinue: false,
  dialogClassName: 'dialog--medium'
}

export default Dialog
