import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './assets/font/font.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/styles/index.scss'

import * as serviceWorker from './serviceWorker'
import WebFontLoader from 'webfontloader'

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
