import React, { Fragment, MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import Button from 'react-md/lib/Buttons/Button';

import styled from 'styled-components';
import Box from 'shared/components/Box';
import Portal from 'shared/components/Portal';

import useClickOutside from 'shared/hooks/useClickOutside';
import { useBreakpoint } from 'shared/hooks/useBreakpoint';
import SvgCheckIcon from 'assets/icons/Check';

export type MenuAction = {
  label: string | ((row: any) => string);
  icon?: ReactNode;
  onClick?: () => void;
};

export type MenuOption = {
  id?: string;
  label: string | ((row: any) => string);
  parent_id?: string;
  onClick?: (row?: any, option?: MenuOption, event?: any) => void;
  conditionalRendering?: (arg: any) => boolean; // for page table
  customIcon?: ReactNode;
  onMouseEnter?: (row?: any, option?: MenuOption, event?: any) => void;
  onMouseLeave?: (row?: any, option?: MenuOption, event?: any) => void;
  setHoverTimer?: (time: number) => void;
};

type DropDownActionProps = {
  isNavMenu?: boolean;
  selected?: string;
  children: ReactNode;
  menuOption?: MenuOption[];
  menuAction?: MenuAction;
  onSelect?: (row?: any, option?: MenuOption, event?: any) => void;
  top?: string;
  right?: string;
  closeAfterClick?: boolean;
  component?: ReactNode;
  isButtonAction?: boolean;
  isRowAction?: boolean;
  row?: any;
  showCheck?: boolean;
  mobileTop?: string;
  mobileRight?: string;
  showPreviousClicked?: boolean;
  disabled?: boolean;
  isDropdownPortalize?: boolean;
  popperPlacement?: 'top' | 'bottom';
  dropDownRef?: any;
  maxHeight?: number;
  isFromEvent?: boolean;
  offset?: any;
  hoverTimer?: MutableRefObject<number | null>;
};

function DropDownAction(props: DropDownActionProps) {
  const {
    top = '0',
    right = '0',
    isNavMenu = false,
    menuAction,
    children,
    selected = '',
    menuOption = [],
    onSelect = null,
    closeAfterClick = false,
    isButtonAction = false,
    isRowAction = false,
    showPreviousClicked = false,
    component,
    row, // for page table
    showCheck = true,
    mobileTop,
    mobileRight = '16px',
    disabled = false,
    isDropdownPortalize = true,
    popperPlacement,
    dropDownRef,
    isFromEvent = false,
    maxHeight = 472,
    offset,
    hoverTimer,
  } = props;

  const { onClick = () => null } = menuAction || {};
  const breakpoint = useBreakpoint();

  // conditionalRendering = arg => true,

  const Component = (component ? component : () => null) as React.ElementType;
  const [isOpened, setIsOpened] = useState(false);
  const [isOpendFromEvent, setIsOpenedForEvent] = useState(true);
  const [optionsRef, setOptionRef] = useState<any>(null);
  const [position, setPosition] = useState<any>({
    top,
    right,
    left: 0,
  });

  useEffect(() => {
    isFromEvent ? setIsOpenedForEvent(true) : setIsOpenedForEvent(true);
  }, [isFromEvent]);

  const isDesktop = useMemo(() => {
    return breakpoint === 'desktop';
  }, [breakpoint]);

  const [inputRef, setInputRef] = useState<any>(null);
  const [panelRef, setPanelRef] = useState<any>(null);

  const { styles, attributes } = usePopper(inputRef, panelRef, {
    placement: popperPlacement ? popperPlacement : isDesktop ? 'bottom' : 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [0, 0], // [y, x]
        },
      },
    ],
  });

  useEffect(() => {
    if (inputRef && panelRef && attributes) {
      let newPosition = { top: position.top - 10, left: position.left + 64 };

      const isReferenceHidden = attributes.popper?.['data-popper-reference-hidden'];
      const isEscaped = attributes.popper?.['data-popper-escaped'];

      if (!isReferenceHidden || !isEscaped) {
        const panelPosition = panelRef.getBoundingClientRect();
        newPosition = { top: panelPosition.y, left: panelPosition.x };
      }

      if (isReferenceHidden && isEscaped) {
        panelRef.style.transform = `translate(${newPosition.left}px, ${newPosition.top}px)`;
      }
    }
  }, [attributes, position]);

  useClickOutside({ current: optionsRef }, () => setIsOpened(false));
  useClickOutside({ current: optionsRef }, () => setIsOpenedForEvent(false));

  return (
    <DropDownContainer>
      <TriggerWrapper ref={setInputRef} $isOpendFromEvent={isFromEvent} onClick={disabled ? () => {} : handleOpen}>
        {!isRowAction && children}
        {isRowAction && <StyledButton $isOpendFromEvent={isFromEvent} icon children={children} />}
      </TriggerWrapper>
      {isFromEvent
        ? isOpendFromEvent &&
          !component &&
          (isDropdownPortalize ? (
            <Portal wrapperId="root">
              <DropDownMenuContainer
                isNavMenu={isNavMenu}
                maxHeight={maxHeight}
                className={'dropdown-menu-item-container'}
                $isButtonAction={isButtonAction}
                ref={ref => {
                  setOptionRef(ref);
                  setPanelRef(ref);
                  dropDownRef && dropDownRef(ref);
                }}
                style={styles.popper}
                {...attributes.popper}
                onMouseEnter={() => {
                  if (hoverTimer?.current) {
                    clearTimeout(hoverTimer?.current);
                  }
                }}
              >
                {menuOption?.map((option: MenuOption, index: number) => {
                  if (option?.conditionalRendering && !option.conditionalRendering(row)) return null;

                  return menuRenderer(option, false, `${option.id ? option.id : index}`);
                })}
                {menuAction && (
                  <MenuActionContainer>
                    {Boolean(menuOption?.length) && <DropDownMenuItemSpacer />}
                    <DropDownMenuItemContainer onClick={handleCloseClick('action')}>
                      <Box>{menuAction?.label}</Box>
                      {menuAction?.icon}
                    </DropDownMenuItemContainer>
                  </MenuActionContainer>
                )}
              </DropDownMenuContainer>
            </Portal>
          ) : (
            <DropDownMenuContainer
              isNavMenu={isNavMenu}
              className={'dropdown-menu-item-container'}
              style={styles.popper}
              {...attributes.popper}
              ref={setPanelRef}
            >
              {menuOption?.map((option: MenuOption, index: number) => {
                if (option?.conditionalRendering && !option.conditionalRendering(row)) return null;

                return menuRenderer(option, false, `${option.id ? option.id : index}`);
              })}
              {menuAction && (
                <MenuActionContainer>
                  {Boolean(menuOption?.length) && <DropDownMenuItemSpacer />}
                  <DropDownMenuItemContainer onClick={handleCloseClick('action')}>
                    <Box>{menuAction?.label}</Box>
                    {menuAction?.icon}
                  </DropDownMenuItemContainer>
                </MenuActionContainer>
              )}
            </DropDownMenuContainer>
          ))
        : isOpened &&
          !component &&
          (isDropdownPortalize ? (
            <Portal wrapperId="root">
              <DropDownMenuContainer
                isNavMenu={isNavMenu}
                className={'dropdown-menu-item-container'}
                $isButtonAction={isButtonAction}
                ref={ref => {
                  setOptionRef(ref);
                  setPanelRef(ref);
                  dropDownRef && dropDownRef(ref);
                }}
                style={styles.popper}
                {...attributes.popper}
              >
                {menuOption?.map((option: MenuOption, index: number) => {
                  if (option?.conditionalRendering && !option.conditionalRendering(row)) return null;

                  return menuRenderer(option, false, `${option.id ? option.id : index}`);
                })}
                {menuAction && (
                  <MenuActionContainer>
                    {Boolean(menuOption?.length) && <DropDownMenuItemSpacer />}
                    <DropDownMenuItemContainer onClick={handleCloseClick('action')}>
                      <Box>{menuAction?.label}</Box>
                      {menuAction?.icon}
                    </DropDownMenuItemContainer>
                  </MenuActionContainer>
                )}
              </DropDownMenuContainer>
            </Portal>
          ) : (
            <DropDownMenuContainer
              isNavMenu={isNavMenu}
              className={'dropdown-menu-item-container'}
              style={styles.popper}
              {...attributes.popper}
              ref={setPanelRef}
            >
              {menuOption?.map((option: MenuOption, index: number) => {
                if (option?.conditionalRendering && !option.conditionalRendering(row)) return null;

                return menuRenderer(option, false, `${option.id ? option.id : index}`);
              })}
              {menuAction && (
                <MenuActionContainer>
                  {Boolean(menuOption?.length) && <DropDownMenuItemSpacer />}
                  <DropDownMenuItemContainer onClick={handleCloseClick('action')}>
                    <Box>{menuAction?.label}</Box>
                    {menuAction?.icon}
                  </DropDownMenuItemContainer>
                </MenuActionContainer>
              )}
            </DropDownMenuContainer>
          ))}

      {isOpened && component && <Component closeMenu={setIsOpened} />}
    </DropDownContainer>
  );

  function handleCloseClick(type: 'action' | 'option', row?: any, optionId?: string, option?: MenuOption) {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (type === 'action') {
        onClick();
      }

      if (type === 'option') {
        (row?.onClick ? row.onClick : onSelect)(row, option, event);
      }

      if (closeAfterClick) {
        setIsOpened(false);
      }
    };
  }

  function handleOpen(event: React.MouseEvent<HTMLDivElement, MouseEvent> & any) {
    event.stopPropagation();
    setIsOpened(!isOpened);
    const { x, y, width } = event?.target?.getBoundingClientRect();
    setPosition({
      top: y + window.scrollY + 40,
      left: x + width / 2 - 200,
      mobileTop,
      mobileRight,
    });
  }

  function menuRenderer(option: any, isSubMenu: boolean, index: string): ReactNode {
    let subs: ReactNode = null;
    if (option.subMenu) {
      subs = option.subMenu.map((menu: any, i: string) => {
        return menuRenderer(menu, true, `${menu.id}-${index.concat(i)}`);
      });
    }
    let hoverShiftManager: number | null = null;
    return (
      <Fragment key={option?.id}>
        <DropDownMenuItemContainer
          $selected={selected === index}
          $subMenu={isSubMenu}
          onClick={handleCloseClick('option', option?.onClick && row ? row : option, index, option)}
          onMouseEnter={(event: any) => {
            if (option?.onMouseEnter) {
              if (hoverShiftManager) {
                clearTimeout(hoverShiftManager);
                hoverShiftManager = null;
              }
              option.onMouseEnter(row, option, event);
            }
          }}
          onMouseLeave={(event: any) => {
            if (option?.onMouseLeave && option?.setHoverTimer) {
              option?.setHoverTimer(
                setTimeout(() => {
                  option.onMouseLeave(row, option, event);
                }, [1000]),
              );
            }
          }}
        >
          <Box id={option?.key} style={{ color: option?.color }}>
            {typeof option?.label === 'string' ? option?.label : option?.label(row)}
          </Box>
          {showCheck && selected === index && <SvgCheckIcon />}
          {option?.customIcon && option?.customIcon}
        </DropDownMenuItemContainer>
        {selected === option.id && subs}
      </Fragment>
    );
  }
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isOpendFromEvent }) => `
    ${
      $isOpendFromEvent
        ? `
        
         display: none;
    `
        : ''
    }
  `}
`;

const DropDownContainer = styled(Box)`
  position: relative;
`;

const DropDownMenuItemSpacer = styled(Box)`
  padding: 8px 0;

  &::before {
    content: '';
    display: inline-block;
    width: 200%;
    position: absolute;
    left: -24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }
`;

const MenuActionContainer = styled(Box)`
  position: relative;
  bottom: 0;
  z-index: 21;
`;

export const DropDownMenuItemContainer = styled(Box)<{ $background?: string; $subMenu?: boolean; $selected?: boolean }>`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ theme, $subMenu }) => `
    ${
      $subMenu
        ? `
      font-size: 14px;
      line-height: 24px;
      padding-left: 16px;
      height: 32px;
    `
        : ''
    }
  `}

  & > * {
    z-index: 10;
  }

  &::before {
    content: '';
    opacity: 0.07;
    display: inline-block;
    width: 200%;
    height: 100%;
    position: absolute;
    left: -24px;

    ${({ $background, theme }) => {
      switch ($background) {
        case 'success':
          return `background: ${theme.colors.green};`;
        case 'danger':
          return `background: ${theme.colors.caution};`;
        case 'warning':
          return `background: ${theme.colors.dark_yellow};`;
        default:
          return '';
      }
    }}

    ${({ $selected, theme }) => (!$selected ? '' : `background: ${theme.colors.green};`)}
  }

  &:hover {
    &::before {
      ${({ theme }) => `
        background: ${theme.colors.gray.light_gray};
        opacity: 1;
      `}
    }
  }
`;

const DropDownMenuContainer = styled(Box)<{
  $isButtonAction?: boolean;
  isNavMenu?: boolean;
  mobileTop?: string;
  mobileRight?: string;
  maxHeight?: number;
}>`
  ${({ $isButtonAction }) => ($isButtonAction ? 'padding: 8px 16px;' : 'padding: 16px 24px;')}

  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  // z-index: 2000;
  z-index: ${({ isNavMenu }) => (isNavMenu ? '1900010' : '1900005')} !important;


  ${({ right }) => (right ? `right:${right}px;` : '')}
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}


  // min-height: 68px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  min-width: 272px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  // text-transform: capitalize;

  animation: fadeIn ease-in-out 0.2s;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(23, 34, 113, 0.07), 0px 4px 12px rgba(23, 34, 113, 0.07);
  border-radius: 6px;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
    border: 1px solid ${theme.colors.gray.medium};
    background: ${theme.colors.white};
  `}

  ${({ theme }) => theme.mediaQueries.largeDown} {
  ${({ mobileTop }) => (mobileTop ? `top: ${mobileTop};` : '')}
  ${({ mobileRight }) => (mobileRight ? `right: ${mobileRight};` : '')}
    left: unset;
  }
`;

const TriggerWrapper = styled(Box)<{ $isOpendFromEvent?: boolean }>`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px 8px 3px;
  margin-top: 8px;
  overflow: visible;
  white-space: nowrap;

  ${({ $isOpendFromEvent }) => `
    ${
      $isOpendFromEvent
        ? `
         padding: 0px !important;
        margin-top: 0px !important;
    `
        : ''
    }
  `}

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding: 0px !important;
    margin-top: 0px !important;
  }
`;

export default DropDownAction;
