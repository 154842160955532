import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import Box from 'shared/components/Box';
import styled from 'styled-components';
import { getInitial } from 'shared/utils/tools';
import moment from 'moment';

type AvatarProps = {
  imageUrl?: string;
  name?: string;
  icon?: ReactNode;
  randomizeCacheId?: Boolean;
  size?: string;
};

export default function Avatar(props: AvatarProps) {
  const { imageUrl = '', name = '', icon, randomizeCacheId, size = '40px' } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [imageUrl]);

  const imageUrlWithTime = useMemo(() => {
    if (randomizeCacheId) {
      const timestamp = moment().unix();
      return `${imageUrl}?cache-id=${timestamp}`;
    }

    return imageUrl;
  }, [randomizeCacheId, imageUrl]);

  return (
    <AvatarContainer size={size}>
      {icon}
      {imageUrl && !error && <img src={imageUrlWithTime} alt={name} onError={() => setError(true)} />}
      {(!imageUrl || error) && name && <span>{getInitial(name)}</span>}
      {(!imageUrl || error) && !name && !icon && <span>-</span>}
    </AvatarContainer>
  );
}

const AvatarContainer = styled(Box)<{ size?: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  border-radius: 100%;
  overflow: hidden;

  align-items: center;
  justify-content: center;
  display: flex;

  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray.dark};
  background: ${({ theme }) => theme.colors.gray.medium};

  img {
    width: 100%;
  }
`;
