import React, { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@react-md/progress';
import styled from 'styled-components';
import Box from 'shared/components/Box';
import useInterval from 'shared/hooks/useInterval';

export type DialogProgressProps = {
  withProgress?: boolean;
  etaInSeconds?: number;
  defaultIncrement: number;
  isProcessing: Boolean;
};

function DialogProgress(props: DialogProgressProps) {
  const { defaultIncrement, isProcessing } = props;

  const [increment, setIncrement] = useState<number>(defaultIncrement);
  const [offset, setOffset] = useState<number>(defaultIncrement * 1.5);
  const [progress, setProgress] = useState<number>(defaultIncrement);

  useEffect(() => {
    if (progress > 75) {
      setIncrement((inc: any) => {
        return inc - (progress - 75) * 0.01 * inc;
      });
    }
  }, [progress]);

  useInterval(() => {
    if (offset <= 100) {
      setOffset((prev: any) => prev + increment);
      setProgress((prev: any) => prev + increment);
    }

    if (!isProcessing) {
      setOffset(() => defaultIncrement * 1.5);
      setProgress(() => defaultIncrement);
    }

    if (!isProcessing && offset >= 88) {
      setOffset(() => 100);
      setProgress(() => 100);
    }

    if (isProcessing && offset >= 88) {
      setOffset((prev: any) => prev + 0.01 * prev);
      setProgress((prev: any) => prev + 0.01 * prev);
    }

    if (isProcessing && offset >= 98) {
      setOffset(() => 100);
      setProgress(() => 98);
    }
  }, 100);

  return (
    <ProgressContainer>
      <StyledLinearProgress
        id="determinate-linear-progress"
        key={progress}
        className="linear-progress"
        value={isProcessing ? progress : 0}
      />
    </ProgressContainer>
  );
}

const ProgressContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

const StyledLinearProgress = styled(LinearProgress)`
  margin: 0;

  &.rmd-linear-progress {
    background-color: ${({ theme }) => theme.colors.secondaries.blue};
    height: 1.5px;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .rmd-linear-progress__bar {
    display: flex;

    &--determinate {
      background-color: ${({ theme }) => theme.colors.main};
      height: 1.5px;
      z-index: 10;
    }

    &--animate {
      transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
      transition: width 0.15s, height 0.15s;
    }
  }
`;

export default DialogProgress;
