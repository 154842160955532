import React from 'react'
import { SvgIconProps } from './Home'

const SvgCalendarIconFilled = ({ fill, className }: SvgIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 6V8C5 8.53043 5.21071 9.03914 5.58579 9.41421C5.96086 9.78929 6.46957 10 7 10C7.53043 10 8.03914 9.78929 8.41421 9.41421C8.78929 9.03914 9 8.53043 9 8V6H15V8C15 8.53043 15.2107 9.03914 15.5858 9.41421C15.9609 9.78929 16.4696 10 17 10C17.5304 10 18.0391 9.78929 18.4142 9.41421C18.7893 9.03914 19 8.53043 19 8V6C19.7956 6 20.5587 6.31607 21.1213 6.87868C21.6839 7.44129 22 8.20435 22 9V11H2V9C2 8.20435 2.31607 7.44129 2.87868 6.87868C3.44129 6.31607 4.20435 6 5 6ZM22 13V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V13H22ZM17 3C17.2652 3 17.5196 3.10536 17.7071 3.29289C17.8946 3.48043 18 3.73478 18 4V8C18 8.26522 17.8946 8.51957 17.7071 8.70711C17.5196 8.89464 17.2652 9 17 9C16.7348 9 16.4804 8.89464 16.2929 8.70711C16.1054 8.51957 16 8.26522 16 8V4C16 3.73478 16.1054 3.48043 16.2929 3.29289C16.4804 3.10536 16.7348 3 17 3V3ZM7 3C7.26522 3 7.51957 3.10536 7.70711 3.29289C7.89464 3.48043 8 3.73478 8 4V8C8 8.26522 7.89464 8.51957 7.70711 8.70711C7.51957 8.89464 7.26522 9 7 9C6.73478 9 6.48043 8.89464 6.29289 8.70711C6.10536 8.51957 6 8.26522 6 8V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3V3Z"
      fill={fill ? fill : '#353B5A'}
    />
  </svg>
)

export default SvgCalendarIconFilled
