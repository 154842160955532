import React from 'react'
import { SvgIconProps } from './Home'

const SvgBackIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    fill="none"
    viewBox="0 0 18 14"
  >
    <path
      fill={fill ?? "#353535"}
      d="M16.714 5.559H4.592L7.53 2.47c.275-.31.367-.618.367-1.03 0-.412-.184-.72-.367-1.03C7.255.104 6.888 0 6.52 0c-.368 0-.643.103-.919.412L.367 5.97C.184 6.279 0 6.588 0 7c0 .412.184.72.367 1.03l5.235 5.558c.184.206.551.412.918.412.368 0 .643-.103.919-.412.275-.206.367-.617.367-1.03 0-.41-.184-.823-.367-1.029L4.592 8.441h12.122C17.45 8.441 18 7.824 18 7c0-.824-.551-1.441-1.286-1.441z"
    ></path>
  </svg>
)


export default SvgBackIcon