import React, { ReactNode, useContext } from 'react';
import withDialog from 'shared/hocs/withDialog';
import flowRight from 'lodash/flowRight';
import Button from '../Button';
import ConfirmIcon from 'assets/icons/done.svg';
import ErrorIcon from 'assets/icons/error.svg';
import styled from 'styled-components';
import Box from '../Box';

type SnackbarProps = {
  message: ReactNode;
  messageBody: ReactNode;
  type: 'success' | 'error' | 'confirm';
  onClose: Function;
  subText: string;
  description?: string;
  autohide: boolean;
  onConfirm: Function;
};

function Snackbar(props: SnackbarProps) {
  const { onClose, type, messageBody, subText, autohide, onConfirm, description } = props;
  const icon = {
    error: ErrorIcon,
    success: ConfirmIcon,
    confirm: null,
  }[type];
  const label = {
    error: 'Close',
    success: 'Done',
    confirm: 'Confirm',
  }[type];

  return (
    <ConfirmDialogBody>
      {!messageBody && icon && <Icon src={icon} $type={type} />}
      <MessageContainer></MessageContainer>
      {description && <SubText>{description}</SubText>}
      {type === 'error' && !subText && <SubText>Please try again or contact support for help</SubText>}
      {subText && <SubText>{subText}</SubText>}
      {!autohide &&
        (type === 'confirm' ? (
          <ActionContainer>
            <InformationalButton variant="danger" onClick={onConfirm}>
              {label}
            </InformationalButton>
            <InformationalButton variant="secondary" onClick={onClose}>
              Cancel
            </InformationalButton>
          </ActionContainer>
        ) : (
          <InformationalButton variant="primary" onClick={onClose}>
            {label}
          </InformationalButton>
        ))}
    </ConfirmDialogBody>
  );
}

const SnackbarDialog = flowRight(withDialog())(Snackbar);

const MessageContainer = styled.div`
  margin-top: 25px;
`;

const ActionContainer = styled.div`
  position: relative;
  bottom: 5px;
  display: flex;
  margin: auto;
  > *:not(:only-child):not(:last-child) {
    margin-right: 15px;
  }
`;

const ConfirmDialogBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000001;
`;

const Icon = styled.img<{ $type: string }>`
  margin: 40px auto ${({ $type }) => ($type === 'error' ? '30px' : '40px')};
`;

const InformationalButton = styled(Button)`
  height: 46px;
  width: 120px;
  margin-bottom: 32px;
`;

const SubText = styled(Box)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 40px;
`;

SnackbarDialog.defaultProps = {
  title: 'Confirmation',
  dialogActionsRenderer: null,
};

export default SnackbarDialog;
