import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'shared/utils/axios';

import {
  addPosition,
  addSchedule,
  confirmAllSchedules,
  confirmSchedule,
  editPosition,
  editPreset,
  editSavedShift,
  modifyPositions,
  removeBulkSchedule,
  removePosition,
  removePreset,
  removeSchedule,
  reorderPosition,
  setError,
  setManagerSchedules,
  setPositions,
  setPresets,
  setWorkerSchedules,
  unconfirmAllSchedules,
  updateManager,
  updateSchedule,
} from 'shared/redux/schedule/reducer';

import {
  Shift,
  fetchWorkerSchedule,
  fetchManagerSchedule,
  updatePreset,
  updateSavedShift,
  updateShift,
  Task,
  Absence,
  deleteSchedule,
  deletePreset,
  confirmAllScheduledEvents,
  confirmScheduledEvent,
  fetchPresets,
  fetchPositions,
  Position,
  createPosition,
  updatePosition,
  arrangePosition,
  deletePosition,
  Assign,
  assignPosition,
  Order,
  arrangePeople,
  detachPosition,
  createShift,
  createTask,
  updateTask,
  createAbsence,
  updateAbsence,
  deleteBulkSchedule,
  updateShiftManager,
  createMultiSchedules,
} from './actions';
import { EventImpl } from '@fullcalendar/core/internal';

// Schedules
function* FetchWorkerSchedules({ payload }: PayloadAction<{ id?: number; userId?: number; query: string }>) {
  const { id, userId, query } = payload;
  try {
    const { data } = yield call(axios.get, `/schedules/${id}/users/${userId}?${query}`);
    yield put(setWorkerSchedules(data));
  } catch (err) {}
}

function* FetchManagerSchedules({ payload }: PayloadAction<{ id?: number; query: string }>) {
  const { id, query } = payload;
  try {
    const { data, unfiltered } = yield call(axios.get, `/schedules/${id}?${query}`);
    yield put(setManagerSchedules({ data, unfiltered }));
  } catch (err) {}
}

function* AddMultiSchedules({ payload }: PayloadAction<{ request: any[]; companyId: any; info: any; view: any }>) {
  const { request, companyId, info, view } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/${companyId}/multiple`, { data: request });
    yield put(addSchedule({ data, presets: [], info, view }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* RemoveSchedule({ payload }: PayloadAction<{ id?: number }>) {
  try {
    yield call(axios.post, `/schedules/${Number(payload.id)}/destroy`);
    yield put(removeSchedule(payload.id));
  } catch (err) {
    yield put(setError(err));
  }
}

function* RemoveBulkSchedule({ payload }: PayloadAction<{ ids?: number[] }>) {
  try {
    yield call(axios.post, `/schedules/destroy/bulk`, { schedule_ids: payload.ids });
    yield put(removeBulkSchedule(payload.ids));
  } catch (err) {
    yield put(setError(err));
  }
}

function* ConfirmSchedule({ payload }: PayloadAction<{ event?: EventImpl; confirmed?: boolean }>) {
  const { event, confirmed } = payload;
  try {
    yield call(axios.post, `/schedules/${event?.id}/confirm`);
    yield put(confirmSchedule({ event, confirmed }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* ConfirmAllSchedules({ payload }: PayloadAction<{ ids?: number[]; events?: any[]; confirm?: boolean }>) {
  try {
    yield call(axios.post, `/schedules/confirm/all`, { schedule_ids: payload.ids, confirm: payload.confirm });
    if (payload.confirm) {
      yield put(confirmAllSchedules({ events: payload.events }));
    } else {
      yield put(unconfirmAllSchedules({ events: payload.events }));
    }
    yield put(setError(null));
  } catch (err) {
    yield put(setError(err));
  }
}

// Presets
function* FetchPresets({ payload }: PayloadAction<{ id?: number; query: string }>) {
  const { id, query } = payload;
  try {
    const { data } = yield call(axios.get, `/schedules/${id}/presets?${query}`);
    yield put(setPresets(data));
  } catch (err) {}
}

function* EditPreset({ payload }: PayloadAction<{ id?: number; request: Task }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/presets/${id}/update`, request);
    yield put(editPreset(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditSavedShift({ payload }: PayloadAction<{ id?: number; request: Shift }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/presets/${id}/update`, request);
    yield put(editSavedShift(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* RemovePreset({ payload }: PayloadAction<{ id?: number }>) {
  try {
    yield call(axios.post, `/schedules/presets/${payload.id}/destroy`);
    yield put(removePreset(payload.id));
  } catch (err) {
    yield put(setError(err));
  }
}

// Shifts
function* AddShift({ payload }: PayloadAction<{ request: Shift; info: any; view: any; event?: any }>) {
  const { request, info, view, event } = payload;
  try {
    const { data, presets } = yield call(axios.post, `/schedules/shifts`, request);
    yield put(addSchedule({ data, presets, info, view, event }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditShift({ payload }: PayloadAction<{ id?: number; request: Shift; info: any; calendar: any; event: any }>) {
  const { id, request, info, calendar, event } = payload;
  try {
    const { status, data } = yield call(axios.post, `/schedules/${id}/shifts`, request);
    yield put(updateSchedule({ type: request.action, event, info, calendar, status, data }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditShiftManager({ payload }: PayloadAction<{ request?: any; events: any[] }>) {
  const { request, events } = payload;
  const { ids, userId } = request;

  try {
    const params = { schedule_ids: ids, user_id: userId };
    const { data } = yield call(axios.post, `/schedules/shifts/managers`, params);
    yield put(updateManager({ events, data }));
  } catch (err) {
    yield put(setError(err));
  }
}

// Tasks
function* AddTask({ payload }: PayloadAction<{ request: Task; info: any; view: any; event?: any }>) {
  const { request, info, view, event } = payload;
  try {
    const { data, presets } = yield call(axios.post, `/schedules/tasks`, request);
    yield put(addSchedule({ data, presets, info, view, event }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditTask({ payload }: PayloadAction<{ id?: number; request: Task; info: any; calendar: any; event: any }>) {
  const { id, request, info, calendar, event } = payload;
  try {
    const { status, data } = yield call(axios.post, `/schedules/${id}/tasks`, request);
    yield put(updateSchedule({ type: request.action, event, info, calendar, status, data }));
  } catch (err) {
    yield put(setError(err));
  }
}

// Absences
function* AddAbsence({ payload }: PayloadAction<{ request: Absence; info: any; view: any }>) {
  const { request, info, view } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/absences`, request);
    yield put(addSchedule({ data, info, view }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditAbsence({
  payload,
}: PayloadAction<{ id?: number; request: Absence; info: any; calendar: any; event: any }>) {
  const { id, request, info, calendar, event } = payload;
  try {
    const { status, data } = yield call(axios.post, `/schedules/${id}/absences`, request);
    yield put(updateSchedule({ type: request.action, event, info, calendar, status, data: [data] }));
  } catch (err) {
    yield put(setError(err));
  }
}

// Positions
function* FetchPositions({ payload }: PayloadAction<{ id?: number }>) {
  try {
    const { data } = yield call(axios.get, `/schedules/${payload.id}/positions`);
    yield put(setPositions(data));
  } catch (err) {}
}

function* AddPosition({ payload }: PayloadAction<{ id?: number; request: Position }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/${id}/positions`, request);
    yield put(addPosition(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* EditPosition({ payload }: PayloadAction<{ id?: number; request: Position }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/positions/${id}`, request);
    yield put(editPosition(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* ReorderPosition({ payload }: PayloadAction<{ id?: number; request: Order }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/${id}/positions/reorder`, request);
    yield put(reorderPosition(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* RemovePosition({ payload }: PayloadAction<{ id?: number }>) {
  try {
    yield call(axios.post, `/schedules/positions/${payload.id}/delete`);
    yield put(removePosition(payload.id));
  } catch (err) {
    yield put(setError(err));
  }
}

// Resources
function* AssignPosition({ payload }: PayloadAction<{ id?: number; request: Assign }>) {
  const { id, request } = payload;
  const { userId, ...rest } = request;

  try {
    const { data } = yield call(axios.post, `/schedules/${id}/workers/${userId}/assign`, rest);
    yield put(modifyPositions({ data, userId, ...rest, type: 'AssignPosition' }));
  } catch (err) {
    yield put(setError(err));
  }
}

function* ReorderPeople({ payload }: PayloadAction<{ id?: number; request: Order }>) {
  const { id, request } = payload;
  try {
    const { data } = yield call(axios.post, `/schedules/${id}/workers/reorder`, request);
    yield put(reorderPosition(data));
  } catch (err) {
    yield put(setError(err));
  }
}

function* DetachPosition({ payload }: PayloadAction<{ id?: number; request: Assign }>) {
  const { id, request } = payload;
  const { userId, ...rest } = request;

  try {
    const { data } = yield call(axios.post, `/schedules/${id}/workers/${userId}/remove`, rest);
    yield put(modifyPositions({ data, userId, ...rest, type: 'DetachPosition' }));
  } catch (err) {
    yield put(setError(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(fetchWorkerSchedule.type, FetchWorkerSchedules);
  yield takeLatest(fetchManagerSchedule.type, FetchManagerSchedules);
  yield takeLatest(createMultiSchedules.type, AddMultiSchedules);
  yield takeLatest(confirmScheduledEvent.type, ConfirmSchedule);
  yield takeLatest(confirmAllScheduledEvents.type, ConfirmAllSchedules);
  yield takeLatest(deleteSchedule.type, RemoveSchedule);
  yield takeLatest(deleteBulkSchedule.type, RemoveBulkSchedule);
  yield takeLatest(fetchPresets.type, FetchPresets);
  yield takeLatest(updatePreset.type, EditPreset);
  yield takeLatest(updateSavedShift.type, EditSavedShift);
  yield takeLatest(deletePreset.type, RemovePreset);
  yield takeLatest(createShift.type, AddShift);
  yield takeLatest(updateShift.type, EditShift);
  yield takeLatest(updateShiftManager.type, EditShiftManager);
  yield takeLatest(createTask.type, AddTask);
  yield takeLatest(updateTask.type, EditTask);
  yield takeLatest(createAbsence.type, AddAbsence);
  yield takeLatest(updateAbsence.type, EditAbsence);
  yield takeLatest(fetchPositions.type, FetchPositions);
  yield takeLatest(createPosition.type, AddPosition);
  yield takeLatest(updatePosition.type, EditPosition);
  yield takeLatest(arrangePosition.type, ReorderPosition);
  yield takeLatest(deletePosition.type, RemovePosition);
  yield takeLatest(assignPosition.type, AssignPosition);
  yield takeLatest(arrangePeople.type, ReorderPeople);
  yield takeLatest(detachPosition.type, DetachPosition);
}
