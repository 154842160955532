import React from 'react'
import { SvgIconProps } from './Home'

const SvgPreferenceIcon = ({ fill, width, height }: SvgIconProps) =>
  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "16"}
      height={height ?? "16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={fill ?? "#353B5A"}
        d="M15 3h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1zM1 5h1v2c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1v2H1c-.6 0-1 .4-1 1s.4 1 1 1zM1 13h5c.6 0 1-.4 1-1s-.4-1-1-1H1c-.6 0-1 .4-1 1s.4 1 1 1zM15 11h-1V9c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h3c.6 0 1-.4 1-1v-2h1c.6 0 1-.4 1-1s-.4-1-1-1z"
      ></path>
    </svg>
  )

export default SvgPreferenceIcon
