import React, { MutableRefObject, useMemo } from 'react';
import SvgMoreActionIcon from 'assets/icons/MoreAction';
import DropDownAction, { MenuOption } from 'shared/components/Layout/NavBar/DropDownAction';

export type MoreOptionProps<T = any> = {
  row: T;
  actions: T[];
  index: number;
  rowLength: number;
  top?: string;
  dropDownRef?: any;
  icon?: any;
  isFromEvent?: boolean;
  popperPlacement?: any;
  offset?: any;
  showCheck?: boolean;
  maxHeight?: any;
  selected?: string;
  isSubDropdown?: boolean;
  hoverTimer?: MutableRefObject<number | null>;
};

export default function MoreOption(props: MoreOptionProps) {
  const {
    row,
    actions,
    rowLength,
    index,
    selected,
    top,
    offset,
    maxHeight,
    showCheck = false,
    popperPlacement = 'bottom',
    icon = null,
    isFromEvent = false,
    dropDownRef,
    hoverTimer,
  } = props;

  const computedTop = useMemo(() => (rowLength >= 3 && rowLength - 1 <= index + 1 ? '-105px' : 'unset'), [
    rowLength,
    index,
  ]);

  return (
    <DropDownAction
      closeAfterClick
      isButtonAction
      isRowAction
      menuOption={actions}
      row={row}
      onSelect={onSelect}
      top={top || computedTop}
      showCheck={showCheck}
      popperPlacement={popperPlacement}
      offset={offset}
      maxHeight={maxHeight}
      isFromEvent={isFromEvent}
      selected={selected}
      dropDownRef={dropDownRef}
      hoverTimer={hoverTimer}
    >
      {!isFromEvent && icon && icon}
      {!isFromEvent && !icon && <SvgMoreActionIcon />}
    </DropDownAction>
  );

  function onSelect(row?: any, option?: MenuOption, event?: any): void {
    option?.onClick?.(row, option, event);
  }
}
