import { genericTexts } from 'shared/utils/formats';
import { getValidationResult } from 'shared/utils/tools';
import * as yup from 'yup';
const { isRequired } = genericTexts;

export const completeRegistrationSchema = yup.object().shape({
  workspace_name: yup.string().required(isRequired),
  phone_number: yup.string(),
  first_name: yup.string().required(isRequired),
  last_name: yup.string().required(isRequired),
  email: yup.string(),
});

export type CompleteRegistrationType = yup.InferType<typeof completeRegistrationSchema>;

export function getEmptyRegistration(): CompleteRegistrationType {
  return {
    workspace_name: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    email: '',
  };
}

export function completeRegistrationValidator(data: CompleteRegistrationType) {
  return getValidationResult(data, completeRegistrationSchema);
}
