import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import Box from 'shared/components/Box';

import NavBarAction from 'shared/components/Layout/NavBar/NavBarAction';
import SvgLogoIcon from 'assets/icons/Logo';
import { _changeLocale } from 'shared/redux/data/reducer';

function AdminNavBar() {
  return (
    <NavBarContainer>
      <MenuContainer>
        <Logo to="/">
          <SvgLogoIcon />
        </Logo>
        <MenuItemContainer to="/">
          <h1>Hours</h1>
        </MenuItemContainer>
      </MenuContainer>
      <NavBarAction />
    </NavBarContainer>
  );
}

const NavBarContainer = styled(Box)`
  width: 100%;
  min-height: 72px;
  padding: 0 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => `
    background: ${theme.colors.yellow};
  `}
`;

const MenuContainer = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 72px;
  display: flex;
  align-items: center;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
  `}
`;

const Logo = styled(Link)`
  margin-right: 32px;
  display: flex;
  align-items: center;
`;
const MenuItemContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  h1 {
    margin: 0;
    color: ${({ theme }) => `${theme.colors.main}`};
    font-weight: 600;
    font-size: 28px;
  }

  span {
    :not(:last-child) {
      margin-right: 12px;
      color: ${({ theme }) => `${theme.colors.main}`};
    }

    :first-child {
      margin-left: 12px;
    }
  }
`;

export default AdminNavBar;
