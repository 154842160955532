import { createSlice } from '@reduxjs/toolkit';

import {
  fetchWorkerSchedule,
  fetchManagerSchedule,
  fetchPresets,
  updatePreset,
  updateSavedShift,
  deleteSchedule,
  deletePreset,
  confirmAllScheduledEvents,
  confirmScheduledEvent,
  fetchPositions,
  createPosition,
  updatePosition,
  arrangePosition,
  deletePosition,
  assignPosition,
  arrangePeople,
  detachPosition,
  createShift,
  updateShift,
  createTask,
  updateTask,
  addNewEvent,
  confirmEvent,
  onHandleUpdateEvent,
  createAbsence,
  updateAbsence,
  deleteBulkSchedule,
  shiftManagerEvent,
  updateShiftManager,
} from './actions';

export interface ScheduleState {
  success: any | null;
  error: any | null;
  updating: boolean;
  settingEvents: boolean;
  filteringEvents: boolean;
  schedules: {
    default: any;
    list: any;
    unconfirmed: any;
    unfiltered: any;
    fetching: boolean;
  };
  presets: {
    list: any;
    fetching: boolean;
    tasks: any;
    shifts: any;
  };
  positions: {
    list: any;
    fetching: boolean;
    max?: number;
  };
  selection: {
    shift: boolean;
    copying: boolean;
    dragging: boolean;
    bounded: boolean;
    events: any[];
    clicked: any[];
    copied: any[];
  };
}

const initialState: ScheduleState = {
  success: null,
  error: null,
  updating: false,
  settingEvents: false,
  filteringEvents: false,
  schedules: {
    default: [],
    list: [],
    unconfirmed: [],
    unfiltered: [],
    fetching: false,
  },
  presets: {
    list: [],
    fetching: false,
    tasks: [],
    shifts: [],
  },
  positions: {
    list: [],
    fetching: false,
    max: 0,
  },
  selection: {
    shift: false,
    copying: false,
    dragging: false,
    bounded: false,
    events: [],
    clicked: [],
    copied: [],
  },
};

const setScheduleData = (state: any, id: any) => {
  const defaultIdx = state.schedules.default.findIndex((item: any) => item.id == id);
  if (~defaultIdx) {
    state.schedules.default.splice(defaultIdx, 1);
  }

  const listIdx = state.schedules.list.findIndex((item: any) => item.id == id);
  if (~listIdx) {
    state.schedules.list.splice(listIdx, 1);
  }

  const unconfirmedIdx = state.schedules.unconfirmed.filter((item: any) => item.id == id);
  if (~unconfirmedIdx) {
    state.schedules.unconfirmed.splice(unconfirmedIdx, 1);
  }

  Object.entries(state.schedules.unfiltered).forEach(([index, item]: any) => {
    const unfilteredIdx = item.findIndex((item: any) => item.id == id);
    if (~unfilteredIdx) {
      state.schedules.unfiltered[index].splice(unfilteredIdx, 1);
    }
  });
};

const setUnfilteredScheduleData = (state: any, data: any) => {
  Object.entries(state.schedules.unfiltered).forEach(([index, _]: any) => {
    data.forEach((dataItem: any) => {
      const dataIdx = index == dataItem.resource_id ? index : dataItem.resource_id;
      if (!state.schedules.unfiltered[dataIdx]) {
        state.schedules.unfiltered[dataIdx] = [dataItem];
      } else {
        const existing = state.schedules.unfiltered[dataIdx].find((item: any) => item.id == dataItem.id);
        if (!existing) {
          const newData = [...state.schedules.unfiltered[dataIdx], dataItem];
          state.schedules.unfiltered[dataIdx] = newData;
        }
      }
    });
  });
};

const setConfirmedScheduleData = (state: any, id: any, confirmed: any) => {
  const listIndex = state.schedules.list.findIndex((item: any) => item.id == id);
  if (~listIndex) {
    state.schedules.list[listIndex] = {
      ...state.schedules.list[listIndex],
      confirmed: confirmed,
      editable: !confirmed,
    };
  }

  Object.entries(state.schedules.unfiltered).forEach(([index, item]: any) => {
    const unfilteredIdx = item.findIndex((item: any) => item.id == id);
    if (~unfilteredIdx) {
      state.schedules.unfiltered[index][unfilteredIdx] = {
        ...state.schedules.unfiltered[index][unfilteredIdx],
        confirmed: confirmed,
        editable: !confirmed,
      };
    }
  });
};

const updateUnfilteredData = (state: any, data: any, type: any) => {
  Object.entries(state.schedules.unfiltered).forEach(([index, item]: any) => {
    data.forEach((dataItem: any) => {
      const dataIdx = item.findIndex((o: any) => o.id == dataItem.id);
      const dataResource = item.find((o: any) => o.resource_id == dataItem.resource_id);

      if (~dataIdx) {
        if (index == dataItem.resource_id) {
          state.schedules.unfiltered[index][dataIdx] = dataItem;
        }

        if (type === 'dropping' && !dataResource) {
          state.schedules.unfiltered[index].splice(dataIdx, 1);
        }
      }
    });
  });
};

const schedule = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setResponse(state) {
      state.success = null;
      state.error = null;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdating(state, action) {
      state.updating = action.payload;
    },
    setSettingEvents(state, action) {
      state.settingEvents = action.payload;
    },
    setFilteringEvents(state, action) {
      state.filteringEvents = action.payload;
    },
    setWorkerSchedules(state, action) {
      state.schedules.default = action.payload;
      state.schedules.list = action.payload;
      state.schedules.fetching = false;
    },
    setManagerSchedules(state, action) {
      const { data, unfiltered } = action.payload;
      if (data?.length === 0) {
        state.settingEvents = false;
      }

      let newList = [...data];
      newList.forEach((event: any) => {
        if (event.pause_length > 0 && event.extendedProps?.is_all_day == 1) {
          let endDate = new Date(event.end);
          endDate.setDate(endDate.getDate() - 1);
          event.end = endDate.toISOString();
        }
      });

      const unconfirmed = newList.filter((item: any) => !item.is_preset && !item.confirmed);

      state.schedules.default = newList;
      state.schedules.list = newList;
      state.schedules.unfiltered = unfiltered;
      state.schedules.unconfirmed = unconfirmed;
      state.schedules.fetching = false;
    },
    confirmSchedule(state, action) {
      const { event, confirmed } = action.payload;
      if (!confirmed) {
        const index = state.schedules.unconfirmed.findIndex((src: any) => src.id == event.id);
        if (~index) {
          state.schedules.unconfirmed.splice(index, 1);
        }
      } else {
        state.schedules.unconfirmed = [...state.schedules.unconfirmed, event];
      }

      setTimeout(() => {
        confirmEvent(event, confirmed);
      }, 0);
    },
    confirmAllSchedules(state, action) {
      const unconfirmedEvents = action.payload.events;
      unconfirmedEvents?.forEach((event: any) => {
        setConfirmedScheduleData(state, event.id, true);
        const index = state.schedules.unconfirmed.findIndex((src: any) => src.id == event.id);
        if (~index) {
          state.schedules.unconfirmed.splice(index, 1);
        }
      });

      setTimeout(() => {
        unconfirmedEvents?.forEach((event: any) => {
          confirmEvent(event, false);
        });
      }, 0);
    },
    unconfirmAllSchedules(state, action) {
      const confirmedEvents = action.payload.events;
      confirmedEvents?.forEach((event: any) => {
        state.schedules.unconfirmed = [...state.schedules.unconfirmed, event];
        setConfirmedScheduleData(state, event.id, false);
      });

      setTimeout(() => {
        confirmedEvents?.forEach((event: any) => {
          confirmEvent(event, true);
        });
      }, 0);
    },
    removeSchedule(state, action) {
      setScheduleData(state, action.payload);
    },
    removeBulkSchedule(state, action) {
      action.payload.forEach((id: any) => {
        setScheduleData(state, id);
      });
    },
    setPresets(state, action) {
      state.presets.list = action.payload;
      state.presets.fetching = false;
      state.presets.tasks = action.payload.filter((item: any) => item.type == 'task');
      state.presets.shifts = action.payload.filter((item: any) => item.type == 'shift');
    },
    editPreset(state, action) {
      const index = state.presets.tasks.findIndex((item: any) => item.id === action.payload.id);
      if (~index) {
        state.presets.tasks[index] = action.payload;
      }
    },
    editSavedShift(state, action) {
      const index = state.presets.shifts.findIndex((item: any) => item.id === action.payload.id);
      if (~index) {
        state.presets.shifts[index] = action.payload;
      }
    },
    removePreset(state, action) {
      const index = state.presets.list.findIndex((item: any) => item.id == action.payload);
      if (~index) {
        state.presets.list.splice(index, 1);
        state.presets.tasks = state.presets.list.filter((item: any) => item.type == 'task');
        state.presets.shifts = state.presets.list.filter((item: any) => item.type == 'shift');
      }
    },
    addSchedule(state, action) {
      const { data, presets, info, view, event } = action.payload;
      state.schedules.list = [].concat(state.schedules.list, data);

      const unconfirmed = data.filter((item: any) => !item.is_preset && !item.confirmed);
      if (unconfirmed.length > 0) {
        state.schedules.unconfirmed = [].concat(state.schedules.unconfirmed, unconfirmed);
      }

      if (presets && presets.length > 0) {
        state.presets.list = [].concat(state.presets.list, presets);

        presets.forEach((item: any) => {
          if (item.type == 'shift') {
            state.presets.shifts = [...state.presets.shifts, item];
          }

          if (item.type == 'task') {
            state.presets.tasks = [...state.presets.tasks, item];
          }
        });
      }

      setUnfilteredScheduleData(state, data);

      setTimeout(() => {
        event && event.remove();
        addNewEvent(info, data, view);
        const calendarWrapper = document.querySelector('div.calendar-wrapper');
        if (calendarWrapper) {
          document.body.style.cursor = '';
          calendarWrapper.classList.remove('Pasting');
        }
      }, 0);
    },
    updateSchedule(state, action) {
      const { type, event, info, calendar, status, data } = action.payload;

      data.forEach((item: any) => {
        const index = state.schedules.list.findIndex((src: any) => src.id == item.id);
        if (~index) {
          state.schedules.list[index] = item;
        } else {
          state.schedules.list = [...state.schedules.list, item];
          state.schedules.unconfirmed = [...state.schedules.unconfirmed, item];
        }
      });

      updateUnfilteredData(state, data, type);

      setTimeout(() => {
        onHandleUpdateEvent(type, event, info, calendar, status, data);
      }, 0);
    },
    updateManager(state, action) {
      const { events, data } = action.payload;
      events.forEach((event: any) => {
        const shift_manager = data.find((manager: any) => manager.schedule_id === event.extendedProps?.schedule_id);
        const index = state.schedules.list.findIndex((src: any) => src.id == event.id);

        if (~index) {
          const newProps = state.schedules.list[index].extendedProps;
          state.schedules.list[index].extendedProps = { ...newProps, shift_manager: shift_manager };
        }
      });

      setTimeout(() => {
        events.forEach((event: any) => {
          const shift_manager = data.find((manager: any) => manager.schedule_id === event.extendedProps?.schedule_id);
          shiftManagerEvent(event, shift_manager);
        });
      }, 0);
    },
    setPositions(state, action) {
      state.positions.list = action.payload;
      state.positions.fetching = false;

      const orders = action.payload?.map((pos: any) => Number(pos.order_number));
      const maxOrder = Math.max(...orders);
      state.positions.max = orders.length > 0 ? maxOrder + 1 : 1;
    },
    modifyPositions(state, action) {
      const { data, userId, position_id, position_ids, type } = action.payload;
      state.positions.list = data;
      state.positions.fetching = false;

      const newList = [...state.schedules.list];
      // if (type === 'AssignPosition') {
      //   const filtered = newList.filter((item: any) => position_ids.include(item.position_id));
      //   state.schedules.list = filtered;
      // }

      if (type === 'DetachPosition') {
        const filtered = newList.filter((item: any) => {
          return item.position_id !== position_id && item.resource_id !== userId;
        });

        state.schedules.list = filtered;
      }

      const orders = data.map((pos: any) => Number(pos.order_number));
      const maxOrder = Math.max(...orders);
      state.positions.max = orders.length > 0 ? maxOrder + 1 : 1;
    },
    addPosition(state, action) {
      state.positions.list = [].concat(state.positions.list, action.payload);
      state.positions.max = (state.positions?.max || 0) + 1;
    },
    editPosition(state, action) {
      const index = state.positions.list.findIndex((item: any) => item.id === action.payload.id);
      state.positions.list[index] = action.payload;
    },
    reorderPosition(state, action) {
      state.settingEvents = true;

      const positionMap = new Map();
      const positionList = [...state.positions.list];
      const scheduleList = [...state.schedules.list];

      action.payload.forEach((currentPosition: any, index: number) => {
        const prevPosition = positionList[index];
        if (prevPosition.id !== currentPosition.id) {
          positionMap.set(prevPosition.id, currentPosition.id);
        }
      });

      const updatedSchedules = scheduleList.map((sched: any) => {
        const newPositionId = positionMap.get(sched?.position_id);

        if (newPositionId) {
          const activeIndex = action.payload.findIndex((item: any) => item.id === sched?.position_id);
          const activePosition = action.payload[activeIndex];
          const resourceId = [`${activePosition.order_number}-${sched.resource_id}`];
          return { ...sched, resourceId: resourceId, resourceIds: resourceId };
        }

        return sched;
      });

      state.schedules.list = updatedSchedules;
      state.schedules.default = updatedSchedules;
      state.positions.list = action.payload;
    },
    removePosition(state, action) {
      const index = state.positions.list.findIndex((item: any) => item.id == action.payload);
      if (~index) {
        state.positions.list.splice(index, 1);
      }
    },
    setSelectionShift(state, action) {
      state.selection.shift = action.payload;
    },
    setSelectionBounded(state, action) {
      state.selection.bounded = action.payload;
    },
    setSelectionDragging(state, action) {
      state.selection.dragging = action.payload;
    },
    setSelectedEvents(state, action) {
      const { data, type } = action.payload;
      if (type === 'update') {
        state.selection.events = data;
      }

      if (type === 'insert') {
        const existing = state.selection.events.find((item: any) => item == data.id);
        if (!existing) {
          state.selection.events = [...state.selection.events, data];
        } else {
          const index = state.selection.events.findIndex((item: any) => item == data.id);
          if (~index) {
            state.selection.events.splice(index, 1);
          }
        }
      }
    },
    setClickedEvents(state, action) {
      const { data, type } = action.payload;
      if (type === 'update') {
        state.selection.clicked = data;
      }

      if (type === 'insert') {
        const existing = state.selection.clicked.find((item: any) => item.id == data.id);
        if (!existing) {
          state.selection.clicked = [...state.selection.clicked, data];
        }
      }
    },
    clearClickedEvents(state) {
      state.selection.clicked = [];
    },
    setCopyingState(state, action) {
      state.selection.copying = action.payload;
    },
    setCopiedEvents(state, action) {
      state.selection.copying = true;

      const { data, type } = action.payload;
      if (type === 'update') {
        state.selection.copied = data;
      }

      if (type === 'insert') {
        const existing = state.selection.copied.find((item: any) => item == data);
        if (!existing) {
          state.selection.copied = [...state.selection.copied, data];
        }
      }
    },
    clearCopiedEvents(state) {
      state.selection.copied = [];
      state.selection.copying = false;
    },
  },
  extraReducers: {
    [fetchWorkerSchedule.type]: state => {
      state.schedules.fetching = true;
    },
    [fetchManagerSchedule.type]: state => {
      state.settingEvents = true;
      state.schedules.fetching = true;
    },
    [confirmScheduledEvent.type]: state => {
      state.success = 'Schedule confirmed successfully';
      state.error = null;
      state.updating = true;
    },
    [confirmAllScheduledEvents.type]: state => {
      state.success = 'Schedules confirmed successfully';
      state.error = null;
      state.updating = true;
    },
    [deleteSchedule.type]: state => {
      state.success = 'Schedule deleted successfully';
      state.error = null;
      state.updating = true;
    },
    [deleteBulkSchedule.type]: state => {
      state.success = 'Schedules deleted successfully';
      state.error = null;
      state.updating = true;
    },
    [fetchPresets.type]: state => {
      state.presets.fetching = true;
    },
    [updatePreset.type]: state => {
      state.success = 'Preset updated successfully';
      state.error = null;
      state.updating = true;
    },
    [updateSavedShift.type]: state => {
      state.success = 'Saved shift updated successfully';
      state.error = null;
      state.updating = true;
    },
    [deletePreset.type]: state => {
      state.updating = true;
    },
    [createShift.type]: state => {
      state.success = 'Shift created successfully';
      state.error = null;
      state.updating = true;
    },
    [updateShift.type]: state => {
      state.success = 'Shift updated successfully';
      state.error = null;
      state.updating = true;
    },
    [updateShiftManager.type]: state => {
      state.success = 'Shift manager updated successfully';
      state.error = null;
      state.updating = true;
    },
    [createTask.type]: state => {
      state.success = 'Task created successfully';
      state.error = null;
      state.updating = true;
    },
    [updateTask.type]: state => {
      state.success = 'Task updated successfully';
      state.error = null;
      state.updating = true;
    },
    [createAbsence.type]: state => {
      state.success = 'Absence created successfully';
      state.error = null;
      state.updating = true;
    },
    [updateAbsence.type]: state => {
      state.success = 'Absence updated successfully';
      state.error = null;
      state.updating = true;
    },
    [fetchPositions.type]: state => {
      state.positions.fetching = true;
    },
    [createPosition.type]: state => {
      state.success = 'Position created successfully';
      state.error = null;
      state.updating = true;
    },
    [updatePosition.type]: state => {
      state.success = 'Position updated successfully';
      state.error = null;
      state.updating = true;
    },
    [arrangePosition.type]: state => {
      state.success = 'Position reordered successfully';
      state.error = null;
      state.updating = true;
    },
    [deletePosition.type]: state => {
      state.success = 'Position deleted successfully';
      state.error = null;
      state.updating = true;
    },
    [assignPosition.type]: state => {
      state.success = 'Position assigned successfully';
      state.error = null;
      state.updating = true;
    },
    [arrangePeople.type]: state => {
      state.success = 'Position people reordered successfully';
      state.error = null;
      state.updating = true;
    },
    [detachPosition.type]: state => {
      state.success = 'Position removed successfully';
      state.error = null;
      state.updating = true;
    },
  },
});

export const {
  setResponse,
  setSuccess,
  setError,
  setUpdating,
  setSettingEvents,
  setFilteringEvents,
  setWorkerSchedules,
  setManagerSchedules,
  confirmSchedule,
  confirmAllSchedules,
  unconfirmAllSchedules,
  removeSchedule,
  removeBulkSchedule,
  setPresets,
  editPreset,
  editSavedShift,
  removePreset,
  addSchedule,
  updateSchedule,
  updateManager,
  setPositions,
  modifyPositions,
  addPosition,
  editPosition,
  reorderPosition,
  removePosition,
  setSelectionShift,
  setSelectionBounded,
  setSelectionDragging,
  setSelectedEvents,
  setClickedEvents,
  clearClickedEvents,
  setCopyingState,
  setCopiedEvents,
  clearCopiedEvents,
} = schedule.actions;

export default schedule.reducer;
