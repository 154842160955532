import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Box from 'shared/components/Box';
import useClickOutside from 'shared/hooks/useClickOutside';
import styled from 'styled-components';
import Button from 'react-md/lib/Buttons/Button';
import NotificationBody from 'shared/components/Notification';
import { UserNotification } from 'shared/types/api';
import { getTranslatedWord } from 'shared/utils/tools';
import MoreOption from 'shared/components/DataTable/MoreOption';
import SvgPreferenceIcon from 'assets/icons/Preference';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dataSelector } from 'shared/redux/selectors';
import { ADMIN } from 'shared/types';

type DropDownActionProps = {
  selected?: string;
  children: ReactNode;
  items?: UserNotification[];
  onSelect?: (row?: any, item?: UserNotification) => void;
  top?: string;
  right?: string;
  closeAfterClick?: boolean;
  component?: ReactNode;
  isButtonAction?: boolean;
  isRowAction?: boolean;
  row?: any;
  showCheck?: boolean;
  mobileTop?: string;
  showPreviousClicked?: boolean;
  actions: Object[];
  isOpenDrawer?: boolean;
};

function NotificationAction(props: DropDownActionProps) {
  const {
    top = '0',
    right = '0',
    items = [],
    children,
    isButtonAction = false,
    isRowAction = false,
    component,
    mobileTop = '0',
    actions,
    isOpenDrawer = false,
  } = props;

  const Component = (component ? component : () => null) as React.ElementType;
  const { persona } = useSelector(dataSelector);
  const optionsRef = useRef(null);
  const dropDownRef = useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside([optionsRef, dropDownRef], () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (isOpenDrawer) {
      setIsOpen(false);
    }
  }, [isOpenDrawer]);

  return (
    <DropDownContainer ref={optionsRef}>
      <TriggerWrapper onClick={handleOpen}>
        {!isRowAction && children}
        {isRowAction && <StyledButton icon children={children} />}
      </TriggerWrapper>
      {isOpen && !component && (
        <DropDownMenuContainer
          className={'dropdown-menu-item-container'}
          top={top}
          right={right}
          $isButtonAction={isButtonAction}
          mobileTop={mobileTop}
        >
          <NotificationHeader isEmpty={items.length <= 0}>
            <HeaderLabel>{getTranslatedWord('Notifications')}</HeaderLabel>
            <ActionContainer>
              {persona !== ADMIN && (
                <PreferenceLink key="settings" to={'/preference/notifications'}>
                  <SvgPreferenceIcon />
                </PreferenceLink>
              )}
              {items.length > 0 && (
                <MoreOptionContainer>
                  <MoreOption
                    dropDownRef={(ref: any) => {
                      dropDownRef.current = ref;
                    }}
                    row={items[0]}
                    actions={actions}
                    index={1}
                    rowLength={1}
                  />
                </MoreOptionContainer>
              )}
            </ActionContainer>
          </NotificationHeader>
          <NotificationBody
            dropDownRef={(ref: any) => {
              dropDownRef.current = ref;
            }}
            items={items}
            setIsOpen={setIsOpen}
          />
        </DropDownMenuContainer>
      )}

      {isOpen && component && <Component closeMenu={setIsOpen(false)} />}
    </DropDownContainer>
  );

  function handleOpen(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    setIsOpen(!isOpen);
  }
}

const NotificationHeader = styled(Box)<{ isEmpty?: boolean }>`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background: white;
  padding: 16px 24px 8px 24px;
  font-weight: 500;
  padding-bottom: ${({ isEmpty }) => (isEmpty ? '16px' : '0px')}
    ${({ theme }) => `
    color: ${theme.colors.black};

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-bottom: 1px solid ${theme.colors.gray.medium};
      width: 200%;
      bottom: 0;
      left: -24px;
    };
  `};
`;

const HeaderLabel = styled.div`
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropDownContainer = styled(Box)`
  position: relative;
`;

const ActionContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MoreOptionContainer = styled(Box)`
  display: block;
  justify-content: flex-end;
  width: 32px;
  z-index: 2000;
  background: transparent;
`;

const DropDownMenuContainer = styled(Box)<{ $isButtonAction?: boolean; mobileTop?: string }>`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 1900010;
  right: ${({ right }) => right};
  top: ${({ top }) => top};

  // min-height: 68px;
  max-height: 680px;
  min-width: 272px;
  max-width: 454px !important;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  // text-transform: capitalize;

  animation: fadeIn ease-in-out 0.2s;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(23, 34, 113, 0.07), 0px 4px 12px rgba(23, 34, 113, 0.07);
  border-radius: 6px;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
    border: 1px solid ${theme.colors.gray.medium};
    background: ${theme.colors.white};
  `}

  ${({ theme }) => theme.mediaQueries.largeDown} {
    top: ${({ mobileTop }) => mobileTop};
  }
`;

const TriggerWrapper = styled(Box)`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px 8px 3px;
  margin-top: 8px;
  overflow: visible;
  white-space: nowrap;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding: 0px !important;
    margin-top: 0px !important;
  }
`;

const PreferenceLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  &:hover {
    background: rgba(153, 153, 153, 0.12);
  }
`;

export default NotificationAction;
