import React, { ReactNode } from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import DefaultTitle, { DialogTitleProps } from './Title';
import DefaultActions, { DialogActionsProps } from './Actions';
import pick from 'lodash/pick';
import styled from 'styled-components';
import classnames from 'classnames';

export type DialogLayoutProps = {
  children: ReactNode;
  dialogTitleRenderer?: React.FC<DialogTitleProps>;
  dialogActionsRenderer?: React.FC<DialogActionsProps> | null;
  dialogClassName?: string;
  dialogClass?: string;
  dialogId?: string;
  theme: string;
  width?: string | number;
  height?: string | number;
  variant?: string;
  hasClose?: boolean;
  withActions?: boolean;
} & DialogActionsProps &
  DialogTitleProps &
  typeof defaultProps;

const defaultProps = {
  dialogTitleRenderer: DefaultTitle,
  dialogActionsRenderer: DefaultActions,
  dialogClassName: '',
  titleClassName: '',
  contentClassName: '',
  footerClassName: '',
  dialogContainerClassName: '',
  dialogId: '',
  theme: 'theme-admin',
  autopadContent: false,
};

function DialogLayout(props: DialogLayoutProps) {
  const {
    children,
    dialogTitleRenderer: DialogTitle,
    dialogActionsRenderer: DialogActions,
    dialogClassName,
    dialogContainerClassName,
    dialogId,
    titleClassName,
    footerClassName,
    title,
    width,
    height,
    withActions = true,
    hasClose = true,
  } = props;

  return (
    <StyledDialogContainer
      visible
      portal
      modal
      autosizeContent={false}
      autopadContent={false}
      id={`${dialogId}_dialog`}
      renderNode={document.body}
      dialogClassName={classnames('dialog', { [dialogClassName]: dialogClassName })}
      className={`dialog__container ${dialogContainerClassName}`}
      title={
        title ? <DialogTitle {...pick(props, ['title', 'onClose', 'onMinimize', 'minimize', 'subtitle'])} /> : null
      }
      titleClassName={classnames('dialog__title', { [titleClassName]: titleClassName })}
      footerClassName={classnames('dialog__footer', { [footerClassName]: footerClassName })}
      focusOnMount={false}
      disableScrollLocking={true}
      hasClose={hasClose}
      withActions={withActions}
      actions={
        DialogActions ? (
          <DialogActions
            {...pick(props, [
              'onContinue',
              'onCancel',
              'onExtra',
              'isProcessing',
              'role_level',
              'continueLabel',
              'cancelLabel',
              'extraLabel',
              'hasContinue',
              'hasCancel',
              'hasExtra',
              'hasClose',
              'continueIsDisabled',
              'context',
              'variant',
            ])}
          />
        ) : null
      }
      height={height}
      width={width}
    >
      {children}
    </StyledDialogContainer>
  );
}

const StyledDialogContainer = styled(DialogContainer)<{ hasClose?: boolean; withActions?: boolean }>`
  background: rgba(0, 0, 0, 0.7);
  .dialog {
    min-width: ${({ width }) => (width ? width : '480px')};
    min-height: ${({ height }) => (height ? height : '200px')};
    box-shadow: none;
    // border-radius: 0;
    border-radius: 6px;
    letter-spacing: 0px !important;

    .md-title {
      letter-spacing: 0px !important;
    }

    &--medium {
      width: 610px;
    }

    &--medium-1 {
      width: 510px;
    }

    &--large {
      width: 860px;
    }

    &--extraLarge {
      width: 900px;
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
      color: ${({ theme }) => theme.colors.secondary};

      .i_dialog_title-close {
        display: ${({ hasClose }) => (hasClose ? 'block' : 'none')};
        position: fixed;
        right: 0;
        margin-right: 10px;
      }

      &--small {
        font-size: 20px;
      }

      &--no-capitalize {
        text-transform: none;
      }

      &--custom {
        padding: 0;
      }
    }

    &__footer {
      display: ${({ withActions }) => (withActions ? 'flex' : 'none')} !important;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 40px 40px 40px;

      &--less-padding {
        padding: 30px;
      }
    }

    &.check-in-members-dialog {
      top: 0;
      left: 0;
      transform: unset;
      width: 550px;
      height: 100%;
      max-height: unset;
      border-radius: 0;

      &-body {
        height: calc(100vh - 136px);
      }

      .dialog__title {
        display: none;
      }
    }
  }

  .md-dialog-content {
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #f5f5f5;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.label};
      border-radius: 50px;
    }
  }

  ${({ theme }) => theme.mediaQueries.largeDown} {
    .md-dialog-content {
      width: 100%;
    }

    .dialog {
      min-height: 100vh;
      min-width: 100vw;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .dialog__title {
        border: none;
        margin-top: 16px;
      }
    }
  }
`;

DialogLayout.defaultProps = defaultProps;

export default DialogLayout;
