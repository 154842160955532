import React, { Fragment, useEffect } from 'react';
import { flowRight, isEmpty } from 'lodash';

import withDrawer from 'shared/hocs/withDrawer';
import AlertComponent, { AlertContainer } from 'shared/components/Alert';
import TextField from 'shared/components/TextField';

import useQuery from 'shared/hooks/useQuery';

import { getTranslatedWord } from 'shared/utils/tools';

import { AddBonusDrawerProps } from './types';
import { BonusDrawerContainer } from './styledComponents';

const RequestDrawer = ({
  formState: { fields, errors },
  formHandlers: { onElementChange, onChange, onSetForm },
  workId,
  origBonus,
}: AddBonusDrawerProps) => {
  const [queryState, queryHandler] = useQuery<any>(
    {
      url: `/works/${workId}/bonus/request`,
    },
    {
      initialData: null,
    },
  );

  const isApproved = Boolean(queryState?.data?.data?.is_approved);

  useEffect(() => {
    if (queryState?.data)
      onSetForm({
        bonus: queryState?.data?.data?.bonus,
        bonus_comment: queryState?.data?.data?.bonus_comment,
      });
  }, [queryState?.data]);

  return (
    <BonusDrawerContainer>
      {isApproved && (
        <AlertContainer marginBottom="32px">
          <AlertComponent type="warning" text={getTranslatedWord('Bonus has already been approved.')} />
        </AlertContainer>
      )}
      {queryState?.loading && <div className="spinner spinner-settings" />}
      {!queryState?.loading && (
        <Fragment>
          <TextField
            id="orig-bonus"
            label={getTranslatedWord('Original requested amount')}
            labelId="bonus-request-orig-bonus"
            value={Number(origBonus) || ''}
            marginBottom="32px"
            step={1}
            disabled
          />
          <TextField
            id="bonus"
            label={getTranslatedWord('New amount')}
            labelId="bonus-request-bonus"
            onChange={e => {
              if (isEmpty(e)) onChange('bonus', '');
              if (/^[0-9\b]+$/.test(String(e))) {
                onChange('bonus', e);
              }
            }}
            errorText={getTranslatedWord(errors.bonus)}
            error={!!errors.bonus}
            value={Number(fields?.bonus) || ''}
            placeholder={getTranslatedWord('New amount')}
            marginBottom="32px"
            step={1}
          />
          <TextField
            id="bonus_comment"
            label={getTranslatedWord('Bonus comment')}
            labelId="bonus-request-bonus_comment"
            onChange={onElementChange}
            errorText={getTranslatedWord(errors.bonus_comment)}
            error={!!errors.bonus_comment}
            value={fields?.bonus_comment || ''}
            placeholder={getTranslatedWord('Comment')}
            marginBottom="32px"
            rows={4}
          />
        </Fragment>
      )}
    </BonusDrawerContainer>
  );
};
const BonusRequestDrawer = flowRight(withDrawer())(RequestDrawer);

export default BonusRequestDrawer;
