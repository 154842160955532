import day from 'dayjs';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
// @ts-ignore
import * as jsonexport from 'jsonexport/dist';
import fileSaver from 'file-saver';
import pick from 'lodash/pick';
import setHours from 'date-fns/setHours';
import setSeconds from 'date-fns/setSeconds';
import setMinutes from 'date-fns/setMinutes';
import setYear from 'date-fns/setYear';
import setMonth from 'date-fns/setMonth';
import setDate from 'date-fns/setDate';
import isSameDay from 'date-fns/isSameDay';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { SurveyObjectForm } from 'shared/types';
import moment from 'moment';

import { formatDuration, formatDurationToDecimal, getPrefix } from 'shared/utils/formats';
import { translationsDefault } from 'shared/utils/defaults';

import { Work } from 'shared/types/api';
import store from 'shared/redux/store';
import { _overrideTranslationValue } from 'shared/redux/data/reducer';

export function getValidationResult<T>(data: T, schema: any) {
  try {
    schema.validateSync(data, { abortEarly: false, allowUnknown: true });
    return {
      errors: {},
      isValid: true,
    };
  } catch (err) {
    const errors = err.inner.reduce((acc: any, el: any) => {
      const { path, message } = el;
      acc[path] = message;
      return acc;
    }, {});
    return { isValid: false, errors };
  }
}

export function toTitleCase(phrase: string = '') {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatMonthYearToISO(data: any, keys = ['start_date', 'end_date']) {
  return keys.reduce(
    (acc, key) => {
      const value = acc[key];
      acc[key] = value
        ? day(value)
            .date(30)
            .toISOString()
        : '';
      return acc;
    },
    { ...data },
  );
}

export function formatDateToHumanReadable(dateString: any) {
  const date = moment(dateString);
  const today = moment();

  if (date.isSame(today, 'day')) {
    return 'today';
  }

  if (date.isSame(today.subtract(1, 'days'), 'day')) {
    return 'yesterday';
  }

  const daysAgo = today.diff(date, 'days') + 1;
  return `${daysAgo} days ago`;
}

export function formatISOToDate(data: any, keys = ['start_date', 'end_date'], format = 'YYYY-MM') {
  return keys.reduce(
    (acc, key) => {
      const value = acc[key];
      acc[key] = value ? day(value).format(format) : '';
      return acc;
    },
    { ...data },
  );
}

export function formatDateToISO(data: any, keys = ['start_date', 'end_date'], format = 'YYYY-MM') {
  return keys.reduce(
    (acc, key) => {
      const value = acc[key];
      acc[key] = value ? day(value, format).toISOString() : '';
      return acc;
    },
    { ...data },
  );
}
export async function delay(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function fieldIsRequired(params: any) {
  const { label, path } = params;
  const display =
    label ||
    path
      .split('.')
      .pop()
      .split('_')
      .filter((e: any) => e !== 'id')
      .map(capitalize)
      .join(' ');
  return getTranslatedWord(`${display} is required`);
}

export function fieldIsInvalid(params: any) {
  const { label, path } = params;
  const display =
    label ||
    path
      .split('.')
      .pop()
      .split('_')
      .filter((e: any) => e !== 'id')
      .map(capitalize)
      .join(' ');
  return getTranslatedWord(`${display} is invalid`);
}

export function sanitizeISODate(newDate: Date | null): string {
  return newDate ? newDate.toISOString() : '';
}

export function formatDateRangeDisplay(startKey: string, endKey: string, row: any, format?: string) {
  format = format || 'MM/DD/YYYY';
  return `${day(get(row, startKey, '')).format(format)} - ${day(get(row, endKey, '')).format(format)}`;
}

export function formatTimeRangeDisplay(start_key: string, end_key: string, row: any) {
  const startTimeFormat =
    day(get(row, start_key, '')).format('A') === day(get(row, end_key, '')).format('A') ? 'h:mm' : 'h:mm A';
  return `${day(get(row, start_key, '')).format(startTimeFormat)} \u2014 ${day(get(row, end_key, '')).format(
    'h:mm A',
  )}`;
}

export function sanitizeDate(date?: string) {
  return date ? new Date(date) : null;
  // const q = moment(date)
  // const year = q.year()
  // const month = q.month()
  // const day = q.day()
  // const hour = q.hour()
  // const minute = q.minute()
  // const second = q.second()

  // return date ? new Date(year, month, day, hour, minute, second) : null
}

export const ObjectId = (h = 16, s = (s: number): string => Math.floor(s).toString(h)) =>
  s(Date.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(Math.random() * h));

export function exportCsv(
  data: object[],
  fields: string[],
  filename: string,
  options?: object,
  fortted: boolean = false,
) {
  return new Promise((resolve, reject) => {
    jsonexport(fortted ? data : data.map(e => pick(e, fields)), options || {}, (err: Error, csv: any) => {
      if (err) {
        reject(err);
      }
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      fileSaver.saveAs(blob, `${day().format('MM-DD-YYYY')}_${filename}.csv`);
      resolve(true);
    });
  });
}

export function getHour(hour: number, date: Date = new Date()) {
  return setSeconds(setHours(setMinutes(new Date(date), 0), hour), 0);
}

export function copyDateOnly(start: string, end: string) {
  const start_date = new Date(start);
  const end_date = new Date(end);
  const new_end = setYear(
    setMonth(setDate(end_date, start_date.getUTCDate()), start_date.getUTCMonth()),
    start_date.getUTCFullYear(),
  );
  if (!isSameDay(start_date, new_end)) {
    return setYear(setMonth(setDate(end_date, start_date.getDate()), start_date.getMonth()), start_date.getFullYear());
  }
  return new_end;
}
export function updateQuestionsName(form: SurveyObjectForm) {
  const newForm = cloneDeep(form);

  let questionCtr = 1;
  const { title } = form;
  newForm.pages = sortBy(newForm.pages, ['name']);
  newForm.pages.forEach(page => {
    page.elements?.forEach(element => {
      element.name = `${questionCtr}`;

      if (element.type !== 'textbox') {
        questionCtr++;
      }
    });
  });
  return {
    newForm,
    totalQuestions: questionCtr - 1,
  };
}

export function getInitial(string = '') {
  [string] = string.split('@');
  const initials = string.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: any[] = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
}

export function getTimeDiff(end: string | moment.Moment, start: string | moment.Moment) {
  const seconds = moment(end).diff(moment(start), 'seconds');
  const minutes = moment(end).diff(moment(start), 'minutes');
  const duration = moment.duration(seconds, 'seconds');
  const momentFormat = moment.utc(duration.asSeconds());
  return { seconds, minutes, duration, momentFormat };
}

export function disectDate(input: Date) {
  const date = input.toLocaleString('en-US', { hour12: false }).split(' ');
  const time = date[1];
  const mdy = date[0];
  const [hours, minutes, seconds] = time.split(':');
  const [month, day, year] = mdy.split('/');

  return { year, month, day, hours, minutes, seconds };
}

export function toDateTime(secs: number) {
  const date = new Date(1970, 0, 1); // Epoch
  date.setSeconds(secs);
  return date;
}

export function durationAsString(start: string, end: string) {
  const duration = moment.duration(moment(end).diff(moment(start)));

  // asDays returns float but we are interested in full days only
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes() + 1;
  const momentFormat = moment(`${hours}:${minutes}`, 'hh:mm');

  return { days, hours, minutes, momentFormat };
}

export function getCoords(t: any) {
  const { coordinates_start, coordinates_end } = t;

  let start: any = null;
  let end: any = null;
  let center: any = null;

  if (coordinates_start) {
    const startPoint = coordinates_start.split(',') || [];

    start = {
      lat: Number(startPoint[0]) || 0,
      lng: Number(startPoint[1]) || 0,
      variant: 'green',
    };
  }

  if (coordinates_end) {
    const endPoint = coordinates_end.split(',') || [];

    end = {
      lat: Number(endPoint[0]) || 0,
      lng: Number(endPoint[1]) || 0,
      variant: 'red',
    };
  }

  if (start && end) {
    center = {
      lat: (start.lat + end.lat) / 2,
      lng: (start.lng + end.lng) / 2,
    };
  } else if (!start && end) {
    center = {
      lat: end.lat,
      lng: end.lng,
    };
  } else if (start && !end) {
    center = {
      lat: start.lat,
      lng: start.lng,
    };
  }

  return { start, end, center };
}

export function getResponseMessage(response: any, messageType: string = 'error') {
  let message = messageType === 'success' ? 'Success' : 'Something went wrong';

  if (typeof response?.data?.message === 'string') {
    message = response?.data?.message;
  }

  if (typeof response?.message === 'string') {
    message = response?.message;
  }

  if (typeof response?.data?.message === 'object') {
    message = Object.entries(response?.data?.message).reduce((text, [key, value]: any) => {
      return (text += `${value}\n`);
    }, '');
  }

  return getTranslatedWord(message);
}

export function formatNetvisorWorkReport(works: Work[], date?: string, worker_id?: number) {
  return (works || [])?.map((work: Work) => {
    let dimensions = [] as string[];

    try {
      const product_dimensions =
        work?.decoded_visma_dimensions || work?.work_type?.decoded_visma_dimensions?.[work?.division_id];
      dimensions =
        values(product_dimensions)?.map((dimension: any) =>
          filter(values(pick(dimension, ['dimension_name', 'name', 'father_id']))).join(' | '),
        ) ?? [];
    } catch (error) {}

    return {
      date,
      worker_id,
      dimensions,
      random_id: work?.random_id,
      work_nr: work?.product_nr,
      work_id: work?.id,
      hours: work?.work_duration,
      formatted_readable_hours: formatDuration(work?.work_duration),
      formatted_decimal_hours: formatDurationToDecimal(work?.work_duration),
      daily_allowance: work?.daily_allowance,
      compensation: work?.compensation,
    };
  });
}

export function formatMeritPalkExportReport(works: Work[], date?: string, worker_id?: number) {
  return (works || [])?.map((work: Work) => {
    return {
      date,
      worker_id,
      random_id: work?.random_id,
      work_nr: work?.product_nr,
      work_id: work?.id,
      work_name: work?.workName,
      hours: work?.work_duration,
      formatted_readable_hours: formatDuration(work?.work_duration),
      formatted_decimal_hours: formatDurationToDecimal(work?.work_duration),
      daily_allowance: work?.daily_allowance,
    };
  });
}

export function serverUploadPath(string: string = '') {
  const slash = string?.charAt(0) === '/' ? '' : '/';
  const url = '/api' + slash + string;

  if (url === '/api' || url === '/api/') return;

  return url;
}

// export function getTranslatedWorkByKey() {
//   const { data } = store.getState()
//   const { translation } = data
//   // let payload: any = clone(translation)
//   // if (overridValue && locale === translation.currentLocale) {
//   //   store.dispatch(
//   //     _overrideTranslationValue({
//   //       locale,
//   //       key,
//   //       value: overridValue,
//   //     }),
//   //   )
//   // }

//   return get(translation?.current, key ? key : defaultLabel || '', defaultLabel)
// }

export function getTranslatedWord(word: any, identifierKey?: string, parameters?: { [key: string]: any }) {
  if (!store) return word;

  const { data } = store.getState();
  const { translation } = data;

  let cachedLocale: string = localStorage.getItem('locale') || 'en';

  const validTranslations =
    translation?.data['en']?.translation === null || translation?.data['en']?.translation === undefined
      ? translationsDefault()
      : translation?.data;

  let words: string = '';
  let keys = Object.keys(
    validTranslations[localStorage.getItem('locale') || translation.currentLocale || 'en']?.translation || {},
  );

  if (!validTranslations[cachedLocale]?.translation) {
    return word;
  }

  let baseLanguage = validTranslations['en'].translation;
  let identifier: string | undefined = '';
  keys.map(key => {
    if (key.trim().toUpperCase() === word?.trim().toUpperCase()) {
      identifier = key;
    } else if (
      baseLanguage[key] &&
      typeof baseLanguage[key] === 'string' &&
      baseLanguage[key].trim().toUpperCase() === word?.trim().toUpperCase()
    ) {
      identifier = key;
    }
  });

  words = get(validTranslations[cachedLocale]?.translation, identifier ? identifier : word, word);

  if (parameters) {
    const keys = Object.keys(parameters);
    keys.map((key: string) => {
      words = words.replace(`:${key}`, String(parameters?.[key]));
    });
  }

  return words;
}

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

export function capitalizeFirstChar(str: String) {
  const formatted = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  return formatted;
}

export function consoleLogger(...data: any[]) {
  if (process.env.NODE_ENV === 'production') return;

  return console.log(...data);
}

export function isWithin24Hours(date?: Date) {
  return Math.abs(moment(date).diff(moment(), 'hour')) < 24;
}

export function isWithinThisWeek(date?: Date) {
  return moment(date).isSame(moment(), 'week');
}

export function getDateOfBirthFromIdCode(id_code: string) {
  if (id_code.includes('-')) {
    const splitValue = id_code?.split('-') || [];
    const dob: any[] = splitValue;
    if (dob.length === 0) {
      return '';
    }
    const date = moment(dob[0], 'DDMMYY').local();
    return date.isValid() ? date : '';
  }

  const index = id_code?.charAt(0);
  const prefix = getPrefix(index);
  const year = id_code?.substring(1, 3);
  const month = id_code?.substring(4, 5);
  const day = id_code?.substring(6, 7);

  const dob = `${prefix}${year}-${month}-${day}`;
  if (isEmpty(dob)) {
    return '';
  }

  const date = moment(dob, 'YYYYY-MM-DD').local();
  return date.isValid() ? date : '';
}

export function getNotificationType(type: String) {
  switch (type) {
    case 'App\\Notifications\\SystemNotifications':
      return 'system';
    case 'App\\Notifications\\CompanyNotifications':
      return 'company';
    case 'App\\Notifications\\WorkerOvertime':
      return 'overtime';
    case 'App\\Notifications\\WorkerInWorkspace':
    case 'App\\Notifications\\WorkerOutWorkspace':
      return 'workspace';
    case 'App\\Notifications\\AutomaticStartMobile':
    case 'App\\Notifications\\AutomaticEndMobile':
      return 'automobile';
    case 'App\\Notifications\\AutomaticPausePreference':
    case 'App\\Notifications\\AutomaticResumePreference':
    case 'App\\Notifications\\AutomaticEndPreference':
      return 'autopreference';
    case 'App\\Notifications\\BonusAdded':
    case 'App\\Notifications\\BonusDeclined':
      return 'bonus';
    case 'App\\Notifications\\BonusRequest':
      return 'bonusrequest';
    case 'App\\Notifications\\AbsenceRequest':
      return 'absencerequest';
    case 'App\\Notifications\\AbsenceAdded':
    case 'App\\Notifications\\AbsenceDeclined':
      return 'absence';
    case 'App\\Notifications\\AbsenceRequest':
      return 'absencerequest';
    default:
      return '';
  }
}

export function getNotificationClass(type: String) {
  switch (type) {
    case 'App\\Notifications\\AbsenceAdded':
    case 'App\\Notifications\\BonusAdded':
      return 'success';
    case 'App\\Notifications\\AbsenceRequest':
    case 'App\\Notifications\\BonusRequest':
      return 'primary';
    case 'App\\Notifications\\WorkerOvertime':
      return 'warning';
    case 'App\\Notifications\\AbsenceDeclined':
    case 'App\\Notifications\\BonusDeclined':
      return 'danger';
    default:
      return '';
  }
}

export function getNotificationMessageTemplate(item: any) {
  switch (item?.type) {
    case 'App\\Notifications\\WorkerInWorkspace':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord(':name has entered :workspace at :datetime.', '', {
        name: item?.data?.name,
        workspace: item?.data?.workspace,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\WorkerOutWorkspace':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord(':name has left :workspace at :datetime.', '', {
        name: item?.data?.name,
        workspace: item?.data?.workspace,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AutomaticStartMobile':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord('Your work (:workspace - :worktype) has automatically started at :datetime.', '', {
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AutomaticEndMobile':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord('Your work (:workspace - :worktype) has automatically ended at :datetime.', '', {
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AutomaticPausePreference':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord('Your work (:workspace - :worktype) has automatically paused at :datetime.', '', {
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AutomaticResumePreference':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord('Your work (:workspace - :worktype) has automatically resumed at :datetime.', '', {
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AutomaticEndPreference':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord('Your work (:workspace - :worktype) has automatically ended at :datetime.', '', {
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AbsenceAdded':
      if (!item?.data?.manager) return item?.data?.message;
      return getTranslatedWord(':manager has :action a paid absence.', '', {
        manager: item?.data?.manager,
        action: getTranslatedWord(item?.data?.action),
      });
    case 'App\\Notifications\\BonusAdded':
      if (!item?.data?.manager) return item?.data?.message;
      return getTranslatedWord('Congrats! :manager has :action a bonus for your great work.', '', {
        manager: item?.data?.manager,
        action: getTranslatedWord(item?.data?.action),
      });
    case 'App\\Notifications\\AbsenceRequest':
      if (!item?.data?.worker) return item?.data?.message;
      return getTranslatedWord(":worker has requested a paid absence ':type' (:start - :end).", '', {
        worker: item?.data?.worker,
        type: item?.data?.type,
        start: item?.data?.start,
        end: item?.data?.end,
      });
    case 'App\\Notifications\\BonusRequest':
      if (!item?.data?.worker) return item?.data?.message;
      return (
        getTranslatedWord(':worker has requested a bonus of :bonus', '', {
          worker: item?.data?.worker,
          bonus: item?.data?.bonus,
        }) + '€.'
      );
    case 'App\\Notifications\\WorkerOvertime':
      if (!item?.data?.workspace) return item?.data?.message;
      return getTranslatedWord(':name is working overtime with work (:workspace - :worktype) at this :datetime.', '', {
        name: item?.data?.name,
        workspace: item?.data?.workspace,
        worktype: item?.data?.worktype,
        datetime: item?.data?.datetime,
      });
    case 'App\\Notifications\\AbsenceDeclined':
      if (!item?.data?.manager) return item?.data?.message;
      return getTranslatedWord(':manager has declined your request for a paid absence.', '', {
        manager: item?.data?.manager,
      });
    case 'App\\Notifications\\BonusDeclined':
      if (!item?.data?.manager) return item?.data?.message;
      return getTranslatedWord(':manager has declined your request for a bonus.', '', {
        manager: item?.data?.manager,
      });
    case 'App\\Notifications\\BillWarningNotification':
      if (!item?.data?.bill_warning) return item?.data?.message;
      return (
        getTranslatedWord(
          'Your Hours.ee invoice is unpaid. If you do not pay the bill, your account will be closed on :bill',
          '',
          {
            bill: item?.data?.bill_warning,
          },
        ) + '.'
      );
    case 'App\\Notifications\\TrialEndsNotification':
      if (!item?.data?.trial_ends_at_in_days) return item?.data?.message;
      return (
        getTranslatedWord('Your Hours trial will end in :trial days.', '', {
          trial: item?.data?.trial_ends_at_in_days,
        }) +
        ' ' +
        getTranslatedWord(
          'By continuing using the Hours software after trial, you will be billed by Hours price list.',
        ) +
        ' ' +
        'https://hours.ee/en/prices/'
      );
    default:
      return getTranslatedWord(item?.data?.message);
  }
}

export const setFormatTime = (time: any) => {
  if (typeof time == 'string') {
    return moment()
      .set({
        hour: Number(time.split(':')[0]),
        minute: Number(time.split(':')[1]),
      })
      .format('HH:mm:ss');
  }

  return moment(time).format('HH:mm:ss');
};

export const generatePauseOptions = () => {
  // const minustes = 180
  let i = 15;
  let choices: any = [];
  while (i <= 180) {
    var num = i;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var label = '';

    if (i < 60) {
      label = `${rminutes}m`;
    } else {
      label = `${rhours}h ` + (rminutes !== 0 ? `${rminutes}m` : '');
    }

    choices.push({
      value: i,
      label: label,
    });

    i += 15;
  }
  return choices;
};

export const getTimePickerValue = (time?: string) => {
  if (!time) return null;

  if (typeof time === 'string') {
    const splitValue = time.split(/[^A-Za-z0-9]+/);

    return moment().set({
      hour: parseInt(splitValue[0]),
      minute: parseInt(splitValue[1]),
    });
  }

  return moment(time);
};

/**
 * Append element to body element
 * @function createWrapperAndAppendToBody
 * @param {string} wrapperId : element id.
 */
export function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export const getDurationBetweenDates = (start: any, end: any) => {
  const fullhour = 24;

  const durationInSecs = end.clone().diff(start, 'seconds');
  const totalTime = formatDuration(durationInSecs);
  let duration = moment(totalTime, 'HH:mm');
  const hoursMins = totalTime.split(':');

  if (Number(hoursMins[0]) >= fullhour) {
    const hours = Number(hoursMins[0]);
    const remainder = hours == fullhour ? fullhour : Number(hoursMins[0]) % fullhour;
    duration = moment(`${remainder}:${hoursMins[1]}`, 'HH:mm');
  }

  return duration;
};

export const getAncestor = (element: any, levels: any) => {
  let ancestor = element;
  for (let i = 0; i < levels; i++) {
    if (!ancestor) {
      return null;
    }

    ancestor = ancestor.parentElement;
  }

  return ancestor;
};
