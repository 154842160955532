import styled from 'styled-components';
import DataTables, { TableHeader, TableRow, TableColumn } from 'react-md/lib/DataTables';

import Box from 'shared/components/Box';
import Button from 'shared/components/Button';

export const AddEditWorkTypeDrawerContainer = styled(Box)`
  &,
  .drawer-toolbar {
    padding: 32px 48px;
  }

  & > *:first-child {
    margin-top: 12px;
  }
`;

export const LabelDivider = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.main};
  height: 48px;
`;

export const LayoutButtons = styled(Button)`
  min-width: 100%;
  padding: 0;
  max-width: 40px;
`;

export const StyledTableContainer = styled(Box)`
  padding: 0 48px;
  margin-top: -25px;
  margin-bottom: 50px;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding: 0;
    margin-bottom: 140px;
  }
`;

export const StyledTableHeader = styled(TableHeader)<{ loading?: boolean }>`
  tr {
    th {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.main};

      :nth-child(2) {
        padding-left: ${({ loading }) => (loading ? '24px' : '12px')};

        ${({ theme }) => theme?.mediaQueries?.largeDown} {
          padding-left: ${({ loading }) => (loading ? '24px' : '18px')};
        }
      }
    }
  }
`;

export const StyledTableColumn = styled(TableColumn)<{ paddingIncrement?: number; isChild?: boolean; width?: string }>`
  &.md-table-column--data {
    max-width: ${({ width }) => width ?? 'unset'};

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      :nth-child(2) {
        max-width: auto;
      }
    }
  }

  &.md-table-column--adjusted {
    padding-right: 32px !important;

    :nth-child(2) {
      padding-right: 72px !important;
    }
  }

  &.md-table-column--bulk-action,
  .md-table-column--bulk-action {
    display: flex;
    align-items: center;
    & > * {
      display: inline-block;
    }

    button {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }

    .md-table-column--count {
      margin-right: 24px;
    }
  }

  :nth-child(2) {
    padding-left: ${({ paddingIncrement }) => paddingIncrement ?? 24}px;
    white-space: nowrap;
  }
`;

export const StyledDataTable = styled(DataTables)`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.label};
    border-radius: 50px;
  }

  .md-selection-control-container {
    margin-right: 0px !important;
  }

  .md-btn--icon {
    height: 42px !important;
  }

  label.md-selection-control-label {
    width: 16px;
    height: auto;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .md-table-row--active {
    background: none;
  }
`;

export const StyledTableRow = styled(TableRow)<{ hidden?: boolean; paddingIncrement?: number }>`
  th:nth-child(2) {
    padding-left: 24px;
  }

  font-weight: 700;

  &:hover {
    background-color: rgba(226, 227, 235, 0.4);
  }

  &.is-parent {
    .button-toggle {
      &.down {
        transform: rotate(0deg);
        transition: transform 0.3s linear;
      }

      &.up {
        transform: rotate(-180deg);
        transition: transform 0.3s linear;
      }
    }

    td:nth-child(2) {
      color: ${({ theme }) => theme.colors.lightGrey};
      padding-left: 12px;

      ${({ theme }) => theme?.mediaQueries?.largeDown} {
        padding-left: 18px;
      }
    }

    &.no-child {
      td:nth-child(2) {
        color: ${({ theme }) => theme.colors.black};
        padding-left: 12px;

        ${({ theme }) => theme?.mediaQueries?.largeDown} {
          padding-left: 18px;
        }
      }
    }
  }

  td.md-table-checkbox {
    width: 48px;

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      width: 32px;
    }
  }

  &.is-child {
    &.show {
      opacity: 1;
      visibility: visible;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
      transition: visibility 0s linear, opacity 0.35s linear;
    }

    &.hide {
      opacity: 0;
      visibility: collapse;
      display: none;
      border-bottom: 0px;
      transition: visibility 0.3s linear, opacity 0.3s linear;
    }

    td.md-table-checkbox {
      padding-left: ${({ paddingIncrement }) => paddingIncrement ?? 0}px;
    }

    td:nth-child(2) {
      color: ${({ theme }) => theme.colors.black};
      padding-left: 48px;
    }
  }
`;

export const VismaInputContainer = styled.div<{ isFirst: boolean }>`
  display: grid;
  grid-template-columns: ${({ isFirst }) => (isFirst ? 'auto' : '4fr 0.8fr')};

  button {
    display: ${({ isFirst }) => (isFirst ? 'none' : 'flex')};
  }
`;

export const BadgeContainer = styled(Box)`
  & > *:not(:last-child) {
    margin-right: 8px;
  }

  &.badge-status-container {
    display: flex;
  }

  &.badge-info-container {
    display: flex;
    column-gap: 0px;
    row-gap: 8px;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    &.badge-info-container {
      max-width: 400px !important;
    }
  }
`;
