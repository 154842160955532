import React from 'react';
import Button from 'shared/components/Button';
import styled from 'styled-components';
import Box from 'shared/components/Box';
import { getTranslatedWord } from 'shared/utils/tools';
import { FormHandlers } from 'shared/hooks/useForm';

export type DrawerActionsProps = {
  onContinue: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onExtra: (event?: React.MouseEvent<HTMLElement, MouseEvent>, formHandlers?: FormHandlers) => void;
  isProcessing: boolean;
  continueIsDisabled?: boolean;
  hasExtra?: boolean;
  hasCancel?: boolean;
  hasContinue?: boolean;
  cancelLabel?: string;
  extraLabel?: string;
  continueLabel?: string;
  role_level?: number;
  context?: 'action' | 'informational';
  formHandlers?: FormHandlers;
  disableDefaultAction?: boolean;
};

function DefaultActions(props: DrawerActionsProps) {
  const {
    onContinue,
    onCancel,
    onExtra,
    isProcessing,
    continueLabel,
    hasCancel,
    cancelLabel,
    hasExtra,
    extraLabel,
    hasContinue,
    continueIsDisabled,
    role_level,
    formHandlers,
    disableDefaultAction,
  } = props;

  if (disableDefaultAction) {
    return null;
  }

  return (
    <DrawerActionContainer>
      <div className="drawer-action-left">
        {hasContinue && (
          <Button
            variant="primary"
            type="button"
            disabled={continueIsDisabled}
            isLoading={isProcessing}
            onClick={onContinue}
            children={continueLabel && getTranslatedWord(continueLabel)}
          />
        )}
        {hasCancel && (
          <Button
            variant="secondary"
            type="button"
            onClick={onCancel}
            children={cancelLabel && getTranslatedWord(cancelLabel)}
          />
        )}
      </div>
      <div className="drawer-action-right">
        {hasExtra && (
          <Button
            variant="anchor"
            type="button"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExtra(e, formHandlers)}
            children={extraLabel && getTranslatedWord(extraLabel)}
          />
        )}
      </div>
    </DrawerActionContainer>
  );
}

const DrawerActionContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 10px 48px;
  justify-content: flex-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  .drawer-action-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  .drawer-action-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;

    button:not(:first-child) {
      margin-left: 8px;
    }
  }

  ${({ theme }) => theme.mediaQueries.largeDown} {
    padding: 10px 16px;
  }
`;

DefaultActions.defaultProps = {
  cancelLabel: 'Cancel',
  continueLabel: 'Save',
  extraLabel: 'Reset',
  hasCancel: true,
  hasContinue: true,
  hasExtra: false,
};

export default DefaultActions;
