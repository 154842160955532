import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FormField } from 'shared/types';
// @ts-ignore
import TextFieldMessage from 'react-md/lib/TextFields/TextFieldMessage';
import classnames from 'classnames';
import styled, { ThemeContext } from 'styled-components';
import Box from '../Box';
import { FieldLabel } from '../TextField';
import range from 'lodash/range';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import Button from '../Button';
import SvgSmallLeftArrowIcon from 'assets/icons/SmallLeftArrow';
import SvgSmallRightArrowIcon from 'assets/icons/SmallRightArrow';
import SelectAutocomplete from '../SelectAutocomplete';
import moment from 'moment';
import { getTranslatedWord } from 'shared/utils/tools';
import SvgCalendarIconFilled from 'assets/icons/CalendarFilled';

const scrollToRef = (ref: any) => {
  const { top } = ref.current.input.getBoundingClientRect();
  ref.current.input.scrollIntoView({
    behavior: 'smooth',
  });
};
type CustomDatePickerProp = Omit<ReactDatePickerProps, 'onChange'> &
  Omit<FormField, 'onChange'> & {
    onChange(arg: Date | null, id: string): void;
    icon?: ReactNode;
    marginBottom?: string | number;
    reverseIcon?: boolean;
    scrollOnFocus?: boolean;
    caution?: boolean;
    deficit?: number;
    offset?: number;
  };

function CustomDatePicker(props: CustomDatePickerProp) {
  const {
    value,
    label,
    onChange,
    id,
    error,
    className,
    icon,
    disabled,
    dateFormat,
    scrollOnFocus = true,
    caution = false,
    includeDates = undefined,
    deficit = 100,
    offset = 1,
    ...restProps
  } = props;

  moment.locale(localStorage.getItem('locale') || 'en');

  const theme = useContext(ThemeContext);
  const years = range(moment().year() - deficit, moment().year() + offset, 1);
  const months = moment.months();
  const ref = useRef<any>(null);
  const [inputDate, setInputDate] = useState('');
  const [timeValue, setTimeValue] = useState('');

  const hanldeDateInputValidation = (dateInput: string) => {
    if (dateInput && dateInput !== undefined && dateInput !== null) {
      if (dateInput.length < 3) {
        const formatted = dateInput.replace(/[^0-9]/g, '');
        if (formatted.length === 2) {
          return setInputDate(`${formatted}.`);
        }
        return setInputDate(formatted);
      }
      if (dateInput.length > 3 && dateInput.length < 6) {
        const formatted = dateInput.replace(/[^.0-9]/g, '');
        if (dateInput.length === 5) {
          const monthString = formatted.substring(3, 5);
          if (parseInt(monthString) > 12) {
            const newUpdatedDate = inputDate.substring(0, 3);
            return setInputDate(`${newUpdatedDate}0${monthString[0]}.`);
          }
          return setInputDate(`${formatted}.`);
        }
        return setInputDate(formatted);
      }

      if (dateInput.length > 6 && dateInput.length < 11) {
        const formatted = dateInput.replace(/[^.0-9]/g, '');
        return setInputDate(formatted);
      }
    }
  };

  const handleRawOnChange = (event: React.FocusEvent<HTMLInputElement>) => {
    hanldeDateInputValidation(event.target.value);
  };

  const handleFormatDateToDisplay = (dateValue: Date): void => {
    const formattedDate = moment(dateValue).format('DD/MM/YYYY');
    const time = moment(dateValue).format('HH:mm:ss');
    const day = formattedDate.substring(0, 2);
    const month = formattedDate.substring(3, 5);
    const year = formattedDate.substring(6, 10);
    const dateToDisplay = `${day}.${month}.${year}`;
    setTimeValue(time);
    setInputDate(dateToDisplay);
  };

  const handleSelectChange = (newDateValue: Date, event: any) => {
    event.preventDefault();
    if (!event.target.value || inputDate.length === 10) {
      handleFormatDateToDisplay(newDateValue);
    }
  };

  const handleKeyDownPress = (event: any) => {
    if (event.keyCode === 8) {
      const updatedString = inputDate.substring(0, inputDate.length - 1);
      setInputDate(updatedString);
    }
  };

  const getSelectedDate = () => {
    const currentDate = new Date();
    if (inputDate.length === 3) {
      const month =
        currentDate.getMonth() > 10
          ? (currentDate.getMonth() + 1).toString()
          : `0${(currentDate.getMonth() + 1).toString()}`;
      return new Date(`${month}/${inputDate[0]}${inputDate[1]}/${currentDate.getFullYear()}`);
    }
    if (inputDate.length === 10) {
      const month = inputDate.substring(3, 5);
      const yearString = inputDate.substring(6, 10);
      return new Date(`${month}/${inputDate[0]}${inputDate[1]}/${yearString}`);
    }
    if (inputDate === '') {
      return null;
    }
  };

  useEffect(() => {
    if (inputDate.length === 10) {
      const yearString = inputDate.substring(6, 10);
      const monthString = inputDate.substring(3, 5);
      const toBeSavedDate = `${monthString}/${inputDate[0]}${inputDate[1]}/${yearString}`;
      return onChange(new Date(`${toBeSavedDate} ${timeValue}`) || null, id);
    }
  }, [inputDate]);

  useEffect(() => {
    if (value) {
      handleFormatDateToDisplay(value);
    }
  }, [value]);

  return (
    <DatePickerContainer className={classnames(className)} mb={props.marginBottom}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Box>
        <StyledDatePicker
          ref={ref}
          value={inputDate}
          selected={getSelectedDate()}
          onSelect={(newDateValue, event) => handleSelectChange(newDateValue, event)}
          onChangeRaw={handleRawOnChange}
          onKeyDown={handleKeyDownPress}
          onChange={(date: any) => {
            if (date === null) {
              onChange(null, id);
              setInputDate('');
            }
          }}
          placeholderText="DD.MM.YYYY"
          dateFormat="dd.MM.yyyy"
          renderCustomHeader={CustomHeader}
          popperPlacement={'bottom-start'}
          useWeekdaysShort
          showPopperArrow={false}
          locale={localStorage.getItem('locale') || 'en'}
          caution={caution}
          includeDates={includeDates}
          onFocus={() => {
            if (ref && ref.current && scrollOnFocus) {
              scrollToRef(ref);
            }
          }}
          disabled={disabled}
          {...restProps}
        />
        <DatePickerIcon reverseIcon={props.reverseIcon}>
          {icon !== undefined ? icon : <SvgCalendarIconFilled fill={theme.colors.gray.dark} />}
        </DatePickerIcon>
      </Box>
      <TextFieldMessage errorText={getTranslatedWord(error)} error={error} />
    </DatePickerContainer>
  );

  function CustomHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) {
    const customStyles = {
      control: (styles: Object) => ({
        ...styles,
        backgroundColor: theme.colors.white,
        fontSize: '14px',
        fontWeight: 500,
        color: theme.colors.secondary,
        minHeight: '34px',
        borderRadius: theme.borderRadius,
        border: `1px solid ${theme.colors.inputBorder}`,
        outline: '0',
        boxShadow: 'none',
        ':hover': {
          border: `1px solid ${theme.colors.main}`,
          boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
        },
        active: {
          border: `1px solid ${theme.colors.main}`,
          boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
        },
        focus: {
          border: `1px solid ${theme.colors.main}`,
          boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
        },
        ':disabled': {
          border: `1px solid ${theme.colors.inputBorder}`,
          boxShadow: 'none',
        },
      }),
      option: (styles: Object, { isFocused }: any) => ({
        ...styles,
        color: theme.colors.secondary,
        backgroundColor: isFocused ? theme.colors.tableAlternate : theme.colors.white,
        minHeight: '32px',
        fontSize: '14px',
        fontWeight: 500,
      }),
      multiValue: (styles: Object) => {
        return {
          ...styles,
          backgroundColor: theme.colors.tableAlternate,
          borderRadius: theme.borderRadius,
        };
      },
    };

    return (
      <StyledCustomHeader>
        <CustomHeaderDateSelections>
          <Box width="153px">
            <SelectAutocomplete
              value={months[getMonth(date)]}
              options={months.map(option => ({
                label: option,
                value: option,
              }))}
              onChange={(value: string) => changeMonth(months.indexOf(value))}
              id="month"
              error=""
              styles={customStyles}
            />
          </Box>
          <Box width="90px">
            <SelectAutocomplete
              value={getYear(date).toString()}
              options={years.map(option => ({
                label: option.toString(),
                value: option.toString(),
              }))}
              onChange={(value: string) => changeYear(value)}
              id="year"
              error=""
              styles={customStyles}
            />
          </Box>
        </CustomHeaderDateSelections>
        <CustomHeaderDate>
          <ArrowButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <SvgSmallLeftArrowIcon fill={prevMonthButtonDisabled ? theme.colors.lightGrey : theme.colors.main} />
          </ArrowButton>
          <CustomHeaderDateText>{`${months[getMonth(date)]} ${getYear(date)}`}</CustomHeaderDateText>
          <ArrowButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <SvgSmallRightArrowIcon fill={nextMonthButtonDisabled ? theme.colors.lightGrey : theme.colors.main} />
          </ArrowButton>
        </CustomHeaderDate>
      </StyledCustomHeader>
    );
  }
}

const DatePickerContainer = styled(Box)`
  input::placeholder {
    ${({ theme }) => `
      color: ${theme.colors.gray.medium_dark};
      font-family: ${theme.fonts.regular};
      font-weight: 500;
    `}
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 9999;
    &[data-placement^='bottom'] {
      .react-datepicker {
        &__triangle {
          border-bottom-color: #ffffff;
        }
      }
    }
  }

  .react-datepicker__close-icon::after {
    background-color: unset;
    color: ${({ theme }) => theme.colors.border};
    position: relative;
    right: 30px;
    font-size: 24px;
    font-weight: 400;
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fonts.regular};
    margin: 0 auto;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(53, 53, 53, 0.2);

    &__header {
      background: none;
      border: none;
      padding: 0;
    }

    &__day-names {
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.secondary};
    }

    &__day-name {
      width: 32px;
    }

    &__month-container {
      width: 350px;
      padding: 20px 30px 30px;
    }

    &__month {
      margin: 0;
      margin-top: 17px;
    }

    &__week {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__day {
      font-size: 14px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-weight: 500;
      outline: 0;
      border-radius: 50%;

      &--today {
        background: ${({ theme }) => theme.colors.tableAlternate};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.main};
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      &--outside-month {
        color: #c9c9c9;
      }

      &--selected,
      &--keyboard-selected {
        background: ${({ theme }) => theme.colors.main};
        border-radius: 50%;
        font-weight: bold;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }

    .date-container {
      .date-bullets {
        position: absolute;
        display: flex;
        height: 6px;
        max-width: 37px;
        min-width: 17px;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%, 0);

        .event-indicator {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin: 0 auto;

          &:not(:last-child) {
            margin-right: 5px;
          }

          &.event {
            background: #000000;
          }

          &.registered {
            background: #003074;
          }

          &.dropin {
            background: #95358f;
          }

          &.partner {
            background: #3a7ab0;
          }
        }
      }
    }

    &__time-container {
      .react-datepicker {
        &__header {
          &--time {
            padding: 10px;
            font-size: 14px;
            font-weight: bold;
          }
        }
        &__time-list {
          &::-webkit-scrollbar {
            width: 8px;
            background-color: #f5f5f5;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.colors.label};
            border-radius: 50px;
          }

          &-item {
            font-size: 14px;
            font-weight: 500;

            &--selected {
              background-color: ${({ theme }) => theme.colors.main} !important;
            }
          }
        }
      }
    }
  }
`;

const DatePickerIcon = styled(Box)<{ reverseIcon?: boolean }>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  ${({ reverseIcon }) => (reverseIcon ? `right: 15px;` : `left: 15px;`)}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDatePicker = styled(DatePicker)<{ reverseIcon?: boolean; disabled?: boolean; caution?: boolean }>`
  border: 1px solid ${({ theme, caution }) => (caution ? theme.colors.caution : theme.colors.inputBorder)};
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  scroll-margin-top: 80px;
  font-weight: 500;
  // ${({ reverseIcon }) => (reverseIcon ? `  padding-right: 15px; ` : `padding-left: 15px;`)}

  ${({ reverseIcon }) =>
    reverseIcon
      ? `padding-left: 15px;
  text-align: left;
      
      `
      : `padding-right: 15px;
        text-align: center;
  `}

  color: ${({ disabled, theme }) => (disabled ? '#ccc' : theme.colors.black)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};


  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.main};
    box-shadow: 0px 0px 5px 1px ${({ theme }) => theme.colors.focusShadow};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.main};
    box-shadow: 0px 0px 5px 1px ${({ theme }) => theme.colors.focusShadow};
  }
  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    box-shadow: none;
  }
`;

const StyledCustomHeader = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 256px;
  margin: 0 auto 20px;
`;

const CustomHeaderDate = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const CustomHeaderDateSelections = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CustomHeaderDateText = styled(Box)`
  width: 180px;
`;

const ArrowButton = styled(Button)<{ disabled: boolean }>`
  height: 6px;
  ${({ disabled }) => disabled && 'background-color: transparent;'}
`;

CustomDatePicker.defaultProps = {
  error: '',
};

export default CustomDatePicker;
