import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { ToastType, setToastMessage } from 'shared/redux/app/reducer';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '../Box';

type ToastsProps = {
  autoDismiss?: boolean;
  delay?: number | null;
  toast: ToastType | null;
  withClose?: boolean;
  alwaysVisible?: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const Toasts = ({ toast, delay = 6000, withClose = true, alwaysVisible = false }: ToastsProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const dismissToast = () => {
    if (!alwaysVisible) {
      dispatch(setToastMessage(null));
    }
  };

  const getAlertStyle = () => {
    if (toast?.message) {
      switch (toast?.type) {
        case 'success':
          return {
            colors: theme.colors.green,
            backgroundColor: theme.colors.secondaries.green,
            border: `1px solid ${theme.colors.green}`,
          };
        case 'info':
          return {
            colors: theme.colors.main,
            backgroundColor: theme.colors.secondaries.blue,
            border: `1px solid ${theme.colors.main}`,
          };
        default:
          return {
            colors: theme.colors.caution,
            backgroundColor: theme.colors.secondaries.pink,
            border: `1px solid ${theme.colors.caution}`,
          };
      }
    }
  };

  return (
    <SnackbarContainer
      open={!!toast?.message}
      autoHideDuration={delay}
      onClose={dismissToast}
      message={toast?.message || ''}
      withClose={withClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      sx={{ bottom: { xs: 68, sm: 24 } }}
    >
      <Alert onClose={dismissToast} severity={toast?.type || 'success'} sx={getAlertStyle()} icon={false}>
        <AlertContainer>
          <span className="message">{toast?.message || ''}</span>
          {toast?.moreActions && toast.moreActions}
        </AlertContainer>
      </Alert>
    </SnackbarContainer>
  );
};

export default React.memo(Toasts);

const SnackbarContainer = styled(Snackbar)<{ withClose?: boolean }>`
  .MuiAlert-root {
    border-radius: 6px;

    .MuiAlert-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      height: 100%;

      .message {
        min-width: 180px;
        display: flex;
        justify-content: center;
      }
    }

    .MuiAlert-action {
      display: ${({ withClose }) => (withClose ? 'flex' : 'none')};
      align-items: center;
      padding: 0px 0px 0px 6px;
    }
  }
`;

const AlertContainer = styled(Box)`
  display: flex;
  align-items: center;
  min-height: 32px;
`;

const ActionContainer = styled(Box)`
  display: flex;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    min-height: 28px;

    > span {
      padding: 0 4px;
    }

    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.hover.blue};
      border-radius: 4px;
    }
  }

  .separator {
    width: 1px;
    border: 1px solid ${({ theme }) => theme.colors.gray.medium_dark};
    margin: 0 12px;
    min-height: 32px;
  }
`;

export const ToastStyles = styled('div')`
  .toast {
    padding: 15px;
    border-radius: 0;
    text-align: center;
  }

  .error {
    color: ${({ theme }) => {
      return theme.colors.caution;
    }};
    background: ${({ theme }) => {
      return theme.colors.secondaries.pink;
    }};
    border: 1px solid ${({ theme }) => theme.colors.caution};
  }

  .success {
    color: ${({ theme }) => {
      return theme.colors.green;
    }};
    background: ${({ theme }) => {
      return theme.colors.secondaries.green;
    }};
    border: 1px solid ${({ theme }) => theme.colors.green};
  }

  .info {
    color: ${({ theme }) => {
      return theme.colors.main;
    }};
    background: ${({ theme }) => {
      return theme.colors.secondaries.blue;
    }};
    border: 1px solid ${({ theme }) => theme.colors.main};
  }
`;
