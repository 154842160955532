import React from 'react'
import { Box as RBox } from 'reakit/Box'
import styled from 'styled-components'
import {
  backgroundImage,
  BackgroundImageProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

export type BoxProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  OverflowProps &
  BackgroundImageProps &
  BorderProps &
  PositionProps &
  TypographyProps

const Box = styled(RBox)<BoxProps>`
  position: relative;
  box-sizing: border-box;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.label};
    border-radius: 50px;
  }

  ${({ theme }) => theme.mediaQueries.largeDown} {
    &.page {
      margin-bottom: 90px;
      padding: 0;
    }
  }

  ${compose(space, color, layout, flexbox, backgroundImage, overflow, border, position, typography)}
`

export default Box
