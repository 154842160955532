import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import DashboardIcon from 'assets/icons/Dashboard';
import OvertimeIcon from 'assets/icons/Overtime';
import SvgPeopleIcon from 'assets/icons/People';
import ReportIcon from 'assets/icons/Report';
import WorkSpaceIcon from 'assets/icons/Workspace';

import { getTranslatedWord } from 'shared/utils/tools';
import { useSelector } from 'react-redux';
import { dataSelector } from 'shared/redux/selectors';

const AdminMobileNavBar = () => {
  const location = useLocation();
  const theme = useTheme();
  useSelector(dataSelector);

  const isActive = (tabName: string) => {
    return location.pathname.includes(tabName);
  };

  return (
    <Container>
      <MenuItem
        to="/admin/logins"
        style={{
          opacity: location.pathname == '/admin' || isActive('/admin/logins') ? 1 : 0.6,
          color:
            location.pathname == '/admin' || isActive('/admin/logins') ? theme.colors.main : theme.colors.gray.dark,
        }}
      >
        <OvertimeIcon
          fill={location.pathname == '/admin' || isActive('/admin/logins') ? theme.colors.main : theme.colors.gray.dark}
        />
        {getTranslatedWord('Logins')}
      </MenuItem>
      <MenuItem
        to="/admin/register-history"
        style={{
          opacity: isActive('/admin/register-history') ? 1 : 0.6,
          color: isActive('/admin/register-history') ? theme.colors.main : theme.colors.gray.dark,
        }}
      >
        <ReportIcon fill={isActive('/admin/register-history') ? theme.colors.main : theme.colors.gray.dark} />
        {getTranslatedWord('Register history')}
      </MenuItem>
      <MenuItem
        to="/admin/warnings"
        style={{
          opacity: isActive('/admin/warnings') ? 1 : 0.6,
          color: isActive('/admin/warnings') ? theme.colors.main : theme.colors.gray.dark,
        }}
      >
        <DashboardIcon fill={isActive('/admin/warnings') ? theme.colors.main : theme.colors.gray.dark} />
        {getTranslatedWord('Warnings')}
      </MenuItem>
      <MenuItem
        to="/admin/signup-history"
        style={{
          opacity: isActive('/admin/signup-history') ? 1 : 0.6,
          color: isActive('/admin/signup-history') ? theme.colors.main : theme.colors.gray.dark,
        }}
      >
        <WorkSpaceIcon fill={isActive('/admin/signup-history') ? theme.colors.main : theme.colors.gray.dark} />
        {getTranslatedWord('Sign up logs')}
      </MenuItem>
      <MenuItem
        to="/admin/accounts"
        style={{
          opacity: isActive('/admin/accounts') ? 1 : 0.6,
          color: isActive('/admin/accounts') ? theme.colors.main : theme.colors.gray.dark,
        }}
      >
        <SvgPeopleIcon fill={isActive('/admin/signup-history') ? theme.colors.main : theme.colors.gray.dark} />
        {getTranslatedWord('Accounts')}
      </MenuItem>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  min-height: 90px;
  background-color: ${({ theme }) => theme.colors.yellow};
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  z-index: 1000;

  ${({ theme }) => theme.mediaQueries.mediumDown} {
    min-height: 56px;
  }
`;

const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 16px;
  font-size: 10px;
  text-decoration: none;
  font-weight: 500;

  svg {
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
  }
`;

export default AdminMobileNavBar;
