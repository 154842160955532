import { getValidationResult } from 'shared/utils/tools';
import * as yup from 'yup';
import { genericTexts } from 'shared/utils/formats';

const { isRequired, isNumber } = genericTexts;

export const addBonusSchema = yup.object().shape({
  is_worker: yup.boolean(),
  bonus: yup
    .number()
    .typeError(isNumber)
    .required(isRequired),
  bonus_comment: yup.string().nullable(),
  manager_id: yup
    .string()
    .when('is_worker', {
      is: true,
      then: yup.string().required(isRequired),
    })
    .nullable(true)
    .notRequired(),
});

export type AddBonusType = yup.InferType<typeof addBonusSchema>;

export function getEmptyBox(): AddBonusType {
  return {
    bonus: 0,
    bonus_comment: '',
    manager_id: '',
    is_worker: false,
  };
}

export function validator(data: AddBonusType) {
  return getValidationResult(data, addBonusSchema);
}
