import React, { useContext } from 'react'
import RcPagination, { PaginationProps as RcPaginationProps } from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import classnames from 'classnames'
import styled, { ThemeContext } from 'styled-components'
import Box from '../Box'
import SvgLeftArrowIcon from 'assets/icons/LeftArrow'
import SvgRightArrowIcon from 'assets/icons/RightArrow'

interface PaginationProps {
  pageSizes: Array<number>
  page: number
  size: number
  count: number
  setSize: Function
  setPage: Function
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const theme = useContext(ThemeContext)

  return (
    <PaginationContainer>
      <Pages>
        <Box mr="40px">
          <RcPagination
            current={props.page + 1}
            defaultCurrent={props.page}
            defaultPageSize={props.size}
            pageSize={props.size}
            onChange={(current: number) => props.setPage(current - 1)}
            total={props.count}
            prevIcon={({ disabled }: any) => <SvgLeftArrowIcon fill={!disabled ? theme.colors.main : "#E0E0E0"} />}
            nextIcon={({ disabled }: any) => <SvgRightArrowIcon fill={!disabled ? theme.colors.main : "#E0E0E0"} />}
            showTitle={false}
          />
        </Box>
        <GoToPage>
          <GoToPageLabel>Go to page</GoToPageLabel>
          <GoToPageInputField
            type="text"
            placeholder="#"
            onChange={e => {
              if (e.currentTarget.value !== '' && parseInt(e.currentTarget.value) <= Math.ceil(props.count / props.size))
                props.setPage(parseInt(e.currentTarget.value) - 1)
            }}
          />
        </GoToPage>
      </Pages>
      <ViewListContainer display="flex">
        <Box fontWeight="bold">View</Box>
        <ViewList>
          {props.pageSizes.map((pageSize, index) => (
            <ViewListItem
              key={index}
              className={classnames({ 'selected': props.size === pageSize })}
              onClick={() => {
                props.setSize(pageSize)
                props.setPage(0)
              }}
            >
              {pageSize}
            </ViewListItem>
          ))}
        </ViewList>
      </ViewListContainer>
    </PaginationContainer>
  )
}

const PaginationContainer = styled(Box)`
  text-align: center;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  height: 30px;
  margin: 30px 0;
`

const Pages = styled(Box)`
  display: flex;
  align-items: center;

  .rc-pagination {
    .rc-pagination-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    .rc-pagination-next {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
    }

    .rc-pagination-item {
      min-width: 30px;
      height: 30px;
      border: 0;
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      
      a {
        color: ${({ theme }) => theme.colors.secondary};
      }

      &.rc-pagination-item-active {
        background-color: ${({ theme }) => theme.colors.main};
        border-radius: ${({ theme }) => theme.borderRadius};
        
        a {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }

    .rc-pagination-disabled {
      &.rc-pagination-prev {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
      }

      &.rc-pagination-next {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
      }
    }
  }
`

const GoToPage = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`

const GoToPageLabel = styled(Box)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-right: 10px;
`

const GoToPageInputField = styled.input`
  width: 44px;
  height: 25px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: center;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #909090;
  }
`

const ViewListContainer = styled(Box)`
  display: flex;
  align-items: center;
`

const ViewList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 14px;
  padding: 0;
`

const ViewListItem = styled.li`
  list-style: none;
  display: inline;
  margin-right: 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.main};
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  &--last {
    margin-right: 0;
  }

  &.selected {
    text-decoration: none;
  }
`

export default Pagination
