import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, putResolve, takeLatest } from 'redux-saga/effects';
import { hideAllDialogs } from 'shared/redux/app/reducer';

import {
  authorize,
  authRequest,
  LoginPayload,
  loginRequest,
  logoutRequest,
  setCsrf,
  setLoginLoading,
  unauthorize,
} from 'shared/redux/auth/reducer';
import { resetState, _setWorkingHoursFilter } from 'shared/redux/data/reducer';
import axios from 'shared/utils/axios';
import { getCookie } from 'shared/utils/tools';

function* LoginUser(action: PayloadAction<LoginPayload>) {
  try {
    // const { payload } = action
    // yield putResolve(setLoginLoading(true))
    // const response = yield call(axios.request, {
    //   url: '/auth/login',
    //   data: payload,
    //   method: 'POST',
    // })
    // yield put(authorize(response))
    // history.push('/')
  } catch (err) {
  } finally {
    yield putResolve(setLoginLoading(false));
  }
}

function* LogoutUser() {
  try {
    yield call(axios.request, {
      url: '/logout',
      method: 'GET',
    });
    yield put(hideAllDialogs());
    yield put(unauthorize());

    // localStorage.removeItem('access_token')
    // localStorage.removeItem('persona')
    // localStorage.removeItem('selected_company')
    // localStorage.removeItem('divisions')
    // localStorage.removeItem('locale')

    const translations = localStorage.getItem('translations');
    const locales = localStorage.getItem('locales');

    localStorage.clear();
    localStorage.setItem('translations', translations || '');
    localStorage.setItem('locales', locales || '');

    yield put(resetState());
    yield put(_setWorkingHoursFilter({}));
  } catch (err) {}
}

function* GetUserSession() {
  try {
    const csrfResponse: { csrf: string } = yield call(axios.request, {
      url: '/sanctum/csrf-cookie',
    });

    yield put(setCsrf(getCookie(`XSRF-TOKEN`)));

    if (localStorage.getItem('access_token')) {
      const { data } = yield call(axios.request, {
        url: '/current-user',
      });

      yield put(authorize(data));
    }
  } catch (err) {}
}

export default function* rootSaga() {
  yield takeLatest(loginRequest.type, LoginUser);
  yield takeLatest(authRequest.type, GetUserSession);
  yield takeLatest(logoutRequest.type, LogoutUser);
}
