import { genericTexts } from 'shared/utils/formats';
import { getValidationResult } from 'shared/utils/tools';
import * as yup from 'yup';
const { isRequired } = genericTexts;

export const connectionSettingsScheme = yup.object().shape({
  url: yup
    .string()
    .label('Url')
    .required(isRequired)
    .transform(value => (value === null ? '' : value))
    .default(''),
  partnerID: yup
    .string()
    .label('Partner ID')
    .required(isRequired)
    .transform(value => (value === null ? '' : value))
    .default(''),
  partnerKey: yup
    .string()
    .label('Partner Key')
    .required(isRequired)
    .transform(value => (value === null ? '' : value))
    .default(''),
});

export type ConnectionSettingsType = yup.InferType<typeof connectionSettingsScheme>;
export function getEmptyConnectionSettings(): ConnectionSettingsType {
  return {
    url: '',
    partnerID: '',
    partnerKey: '',
  };
}

export function validator(data: ConnectionSettingsType) {
  return getValidationResult(data, connectionSettingsScheme);
}
