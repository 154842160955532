import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ThemeContext } from 'styled-components';

import SvgSmileIcon from 'assets/icons/Smile';
import SvgAlertIcon from 'assets/icons/Alert';
import SvgBellIcon from 'assets/icons/Bell';
import SvgRequestIcon from 'assets/icons/Request';

import { getNotificationClass, getNotificationMessageTemplate, getTranslatedWord } from 'shared/utils/tools';

import MoreOption from '../DataTable/MoreOption';

import Parser from 'html-react-parser';

import { NotificationItemProps } from './propsType';
import {
  NotificationItemContainer,
  ItemIconWrapper,
  NotificationContentWrapper,
  NotificationMessage,
  NotificationComment,
  NotificationDate,
  NotificationActionContainer,
  NotificationButton,
  NotificationActionLabel,
  NotificationActionDeclinedLabel,
  MoreOptionContainer,
} from './styledComponents';

const NotificationItem = ({ item, handleOnClick, handleOnAction, actions, dropDownRef }: NotificationItemProps) => {
  const theme = useContext(ThemeContext);
  const className = getNotificationClass(item.type) || '';
  const status = item.data.status;
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  moment.locale(localStorage.getItem('locale') || 'en');

  const handleNotifClick = (e: any) => {
    handleOnClick(item);
  };

  const handleAction = (action: string, e?: React.ChangeEvent<HTMLButtonElement>) => {
    if (e) e.stopPropagation();

    handleOnAction(item, action);
    if (action === 'accept') {
      setIsAccepting(true);
    }
    if (action === 'decline') {
      setIsDeclining(true);
    }
  };

  useEffect(() => {
    if (item.data.status === 'approved' || item.data.status === 'declined') {
      setIsAccepting(false);
    }
  }, [item.data.status]);

  const getNotifionMessage = (item: any) => {
    const translated = getNotificationMessageTemplate(item);
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const replaced = translated.replace(urlRegex, function(url: any) {
      return `<a href="${url}" target="_blank" rel="noopener">${url}</a>`;
    });
    return Parser(replaced);
  };

  return (
    <NotificationItemContainer $background={className}>
      <ItemIconWrapper>
        {className === '' && <SvgBellIcon />}
        {className === 'primary' && <SvgRequestIcon />}
        {className === 'success' && <SvgSmileIcon />}
        {className === 'warning' && <SvgAlertIcon fill={theme.colors.dark_yellow} />}
        {className === 'danger' && <SvgAlertIcon />}
        {item.read_at == null && <div className="dot" />}
      </ItemIconWrapper>
      <NotificationContentWrapper onClick={handleNotifClick}>
        <NotificationMessage>{getNotifionMessage(item)}</NotificationMessage>
        {item.data.comment && (
          <NotificationComment>
            {getTranslatedWord('Comment')}: {item.data.comment}
          </NotificationComment>
        )}
        <NotificationDate>{moment(item.created_at).fromNow()}</NotificationDate>
        {className === 'primary' && status === 'none' && (
          <NotificationActionContainer>
            <NotificationButton
              type="button"
              variant="primary"
              onClick={(e: React.ChangeEvent<HTMLButtonElement>) => handleAction('accept', e)}
              isLoading={isAccepting}
            >
              {getTranslatedWord('Accept')}
            </NotificationButton>
            <NotificationButton
              type="button"
              variant="white"
              onClick={(e: React.ChangeEvent<HTMLButtonElement>) => handleAction('edit', e)}
            >
              {getTranslatedWord('Edit')}
            </NotificationButton>
            <NotificationButton
              type="button"
              variant="danger"
              onClick={(e: React.ChangeEvent<HTMLButtonElement>) => handleAction('decline', e)}
              isLoading={isDeclining}
            >
              {getTranslatedWord('Decline')}
            </NotificationButton>
          </NotificationActionContainer>
        )}
        {className === 'primary' && status === 'approved' && (
          <NotificationActionLabel>
            {getTranslatedWord('You have already approved this request!')}
          </NotificationActionLabel>
        )}
        {className === 'primary' && status === 'declined' && (
          <NotificationActionDeclinedLabel>
            {getTranslatedWord('You have declined this request!')}
          </NotificationActionDeclinedLabel>
        )}
      </NotificationContentWrapper>
      <MoreOptionContainer>
        <MoreOption dropDownRef={dropDownRef} row={item} actions={actions} index={item.id} rowLength={1} top="0" />
      </MoreOptionContainer>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
