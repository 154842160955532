import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import useClickOutside from 'shared/hooks/useClickOutside';
import { createWrapperAndAppendToBody } from 'shared/utils/tools';

export default function Portal({ children, wrapperId, callback, ...restProps }: any) {
  const [wrapperElement, setWrapperElement] = useState<any>(null);

  useEffect(() => {
    let element = document.getElementById(wrapperId);
    let isSystemCreated = false;

    if (!element) {
      isSystemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }

    setWrapperElement(element);

    return () => {
      if (isSystemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}
