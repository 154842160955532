import React from 'react'
import { SvgIconProps } from './Home'

const SvgLeftArrowIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    fill="none"
    viewBox="0 0 8 14"
  >
    <path
      fill={fill ?? "#E0E0E0"}
      fillRule="evenodd"
      d="M7.564 2.034c.39-.398.39-1.045 0-1.443a.986.986 0 00-1.414 0L.436 6.424a1.036 1.036 0 000 1.444L6.15 13.7a.986.986 0 001.414 0c.39-.399.39-1.045 0-1.444l-5.01-5.114 5.01-5.109z"
      clipRule="evenodd"
    ></path>
  </svg>
)



export default SvgLeftArrowIcon