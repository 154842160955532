import { call, put, takeLatest, putResolve } from 'redux-saga/effects'
import history from 'shared/utils/history'
import { fetchCompanies, fetch } from 'shared/redux/company/reducer'
import { PayloadAction } from '@reduxjs/toolkit'
import axios from 'shared/utils/axios'

function* FetchCompanies() {
  try {
    const { data } = yield call(axios.request, {
      url: 'users/companies',
    })

    yield put(fetch(data))
  } catch (err) {}
}

export default function* rootSaga() {
  yield takeLatest(fetchCompanies.type, FetchCompanies)
}
