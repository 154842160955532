import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Company } from 'shared/types/api'

export type TimeElapsePayload = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export interface IWorkType {
  start_datetime: string
}

export type WorkStateType = {
  time_elapse: TimeElapsePayload
  total_elapse_time: number
  current_work: any
  timer_started: boolean
}

const initialState: WorkStateType = {
  time_elapse: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  total_elapse_time: 0,
  current_work: {},
  timer_started: false,
}

const work = createSlice({
  name: 'work',
  initialState,
  reducers: {
    setTimeElapse(state, action: PayloadAction<TimeElapsePayload>) {
      state.time_elapse = action.payload
    },
    setTotalTimeElapse(state, action: PayloadAction<number>) {
      state.total_elapse_time = action.payload
    },
    clearElapseTime(state, action: PayloadAction) {
      state.time_elapse = initialState.time_elapse
      state.total_elapse_time = initialState.total_elapse_time
    },
    setCurrentWork(state, action: any) {
      state.current_work = action.payload
    },
    setTimerStarted(state, action: PayloadAction<boolean>) {
      state.timer_started = action.payload
    },
  },
})

export const { setTimeElapse, setTotalTimeElapse, clearElapseTime, setCurrentWork, setTimerStarted } = work.actions

export default work.reducer
