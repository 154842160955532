import React, { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';
import Box from 'shared/components/Box';

import NavBarAction from 'shared/components/Layout/NavBar/NavBarAction';
import SvgLogoIcon from 'assets/icons/Logo';
import { MANAGER, WORKER } from 'shared/types';
import { dataSelector } from 'shared/redux/selectors';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getTranslatedWord } from 'shared/utils/tools';
import { permissionSelector } from 'shared/hocs/withAuth';

import useIntercomMethods from 'shared/hooks/useIntercomMethods';

interface NavBarProps {}

function NavBar(props: NavBarProps) {
  const { persona } = useSelector(dataSelector);
  const { handleOnChangePage } = useIntercomMethods();

  const {
    CAN_MANAGE_USER_WORK_HOURS,
    CAN_CONFIRM_WORK_HOURS,
    CAN_GENERATE_REPORTS,
    CAN_GENERATE_WORKER_REPORTS,
    CAN_MANAGE_USERS,
    CAN_MANAGE_WORKSPACES,
    IS_SCHEDULE_ACTIVE,
  } = useSelector(permissionSelector);

  const dashboard = useMemo(() => {
    return CAN_MANAGE_USER_WORK_HOURS || CAN_CONFIRM_WORK_HOURS;
  }, [CAN_MANAGE_USER_WORK_HOURS, CAN_CONFIRM_WORK_HOURS]);

  const raport = useMemo(() => {
    if (persona === MANAGER && CAN_GENERATE_WORKER_REPORTS) return true;
    if (persona === WORKER && CAN_GENERATE_REPORTS) return true;
    return false;
  }, [persona, CAN_GENERATE_REPORTS, CAN_GENERATE_WORKER_REPORTS]);

  const people = useMemo(() => {
    return CAN_MANAGE_USERS;
  }, [CAN_MANAGE_USERS]);

  const workspace = useMemo(() => {
    return CAN_MANAGE_WORKSPACES;
  }, [CAN_MANAGE_WORKSPACES]);

  return (
    <NavBarContainer>
      <MenuContainer>
        <Logo to="/login">
          <SvgLogoIcon />
        </Logo>
        <MenuItemContainer>
          <MenuItem activeClassName="active" to="/dashboard" onClick={handleOnChangePage}>
            {getTranslatedWord('Dashboard', 'dashboard')}
          </MenuItem>
          <MenuItem activeClassName="active" to="/timesheet" onClick={handleOnChangePage}>
            {getTranslatedWord('Timesheet', 'timesheet')}
          </MenuItem>
          {IS_SCHEDULE_ACTIVE && (
            <MenuItem activeClassName="active" to="/schedules" onClick={handleOnChangePage}>
              {getTranslatedWord('Schedules')}
            </MenuItem>
          )}
          {raport && (
            <MenuItem activeClassName="active" to="/raport" onClick={handleOnChangePage}>
              {getTranslatedWord('Reports', 'reports')}
            </MenuItem>
          )}
          {persona === MANAGER && (
            <>
              {people && (
                <MenuItem activeClassName="active" to="/people" onClick={handleOnChangePage}>
                  {getTranslatedWord('People', 'people')}
                </MenuItem>
              )}
              {workspace && (
                <MenuItem activeClassName="active" to="/workspace" onClick={handleOnChangePage}>
                  {getTranslatedWord('Workspaces', 'workspaces')}
                </MenuItem>
              )}
            </>
          )}
        </MenuItemContainer>
      </MenuContainer>
      <NavBarAction />
    </NavBarContainer>
  );
}

const NavBarContainer = styled(Box)`
  width: 100%;
  min-height: 72px;
  padding: 0 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => `
    background: ${theme.colors.yellow};
  `}
`;

const MenuContainer = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 72px;
  display: flex;
  align-items: center;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
  `}
`;

const Logo = styled(Link)`
  margin-right: 32px;
  display: flex;
  align-items: center;
`;
const MenuItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MenuItem = styled(NavLink)<{ active?: boolean }>`
  text-decoration: none;
  margin-right: 32px;
  cursor: pointer;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};

    &:hover {
      color: ${theme.colors.main};
    }
  `}

  transition: transform 250ms ease;

  ${({ theme }) => `
    &.active {
      border-bottom: 2px solid ${theme.colors.main};
      color: ${theme.colors.black};
      font-weight: 500;
    }
  `}
`;

export default NavBar;
