import React from 'react'
import { SvgIconProps } from './Home'

const SvgBellIcon = ({ fill, width, height }: SvgIconProps) =>
(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 22.358c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-3.92-6.606a4.264 4.264 0 007.84 0 1 1 0 00-1.84-.788 2.264 2.264 0 01-4.16 0 1 1 0 10-1.84.788zM9 8.358a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm6 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z"
    ></path>
    <path
      fill={fill ?? "#353B5A"}
      d="M20.15 16.313c-.338 0-.662-.139-.902-.385a1.332 1.332 0 01-.373-.928v-4.254a7.096 7.096 0 00-1.494-4.58 6.73 6.73 0 00-4.031-2.477V1.875a.888.888 0 00-.249-.619A.838.838 0 0012.5 1a.838.838 0 00-.601.256.888.888 0 00-.249.619v1.814a6.73 6.73 0 00-4.03 2.476 7.096 7.096 0 00-1.495 4.58V15c0 .348-.134.682-.373.928-.24.246-.564.384-.902.384a.838.838 0 00-.601.257.888.888 0 00-.249.619c0 .232.09.454.249.618.16.164.376.256.601.256h15.3a.838.838 0 00.601-.256.888.888 0 00.249-.619.888.888 0 00-.249-.618.838.838 0 00-.601-.256zM14.4 19.375h-3.8a.209.209 0 00-.142.056.221.221 0 00-.07.138 2.22 2.22 0 00.609 1.79c.399.41.94.641 1.503.641.564 0 1.104-.23 1.503-.64a2.22 2.22 0 00.608-1.79.22.22 0 00-.069-.14.207.207 0 00-.141-.055z"
    ></path>
  </svg>
)

export default SvgBellIcon
