import styled from 'styled-components';

import Box from 'shared/components/Box';

export const DrawerContainer = styled(Box)`
  &,
  .drawer-toolbar {
    padding: 32px 48px;
  }
`;

export const GroupLabel = styled(Box)`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 32px;
`;
