import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Division, Company, OvertimeGroup } from 'shared/types/api';
import { ColumnType } from 'shared/components/DataTable/Column';
import { MutableRefObject } from 'react';
import { EventClickArg } from '@fullcalendar/core';

export interface DataState {
  missing_works: {
    list: any;
    fetching: boolean;
  };
  divisions: {
    all: Division[];
    list: Division[];
    selected: Division[];
    fetching: boolean;
  };
  workTypes: {
    list: any[];
    fetching: boolean;
  };
  people: {
    all: any;
    list: any;
    fetching: boolean;
  };
  boxes: {
    data: any;
    fetching: boolean;
  };
  overtimeGroup: {
    list: OvertimeGroup[];
    fetching: boolean;
  };
  workshiftGroups: {
    list: any[];
    fetching: boolean;
  };
  allowedPauseGroups: {
    list: any[];
    fetching: boolean;
  };
  dailyAllowanceGroups: {
    list: any[];
    fetching: boolean;
  };
  compensationGroups: {
    list: any[];
    fetching: boolean;
  };
  vacationTypes: {
    list: any[];
    fetching: boolean;
  };
  innerRangeDoors: {
    data: any;
    fetching: boolean;
  };
  innerRangeSettings: {
    data: any | null;
    fetching: boolean;
  };
  geofenceGateways: {
    data: any;
    fetching: boolean;
  };
  netvisorDimensions: {
    data: any | null;
    fetching: boolean;
  };
  netvisorSettings: {
    data: any | null;
    fetching: boolean;
  };
  positions: {
    list: any[];
    fetching: boolean;
  };
  notifications: {
    list: any[];
    fetching: boolean;
  };
  permissions: {
    list: any[];
    fetching: boolean;
  };
  translation: {
    data?: any;
    fetching?: boolean;
    current?: any;
    currentLocale?: string;
    base?: any;
  };
  workSpace: {
    selected: Company | null;
    settings?: any;
  };
  persona: any;
  isProgressing: Boolean;
  isSwitching: Boolean;
  peopleReport: {
    columns:
      | null
      | (ColumnType & {
          label: string;
          visible: string;
        })[];
  };
  workingHoursReport: {
    filters: {
      ranges: number[];
      [key: string]: any;
    };
    columns:
      | null
      | (ColumnType & {
          label: string;
          visible: string;
        })[];
  };
  scheduleFilters: {
    filters: {
      ranges: number[];
      [key: string]: any;
    };
  };
  filters: {
    [route: string]: any;
  };
  holidays: {
    list: any[];
    fetching: boolean;
  };
  isIntercomBooted: boolean;
}

const translations: any = JSON.parse(localStorage.getItem('translations') || '{}');
const locale: string = localStorage.getItem('locale') || 'en';
const locales: any = JSON.parse(localStorage.getItem('locales') || '[]');
const initialCompany = JSON.parse(localStorage.getItem('selected_company') || '{}') || {};
const initialDivisions = JSON.parse(localStorage.getItem('divisions') || '[]') || [];
const initialPersona = JSON.parse(localStorage.getItem('persona') || '[]') || '';
const initialPeopleReportColumns = JSON.parse(localStorage.getItem('peopleReportColumns') as any);
const initialWorkingHoursReportColumns = JSON.parse(localStorage.getItem('initialWorkingHoursReportColumns') as any);
const filters: any = JSON.parse(localStorage.getItem('filters') || '{}');
const scheduleFilters: any = JSON.parse(localStorage.getItem('schedule-filters') || '{}');
const pageFilters: any = JSON.parse(localStorage.getItem('page-filters') || '{}');

const initialState: DataState = {
  missing_works: {
    list: [],
    fetching: false,
  },
  divisions: {
    all: [],
    list: [],
    selected: initialDivisions,
    fetching: false,
  },
  workTypes: {
    list: [],
    fetching: false,
  },
  people: {
    all: [],
    list: [],
    fetching: false,
  },
  boxes: {
    data: null,
    fetching: false,
  },
  overtimeGroup: {
    list: [],
    fetching: false,
  },
  workshiftGroups: {
    list: [],
    fetching: false,
  },
  allowedPauseGroups: {
    list: [],
    fetching: false,
  },
  dailyAllowanceGroups: {
    list: [],
    fetching: false,
  },
  compensationGroups: {
    list: [],
    fetching: false,
  },
  vacationTypes: {
    list: [],
    fetching: false,
  },
  innerRangeDoors: {
    data: null,
    fetching: false,
  },
  innerRangeSettings: {
    data: null,
    fetching: false,
  },
  geofenceGateways: {
    data: null,
    fetching: false,
  },
  netvisorDimensions: {
    data: null,
    fetching: false,
  },
  netvisorSettings: {
    data: null,
    fetching: false,
  },
  positions: {
    list: [],
    fetching: false,
  },
  notifications: {
    list: [],
    fetching: false,
  },
  permissions: {
    list: [],
    fetching: false,
  },
  translation: {
    data: {
      ...translations,
      locales: locales,
    },
    fetching: false,
    currentLocale: locale,
    current: translations?.[locale]?.translation || {},
    base: translations?.['en']?.translation,
  },
  workSpace: {
    selected: initialCompany,
  },
  persona: initialPersona,
  isProgressing: false,
  isSwitching: false,
  peopleReport: {
    columns: initialPeopleReportColumns ?? null,
  },
  workingHoursReport: {
    columns: initialWorkingHoursReportColumns ?? null,
    filters: filters || {
      ranges: [],
    },
  },
  scheduleFilters: {
    filters: scheduleFilters || {
      ranges: [],
    },
  },
  filters: pageFilters,
  holidays: {
    list: [],
    fetching: false,
  },
  isIntercomBooted: false,
};

const name = 'data';

export const fetchDivisions = createAction<{ data: Company; rights?: number[] | string[] }>(
  `${name}/divisions/fetching`,
);
export const fetchWorkTypes = createAction<{ division_ids: number[]; company_id: number }>(
  `${name}/worktypes/fetching`,
);
export const fetchMissingWorks = createAction<Company>(`${name}/workers/works/missing/fetching`);
export const fetchPeople = createAction<Company>(`${name}/people/fetching`);
export const fetchAllPeople = createAction<Company>(`${name}/people/all/fetching`);
export const fetchLoginBoxes = createAction<Company>(`${name}/box/fetching`);
export const fetchOvertimeGroups = createAction<Company>(`${name}/overtimegroups/fetching`);
export const fetchWorkshiftGroups = createAction<Company>(`${name}/workshiftgroups/fetching`);
export const fetchAllowedPauseGroups = createAction<Company>(`${name}/allowedpausegroups/fetching`);
export const fetchDailyAllowanceGroups = createAction<Company>(`${name}/dailyallowancegroups/fetching`);
export const fetchCompensationGroups = createAction<Company>(`${name}/compensationgroups/fetching`);
export const fetchVacationTypes = createAction<Company[]>(`${name}/vacationtypes/fetching`);
export const fetchInnerRangeDoors = createAction<Company>(`${name}/innerrange/doors/fetching`);
export const fetchInnerRangeSettings = createAction<Company>(`${name}/innerrange/settings/fetching`);
export const fetchGeofenceGateways = createAction<Company>(`${name}/geofence/gateways/fetching`);
export const fetchNetvisorDimensions = createAction<Company>(`${name}/company/netvisor/dimension/fetching`);
export const fetchNetvisorSettings = createAction<Company>(`${name}/company/netvisor/settings/fetching`);
export const fetchNotifications = createAction(`${name}/notifications`);
export const fetchPermissions = createAction<Company>(`${name}/company/netvisor/dimension/fetching`);
export const fetchTranslations = createAction(`${name}/translation/fetching`);
export const fetchCompanySettings = createAction<Company>(`${name}/company/settings/fetching`);
export const fetchHolidays = createAction<{ code?: string }>(`${name}/holidays/fetching`);

const data = createSlice({
  name,
  initialState,
  reducers: {
    // Missing Works
    _fetchMissingWorks(state, action) {
      state.missing_works.list = action.payload;
      state.missing_works.fetching = false;
    },
    _setMissingWorks(state, action) {
      state.missing_works.list = action.payload;
    },

    // Divisions
    _fetchDivision(state, action) {
      state.divisions.all = action.payload.data.filter((o: any) => o.active !== 0);

      const divisions = action.payload.data.filter(
        (o: any) =>
          action.payload?.rights &&
          Array.isArray(action.payload?.rights) &&
          action.payload?.rights.includes(o.id) &&
          o.active !== 0,
      );

      if (action.payload?.rights && Array.isArray(action.payload?.rights) && action.payload?.rights.length > 0) {
        state.divisions.list = divisions;
        state.divisions.selected = divisions;
      } else {
        state.divisions.list =
          action.payload.data &&
          Array.isArray(action.payload.data) &&
          action.payload.data.filter((o: any) => o?.active !== 0);
        state.divisions.selected = action.payload.data;
      }

      state.divisions.fetching = false;
    },
    _setDivisions(state, action) {
      state.divisions.list = action.payload;
      state.divisions.selected = action.payload;
    },
    _setAllDivisions(state, action) {
      state.divisions.all = action.payload;
    },

    // Work types
    _fetchWorkTypes(state, action) {
      if (action.payload && Array.isArray(action.payload)) {
        state.workTypes.list = action.payload.filter((o: any) => o.active === 1);
      }
      state.workTypes.fetching = false;
    },
    _setWorkTypes(state, action) {
      state.workTypes.list = action.payload;
    },

    // People
    _fetchPeople(state, action) {
      state.people.list = action.payload;
      state.people.fetching = false;
    },
    _setPeople(state, action) {
      state.people.list = action.payload;
    },

    _fetchAllPeople(state, action) {
      state.people.all = action.payload;
      state.people.fetching = false;
    },
    _setAllPeople(state, action) {
      state.people.all = action.payload;
    },

    // Login boxes
    _fetchLoginBoxes(state, action) {
      state.boxes.data = action.payload;
      state.boxes.fetching = false;
    },
    _setLoginBoxes(state, action) {
      state.boxes.data = action.payload;
    },

    // Preferences
    _fetchOvertimeGroups(state, action) {
      state.overtimeGroup.list = action.payload;
      state.overtimeGroup.fetching = false;
    },
    _fetchWorkshiftGroups(state, action) {
      state.workshiftGroups.list = action.payload;
      state.workshiftGroups.fetching = false;
    },
    _fetchAllowedPauseGroups(state, action) {
      state.allowedPauseGroups.list = action.payload;
      state.allowedPauseGroups.fetching = false;
    },
    _fetchDailyAllowanceGroups(state, action) {
      state.dailyAllowanceGroups.list = action.payload;
      state.dailyAllowanceGroups.fetching = false;
    },
    _fetchCompensationGroups(state, action) {
      state.compensationGroups.list = action.payload;
      state.compensationGroups.fetching = false;
    },

    // Vacation types
    _fetchVacationTypes(state, action) {
      state.vacationTypes.fetching = false;
      state.vacationTypes.list = action.payload;
    },
    _setVacationTypes(state, action) {
      state.vacationTypes.list = action.payload;
    },

    // Inner range
    _fetchInnerRangeDoors(state, action) {
      state.innerRangeDoors.data = action.payload;
      state.innerRangeDoors.fetching = false;
    },
    _setInnerRangeDoors(state, action) {
      state.innerRangeDoors.data = action.payload;
    },
    _fetchInnerRangeSettings(state, action) {
      state.innerRangeSettings.data = action.payload;
      state.innerRangeSettings.fetching = false;
    },
    _setInnerRangeSettings(state, action) {
      state.innerRangeSettings.data = action.payload;
    },

    // Geofence
    _fetchGeofenceGateways(state, action) {
      state.geofenceGateways.data = action.payload;
      state.geofenceGateways.fetching = false;
    },
    _setGeofenceGateways(state, action) {
      state.geofenceGateways.data = action.payload;
    },

    // Netvisor
    _fetchNetvisorDimensions(state, action) {
      state.netvisorDimensions.data = action.payload;
      state.netvisorDimensions.fetching = false;
    },
    _setNetvisorDimensions(state, action) {
      state.netvisorDimensions.data = action.payload;
    },
    _fetchNetvisorSettings(state, action) {
      state.netvisorSettings.data = action.payload;
      state.netvisorSettings.fetching = false;
    },
    _setNetvisorSettings(state, action) {
      state.netvisorSettings.data = action.payload;
    },

    // Notifications
    _fetchNotifications(state, action) {
      state.notifications.list = action.payload;
      state.notifications.fetching = false;
    },
    _setNotifications(state, action) {
      state.notifications.list = action.payload;
    },

    // Permissions
    _fetchPermissions(state, action) {
      state.permissions.list = action.payload;
      state.permissions.fetching = false;
    },

    // Translations
    _fetchTranslations(state, action) {
      const { locales, ...data } = action.payload || {};
      state.translation.data = {
        ...state.translation.data,
        ...action.payload,
      };
      state.translation.current = state.translation.data?.[locale]?.translation;
      state.translation.base = state.translation.data?.['en']?.translation;

      localStorage.setItem('locales', JSON.stringify(locales));
      localStorage.setItem('translations', JSON.stringify(data));
    },
    _changeLocale(state, action) {
      state.translation.fetching = false;
      if (locale === action.payload) {
        state.translation.current = state.translation.data?.[locale || action.payload]?.translation;
      } else {
        state.translation.current = state.translation.data?.[action.payload || locale]?.translation;
      }
      state.translation.currentLocale = action.payload;
      localStorage.setItem('locale', action.payload);
    },
    _overrideTranslationValue(state, action) {
      state = {
        ...state,
        translation: {
          data: {
            translation: { [action.payload.locale]: { translation: { [action.payload.key]: action.payload.value } } },
          },
        },
      };
    },
    // Additional settings
    setHolidays(state, action: PayloadAction<any>) {
      const nationalHolidays = action.payload?.filter((item: any) => {
        return item.type == 'National';
      });
      state.holidays.list = nationalHolidays;
    },
    setSelectedCompany(state, action: PayloadAction<Company>) {
      state.workSpace.selected = action.payload;
      localStorage.setItem('selected_company', JSON.stringify(action.payload));
    },
    setCompanySettings(state, action: PayloadAction<any>) {
      state.workSpace.settings = action.payload;
    },
    setSelectedDivisions(state, action) {
      const selected = action.payload?.filter((division: any) => {
        if (typeof division === 'number') {
          return state.divisions.list?.find((list: Division) => list?.id === division && list);
        }
        return state.divisions.list?.find((list: Division) => list?.id === division?.id);
      });

      state.divisions.selected = selected || localStorage.getItem('divisions');
      localStorage.setItem('divisions', JSON.stringify(selected));
    },
    setPersona(state, action) {
      state.persona = action.payload;
      localStorage.setItem('persona', JSON.stringify(action.payload));
      localStorage.removeItem('reportUrl');
      localStorage.removeItem('batchId');
    },
    setIsProgressing(state, action) {
      state.isProgressing = action.payload;
    },
    setIsSwitching(state, action) {
      state.isSwitching = action.payload;
    },
    resetState(state) {
      state.divisions = initialState.divisions;
      state.workTypes = initialState.workTypes;
      state.people = initialState.people;
      state.workSpace = initialState.workSpace;
      state.vacationTypes = initialState.vacationTypes;
      state.persona = initialState.persona;
      state.translation.current = {};
      state.overtimeGroup = initialState.overtimeGroup;
      state.workingHoursReport = initialState.workingHoursReport;
      state.scheduleFilters = initialState.scheduleFilters;
      state.notifications = initialState.notifications;
      state.filters = {};
    },

    // Filters
    setPeopleReportColumns(state, action) {
      state.peopleReport.columns = action.payload;
      localStorage.setItem('peopleReportColumns', JSON.stringify(action.payload));
    },
    setPeopleWorkingHoursColumns(state, action) {
      state.workingHoursReport.columns = action.payload;
    },
    _setWorkingHoursFilter(state, action) {
      state.workingHoursReport.filters = action.payload;
      localStorage.setItem('filters', JSON.stringify(action.payload));
    },
    _setWorkingHoursFilterSchedule(state, action) {
      state.scheduleFilters.filters = action.payload;
      localStorage.setItem('schedule-filters', JSON.stringify(action.payload));
    },
    setGlobalFilters(state, action) {
      state.filters = action.payload;
      localStorage.setItem('page-filters', JSON.stringify(action.payload));
    },
    setIsIntercomBooted(state, action) {
      state.isIntercomBooted = action.payload;
      localStorage.setItem('intercoom-booted', action.payload);
    },
  },
  extraReducers: {
    [fetchMissingWorks.type]: state => {
      state.missing_works.fetching = true;
    },
    [fetchDivisions.type]: state => {
      state.divisions.fetching = true;
    },
    [fetchWorkTypes.type]: state => {
      state.workTypes.fetching = true;
    },
    [fetchPeople.type]: state => {
      state.people.fetching = true;
    },
    [fetchAllPeople.type]: state => {
      state.people.fetching = true;
    },
    [fetchLoginBoxes.type]: state => {
      state.boxes.fetching = true;
    },
    [fetchOvertimeGroups.type]: state => {
      state.overtimeGroup.fetching = true;
    },
    [fetchVacationTypes.type]: state => {
      state.divisions.fetching = true;
    },
    [fetchInnerRangeDoors.type]: state => {
      state.innerRangeDoors.fetching = true;
    },
    [fetchInnerRangeSettings.type]: state => {
      state.innerRangeSettings.fetching = true;
    },
    [fetchGeofenceGateways.type]: state => {
      state.geofenceGateways.fetching = true;
    },
    [fetchNetvisorDimensions.type]: state => {
      state.netvisorDimensions.fetching = true;
    },
    [fetchNetvisorSettings.type]: state => {
      state.netvisorSettings.fetching = true;
    },
    [fetchPermissions.type]: state => {
      state.permissions.fetching = true;
    },
    [fetchNotifications.type]: state => {
      state.notifications.fetching = true;
    },
    [fetchTranslations.type]: state => {
      state.translation.fetching = true;
    },
    [fetchHolidays.type]: state => {
      state.permissions.fetching = true;
    },
  },
});

export const {
  // Missing WOrks
  _fetchMissingWorks,
  _setMissingWorks,

  // Division
  _fetchDivision,
  _setDivisions,
  _setAllDivisions,

  // Work types
  _fetchWorkTypes,
  _setWorkTypes,

  // People
  _fetchPeople,
  _setPeople,
  _fetchAllPeople,
  _setAllPeople,

  // Login boxes
  _fetchLoginBoxes,
  _setLoginBoxes,

  // Preferences
  _fetchOvertimeGroups,
  _fetchWorkshiftGroups,
  _fetchAllowedPauseGroups,
  _fetchDailyAllowanceGroups,
  _fetchCompensationGroups,

  // Vacation types
  _fetchVacationTypes,
  _setVacationTypes,

  // Inner range
  _fetchInnerRangeDoors,
  _setInnerRangeDoors,
  _fetchInnerRangeSettings,
  _setInnerRangeSettings,

  // Geofence
  _fetchGeofenceGateways,
  _setGeofenceGateways,

  // Netvisor
  _fetchNetvisorDimensions,
  _setNetvisorDimensions,
  _fetchNetvisorSettings,
  _setNetvisorSettings,

  // Notifications
  _fetchNotifications,
  _setNotifications,

  // Permissions
  _fetchPermissions,

  // Tranlations
  _fetchTranslations,
  _changeLocale,
  _overrideTranslationValue,

  // Additional settings
  setHolidays,
  setSelectedCompany,
  setCompanySettings,
  setSelectedDivisions,
  setPersona,
  setIsProgressing,
  setIsSwitching,
  resetState,

  // Filters
  setPeopleReportColumns,
  setPeopleWorkingHoursColumns,
  _setWorkingHoursFilter,
  _setWorkingHoursFilterSchedule,
  setGlobalFilters,
  setIsIntercomBooted,
} = data.actions;

export default data.reducer;
