import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { _changeLocale } from 'shared/redux/data/reducer';

import useQuery from 'shared/hooks/useQuery';

import { translationsDefault } from 'shared/utils/defaults';

const useLocaleChange = () => {
  const dispatch = useDispatch();
  const [translationState, translationHandler] = useQuery<any>(
    {
      url: `/onesky/translations`,
    },
    {
      initialData: null,
      skip: localStorage.getItem('translations') !== null,
    },
  );

  const dispatchLocaleChange = (lang: string) => {
    return dispatch(_changeLocale(lang));
  };

  const getTranslationFromStorage = () => {
    return localStorage.getItem('translations');
  };

  const getLocaleFromStorage = () => {
    return localStorage.getItem('locale');
  };

  const dispatchLocaleChangeFromURL = (pathName: string, search: string) => {
    if (pathName === '/login' || pathName === '/signup' || pathName === '/') {
      const querySearch = queryString.parse(search);
      const lang =
        search === '' ? (localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en') : querySearch.lang;
      return dispatch(_changeLocale(lang));
    }
  };

  useEffect(() => {
    const cachedTranslations = localStorage.getItem('translations');
    if (cachedTranslations === null || cachedTranslations === undefined) {
      localStorage.setItem('translations', JSON.stringify(translationsDefault()));
    }
    if (translationState?.data?.data) {
      if (cachedTranslations !== translationState?.data?.data) {
        localStorage.setItem('translations', JSON.stringify(translationState?.data?.data));
      }
    }
  }, [translationState?.data?.data]);

  return {
    dispatchLocaleChange,
    getTranslationFromStorage,
    getLocaleFromStorage,
    dispatchLocaleChangeFromURL,
  };
};

export default useLocaleChange;
