import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MemoExoticComponent, MouseEventHandler, ReactNode } from 'react';

export interface Crumb {
  label: string;
  link: string;
}

export type ToastAction = {
  id?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  content: any;
};

export type ToastType = {
  message: string | null;
  type: AlertColor;
  icon?: string | boolean;
  delay?: number | null;
  moreActions?: any | null;
  withClose?: boolean;
  alwaysVisible?: boolean;
};

export interface AppState {
  toast: ToastType | null;
  dialogToast: Notification | null;
  formProcessing: boolean;
  minimizeDialogs: Dialog[] | any;
  crumbs: Crumb[];
  media: string;
  isSidebarVisible: boolean;
  opened: string;
  isUploading: boolean;

  dialogProcessing: boolean;
  dialog: Dialog | null;
  temporaryClosedDialogs: any;

  drawer: Drawer | null;
  drawerProcessing: boolean;
  temporaryClosedDrawers: any;

  refetchPage: string | null;

  bottomNavBar: BottomNavBar;

  schedulerUpdating: boolean;
}

export interface BottomNavBar {
  isShown: boolean;
}

export interface Dialog {
  minimizeName?: string;
  component: Function;
  props: object | any;
}

export interface Drawer {
  minimizeName?: string;
  component: Function;
  props: object | any;
}

export interface Notification {
  type: 'error' | 'success' | 'confirm';
  message: string | ReactNode;
  messageBody?: ReactNode;
  subText?: string;
  description?: string;
  autohideTimeout?: number;
  onDismiss?(): void;
  onConfirm?(): void;
}

const initialState: AppState = {
  toast: null,
  dialogToast: null,
  formProcessing: false,
  minimizeDialogs: [],
  crumbs: [],
  media: '',
  isSidebarVisible: true,
  isUploading: false,
  opened: '/participants',

  dialog: null,
  dialogProcessing: false,
  temporaryClosedDialogs: [],

  drawer: null,
  drawerProcessing: false,
  temporaryClosedDrawers: [],

  refetchPage: null,

  bottomNavBar: {
    isShown: true,
  },

  schedulerUpdating: false,
};

// shared logic
function hideDialogAction(state: AppState) {
  let dialog = null;
  let { temporaryClosedDialogs } = state;
  if (temporaryClosedDialogs.length) {
    [dialog] = [...temporaryClosedDialogs].reverse();
    temporaryClosedDialogs = temporaryClosedDialogs.slice(0, temporaryClosedDialogs.length - 1);
  }
  state.dialog = dialog;
  state.dialogProcessing = false;
  state.temporaryClosedDialogs = temporaryClosedDialogs;
}

// shared logic
function hideDrawerAction(state: AppState) {
  let drawer = null;
  let { temporaryClosedDrawers } = state;
  if (temporaryClosedDrawers.length) {
    [drawer] = [...temporaryClosedDrawers].reverse();
    temporaryClosedDrawers = temporaryClosedDrawers.slice(0, temporaryClosedDrawers.length - 1);
  }
  state.drawer = drawer;
  state.drawerProcessing = false;
  state.temporaryClosedDrawers = temporaryClosedDrawers;
}

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateDialogProps(state, { payload }: PayloadAction<{ props: object }>) {
      if (state.dialog) {
        state.dialog.props = {
          ...state.dialog.props,
          ...payload.props,
        };
      }
    },
    updateDrawerProps(state, { payload }: PayloadAction<{ props: object }>) {
      if (state.drawer) {
        state.drawer.props = {
          ...state.drawer.props,
          ...payload.props,
        };
      }
    },
    minimizeDialog(state, { payload }) {
      let { dialog, temporaryClosedDialogs, minimizeDialogs } = state;

      if (dialog) {
        minimizeDialogs = [...minimizeDialogs, { ...dialog, minimizeName: payload }];
      }

      if (temporaryClosedDialogs.length) {
        [dialog] = [...temporaryClosedDialogs].reverse();
        temporaryClosedDialogs = temporaryClosedDialogs.slice(0, temporaryClosedDialogs.length - 1);
      } else {
        dialog = null;
      }

      state.dialog = dialog;
      state.minimizeDialogs = minimizeDialogs;
    },
    showMinimizeDialog(state, { payload }) {
      const { dialog } = state;
      let { temporaryClosedDialogs, minimizeDialogs } = state;

      if (minimizeDialogs.length && payload) {
        const minimizeIndex = minimizeDialogs.findIndex((each: any) => each.minimizeName === payload);
        if (minimizeIndex !== -1) {
          state.dialog = minimizeDialogs[minimizeIndex];
          minimizeDialogs = minimizeDialogs.filter((e: any, index: number) => index !== minimizeIndex);

          if (dialog) {
            temporaryClosedDialogs = [...temporaryClosedDialogs, dialog];
          }
        }
      }

      state.minimizeDialogs = minimizeDialogs;
      state.temporaryClosedDialogs = temporaryClosedDialogs;
    },
    showDialog(state, { payload }: PayloadAction<Dialog>) {
      const { dialog } = state;
      let { temporaryClosedDialogs, minimizeDialogs } = state;
      if (dialog) {
        temporaryClosedDialogs = [...temporaryClosedDialogs, dialog];
      }

      if (minimizeDialogs.length && payload.props.title) {
        const minimizeIndex = minimizeDialogs.findIndex((each: any) => each.minimizeName === payload.props.title);
        if (minimizeIndex !== -1) {
          state.dialog = minimizeDialogs[minimizeIndex];
          minimizeDialogs = minimizeDialogs.filter((e: any, index: number) => index !== minimizeIndex);
        } else {
          state.dialog = payload;
        }
      } else {
        state.dialog = payload;
      }

      state.minimizeDialogs = minimizeDialogs;
      state.temporaryClosedDialogs = temporaryClosedDialogs;
    },
    showDrawer(state, { payload }: PayloadAction<Drawer>) {
      const { drawer } = state;
      let { temporaryClosedDrawers } = state;
      if (drawer) {
        temporaryClosedDrawers = [...temporaryClosedDrawers, drawer];
      }

      state.drawer = payload;
      state.temporaryClosedDrawers = temporaryClosedDrawers;
    },
    hideAllDialogs(state) {
      state.dialog = null;
      state.temporaryClosedDialogs = [];
      state.minimizeDialogs = [];
      state.dialogProcessing = false;
    },
    hideAllDrawers(state) {
      state.drawer = null;
      state.temporaryClosedDrawers = [];
      state.drawerProcessing = false;
    },
    hideDialog: hideDialogAction,
    hideDrawer: hideDrawerAction,
    dialogProcessing(state, action: PayloadAction<boolean>) {
      const { dialog } = state;
      if (dialog && action.payload) {
        state.dialogProcessing = action.payload;
      } else if (!action.payload) {
        state.dialogProcessing = action.payload;
      }
    },
    drawerProcessing(state, action: PayloadAction<boolean>) {
      const { drawer } = state;
      if (drawer && action.payload) {
        state.drawerProcessing = action.payload;
      } else if (!action.payload) {
        state.drawerProcessing = action.payload;
      }
    },
    formProcessing(state, action: PayloadAction<boolean>) {
      state.formProcessing = action.payload;
    },
    hideNotification(state) {
      state.dialogToast = null;
    },
    showError(
      state,
      {
        payload,
      }: PayloadAction<{ description?: string; subText?: string; message: string | ReactNode; onDismiss?: () => void }>,
    ) {
      state.dialogToast = {
        ...payload,
        type: 'error',
      };
      state.formProcessing = false;
      state.dialogProcessing = false;
      state.drawerProcessing = false;
    },
    showConfirm(
      state,
      {
        payload,
      }: PayloadAction<{ title: string | ReactNode; onConfirm?(): void; onDismiss?: () => void; message: string }>,
    ) {
      state.dialogToast = {
        ...payload,
        type: 'confirm',
        message: payload.title,
        subText: payload.message,
      };
      state.formProcessing = false;
      state.dialogProcessing = false;
      state.drawerProcessing = false;
    },
    showSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        message: string | ReactNode;
        messageBody?: ReactNode;
        subText?: string;
        hideDialog?: boolean;
        hideDrawer?: boolean;
        autohideTimeout?: number;
        onDismiss?: () => void;
      }>,
    ) {
      const {
        message,
        messageBody,
        subText,
        hideDialog = true,
        hideDrawer = false,
        autohideTimeout,
        onDismiss,
      } = payload;
      if (hideDialog) {
        hideDialogAction(state);
      }

      if (hideDrawer) {
        hideDrawerAction(state);
      }

      state.dialogToast = {
        type: 'success',
        message,
        messageBody,
        subText,
        autohideTimeout,
        onDismiss,
      };
    },
    clearLoading(state) {
      state.formProcessing = false;
      state.dialogProcessing = false;
      state.drawerProcessing = false;
    },
    setIsUploading(state, { payload }: PayloadAction<boolean>) {
      state.isUploading = payload;
    },
    setRefreshPage(state, { payload }: PayloadAction<string | null>) {
      state.refetchPage = payload;
    },
    setCrumbs(state, { payload }) {
      const { crumbs } = payload;
      state.crumbs = state.crumbs.concat(crumbs);
    },
    removeCrumbs(state, { payload }: PayloadAction<Crumb[]>) {
      const labels = payload.map(e => e.label);
      state.crumbs = state.crumbs.filter(e => !labels.includes(e.label));
    },
    changeMedia(state, { payload }: PayloadAction<string>) {
      state.media = payload;
    },
    addToast(state, { payload }: PayloadAction<ToastType>) {
      state.toast = payload;
    },
    setToastMessage(state, { payload }: PayloadAction<string | null>) {
      if (state.toast) {
        state.toast = { ...state.toast, message: payload };
      }
    },
    removeToast(state) {
      state.toast = null;
    },
    showBottomNavBar(state) {
      state.bottomNavBar = {
        ...state.bottomNavBar,
        isShown: true,
      };
    },
    hideBottomNavBar(state) {
      state.bottomNavBar = {
        ...state.bottomNavBar,
        isShown: false,
      };
    },
    schedulerUpdating(state, action: PayloadAction<boolean>) {
      state.schedulerUpdating = action.payload;
    },
  },
});

export const {
  showDialog,
  hideDialog,
  dialogProcessing,
  hideAllDialogs,
  minimizeDialog,
  showMinimizeDialog,
  updateDialogProps,

  showDrawer,
  hideDrawer,
  drawerProcessing,
  hideAllDrawers,
  updateDrawerProps,

  formProcessing,
  hideNotification,
  showError,
  showSuccess,
  clearLoading,
  setCrumbs,
  removeCrumbs,
  changeMedia,
  setIsUploading,
  showConfirm,
  addToast,
  setToastMessage,
  removeToast,
  setRefreshPage,

  showBottomNavBar,
  hideBottomNavBar,

  schedulerUpdating,
} = app.actions;

export default app.reducer;
