import React, { Fragment, ReactNode, useState } from 'react';
import flowRight from 'lodash/flowRight';
import withDrawer, { WrappedProps } from 'shared/hocs/withDrawer';

import styled from 'styled-components';
import Box from 'shared/components/Box';
import { UserNotification } from 'shared/types/api';
import { getTranslatedWord } from 'shared/utils/tools';
import { DropDownMenuItemContainer, MenuAction, MenuOption } from '../NavBar/DropDownAction';
import { useDispatch } from 'react-redux';
import { hideDrawer, showBottomNavBar } from 'shared/redux/app/reducer';
import SvgCheckIcon from 'assets/icons/Check';

export type DropdownOptions = {
  id: string;
  name?: string;
  label: string;
  onClick?: () => void;
};

type DropdownProps = WrappedProps<any> & {
  options?: DropdownOptions[];
  action?: MenuAction;
  isAvatar: boolean;
  selected?: string;
  selectedPersona?: string;
  showPreviousClicked?: boolean;
};

const DropDown = (props: DropdownProps) => {
  const { options, action, isAvatar, selected = '', selectedPersona = '', showPreviousClicked = false } = props;
  const dispatch = useDispatch();
  const [selectedPersonaOption, setSelectedPersonaOption] = useState<string>(selectedPersona);

  const { onClick = () => null } = action || {};

  return (
    <DrawerContainer>
      {options &&
        options?.map((item: DropdownOptions, index: number) => {
          return menuRenderer(item, `${item.id ? item.id : index}`, isAvatar);
        })}
      {action && (
        <MenuActionContainer>
          {Boolean(options?.length) && <DropDownMenuItemSpacer />}
          <OptionContainer onClick={handleCloseClick(action)}>
            <Box>{action?.label}</Box>
            {action?.icon}
          </OptionContainer>
        </MenuActionContainer>
      )}
      <DropDownMenuItemSpacer />
    </DrawerContainer>
  );

  function menuRenderer(option: DropdownOptions, index: string, isAvatar: boolean): ReactNode {
    const selectedOption = isAvatar ? selectedPersonaOption : selected;
    return (
      <Fragment key={option?.id}>
        <OptionContainer $selected={selectedOption === index} onClick={handleClick(option)}>
          <Box>{option.label}</Box>
          {selectedOption === index && <SvgCheckIcon />}
        </OptionContainer>
      </Fragment>
    );
  }

  function handleCloseClick(action: MenuAction) {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      onClick();
      dispatch(hideDrawer());
    };
  }

  function handleClick(item: DropdownOptions) {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      showPreviousClicked && setSelectedPersonaOption(item.id ? item.id : '');

      if (item.onClick) {
        item.onClick();
      }

      if (!['company-profile', 'visma-admin', 'merit-palk'].includes(item.id)) {
        dispatch(hideDrawer());
      }
    };
  }
};

const DropDownDrawer = flowRight(withDrawer())(DropDown);

const DrawerContainer = styled(Box)`
  &,
  .drawer-toolbar {
    padding: 0 16px;

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      padding: 0 !important;
      margin-bottom: 16px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
`;

const OptionContainer = styled(DropDownMenuItemContainer)`
  cursor: pointer;
  padding: 12px 24px;
  font-size: 16px;
`;

const DropDownMenuItemSpacer = styled(Box)`
  padding: 8px 0;

  &::before {
    content: '';
    display: inline-block;
    width: 200%;
    position: absolute;
    left: -24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }
`;

const MenuActionContainer = styled(Box)`
  postion: relative;
  bottom: 0;
`;

DropDownDrawer.defaultProps = {
  dialogId: 'dropdown',
  // dialogActionsRenderer: () => null,
};

export default DropDownDrawer;
