import { combineReducers } from 'redux';
import app from './app/reducer';
import auth from './auth/reducer';
import sidebar from './sidebar/reducer';
import work from './work/reducer';
import data from './data/reducer';
import report from './report/reducer';
import meritreport from './meritpalk/reducer';
import callbacks from './callbacks/reducer';
import schedule from './schedule/reducer';

const rootReducer = combineReducers({
  app,
  auth,
  sidebar,
  work,
  data,
  report,
  meritreport,
  callbacks,
  schedule,
});

export type GlobalState = ReturnType<typeof rootReducer>;

export default rootReducer;
