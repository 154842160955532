import React, { Suspense } from 'react';
import { Switch, Route, RouteComponentProps, SwitchProps } from 'react-router-dom';

import PageLayout from 'shared/components/Layout/Page';
import { Location } from 'history';

export interface RouteConfigComponentProps<Params extends { [K in keyof Params]?: string } = {}>
  extends RouteComponentProps<Params> {
  route?: RouteConfig;
}

export interface RouteConfig {
  key?: React.Key;
  location?: Location;
  component?: React.FC<any>;
  path?: string | string[];
  exact?: boolean;
  strict?: boolean;
  routes?: RouteConfig[];
  render?: (props: RouteConfigComponentProps<any>) => React.ReactNode;
  [propName: string]: any;
}

export default function renderRoutes(
  routes: RouteConfig[] | undefined,
  extraProps = {},
  switchProps: SwitchProps = {},
  isMain = false,
  id?: string,
): JSX.Element | null {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route: any, i: any) => {
        return (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props => {
              const { pageProps = {}, key } = route;
              const {
                pageTitle,
                hasNavigation,
                requireAuth,
                pageId,
                pageDescription,
                requiredRoles,
                permissions,
                personas,
                level,
              } = pageProps;

              const children = (
                <Suspense fallback={<div></div>}>
                  <route.component {...props} {...extraProps} route={route} />
                </Suspense>
              );
              if (isMain) {
                return (
                  <PageLayout
                    key={key}
                    pageId={pageId || key}
                    pageTitle={pageTitle}
                    hasNavigation={hasNavigation}
                    requireAuth={requireAuth}
                    pageDescription={pageDescription}
                    requiredRoles={requiredRoles}
                    permissions={permissions}
                    personas={personas}
                    level={level}
                    {...props}
                  >
                    {children}
                  </PageLayout>
                );
              }
              return children;
            }}
          />
        );
      })}
    </Switch>
  ) : null;
}
