import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import flowRight from 'lodash/flowRight';

import { dataSelector } from 'shared/redux/selectors';
import useQuery from 'shared/hooks/useQuery';
import withDrawer, { WrappedProps } from 'shared/hocs/withDrawer';

import { Option, ResponseAPI } from 'shared/types';
import { MeritPalkSetting } from 'shared/types/api';

import { getTranslatedWord } from 'shared/utils/tools';

import styled from 'styled-components';
import Box from 'shared/components/Box';
import Checkbox from 'shared/components/Checkbox';
import TextField from 'shared/components/TextField';
import SelectAutocomplete from 'shared/components/SelectAutocomplete';

import { MeritPalkSettingType } from 'pages/MeritPalk/model/settings';

type MeritPalkSettingDrawerProps = WrappedProps<MeritPalkSettingType> & {
  companyId?: string;
};

const MeritPalkSettings = (props: MeritPalkSettingDrawerProps & any) => {
  const { formState, formHandlers, companyId } = props;
  const { onElementChange, onChange, onSetForm } = formHandlers;
  const { fields, errors } = formState;
  const { divisions } = useSelector(dataSelector);
  const [workspaces, setWorkspaces] = useState<any>({});

  const [queryState, queryhandler] = useQuery<null | ResponseAPI<MeritPalkSetting>>(
    {
      url: `/merit-palk/companies/${companyId}/settings`,
    },
    {
      skip: !companyId,
      initialData: null,
      onFetchSuccess,
    },
  );

  const divisionOptions = useMemo(() => {
    return divisions?.list
      ?.map((item: any) => ({
        value: String(item.id),
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) as Option[];
  }, [divisions.list]);

  useEffect(() => {
    if (!fields?.apply_all_workspace && !fields?.workspace_id) {
      onChange('workspace_id', divisionOptions[0]?.value);
    }
  }, [fields?.apply_all_workspace, fields?.workspace_id]);

  if (queryState?.loading)
    return (
      <Box padding="38px 38px">
        <div className="spinner spinner-settings" />
      </Box>
    );

  return (
    <DrawerContainer>
      <Checkbox
        name="apply_all_workspace"
        id="apply_all_workspace"
        label={<StyledLabel>{getTranslatedWord('Use same key for all workspaces')}</StyledLabel>}
        onChange={onElementChange}
        checked={fields?.apply_all_workspace}
        style={{
          marginTop: '24px',
          marginBottom: '24px',
        }}
      />
      <SelectAutocomplete
        label={getTranslatedWord('Workspaces')}
        labelId="workspace"
        id="workspace"
        className="expanded"
        options={divisionOptions}
        value={fields.workspace_id}
        error={errors?.workspace_id}
        disabled={fields?.apply_all_workspace || false}
        onChange={(value: any, key: any) => {
          onChange('workspace_id', value);
          onChangeWorkspace(value, workspaces);
        }}
        autoComplete="off"
        marginBottom="16px"
        placeholder=""
      />
      <LabelDivider>{getTranslatedWord('API Setting')}</LabelDivider>
      <TextField
        id="api_id"
        type="text"
        label={getTranslatedWord('API ID')}
        labelId="merit-palk-settings-api_id"
        value={fields?.api_id || ''}
        placeholder={getTranslatedWord('API ID')}
        error={!!errors.api_id}
        errorText={getTranslatedWord(errors.api_id)}
        onChange={onElementChange}
        caution={Boolean(errors.api_id)}
        marginBottom="32px"
        required
      />
      <TextField
        id="api_key"
        type="text"
        label={getTranslatedWord('API Key')}
        labelId="merit-palk-settings-api_key"
        onChange={onElementChange}
        value={fields?.api_key || ''}
        placeholder={getTranslatedWord('API Key')}
        error={!!errors.api_id}
        errorText={getTranslatedWord(errors.api_id)}
        caution={Boolean(errors.api_id)}
        marginBottom="32px"
        rows={3}
        required
      />
    </DrawerContainer>
  );

  function onFetchSuccess(response: null | ResponseAPI<MeritPalkSettingType>) {
    const data = response?.data as any;
    setWorkspaces(data?.workspaces || []);

    if (data) {
      const divisionIds = divisions?.list?.map((item: any) => item.id);
      onSetForm({ ...data, workspace_ids: divisionIds });

      if (data?.apply_all_workspace == 1) {
        onChange('api_id', data?.api_id);
        onChange('api_key', data?.api_key);
        return null;
      }

      const division = divisionOptions[0]?.value;
      onChange('workspace_id', division);

      if (data?.workspaces?.length > 0) {
        onChangeWorkspace(division, data?.workspaces);
      }
    }
  }

  function onChangeWorkspace(value: any, workspaces: any) {
    const workspace = workspaces.find((item: any) => item.division_id == value);
    onChange('api_id', workspace?.api_id);
    onChange('api_key', workspace?.api_key);
  }
};

const DrawerContainer = styled(Box)`
  & > *:first-child {
    margin-top: 12px;
  }

  &,
  .drawer-toolbar {
    padding: 0px 48px;
  }
`;

const LabelDivider = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.main};
  line-height: 40px;
  margin-bottom: 24px;
`;

const StyledLabel = styled(Box)<{ disabled?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;

  color: ${({ disabled, theme }) => (disabled ? '#545454' : theme.colors.black)};
`;

export default flowRight(withDrawer())(MeritPalkSettings);
