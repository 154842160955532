import React from 'react'
import { SvgIconProps } from './Home'

const SvgSmallLeftArrowIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    fill="none"
    viewBox="0 0 8 12"
  >
    <path
      fill={fill ?? "#353535"}
      stroke={fill ?? "#353535"}
      strokeWidth="0.5"
      d="M6.945 1.992h0a.968.968 0 000-1.411A1.055 1.055 0 005.5.58L1.055 4.819a.968.968 0 000 1.41L5.5 10.469c.4.381 1.044.381 1.445 0a.967.967 0 000-1.411L3.238 5.522l3.707-3.53z"
    ></path>
  </svg>
)


export default SvgSmallLeftArrowIcon