import React from 'react'
import { SvgIconProps } from './Home'

const SvgSettingsIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "48"}
    height={height ?? "48"}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill={fill ?? "#000"}
      d="M32.355 25.085c.038-.316.064-.644.064-.985 0-.34-.026-.67-.076-.985l2.136-1.666c.19-.152.24-.43.127-.644l-2.023-3.497a.515.515 0 00-.62-.227l-2.515 1.01a7.409 7.409 0 00-1.707-.985l-.38-2.677a.505.505 0 00-.505-.429H22.81a.492.492 0 00-.493.43l-.38 2.676a7.606 7.606 0 00-1.706.984l-2.516-1.01a.5.5 0 00-.62.228l-2.022 3.497a.489.489 0 00.126.644l2.137 1.666c-.05.316-.089.657-.089.985 0 .328.026.67.076.985l-2.136 1.666a.521.521 0 00-.127.644l2.023 3.497c.126.227.392.303.62.227l2.515-1.01a7.406 7.406 0 001.707.985l.38 2.677c.05.252.252.429.505.429h4.046a.483.483 0 00.493-.43l.38-2.676a7.604 7.604 0 001.706-.985l2.516 1.01a.5.5 0 00.62-.227l2.022-3.497a.489.489 0 00-.126-.644l-2.111-1.666zm-7.522 2.802A3.801 3.801 0 0121.04 24.1a3.801 3.801 0 013.793-3.788 3.801 3.801 0 013.793 3.788 3.801 3.801 0 01-3.793 3.787z"
    ></path>
  </svg>
)

export default SvgSettingsIcon
