import React from 'react';

import { UserNotification } from 'shared/types/api';

import { getTranslatedWord } from 'shared/utils/tools';

import NotificationItem from './NotificationItem';

import { NotificationDataProps } from './propsType';
import { LabelDivider } from './styledComponents';

const OlderNotifications = ({
  isVisible,
  notificationData,
  handleOnClick,
  handleOnAction,
  actions,
  dropDownRef,
}: NotificationDataProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <LabelDivider>{getTranslatedWord('Older')}</LabelDivider>
      {notificationData.map((item: UserNotification) => (
        <NotificationItem
          dropDownRef={dropDownRef}
          item={item}
          handleOnClick={handleOnClick}
          handleOnAction={handleOnAction}
          actions={actions}
        />
      ))}
    </>
  );
};

export default OlderNotifications;
