import React, { useState, useMemo } from 'react';
import { Cell } from 'react-md';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import flowRight from 'lodash/flowRight';

import { showDialog } from 'shared/redux/app/reducer';
import { setSelectedCompany } from 'shared/redux/data/reducer';

import SvgDeleteIcon from 'assets/icons/Delete';
import SvgEditInformationIcon from 'assets/icons/EditInformation';

import withDrawer, { WrappedProps } from 'shared/hocs/withDrawer';
import useMutation from 'shared/hooks/useMutation';
import useQuery from 'shared/hooks/useQuery';

import { ResponseAPI } from 'shared/types';
import { Company } from 'shared/types/api';

import { getTranslatedWord, serverUploadPath } from 'shared/utils/tools';

import Box from 'shared/components/Box';
import ConfirmDialog from 'shared/components/ConfirmDialog';
import TextField from 'shared/components/TextField';
import Avatar from 'shared/components/DataTable/Avatar';
import SelectUpload from 'shared/components/Attachment/Dialogs/SelectUpload';

import { CompanyInfoType } from 'pages/Admin/model/company';

type CompanyInfoDrawerProps = WrappedProps<CompanyInfoType> & {
  companyId?: string;
};

const CompanyInfoDrawer = (props: CompanyInfoDrawerProps) => {
  const dispatch = useDispatch();
  const { formState, formHandlers, companyId } = props;
  const { onElementChange, onChange, onSetForm } = formHandlers;
  const { fields, errors } = formState;
  const [base64Photo, setBase64Photo] = useState<null | string>(null);

  const [queryState, queryhandler] = useQuery<null | ResponseAPI<Company>>(
    {
      url: `/companies/${companyId}/show`,
    },
    {
      skip: !companyId,
      initialData: null,
      onFetchSuccess,
    },
  );

  const company = useMemo(() => {
    return queryState?.data?.data as Company;
  }, [queryState?.data?.data]);

  const [, onUploadPhoto] = useMutation({
    url: `/companies/${companyId}/logo`,
    method: 'POST',
    message: getTranslatedWord('Company logo updated successfully'),
  });

  const [, onDeletePhoto] = useMutation({
    url: `/companies/${companyId}/logo/remove`,
    method: 'POST',
    message: getTranslatedWord('Company logo removed successfully'),
    onSuccess: (response: any) => {
      dispatch(setSelectedCompany(response?.data));
    },
  });

  const imageUrl = useMemo(() => {
    return base64Photo ?? (company?.logo ? serverUploadPath(company?.logo) : '') + '?cache_id=' + company?.logo_cache;
  }, [base64Photo, company?.logo, company?.logo_cache]);

  if (queryState?.loading)
    return (
      <Box padding="38px 38px">
        <div className="spinner spinner-settings" />
      </Box>
    );

  return (
    <Container>
      <AvatarContainer>
        <Avatar name={company?.name} imageUrl={imageUrl} />
        <EditIconContainer onClick={onUploadImage}>
          <SvgEditInformationIcon />
        </EditIconContainer>
        <RemoveIconContainer onClick={onRemovePhoto}>
          <SvgDeleteIcon fill={'#C52929'} solid />
        </RemoveIconContainer>
      </AvatarContainer>
      <TextField
        id="name"
        type="text"
        labelId="company-profile-name"
        label={getTranslatedWord('Company name')}
        value={fields?.name || ''}
        error={!!errors.name}
        errorText={getTranslatedWord(errors.name)}
        onChange={onElementChange}
        caution={Boolean(errors.name)}
        marginBottom="32px"
      />
      <TextField
        id="tax_number"
        label={getTranslatedWord('Tax number')}
        labelId="company-profile-tax_number"
        onChange={onElementChange}
        errorText={getTranslatedWord(errors.tax_number)}
        value={fields?.tax_number || ''}
        placeholder={getTranslatedWord('Tax number')}
        marginBottom="32px"
        caution={Boolean(errors.tax_number)}
        error={Boolean(errors.tax_number)}
      />
      <TextField
        key={`address`}
        id={`address`}
        label={getTranslatedWord('Address')}
        labelId="company-profile-address"
        onChange={onElementChange}
        value={fields?.address}
        placeholder="Address"
        caution={Boolean(errors.address)}
        error={Boolean(errors.address)}
        rows={4}
        maxRows={4}
        marginBottom="32px"
      />
      <TextField
        key={'telephone_number'}
        id={'telephone_number'}
        label={getTranslatedWord('Telephone')}
        labelId="company-profile-telephone_number"
        onChange={onElementChange}
        value={fields?.telephone_number || ''}
        placeholder={getTranslatedWord('Telephone')}
        caution={Boolean(errors.telephone_number)}
        error={Boolean(errors.telephone_number)}
        marginBottom="32px"
      />
      <TextField
        id="activity"
        type="text"
        label={getTranslatedWord('Business Area')}
        labelId="company-profile-activity"
        value={fields?.activity || ''}
        error={!!errors.activity}
        errorText={getTranslatedWord(errors.activity)}
        onChange={onElementChange}
        caution={Boolean(errors.activity)}
        marginBottom="32px"
        placeholderPrefix={getTranslatedWord('Your')}
      />
    </Container>
  );

  function onRemovePhoto() {
    dispatch(
      showDialog({
        component: ConfirmDialog,
        props: {
          variant: 'danger',
          title: getTranslatedWord('Confirm action'),
          message: getTranslatedWord(`Are you sure you want to remove profile image?`),
          onValid: onDeletePhoto,
          continueLabel: getTranslatedWord('Delete'),
        },
      }),
    );
  }

  function onUploadImage() {
    dispatch(
      showDialog({
        component: SelectUpload,
        props: {
          onSaveImage,
          cropImage: false,
          takePhoto: false,
          hasCancel: false,
          title: getTranslatedWord('Upload Company Logo'),
        },
      }),
    );
  }

  async function onSaveImage(data: string) {
    setBase64Photo(data);
    const file = await fetch(data)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], 'company-logo.png', { type: 'image/png' });
      });

    const form = new FormData();
    form.append('photo', file, 'company-logo.png');

    onUploadPhoto({
      data: form,
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data;`,
      },
      onSuccess: (response: any) => {
        dispatch(setSelectedCompany(response?.data));
      },
    });
  }

  function onFetchSuccess(response: null | ResponseAPI<Company>) {
    if (response?.data) {
      onSetForm({ ...response.data });
      dispatch(setSelectedCompany(response?.data));
    }
  }
};

const Container = styled(Box)`
  padding: 0 48px !important;

  ${({ theme }) => theme.mediaQueries.largeDown} {
    padding: 0 16px !important;
    padding-bottom: 144px !important;
  }
`;

const RemoveIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  right: -35px;
  top: 75px;
  position: relative;
  border-radius: 100%;
  transform: translate3d(120px, -160px, 10px);
  background-color: ${({ theme }) => theme.colors.secondaries.pink};
  cursor: pointer;
`;

const EditIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 115px;
  position: relative;
  border-radius: 100%;
  transform: translate3d(120px, -160px, 10px);
  background-color: ${({ theme }) => theme.colors.secondaries.blue};
  cursor: pointer;
`;

const AvatarContainer = styled(Box)`
  height: 144px;
  width: 144px;
  margin: 32px auto;
  div:first-child {
    margin: auto;
    height: 144px;
    width: 144px;

    span {
      font-weight: 400;
      font-size: 48px;
      line-height: 32px;
    }
  }
`;

export default flowRight(withDrawer())(CompanyInfoDrawer);
