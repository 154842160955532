import React from 'react'
import { SvgIconProps } from './Home'

const SvgSmallRightArrowIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    fill="none"
    viewBox="0 0 8 12"
  >
    <path
      fill={fill ?? "#353535"}
      stroke={fill ?? "#353535"}
      strokeWidth="0.5"
      d="M1.055 9.057h0a.968.968 0 000 1.411c.4.382 1.045.382 1.445 0L6.945 6.23a.968.968 0 000-1.41L2.5.58a1.055 1.055 0 00-1.445 0 .968.968 0 000 1.41l3.707 3.536-3.707 3.53z"
    ></path>
  </svg>
)


export default SvgSmallRightArrowIcon