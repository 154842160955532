import React from 'react'
import { SvgIconProps } from './Home'

const SvgRequestIcon = ({ fill, width, height }: SvgIconProps) =>
(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "26"}
    height={height ?? "26"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill ?? "#172271"}
      d="M10 19.7104C4.477 19.7104 0 15.4642 0 10.2259C0 4.98851 4.477 0.741333 10 0.741333C15.523 0.741333 20 4.98851 20 10.2259C20 15.4642 15.523 19.7104 10 19.7104ZM11.276 11.916L11.67 11.6239C12.0322 11.3555 12.3351 11.022 12.5614 10.6426C12.7877 10.2632 12.933 9.84516 12.989 9.41247C13.045 8.97977 13.0106 8.54085 12.8877 8.12076C12.7649 7.70068 12.556 7.30766 12.273 6.96414C11.99 6.62062 11.6384 6.33334 11.2384 6.11869C10.8383 5.90404 10.3976 5.76622 9.94139 5.71311C9.48517 5.66001 9.0224 5.69265 8.57948 5.80917C8.13657 5.92569 7.72219 6.12381 7.36 6.39222L6.966 6.68435C6.86035 6.76027 6.77166 6.85537 6.70511 6.9641C6.63855 7.07283 6.59545 7.19302 6.57832 7.31769C6.56118 7.44236 6.57035 7.56902 6.60529 7.6903C6.64023 7.81158 6.70024 7.92506 6.78184 8.02414C6.86344 8.12322 6.96499 8.20593 7.0806 8.26745C7.1962 8.32897 7.32354 8.36808 7.45523 8.3825C7.58691 8.39692 7.7203 8.38636 7.84765 8.35145C7.97499 8.31653 8.09374 8.25795 8.197 8.17911L8.591 7.88699C8.90461 7.65614 9.30192 7.55262 9.69587 7.59911C10.0898 7.6456 10.4483 7.83831 10.6927 8.135C10.9371 8.4317 11.0475 8.80819 10.9998 9.18198C10.952 9.55576 10.75 9.89636 10.438 10.1291L10.044 10.4213C9.94052 10.498 9.85398 10.5933 9.78934 10.7017C9.72469 10.8102 9.68319 10.9296 9.66722 11.0533C9.65125 11.1769 9.66111 11.3024 9.69625 11.4224C9.73139 11.5424 9.79111 11.6547 9.872 11.7529C9.95289 11.851 10.0534 11.9331 10.1677 11.9944C10.2821 12.0557 10.408 12.0951 10.5384 12.1102C10.6688 12.1254 10.801 12.116 10.9276 12.0827C11.0541 12.0494 11.1725 11.9927 11.276 11.916V11.916ZM10 14.9681C10.2652 14.9681 10.5196 14.8682 10.7071 14.6903C10.8946 14.5125 11 14.2712 11 14.0197C11 13.7681 10.8946 13.5269 10.7071 13.349C10.5196 13.1712 10.2652 13.0712 10 13.0712C9.73478 13.0712 9.48043 13.1712 9.29289 13.349C9.10536 13.5269 9 13.7681 9 14.0197C9 14.2712 9.10536 14.5125 9.29289 14.6903C9.48043 14.8682 9.73478 14.9681 10 14.9681V14.9681Z"
    ></path>
  </svg>
)

export default SvgRequestIcon

