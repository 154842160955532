import React from 'react'
import { SvgIconProps } from './Home'

const SvgCheckIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "16"}
    height={height ?? "16"}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={fill ?? "#108D59"}
      d="M6.255 12.071a.997.997 0 01-.707-.292L1.306 7.536a1 1 0 111.413-1.415l3.535 3.535 6.365-6.363a1 1 0 111.415 1.414L6.962 11.78a.997.997 0 01-.707.292z"
    ></path>
  </svg>
)

export default SvgCheckIcon
