import axiosLib, { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'shared/utils/axios';

export interface UseQueryOptions<T> {
  initialData: T | (() => T);
  isBase?: boolean;
  skip?: boolean;
  onFetchSuccess?: (data: T) => void;
}

export interface UseQueryState<T> {
  data: T;
  loading: boolean;
  error: Error | undefined;
  query: any;
}

export interface UseQueryHandlers {
  refetch: () => void;
}

function useQuery<T>(config: AxiosRequestConfig, options: UseQueryOptions<T>, getData?: (response: any) => any) {
  const { initialData, skip = false, onFetchSuccess = () => {} } = options;
  const { url, data: axiosData } = config;
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [localURL, setLocalURL] = useState<string | undefined>(url);
  const [error, setError] = useState<Error>();
  const [refetchCount, setRefetchCount] = useState(0);
  useEffect(() => {
    const source = axiosLib.CancelToken.source();

    if (!skip) {
      fetchData(config, source);
    } else {
      setIsLoading(false);
    }
    return () => {
      source.cancel();
    };
  }, [url, refetchCount, axiosData]);
  const queryState: UseQueryState<T> = { data: getData ? getData(data) : data, loading: isLoading, error, query: {} };
  return [queryState, { refetch }, data] as const;

  function fetchData(config?: AxiosRequestConfig, source?: any, callback?: Function, onError?: Function) {
    setIsLoading(true);
    setLocalURL(url);
    axios({
      ...config,
      cancelToken: source.token,
    })
      .then((response: any) => {
        setData(response);
        setIsLoading(false);
        if (callback) {
          callback(response);
        } else {
          onFetchSuccess(response);
        }
      })
      .catch(error => {
        if (!axiosLib.isCancel(error)) {
          setError(error);
        }

        if (onError) {
          onError(error);
        }

        setLocalURL(prev => {
          if (prev === url) setIsLoading(false);
          return prev;
        });
      });
  }

  function refetch(refetchConfig?: AxiosRequestConfig & { callback?: Function; onError?: Function }) {
    if (config) {
      const source = axiosLib.CancelToken.source();
      let axiosConfig = {
        ...config,
        ...omit(refetchConfig, 'callback'),
      };
      fetchData(axiosConfig, source, refetchConfig?.callback, refetchConfig?.onError);
    } else {
      setRefetchCount(prev => prev + 1);
    }
  }
}

export default useQuery;
