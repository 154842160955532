import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Button as RButton } from 'reakit';
import {
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant as variantHelper,
} from 'styled-system';

type ButtonProps = SpaceProps &
  LayoutProps &
  TypographyProps & {
    id?: string;
    type?: 'button' | 'submit' | 'reset';
    value?: string;
    className?: string;
    children?: ReactNode;
    onClick?: any;
    disabled?: boolean;
    block?: boolean;
    expanded?: boolean;
    variant?: string;
    isLoading?: boolean;
    icon?: string | ReactNode;
    size?: 'small' | 'medium' | 'large' | 'extraLarge';
    background?: string;
    isFullWidth?: boolean;
    isAutoHeight?: boolean;
    marginBottom?: string;
  };

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    className,
    block,
    expanded,
    disabled,
    id,
    type,
    value,
    onClick = () => {},
    isLoading,
    size,
    isFullWidth = false,
    isAutoHeight = false,
    marginBottom,
    ...restProps
  } = props;

  const buttonClasses = classNames(className, {
    block: block,
    expanded: expanded,
  });

  const isActionButton = useMemo(() => restProps?.variant?.includes('action'), [restProps?.variant]);

  return (
    <ButtonContainer
      id={id}
      type={type}
      className={buttonClasses}
      value={value}
      onClick={onClick}
      disabled={disabled}
      marginBottom={marginBottom}
      $size={size}
      $isFullWidth={isFullWidth}
      $isAutoHeight={isAutoHeight}
      $isNoWrap={isActionButton}
      {...restProps}
    >
      {isActionButton && !props.isLoading && props.icon !== undefined && getIcon(props.icon, true)}
      {props.isLoading ? <div className="spinner" /> : props.children}
      {!isActionButton && !props.isLoading && props.icon !== undefined && getIcon(props.icon, false)}
    </ButtonContainer>
  );

  function getIcon(icon: string | ReactNode, isActionButton?: boolean) {
    return (
      <IconContainer $action={isActionButton}>
        {typeof icon === 'string' ? <img src={icon} className={'button__icon'} /> : props.icon}
      </IconContainer>
    );
  }
};

const ButtonContainer = styled(RButton)<
  ButtonProps & { $size?: string; $isFullWidth?: boolean; $isAutoHeight?: boolean; $isNoWrap?: boolean }
>`
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
  min-width: ${({ value }) => {
    const minLength = value !== undefined ? value!.length * 8 + 30 : 85;
    return `${minLength}px`;
  }};
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100% !important' : 'fit-content')};
  ${({ $isAutoHeight }) => {
    return $isAutoHeight ? 'height: auto !important;' : '';
  }}
  ${({ $isNoWrap }) => {
    return $isNoWrap ? 'white-space: nowrap !important;' : '';
  }}
  padding: 12px 16px;
  // text-transform: capitalize;

  transition: background-color 250ms
    cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms
    cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms
    cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${compose(space, layout, typography)}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return 'height: 32px;';
      case 'medium':
        return 'height: 48px;';
      case 'large':
        return 'height: 56px;';
      case 'extraLarge':
        return 'height: 60px;';
      default:
        return 'height: 48px;';
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case 'danger':
        return `
        background-color: ${theme.colors.caution};
        color: ${theme.colors.white};
        border: 0;

        &:hover {
          background-color: ${theme.colors.gray.very_dark};
        }

        &:active {
          background-color: ${theme.colors.gray.almost_black};
        }

        &:disabled {
          background-color: ${theme.colors.gray.medium_dark};
        }
        `;
      case 'primary':
        return `
          background-color: ${theme.colors.main};
          color: ${theme.colors.white};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.very_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.almost_black};
          }

          &:disabled {
            background-color: ${theme.colors.gray.medium_dark};
          }
        `;
      case 'additional':
        return `
          background-color: ${theme.colors.gray.dark};
          color: ${theme.colors.white};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.pitch_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.pitch_dark};
          }

          &:disabled {
            color: ${theme.colors.disabled};
            background-color: ${theme.colors.disabled};
          }
        `;
      case 'success':
        return `
          background-color: ${theme.colors.secondaries.green};
          color: ${theme.colors.green};
          border: 0;

          &:hover {
            background-color: #D3D6E8;
          }

          &:active {
            background: #C2C5D8;
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.gray.medium_dark};
            background-color: ${theme.colors.secondaries.blue};
          }
        `;
      case 'secondary':
        return `
          font-weight: normal;
          background-color: ${theme.colors.gray.medium};
          color: ${theme.colors.gray.dark};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.medium_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.dark_gray};
          }

          &:disabled {
            color: ${theme.colors.gray.medium_dark};
            background-color: ${theme.colors.gray.light_gray};
          }
        `;
      case 'white':
        return `
          font-weight: normal;
          background-color: ${theme.colors.gray.light};
          color: ${theme.colors.gray.dark};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.medium_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.dark_gray};
          }

          &:disabled {
            color: ${theme.colors.gray.medium_dark};
            background-color: ${theme.colors.gray.light_gray};
          }
        `;
      case 'action':
        return `
          border: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 24px;

          background-color: ${theme.colors.secondaries.blue};
          color: ${theme.colors.gray.dark};

          border-radius: 6px;
          height: 32px;
          padding: 8px 16px;


          &:hover {
            background-color: #D3D6E8;
          }

          &:active {
            background: #C2C5D8;
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.gray.medium_dark};
            background-color: ${theme.colors.secondaries.blue};
          }
        `;

      case 'action-success':
        return `
          border: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 24px;
          text-transform: none;

          background-color: ${theme.colors.secondaries.green};
          color: ${theme.colors.green};

          border-radius: 6px;
          height: 32px;
          padding: 8px 16px;
          opacity: 1;

          &:hover {
            background-color: #D3D6E8;
          }

          &:active {
            background: #C2C5D8;
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.success};
            background-color: ${theme.colors.secondaries.green};
          }
        `;
      case 'action-danger':
        return `
          border: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 24px;
          text-transform: none;
          opacity: 1;

          background-color: ${theme.colors.secondaries.pink};
          color: ${theme.colors.caution};

          border-radius: 6px;
          height: 32px;
          padding: 8px 16px;


          &:hover {
            background-color: #D3D6E8;
          }

          &:active {
            background: #C2C5D8;
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.caution};
            background-color: ${theme.colors.secondaries.pink};
          }
        `;

      case 'action-warning':
        return `
          border: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 24px;
          text-transform: none;

          background-color: ${theme.colors.yellow};
          color: ${theme.colors.dark_yellow};

          border-radius: 6px;
          height: 32px;
          padding: 8px 16px;


          &:hover {
            background-color: #D3D6E8;
          }

          &:active {
            background: #C2C5D8;
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.dark_yellow};
            background-color: ${theme.colors.yellow};
          }
        `;
      case 'anchor':
        return `
          border: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 32px;
          color: ${theme.colors.main};
          background: none;

          &:hover {
            color: #0C1139;
            text-decoration-line: underline;
          }

          &:active {
            color: #050717;
          }

          &:disabled {
            color: #C9CBD7;
          }
        `;
      default:
        return `
          background: none;
          border: 0;
        `;
    }
  }}

  &:focus {
    outline: none;
  }
`;

const IconContainer = styled.div<{ $action?: boolean }>`
  display: flex;

  ${({ $action }) => `
    ${$action ? `margin-right: 10px;` : `margin-left: 10px;`}
  `}
`;

export default Button;
