import React from 'react';

export default class ArrowDown extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00003 10.0009C7.78197 10.0012 7.56636 9.95496 7.36759 9.86529C7.16883 9.77562 6.99147 9.64458 6.84736 9.48093L0.306695 2.04626C0.0999679 1.7999 -0.00216486 1.48243 0.0221348 1.16174C0.0464344 0.841047 0.195239 0.542592 0.436729 0.330189C0.678219 0.117786 0.993226 0.00829416 1.31439 0.0251265C1.63556 0.0419588 1.9374 0.183779 2.15536 0.420262L7.8747 6.92159C7.89034 6.93944 7.90961 6.95374 7.93123 6.96354C7.95284 6.97333 7.9763 6.9784 8.00003 6.9784C8.02376 6.9784 8.04722 6.97333 8.06883 6.96354C8.09045 6.95374 8.10972 6.93944 8.12536 6.92159L13.8447 0.420262C14.0627 0.183779 14.3645 0.0419589 14.6857 0.0251266C15.0068 0.00829432 15.3218 0.117786 15.5633 0.330189C15.8048 0.542592 15.9536 0.841047 15.9779 1.16174C16.0022 1.48243 15.9001 1.7999 15.6934 2.04626L9.1547 9.47826C9.01033 9.64232 8.83271 9.77377 8.63363 9.86389C8.43454 9.95401 8.21856 10.0007 8.00003 10.0009Z"
          fill="#353B5A"
        />
      </svg>
    );
  }
}
