import SvgCalendarIconFilled from 'assets/icons/CalendarFilled';
import { flowRight } from 'lodash';
import { PaidAbsenceProps } from 'pages/People/model/paid-absence';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from 'shared/components/Box';
import CustomDatePicker from 'shared/components/DatePicker';
import SelectAutocomplete from 'shared/components/SelectAutocomplete';
import TextField from 'shared/components/TextField';
import withDrawer from 'shared/hocs/withDrawer';
import useQuery from 'shared/hooks/useQuery';
import useRouter from 'shared/hooks/useRouter';
import { dataSelector } from 'shared/redux/selectors';
import { Option } from 'shared/types';
import { getTranslatedWord } from 'shared/utils/tools';
import styled from 'styled-components';
import moment from 'moment';

const PaidAbsenceRequestForm = (props: PaidAbsenceProps) => {
  const router = useRouter<any>();
  const { vacationTypes, people } = useSelector(dataSelector);
  const { formState, formHandlers, requestId } = props;
  const { fields, errors } = formState;
  const { onChange, onElementChange, onSetForm } = formHandlers;

  const [queryState, queryHandler] = useQuery<any>(
    {
      url: `/users/work-leaves/request`,
      method: 'GET',
      params: {
        id: requestId,
      },
    },
    {
      initialData: null,
    },
  );

  const vacation_types = (vacationTypes?.list ?? [])?.map((item: any) => ({
    value: item.id,
    label: item.name,
    division_id: item.division_id,
  })) as Option[];

  useEffect(() => {
    if (queryState?.data)
      onSetForm({
        type_id: queryState?.data?.data?.type_id,
        started_at: moment.unix(queryState?.data?.data?.started_at / 1000),
        ended_at: moment.unix(queryState?.data?.data?.ended_at / 1000),
        comment: queryState?.data?.data?.comment,
      });
  }, [queryState?.data]);

  return (
    <Container>
      {queryState?.loading && <div className="spinner spinner-settings" />}
      {!queryState?.loading && (
        <Fragment>
          <SelectAutocomplete
            label={getTranslatedWord('Choose type')}
            labelId="paid-absence-request-type_id"
            id="type_id"
            key="type_id"
            className="expanded"
            onChange={onElementChange}
            options={vacation_types}
            value={fields?.type_id}
            error={errors?.type_id}
            disabled={false}
            marginBottom="32px"
            required
          />
          <CustomDatePicker
            id="started_at"
            label={getTranslatedWord('Start')}
            value={fields?.started_at}
            onChange={value => {
              onChange('started_at', value);
              onChange('ended_at', value);
            }}
            marginBottom="32px"
            error={errors.started_at}
            icon={<SvgCalendarIconFilled />}
            dateFormat={'dd.MM.yyyy'}
            reverseIcon
            scrollOnFocus
          />
          <CustomDatePicker
            id="ended_at"
            label={getTranslatedWord('Until')}
            onChange={onElementChange}
            value={fields?.ended_at}
            marginBottom="32px"
            error={errors.ended_at}
            dateFormat={'dd.MM.yyyy'}
            icon={<SvgCalendarIconFilled />}
            reverseIcon
            scrollOnFocus
          />
          <TextField
            id="comment"
            label={getTranslatedWord('Comment')}
            labelId="paid-absence-request-comment"
            onChange={onElementChange}
            errorText={getTranslatedWord(errors?.comment)}
            value={fields?.comment}
            placeholder={getTranslatedWord('Comment')}
            marginBottom="32px"
            caution={Boolean(errors.comment)}
            error={Boolean(errors.comment)}
            rows={4}
            maxRows={4}
          />
        </Fragment>
      )}
    </Container>
  );
};

export default flowRight(withDrawer())(PaidAbsenceRequestForm);

const Container = styled(Box)`
  padding: 32px 48px;
  height: 100%:

  ${({ theme }) => theme.mediaQueries.largeDown} {
    padding: 32px 8px;
  }
`;
