import { WrappedProps } from 'shared/hocs/withDrawer';
import { Identifiable } from 'shared/types';
import { genericTexts } from 'shared/utils/formats';
import { getValidationResult } from 'shared/utils/tools';

import { getEmptyRights, UserRightsType, schema as UserRightsSchema } from 'pages/People/model/user_rights';

import * as yup from 'yup';

const { isRequired } = genericTexts;

export const isEmail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const schema = yup.object().shape({
  workspace: yup.string().required(isRequired),
  language: yup.string().required(isRequired),
  emails: yup
    .array(yup.string())
    .required(isRequired)
    .test(
      'emails',
      ({ value }: any) => {
        const inValids = value.filter((val: string) => !isEmail(val))?.join(',');
        if (inValids) {
          return `Invalid email address [${inValids}]`;
        }
        return 'Invalid email address';
      },
      (value: string[]) => value && value.every(isEmail),
    ),
  rights: UserRightsSchema,
});

export type UserInviteType = yup.InferType<typeof schema>;

export function getEmptyUserInvite(): UserInviteType {
  return {
    workspace: '',
    language: '',
    emails: [],
    rights: getEmptyRights(),
  };
}

export default function validator(data: UserInviteType) {
  return getValidationResult(data, schema);
}
