import React from 'react'
import { SvgIconProps } from './Home'
import styled, { ThemeContext } from 'styled-components'

const SvgArrowdDownIcon = ({ fill, width, height, rotate }: SvgIconProps & { rotate?: string}) =>
(
  <StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "16"}
    height={height ?? "16"}
    fill="none"
    viewBox="0 0 16 16"
    rotate={rotate}
  >
    <g clipPath="url(#clip0)">
      <path
        fill={fill ?? "#353B5A"}
        d="M8 13a1.534 1.534 0 01-1.153-.52L.307 5.045A1.231 1.231 0 012.155 3.42l5.72 6.502a.167.167 0 00.25 0l5.72-6.502a1.231 1.231 0 011.848 1.626l-6.538 7.432A1.54 1.54 0 018 13z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H16V16H0z" transform="rotate(90 8 8)"></path>
      </clipPath>
    </defs>
  </StyledSVG>
)

const StyledSVG = styled.svg<{rotate?: string}>`
  transition: all .3s ease-out;

  ${({ rotate}) => rotate && `
    transform: rotate(${rotate});
  `}
`

export default SvgArrowdDownIcon


