import React, { ReactNode } from 'react';
import Button from 'react-md/lib/Buttons/Button';
import { SVGIcon } from 'react-md';

export type DrawerTitleProps = {
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMinimize?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: ReactNode;
  subtitle?: string;
  minimize?: boolean;
  $noBottom?: boolean;
  $withBottomMargin?: boolean;
};

function DefaultDrawerTitle(props: DrawerTitleProps) {
  const { onClose, onMinimize, title, subtitle, minimize } = props;
  return (
    <>
      <span className="i_dialog_title-title">{title}</span>
      {subtitle && <div className="i_dialog_title-subtitle">{subtitle}</div>}
      {minimize && (
        <Button icon className="i_dialog_title-minimize" onClick={onMinimize}>
          <SVGIcon aria-label="Github">
            <rect fill="none" height="24" width="24" />
            <path d="M22,3.41l-5.29,5.29L20,12h-8V4l3.29,3.29L20.59,2L22,3.41z M3.41,22l5.29-5.29L12,20v-8H4l3.29,3.29L2,20.59L3.41,22z" />
          </SVGIcon>
        </Button>
      )}
      <Button icon children="close" onClick={onClose} className="i_dialog_title-close" />
    </>
  );
}

export default DefaultDrawerTitle;
