import React from 'react'
import { SvgIconProps } from './Home'

const SvgSmileIcon = ({ fill, width, height }: SvgIconProps) =>
(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill ?? "#108D59"}
      d="M12 22.358c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-3.92-6.606a4.264 4.264 0 007.84 0 1 1 0 00-1.84-.788 2.264 2.264 0 01-4.16 0 1 1 0 10-1.84.788zM9 8.358a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm6 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z"
    ></path>
  </svg>
)

export default SvgSmileIcon
