import React, { useContext, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import PropTypes from 'prop-types'
import Button from 'shared/components/Button'
import 'react-image-crop/dist/ReactCrop.css'
import Box from '../Box'
import { DialogBackToContainer, DialogBackToText } from '../ImageTaker'
import styled, { ThemeContext } from 'styled-components'
import { hideDialog } from 'shared/redux/app/reducer'
import SvgBackIcon from 'assets/icons/Back'
import { useDispatch } from 'react-redux'

type ImageCropperProps = {
  onSave: Function
  cropConfig: Crop
  locked?: boolean
  imgSrc: string
  maxWidth?: number
  minWidth?: number
  onBack: () => void
  overflow: string
}

function ImageCropper(props: ImageCropperProps) {
  const { onSave, cropConfig, locked, imgSrc, maxWidth, minWidth, onBack, overflow } = props

  const imageRef = useRef<HTMLImageElement | null>(null)

  const [crop, setCrop] = useState<Crop>(cropConfig)

  const dispatch = useDispatch()

  const theme = useContext(ThemeContext)

  return (
    <>
      <Box>
        <CropContainer $overflow={overflow}>
          <ReactCrop
            locked={locked}
            crop={crop}
            src={imgSrc}
            onChange={newCrop => setCrop(newCrop)}
            onImageLoaded={onSetImage}
            maxWidth={maxWidth}
            minWidth={minWidth}
          />
        </CropContainer>
        <ActionButtonsContainer>
          <Button variant="outline-primary" size="extraLarge" width="140px" mr="20px" onClick={() => dispatch(hideDialog())}>Retake</Button>
          <Button variant="primary" size="extraLarge" width="220px" onClick={onSaveCrop}>Set as Profile Photo</Button>
        </ActionButtonsContainer>
      </Box>
      <DialogBackToContainer onClick={onBack}>
        <SvgBackIcon fill={theme.colors.main} />
        <DialogBackToText>Back</DialogBackToText>
      </DialogBackToContainer>
    </>
  )

  function onSetImage(image: HTMLImageElement) {
    imageRef.current = image
  }

  function onSaveCrop() {
    const image = imageRef.current
    const canvas: any = document.createElement('canvas')
    if (canvas && image) {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(
          image,
          (crop.x || 0) * scaleX,
          (crop.y || 0) * scaleY,
          (crop.width || 0) * scaleX,
          (crop.height || 0) * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        )
        onSave(canvas.toDataURL('image/jpeg'))
      }
    }
  }
}

ImageCropper.defaultProps = {
  cropConfig: {
    unit: 'px',
    x: 130,
    y: 50,
    width: 300,
    aspect: 1 / 1,
  },
  maxWidth: 400,
  minWidth: 300,
  locked: false,
  onSave: () => {},
  overflow: 'hidden'
}

ImageCropper.propTypes = {
  cropConfig: PropTypes.object,
  locked: PropTypes.bool,
  onSave: PropTypes.func,
}

const ActionButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CropContainer = styled(Box)<{ $overflow: string }>`
  width: 550px;
  height: 412.5px;
  margin: 40px auto;
  overflow: ${({ $overflow }) => $overflow};
  border-radius: ${({ theme }) => theme.borderRadius};

  img {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`

export default ImageCropper
