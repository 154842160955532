import * as yup from 'yup';

import { getValidationResult } from 'shared/utils/tools';
import { genericTexts } from 'shared/utils/formats';

const { isRequired } = genericTexts;

export const meritPalkSettingSchema = yup.object().shape({
  apply_all_workspace: yup.boolean().notRequired(),
  workspace_ids: yup
    .array()
    .of(yup.string())
    .nullable(true)
    .notRequired(),
  workspace_id: yup
    .string()
    .when('apply_all_workspace', {
      is: false,
      then: yup.string().required(isRequired),
    })
    .nullable(true)
    .notRequired(),
  api_id: yup
    .string()
    .required(isRequired)
    .nullable(true),
  api_key: yup
    .string()
    .required(isRequired)
    .nullable(true),
});

export type MeritPalkSettingType = yup.InferType<typeof meritPalkSettingSchema>;

export function getEmptyMeritPalkSetting(): MeritPalkSettingType {
  return {
    apply_all_workspace: false,
    workspace_ids: null,
    workspace_id: null,
    api_id: null,
    api_key: null,
  };
}

export default function validator(data: MeritPalkSettingType) {
  return getValidationResult(data, meritPalkSettingSchema);
}
