import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from 'shared/redux/reducer';
import flatMap from 'lodash/flatMap';

export const userSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  state => state.auth.sessionLoading,
  state => state.auth.sessionRequested,
  (auth, sessionLoading, sessionRequested) => {
    const isAdmin = auth?.roles?.find(role => role.name === 'admin');
    const isManager = auth?.roles?.find(role => role.name === 'manager');

    const availableRoles = [3, isAdmin && 2, isManager && 1];

    return {
      auth,
      sessionLoading,
      sessionRequested,
      roles: { isManager, isAdmin },
      availableRoles,
    };
  },
);

export const authSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  state => state.auth.sessionLoading,
  state => state.auth.sessionRequested,
  (auth, sessionLoading, sessionRequested) => ({
    auth,
    sessionLoading,
    sessionRequested,
  }),
);

export const dataSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  state => state.auth.sessionLoading,
  state => state.auth.sessionRequested,
  state => state.data,
  (auth, sessionLoading, sessionRequested, data) => {
    const divisions = flatMap(auth?.companies, company => company.divisions);
    return {
      missingWorks: {
        list: data?.missing_works?.list,
        fetching: data?.missing_works?.fetching,
      },
      divisions: {
        all: data?.divisions?.all,
        list: data?.divisions?.list || divisions,
        selected: data?.divisions.selected,
        fetching: sessionLoading,
      },
      companies: {
        list: auth?.companies,
        fetching: sessionLoading,
      },
      people: {
        all: data?.people?.all,
        list: data?.people?.list,
        fetching: data?.people?.fetching,
      },
      workTypes: data?.workTypes,
      workSpace: data.workSpace,
      vacationTypes: data?.vacationTypes,
      persona: data.persona,
      translation: data.translation,
      overtimeGroup: data.overtimeGroup,
      workshiftGroup: data.workshiftGroups,
      allowedPauseGroup: data.allowedPauseGroups,
      dailyAllowanceGroup: data.dailyAllowanceGroups,
      compensationGroup: data.compensationGroups,
      workingHoursReport: data.workingHoursReport,
      scheduleFilters: data.scheduleFilters,
      netvisorDimension: data.netvisorDimensions,
      netvisorSettings: data.netvisorSettings,
      boxes: data.boxes,
      innerRangeDoors: data.innerRangeDoors,
      innerRangeSettings: data.innerRangeSettings,
      permissions: data.permissions,
      globalFilters: data.filters,
      notifications: data.notifications,
      holidays: data.holidays,
      isSwitching: data.isSwitching,
      isProgressing: data.isProgressing,
      isIntercomBooted: data.isIntercomBooted,
    };
  },
);

export const callbacksSelector = createSelector(
  (state: GlobalState) => state.callbacks.refetch,
  refetch => ({ refetch }),
);

export const scheduleSelector = createSelector(
  (state: GlobalState) => state.schedule,
  schedule => {
    return {
      success: schedule?.success,
      error: schedule?.error,
      updating: schedule?.updating,
      settingEvents: schedule?.settingEvents,
      filteringEvents: schedule?.filteringEvents,
      schedules: {
        default: schedule?.schedules?.default,
        list: schedule?.schedules?.list,
        unfiltered: schedule?.schedules?.unfiltered,
        unconfirmed: schedule?.schedules?.unconfirmed,
        fetching: schedule?.schedules?.fetching,
      },
      presets: {
        list: schedule?.presets?.list,
        fetching: schedule?.presets?.fetching,
        tasks: schedule?.presets?.tasks,
        shifts: schedule?.presets?.shifts,
      },
      positions: {
        list: schedule?.positions?.list,
        fetching: schedule?.positions?.fetching,
        max: schedule?.positions?.max,
      },
      selection: {
        shift: schedule?.selection?.shift,
        copying: schedule?.selection?.copying,
        dragging: schedule?.selection?.dragging,
        bounded: schedule?.selection?.bounded,
        events: schedule?.selection?.events,
        clicked: schedule?.selection?.clicked,
        copied: schedule?.selection?.copied,
      },
    };
  },
);
