import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { User } from 'shared/redux/auth/reducer';
import { setIsIntercomBooted } from 'shared/redux/data/reducer';
import { authSelector } from 'shared/hocs/withAuth';

const useIntercomMethods = () => {
  const { boot, shutdown, update } = useIntercom();
  const { auth } = useSelector(authSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  //=============================
  // Booting Intercom
  //=============================
  const bootIntercom = (user: User | null) => {
    boot({
      userId: String(user?.id),
      userHash: user?.user_hash,
      name: user?.full_name,
      email: user?.email,
      phone: String(user?.phone_numbers?.[0]),
      languageOverride: user?.language,
      avatar: {
        type: 'avatar',
        imageUrl: user?.thumb_nail,
      },
      company: {
        companyId: String(user?.default_company?.id),
        name: user?.default_company?.name,
      },
      customLauncherSelector: '.intercom-launcher',
      hideDefaultLauncher: true,
    });

    const timeout = setTimeout(() => clearInterval(interval), 50000);
    const interval = setInterval(() => {
      // Add class to show the launcher
      document.querySelector('.intercom-launcher')?.classList.add('intercom-booted');
      document.querySelector('.intercom-icon-open')?.classList.remove('intercom-open');
      document.querySelector('.intercom-icon-close')?.classList.remove('intercom-open');
      dispatch(setIsIntercomBooted(true));

      clearInterval(interval);
      clearTimeout(timeout);
    }, 100);
  };

  //=============================
  // Shutting Down Intercom
  //=============================
  const shutdownIntercom = () => {
    shutdown();
    document.querySelector('.intercom-launcher')?.classList.remove('intercom-booted');
  };

  //=============================
  // Handle On Change Page
  //=============================
  const handleOnChangePage = (page?: any) => {
    update({
      email: auth?.email,
      lastRequestAt: String(moment().unix()),
    });

    if (page) {
      history.push(page);
    }
  };

  //=============================
  // Handle On Change Language
  //=============================
  const handleOnChangeLanguage = (language?: any) => {
    update({
      email: auth?.email,
      languageOverride: language,
    });
    shutdownIntercom();
    bootIntercom(auth);
  };

  return {
    bootIntercom,
    shutdownIntercom,
    handleOnChangePage,
    handleOnChangeLanguage,
  };
};

export default useIntercomMethods;
