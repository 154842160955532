import React from 'react'
import { SvgIconProps } from './Home'

const SvgCameraIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "59"}
    height={height ?? "50"}
    fill="none"
    viewBox="0 0 59 50"
  >
    <path
      fill={fill ?? "#C7C7C7"}
      d="M19.34 11.552a2.437 2.437 0 01-2.027 1.086h-9.75a2.437 2.437 0 00-2.438 2.437v26.813a2.437 2.437 0 002.438 2.437h43.874a2.437 2.437 0 002.438-2.437V15.075a2.437 2.437 0 00-2.438-2.437h-9.75a2.437 2.437 0 01-2.028-1.086l-4.151-6.227H23.492l-4.151 6.227zm32.098-3.79a7.312 7.312 0 017.312 7.313v26.813a7.312 7.312 0 01-7.313 7.312H7.563A7.312 7.312 0 01.25 41.888V15.075a7.312 7.312 0 017.313-7.312h8.445l4.151-6.227A2.437 2.437 0 0122.188.45h14.625c.814 0 1.575.408 2.028 1.086l4.151 6.227h8.446zM29.5 39.45c-6.731 0-12.188-5.456-12.188-12.187 0-6.731 5.457-12.188 12.188-12.188 6.731 0 12.188 5.457 12.188 12.188 0 6.73-5.457 12.187-12.188 12.187zm0-4.875a7.312 7.312 0 100-14.625 7.312 7.312 0 000 14.625z"
    ></path>
  </svg>
)


export default SvgCameraIcon