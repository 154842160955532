import styled from 'styled-components';
import Box from '../Box';
import {
  StyledTableColumn as StyledTableColumnWorkType,
  StyledTableRow as StyledTableRowWorkType,
} from 'pages/WorkType/components/styledComponents';

export const StyledTableRow = styled('tr')`
  height: 60px;
  cursor: pointer;

  &:not(:first-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray.medium};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  td {
    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
`;

export const LoadingSkeleton = styled(Box)<{ $width: number }>`
  background-color: ${({ theme }) => theme.colors.gray.medium};
  transition: width 1s ease-in-out;
  display: inline-block;
  height: 15px;
  overflow: hidden;
  position: relative;
  width: ${({ $width }) => $width}%;
  border-radius: 20px;

  &::after {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
  }
`;

export const StyledTableColumnWorkTypeLoader = styled(StyledTableColumnWorkType)`
  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;

    .line {
      margin-left: 16px;
      width: 100%;
    }

    .avatar {
      padding: 5px 0;
    }
  }
`;

export const StyledTableRowWorkTypeLoader = styled(StyledTableRowWorkType)`
  height: 60px;
`;
