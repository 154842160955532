import React from 'react'
import { SvgIconProps } from './Home'

const SvgDeleteIcon = ({
  fill,
  width,
  height,
  solid,
}: SvgIconProps & {
  solid?: Boolean
}) =>
  solid ? (
    <svg width="14" height="18" viewBox="0 0 14 18" fill={fill ?? '#969696'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
        fill="#C52929"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '20'}
      height={height ?? '20'}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill ?? '#969696'}
        fillRule="evenodd"
        d="M6.658.027c-.613 0-1.119.479-1.119 1.092v2.21H1.12C.506 3.329 0 3.835 0 4.447c0 .613.506 1.119 1.119 1.119h1.118v13.316c0 .612.48 1.118 1.092 1.118h13.316c.612 0 1.118-.506 1.118-1.119V5.566h1.118c.613 0 1.119-.506 1.119-1.119 0-.612-.506-1.118-1.119-1.118h-4.447v-2.21c0-.613-.48-1.119-1.118-1.119H6.658v.027zm1.118 2.21h4.448v1.119H7.776V2.237zM4.447 5.566h11.106v12.197H4.447V5.566zm3.303 2.21h-.133c-.56.08-.986.56-.986 1.119v5.54c-.026.612.48 1.118 1.092 1.144.613.027 1.119-.48 1.145-1.092V8.894c0-.612-.48-1.118-1.092-1.118H7.75zm4.447 0h-.106c-.56.054-.986.56-.986 1.119v5.54c-.026.612.48 1.118 1.092 1.144.613.027 1.119-.48 1.145-1.092V8.894c0-.612-.48-1.118-1.092-1.118h-.053z"
        clipRule="evenodd"
      ></path>
    </svg>
  )

export default SvgDeleteIcon
