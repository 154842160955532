import React from 'react'
import { SvgIconProps } from './Home'

const SvgCheckboxUnCheckedIcon = ({ fill, width, height, stroke }: SvgIconProps & { stroke?: string}) =>(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    fill="none"
    viewBox="0 0 20 20"
  >
    <rect
      width="19"
      height="19"
      x="0.5"
      y="0.5"
      fill={fill ?? "#fff"}
      stroke={stroke ?? "#C7C7C7"}
      rx="2.5"
    ></rect>
  </svg>
)

export default SvgCheckboxUnCheckedIcon