import React, { Component, useState, useEffect } from 'react';
import moment from 'moment';

import packageJson from '../../../package.json';

const withClearCache = (Component: any) => {
  const ClearCacheComponent = (props: any) => {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    const buildDateGreaterThan = (latestDate: any, currentDate: any) => {
      const momLatestDateTime = moment(latestDate);
      const momCurrentDateTime = moment(currentDate);

      return momLatestDateTime.isAfter(momCurrentDateTime);
    };

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then(names => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      window.location.reload();
    };

    useEffect(() => {
      fetch('/meta.json')
        .then(response => response.json())
        .then(meta => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    return <>{isLatestBuildDate ? <Component {...props} /> : null}</>;
  };

  return ClearCacheComponent;
};

export default withClearCache;
