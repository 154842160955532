import React from 'react'
import { SvgIconProps } from './Home'

const SvgCloseIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "16"}
    height={height ?? "16"}
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0)">
      <path
        fill={fill ?? "#000"}
        d="M9.533 8.12a.167.167 0 010-.236l6.176-6.175A1 1 0 0014.293.295L8.118 6.467a.166.166 0 01-.236 0L1.707.295A1 1 0 00.293 1.71l6.174 6.175a.167.167 0 010 .236L.293 14.295a1 1 0 001.414 1.414l6.175-6.175a.167.167 0 01.236 0l6.175 6.175a1 1 0 101.414-1.414L9.533 8.12z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default SvgCloseIcon
