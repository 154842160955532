import React from 'react'
import { SvgIconProps } from './Home'

const SvgEditInformationIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={fill ?? "#353535"}
      stroke={fill ?? "#353535"}
      strokeWidth="0.2"
      d="M4.41 13.657a.1.1 0 00-.011.087.104.104 0 00-.015.008l.006-.099c.003 0 .01.001.019.004 0 0 0 0 0 0zm0 0a.106.106 0 01.04.021.098.098 0 01.025.037.102.102 0 01.017-.004l-.07-.07a.096.096 0 00-.012.016s0 0 0 0zM2.343 11.59a.102.102 0 00.016-.012l-.041-.041-.029-.03a.106.106 0 01-.005.017.1.1 0 01.06.066zm0 0a.133.133 0 01-.023.011.1.1 0 01-.065 0 .108.108 0 01-.007.015l.098-.006c0-.003 0-.01-.003-.02zm2.1 2.03a.955.955 0 00-.082.032L2.46 13.54l-.112-1.902a1.097 1.097 0 00.03-.08l.007-.007.078-.077.294-.294 1.049-1.05 3.183-3.182 2.795-2.796 2.064 2.064-7.405 7.405zm7.104-11.233l.024.024.25.25.758.759.757.758.25.25.024.025-2.063-2.066zm.953-1.094a1.349 1.349 0 00-1.907.003l-9.258 9.258a1.537 1.537 0 00-.324.53c-.076.202-.12.421-.11.611l.148 2.502c.023.392.359.73.754.754l2.502.147a1.54 1.54 0 00.61-.11c.202-.076.396-.189.53-.323l9.258-9.259c.528-.527.53-1.38.004-1.906L12.5 1.293z"
    ></path>
  </svg>
)


export default SvgEditInformationIcon