import { useMemo } from 'react'
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import queryString from 'qs'

export default function useRouter<T>() {
  const params = useParams<T>()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch<T>()
  const searchParams = new URLSearchParams(location.search)

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: queryString.parse(location.search),
      searchParams,
      params,
      match,
      location,
      history,
    }
  }, [params, match, location, history])
}
