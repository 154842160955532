import React from 'react'
import { SvgIconProps } from './Home'

const SvgCheckboxCheckedIcon = ({ fill, width = '24', height = '24' }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? '24'}
    height={height ?? '24'}
    fill="none"
    viewBox={`0 0 24 24`}
  >
    <rect width={24} height={24} fill={fill ?? '#108D59'} rx="5"></rect>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 11.583L9.797 17 20.5 7"
    ></path>
  </svg>
)

export default SvgCheckboxCheckedIcon
