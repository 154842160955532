import React from 'react'
import { SvgIconProps } from './Home'

const SvgRightArrowIcon = ({ fill }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    fill="none"
    viewBox="0 0 8 14"
  >
    <path
      fill={fill ?? "#E0E0E0"}
      fillRule="evenodd"
      d="M.436 11.966a1.036 1.036 0 000 1.443.986.986 0 001.414 0l5.714-5.833c.39-.399.39-1.045 0-1.444L1.85.3a.986.986 0 00-1.414 0 1.036 1.036 0 000 1.444l5.01 5.114-5.01 5.109z"
      clipRule="evenodd"
    ></path>
  </svg>
)



export default SvgRightArrowIcon