import React, { useCallback, useState, useEffect } from 'react';
import { flowRight } from 'lodash';
import withDialog, { WrappedProps } from 'shared/hocs/withDialog';
import TextField from '../TextField';
import { CompleteRegistrationType } from './models/completeRegistration';
import { getTranslatedWord } from 'shared/utils/tools';
import CameraFill from 'assets/icons/CameraFill';
import { useDropzone } from 'react-dropzone';
import { FirstGroup, ImageContainer, DetailsContainer, SecondGroup, Wrapper } from './styledComponents';
import SvgCloseIcon from 'assets/icons/Close';

const CompleteRegistration = (props: WrappedProps<CompleteRegistrationType> & { is_email_spoof: boolean }) => {
  const { formState, formHandlers, is_email_spoof } = props;
  const [file, setPreview] = useState<any>();
  const { onElementChange, onSetForm } = formHandlers;
  const { errors, fields } = formState;
  const onDrop = useCallback(files => {
    setPreview(
      Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      }),
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
  });

  useEffect(() => {
    onSetForm({
      ...fields,
      photo: file,
    });
  }, [file]);

  return (
    <Wrapper>
      <FirstGroup>
        <ImageContainer {...getRootProps()}>
          {file ? (
            <div className="close" onClick={() => setPreview(null)}>
              <SvgCloseIcon />
            </div>
          ) : null}
          {!file ? (
            <>
              <CameraFill fill="#B1B1B5" />
              <input {...getInputProps()} />
              <p>{getTranslatedWord('Select logo')}</p>
              <p>{getTranslatedWord('or drag one here')}</p>
            </>
          ) : (
            <img src={file?.preview} onLoad={() => URL.revokeObjectURL(file?.preview)} />
          )}
        </ImageContainer>
        <DetailsContainer>
          <TextField
            id="workspace_name"
            label={getTranslatedWord('Company name')}
            labelId="complete-registration-workspace_name"
            onChange={onElementChange}
            errorText={getTranslatedWord(errors.workspace_name)}
            value={fields?.workspace_name}
            placeholder={getTranslatedWord('Company name')}
            marginBottom="32px"
            caution={Boolean(errors.workspace_name)}
            error={Boolean(errors.workspace_name)}
          />
          <TextField
            id="phone_number"
            label={getTranslatedWord('Phone number')}
            labelId="complete-registration-phone_number"
            onChange={onElementChange}
            errorText={getTranslatedWord(errors.phone_number)}
            value={fields?.phone_number}
            placeholder={getTranslatedWord('Phone number')}
            marginBottom="32px"
            caution={Boolean(errors.phone_number)}
            error={Boolean(errors.phone_number)}
          />
        </DetailsContainer>
      </FirstGroup>
      <SecondGroup>
        <TextField
          id="first_name"
          label={getTranslatedWord('First name')}
          labelId="complete-registration-first_name"
          onChange={onElementChange}
          errorText={getTranslatedWord(errors.first_name)}
          value={fields?.first_name}
          placeholder={getTranslatedWord('First name')}
          marginBottom="32px"
          caution={Boolean(errors.first_name)}
          error={Boolean(errors.first_name)}
        />
        <TextField
          id="last_name"
          label={getTranslatedWord('Last name')}
          labelId="complete-registration-last_name"
          onChange={onElementChange}
          errorText={getTranslatedWord(errors.last_name)}
          value={fields?.last_name}
          placeholder={getTranslatedWord('Last name')}
          marginBottom="32px"
          caution={Boolean(errors.last_name)}
          error={Boolean(errors.last_name)}
        />
      </SecondGroup>
      {is_email_spoof && (
        <TextField
          id="email"
          label={getTranslatedWord('Email')}
          labelId="complete-registration-email"
          onChange={onElementChange}
          errorText={getTranslatedWord(errors.email)}
          value={fields?.email}
          placeholder={getTranslatedWord('Email')}
          marginBottom="32px"
          caution={Boolean(errors.email)}
          error={Boolean(errors.email)}
        />
      )}
    </Wrapper>
  );
};

const CompleteRegistrationForm = flowRight(withDialog())(CompleteRegistration);

CompleteRegistrationForm.defaultProps = {
  titleClassName: 'dialog__title--no-capitalize',
  width: '550px',
  height: 'auto',
};

export default CompleteRegistrationForm;
