import EmptyTableIcon from 'assets/icons/EmptyTableIcon';
import React from 'react';
import styled from 'styled-components';
import { EmptyTableProps } from '.';
import Box from '../Box';

const EmptyTable = (props: EmptyTableProps) => {
  return (
    <StyledBox>
      <div className="svg-icon-container">{props.emptyRowIcon ? props.emptyRowIcon : <EmptyTableIcon />}</div>
      <div className="title">{props.emptyRowText}</div>
      {Array.isArray(props.emptyRowSubText) ? (
        props.emptyRowSubText.map(subText => {
          return <div className="sub-title">{subText}</div>;
        })
      ) : (
        <div className="sub-title">{props.emptyRowSubText}</div>
      )}
      <ActionsContainer>{props.emptyRowActions}</ActionsContainer>
    </StyledBox>
  );
};

export default EmptyTable;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding-bottom: 180px;

  .svg-icon-container svg {
    margin-top: 90px;
  }

  .title {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    margin: 32px; 0;
  }

  .sub-title {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.main};
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
`;

const ActionsContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0 32px 0;
    min-height: 88px,
    width: 100%;

    button {
        margin-right: 15px;
    }

    button:last-child {
        margin-right: 0;
    }

    ${({ theme }) => theme.mediaQueries.largeDown} {
      display: none;
    }
`;
