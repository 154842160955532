import React from 'react'
import { SvgIconProps } from './Home'

const SvgMoreActionIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill ?? "#000"}
      d="M12.167 8.333a2.167 2.167 0 100-4.333 2.167 2.167 0 000 4.333zM12.167 14.167a2.167 2.167 0 100-4.334 2.167 2.167 0 000 4.334zM12.167 20a2.167 2.167 0 100-4.333 2.167 2.167 0 000 4.333z"
    ></path>
  </svg>
)

export default SvgMoreActionIcon
