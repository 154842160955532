import { createGlobalStyle, CSSProperties, DefaultTheme } from 'styled-components';

export const fontSizes = [
  '48px',
  '32px',
  '24px',
  '16px',
  '40px',
  '56px',
  '21px',
  '20px',
  '36px',
  '28px',
  '14px',
  '12px',
];

const letterSpacing = [
  '2.67px',
  '2px',
  '1.75px',
  '1.17px',
  '0.85px',
  '1.5px',
  '1.46px',
  '1.2px',
  '0.5px',
  '0.33px',
  '0.43px',
];

export const lineHeight = [1.125, 1.25, 1.5, 1.67, 1.8, 1.3, 1.2, 1.43, 2];
const breakpoints = ['41.6875em', '48em', '64em', '80em']; // 667px, 768px, 1024px, 1280px

export const colors = {
  black: '#000000',
  border: '#E0E0E0',
  label: '#919191',
  main: '#172271',
  secondary: '#353535',
  white: '#FFFFFF',
  lightGrey: '#C0C0C0',
  inputBorder: '#C9CBD7',
  textLink: '#1573FF',
  darkMain: '#872B81',
  tableAlternate: '#F9F3F8',
  focusShadow: 'rgba(149, 53, 143, 0.2)',
  fabShadow: 'rgba(23, 34, 113, 0.4)',
  caution: '#C52929',
  disabled: '#C9CBD7',
  success: '#28a745',
  subcontext: '#828282',
  danger: '#ff0000',

  light_pink: 'rgb(253, 231, 241, 0.7)',
  yellow: '#FAF6EB',
  green: '#108D59',
  dark_yellow: '#D8890B',

  secondaries: {
    pink: '#FDE7F1',
    green: '#E0F3EA',
    blue: '#E5E7F8',
    yellow: 'rgba(250, 246, 235, 0.5)',
  },

  gray: {
    very_light: 'rgba(250, 250, 253, 0.8)',
    light: '#FAFAFD',
    medium: '#E2E3EB',
    light_gray: 'rgba(226, 227, 235, 0.4)',
    dark_gray: 'rgba(53, 59, 90, 0.4)',
    medium_dark: '#C9CBD7',
    dark: '#353B5A',
    very_dark: '#0C1139',
    almost_black: '#050717',
    pitch_dark: '#1C2030',
  },

  hover: {
    action: '#D3D6E8',
    pink: '#EDD6E1',
    yellow: '#E8E5DB',
    green: '#CEE2DA',
    anchor: '#0C1139',
    blue: '#D5D6E8',
  },

  active: {
    action: '#C2C5D8',
    pink: '#DCC5D1',
    yellow: '#D7D4CB',
    green: '#BDD1CA',
    anchor: '#050717',
    blue: '#C4C5D8',
  },
};

export const fonts = {
  heavy: "CeraPro, 'Roboto', Arial, sans-serif",
  regular: "CeraPro, 'Roboto', Arial, sans-serif",
};

const buttons = {
  icon: {
    padding: '0',
  },
  primary: {
    background: `${colors.secondary}`,
    borderRadius: '48px',
    color: `${colors.main}`,
    fontSize: '18px',
    padding: '16px 24px',
  },
  secondary: {
    background: `${colors.secondary}`,
    borderRadius: '48px',
    color: `${colors.black}`,
    fontSize: '18px',
    padding: '16px 24px',
  },
};

const headings = {
  h1: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[0],
    letterSpacing: letterSpacing[1],
    lineHeight: lineHeight[0],
    textTransform: 'capitalize',
  } as CSSProperties,
  h1M: {
    color: colors.main,
    fontFamily: fonts.heavy,
    fontSize: fontSizes[6],
    letterSpacing: letterSpacing[5],
    lineHeight: lineHeight[0],
    textTransform: 'capitalize',
  } as CSSProperties,
  h2: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[5],
    lineHeight: lineHeight[0],
    textTransform: 'capitalize',
  } as CSSProperties,
  h2M: {
    font: `${fontSizes[2]} / ${lineHeight[5]} ${fonts.heavy}`,
    letterSpacing: letterSpacing[2],
    textTransform: 'capitalize',
  } as CSSProperties,
  h3: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[8],
    letterSpacing: letterSpacing[1],
    lineHeight: lineHeight[6],
    textTransform: 'capitalize',
  } as CSSProperties,
  h3M: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[7],
    letterSpacing: letterSpacing[6],
    lineHeight: lineHeight[6],
    textTransform: 'capitalize',
  } as CSSProperties,
  h4: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[1],
    letterSpacing: letterSpacing[0],
    lineHeight: lineHeight[1],
    textTransform: 'capitalize',
  } as CSSProperties,
  h4M: {
    font: `${fontSizes[3]} / ${lineHeight[1]} ${fonts.heavy}`,
    letterSpacing: letterSpacing[3],
    textTransform: 'capitalize',
  } as CSSProperties,
  h4T: {
    font: `${fontSizes[9]} / ${lineHeight[5]} ${fonts.heavy}`,
    letterSpacing: letterSpacing[1],
    textTransform: 'capitalize',
  } as CSSProperties,
  h5: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[2],
    letterSpacing: letterSpacing[2],
    lineHeight: lineHeight[2],
    textTransform: 'capitalize',
  } as CSSProperties,
  h5M: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[10],
    letterSpacing: letterSpacing[7],
    lineHeight: lineHeight[2],
    textTransform: 'capitalize',
  } as CSSProperties,
  h5T: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[7],
    letterSpacing: letterSpacing[6],
    lineHeight: lineHeight[4],
    textTransform: 'capitalize',
  } as CSSProperties,
  h6: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[3],
    letterSpacing: letterSpacing[3],
    lineHeight: lineHeight[2],
    textTransform: 'capitalize',
  } as CSSProperties,
  h6M: {
    fontFamily: fonts.heavy,
    fontSize: fontSizes[11],
    letterSpacing: letterSpacing[10],
    lineHeight: lineHeight[3],
    textTransform: 'capitalize',
  } as CSSProperties,
};

const paragraphs = {
  body: {
    font: `${fontSizes[2]} / ${lineHeight[2]} ${fonts.regular}`,
    letterSpacing: letterSpacing[8],
  },
  bodyT: {
    font: `${fontSizes[2]} / ${lineHeight[2]} ${fonts.regular}`,
    letterSpacing: letterSpacing[8],
  },
  bodyM: {
    font: `${fontSizes[10]} / ${lineHeight[7]} ${fonts.regular}`,
    letterSpacing: letterSpacing[3],
  },
  caption: {
    font: `${fontSizes[3]} / ${lineHeight[2]} ${fonts.regular}`,
    letterSpacing: letterSpacing[9],
  },
};

/*
  0 = 667px    mobile
  1 = 768px    tablet
  2 = 1024px   tablet-landscape
  3 = 1280px   desktop

  small = mobile to desktop
  smallOnly = mobile

  medium     = tablet to desktop
  mediumOnly = tablet
  mediumDown = mobile to tablet

  large     = tablet-landscape - desktop
  largeOnly = tablet-landscape
  largeDown = mobile to tablet-landscape

  extraLarge = desktop
*/

const scoreboardTheme: DefaultTheme = {
  borderRadius: '5px',
  breakpoints,
  buttons,
  colors,
  fontSize: fontSizes,
  fonts,
  headings,
  letterSpacing,
  lineHeight,
  mediaQueries: {
    extraLarge: `@media screen and (min-width: ${breakpoints[3]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    mediumOnly: `@media screen and (min-width: ${breakpoints[1]}) and (max-width: 1023px)`,
    mediumDown: `@media screen and (max-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
    largeOnly: `@media screen and (min-width: ${breakpoints[2]}) and (max-width: 1279px)`,
    largeDown: `@media screen and (max-width: ${breakpoints[2]})`,
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    smallOnly: `@media screen and (max-width: 1023px)`,
  },
  paragraphs,
};

const GlobalStyles = createGlobalStyle`

  html {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }


`;

export { scoreboardTheme, GlobalStyles, breakpoints };
