export const translationsDefault = () => {
  return {
    en: {
      translation: {
        'Holiday extra': 'Holiday extra',
        'Starting month of overtime calculation of a period': 'Starting month of overtime calculation of a period',
        'Overtime multiplier': 'Overtime multiplier',
        ':worker is missing from work': { ' (:start - :end)': ':worker is missing from work. (:start - :end)' },
        'Please wait while vour request is being processed': 'Please wait while your request is being processed.',
        'Save shift': 'Save shift',
        'Saved shifts': 'Saved shifts',
        'Type here': 'Type here',
        'No users found': 'No users found',
        'Show events as full-day': 'Show events as full-day',
        'Show holidays': 'Show holidays',
        'No shifts added': 'No shifts added',
        'Edit shift': 'Edit shift',
        'is missing from work': ':name is missing from work.',
        Copy: 'Copy',
        'No presets added': 'No presets added',
        'Add task': 'Add task',
        'Edit repeating task': 'Edit repeating task',
        'Edit task': 'Edit task',
        'Add shift': 'Add shift',
        Shifts: 'Shifts',
        'Add new task': 'Add new task',
        'New task': 'New task',
        'Task name': 'Task name',
        Task: 'Task',
        'Time schedules': 'Schedules',
        Add: 'Add',
        'Remove recurring event': 'Remove recurring event',
        'This event': 'This event',
        'This and following unconfirmed events': 'This and following unconfirmed events',
        'All unconfirmed events': 'All unconfirmed events',
        Remove: 'Remove',
        'Add new shift': 'Add new shift',
        'Add Schedule': 'Add Schedule',
        'Add paid absence': 'Add paid absence',
        Absence: 'Absence',
        'Saved presets': 'Saved presets',
        'New shift': 'New shift',
        'Shift name': 'Shift name',
        Repeating: 'Repeating',
        'Save preset': 'Save preset',
        Daily: 'Daily',
        Weekly: 'Weekly',
        Byweekly: 'Biweekly',
        Monthly: 'Monthly',
        Yearly: 'Yearly',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Shift: 'Shift',
        'All day': 'All day',
        'Total pauses during selected period': 'Total pauses during selected period',
        Residue: 'Residue',
        'Pause end': 'Pause end',
        'Ended work using fingerprint': 'Ended work using fingerprint',
        'Started work using fingerprint': 'Started work using fingerprint',
        'Success!': 'Success!',
        'Your request has been received': {
          ' We will be calling you at the registered phone number shortly': {
            ' Please ensure that your phone is on and within reach': {
              ' Thank you for your patience': {
                '':
                  'Your request has been received. We will be calling you at the registered phone number shortly. Please ensure that your phone is on and within reach. Thank you for your patience.',
              },
            },
          },
        },
        'An email has been sent to your registered email address': {
          ' Please check your inbox to ensure receipt': {
            " Don't forget to check your spam/junk folder if you can't find it": {
              ' Thank you!':
                "An email has been sent to your registered email address. Please check your inbox to ensure receipt. Don't forget to check your spam/junk folder if you can't find it. Thank you!",
            },
          },
        },
        'Account manager': 'Account manager',
        'Your email address has not been confirmed': { '': 'Your email address has not been confirmed.' },
        'Change your email': 'Change your email',
        'Enter your phone number': 'Enter your phone number',
        'Contact us': 'Contact us',
        "Let's talk": "Let's talk",
        'Schedule online meeting': 'Schedule online meeting',
        'Request callback': 'Request callback',
        'Price list': 'Price list',
        'Work is started with full-auto function': 'Work was started with full-auto function',
        'Work is ended with full-auto function': 'Work was ended with full-auto function',
        'Work is started with semi-auto function': 'Work was started with semi-auto function',
        'Work is ended with semi-auto function': 'Work was ended with semi-auto function',
        'Manually added work using web': 'Manually added work using web',
        'Manually added work using mobile': 'Manually added work using mobile',
        Outside: 'Outside',
        'You have no deleted workers vet': 'You have no deleted workers yet',
        'You have no inactive workers yet': 'You have no inactive workers yet',
        'Show other locations': 'Show other locations',
        'Search address': 'Search address',
        'Reset to current location': 'Reset to current location',
        'Must be greater than or equal to 100 meters': 'Must be greater than or equal to 100 meters',
        'Daily allowance group': 'Daily allowance group',
        Invites: 'Invites',
        'The following email addresses are already associated with an account in our system and could not be invited again': {
          '':
            'The following email addresses are already associated with an account in our system and could not be invited again.',
        },
        'The other invitations have been sent successfully': {
          ' If you need further assistance, please contact our support team': {
            '':
              'The other invitations have been sent successfully. If you need further assistance, please contact our support team.',
          },
        },
        'Conflicting emails': 'Conflicting emails',
        Text: 'Text',
        Data: 'Data',
        'workers information': 'workers information',
        'This email is sent by :companyName from HOURS management software':
          'This email is sent by :companyName from HOURS management software',
        'To answer this email, please use :managerEmail': 'To answer this email, please use :managerEmail',
        'Please make sure that your password': 'Please make sure that your password',
        'New work type': 'New work type',
        'Select logo': 'Select logo',
        'or drag one here': 'or drag one here',
        'Workspace name': 'Workspace name',
        'Phone number': 'Phone number',
        'Do you want to change your email for verification?': 'Do you want to change your email for verification?',
        'Your email is unverified and your trial has ended': {
          ' Verify your email if you want to continue using the Hours software': {
            '':
              'Your email is unverified and your trial has ended. Verify your email if you want to continue using the Hours software.',
          },
        },
        'Resend verification': 'Resend verification',
        Refresh: 'Refresh',
        'Already have an account?': 'Already have an account?',
        'Log in here': 'Log in here',
        'By signing up, you agree to our': 'By signing up, you agree to our',
        'terms of service': 'terms of service',
        'privacy policy': 'privacy policy',
        'and to receiving marketing communication from Hours': 'and to receiving marketing communication from Hours',
        'You can opt out anytime': 'You can opt out anytime',
        'Your email address has not been verified': { '': 'Your email address has not been verified.' },
        'Check your email for the activation link or': 'Check your email for the activation link or',
        'Work resumed from being idle at': 'Work resumed from being idle at',
        'Office door setting': 'Office door setting',
        'Automatically stop work after': 'Automatically stop work after',
        'minutes of idle time': 'minutes of idle time',
        '* when idle time is not set, work will automatically stop after 60 minutes':
          '* when idle time is not set, work will automatically stop after 60 minutes',
        'Work was idle starting at': 'Work was idle starting at',
        None: 'None',
        'This email is already in use': { '': 'This email is already in use.' },
        'RFID is already in use': { '': 'Rfid is already in use.' },
        'Normal workday length': 'Normal workday length',
        'National holiday settings': 'National holiday settings',
        Calendar: 'Calendar',
        'The previous working day before a national holiday will be shortened':
          'The previous working day before a national holiday will be shortened',
        'The following fields are missing: ': 'The following fields are missing',
        main: 'main',
        'Visma Settings': 'Visma Settings',
        'Show weekend hours separately': 'Show weekend hours separately',
        'Show columns': 'Show columns',
        'Weekend hours': 'Weekend hours',
        'User does not have work type salaries': 'User does not have work type salaries',
        'Updated daily allowance': 'Updated daily allowance',
        'Deleted pauses': 'Deleted pauses',
        'Round to nearest 15 minutes': 'Round to nearest 15 minutes',
        'Add to login box': 'Add to login box',
        'Work end time': 'Work end time',
        'Aloita lisäämällä uusi päiväraharyhmä': 'Start by adding new daily allowance group',
        'Your daily allowance group is empty': 'Your daily allowance group is empty',
        'Use in daily allowance calculation': 'Use in daily allowance calculation',
        'View notification': 'View notification',
        'No allowance': 'No allowance',
        "If you haven't received verification email in a few minutes, please check the spam folder": {
          '': "If you haven't received verification email in a few minutes, please check the spam folder.",
        },
        'Do nothing': 'Do nothing',
        Unsubscribe: 'Unsubscribe',
        "If you don't want to receive notifications from Hours to your email, then you can unsubscribe": {
          '': "If you don't want to receive notifications from Hours to your email, then you can unsubscribe.",
        },
        "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser":
          "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser",
        'You have a new notification': 'You have a new notification',
        ':name has entered :workspace at :datetime': { '': ':name has entered :workspace at :datetime.' },
        ':name has left :workspace at :datetime': { '': ':name has left :workspace at :datetime.' },
        'Your work (:workspace - :worktype) has automatically started at :datetime': {
          '': 'Your work (:workspace - :worktype) has automatically started at :datetime.',
        },
        'Your Hours trial will end in :trial days': { '': 'Your Hours trial will end in :trial days.' },
        'By continuing using the Hours software after trial, you will be billed by Hours price list': {
          '': 'By continuing using the Hours software after trial, you will be billed by Hours price list.',
        },
        'Email verification': 'Email verification',
        'Unable to verify your email': { '': 'Unable to verify your email' },
        End: 'End',
        'Your Hours': {
          'ee invoice is unpaid': {
            ' If you do not pay the bill, your account will be closed on :bill':
              'Your Hours.ee invoice is unpaid. If you do not pay the bill, your account will be closed on :bill',
          },
        },
        Equal: 'Equal',
        Down: 'Down',
        Up: 'Up',
        'Daily allowance': 'Daily allowance',
        Post: 'Post',
        'Group does not have a daily allowance item': 'Group does not have a daily allowance item',
        'Add daily allowance': 'Add daily allowance',
        'Daily allowances': 'Daily allowances',
        View: 'View',
        'Generating Report': { ' Please wait': { '': { '': { '': 'Generating Report. Please wait...' } } } },
        'Total:': 'Total:',
        'User preference updated successfully': 'User preference updated successfully',
        'Work log updated successfully': 'Work log updated successfully',
        'Work paused successfully': 'Work paused successfully',
        'Work resumed successfully': 'Work resumed successfully',
        'Work stopped successfully': 'Work stopped successfully',
        'Paid absence created successfully': 'Paid absence created successfully',
        'Semi-automatic (notify user before starting /ending work)':
          'Semi-automatic (notify user before starting/ending work)',
        'Fully-automatic (automatically start/end work)': 'Fully-automatic (automatically start/end work)',
        'User language updated successfully': 'User language updated successfully',
        'Automatic start/end work with Mobile App': 'Automatic start/end work with Mobile App (Beta - testing)',
        'When entering/leaving workspace': 'When entering/leaving workspace',
        'This set password link will expire 1 week from now': 'This set password link will expire 1 week from now',
        'Notify manager': 'Notify manager',
        'Work log confirmed successfully': 'Work log confirmed successfully',
        'Options updated successfully': 'Options updated successfully',
        'Add a pause': 'Add a pause',
        'The entered working time is more than 24 hours': {
          ' Are you sure you want to add it?':
            'The entered working time is more than 24 hours. Are you sure you want to add it?',
        },
        'User registered successfully': 'User registered successfully',
        'Reset password link sent successfully': 'Reset password link sent successfully',
        'Added lunch time using general preference': 'Added lunch time using general preference',
        'Changed pause time to': 'Changed pause time to',
        'Changed resume time to': 'Changed resume time to',
        Percentage: 'Percentage',
        added: 'added',
        accepted: 'accepted',
        declined: 'declined',
        'Congrats! :manager has :action a bonus for your great work': {
          '': 'Congrats! :manager has :action a bonus for your great work.',
        },
        Doors: 'Doors',
        'Merit Palk Salary type nr': 'Merit Palk Salary type nr',
        'There are conflicting times for this timeframe': {
          ' Please review previous entries': {
            '': 'There are conflicting times for this timeframe. Please review previous entries.',
          },
        },
        'Conflicting times': 'Conflicting times',
        'Sent to Netvisor': 'Sent to Netvisor',
        'Sent to': 'Sent to',
        'Rounded using general preference': 'Rounded using general preference',
        'Rounded end time to': 'Rounded end time to',
        'Added using general preference': 'Added using general preference',
        'Updated pauses': 'Updated pauses',
        'Confirmed work at': 'Confirmed work at',
        'Uncomfirmed work at': 'Uncomfirmed work at',
        'Added workspace': 'Added workspace',
        'Added work type': 'Added work type',
        'Added address': 'Added address',
        'Added pause': 'Added pause',
        'Added comment': 'Added comment',
        'Added bonus': 'Added bonus',
        'Added bonus comment': 'Added bonus comment',
        to: 'to',
        'Work edited': 'Work edited',
        'Add work type extra': 'Add work type extra',
        'Ends at': 'Ends at',
        'Out of door at': 'Out of door at',
        'Resumed work at': 'Resumed work at',
        'Ended work at': 'Ended work at',
        'Ended work using door': 'Ended work using door',
        'Ended work using box': 'Ended work using box',
        'Ended work using mobile': 'Ended work using mobile',
        'Ended work using web': 'Ended work using web',
        'Rounded start time to': 'Rounded start time to',
        'Started work using box': 'Started work using box',
        'Started work using door': 'Started work using door',
        'Started work using mobile': 'Started work using mobile',
        'Started work using web': 'Started work using web',
        'Started work at': 'Started work at',
        'Entered door': 'Entered door',
        'Entered door at': 'Entered door at',
        'Paused work at': 'Paused work at',
        'Out of door': 'Out of door',
        'Congratulations! You have just set your account password': {
          ' You are now able to access the web portal': {
            '': 'Congratulations! You have just set your account password. You are now able to access the web portal.',
          },
        },
        'You are all set': { '': 'You are all set.' },
        'Your email has been verified': { '': 'Your email has been verified.' },
        'Activation link is active within a week after creating an account': {
          ' Please resend a new activation link to your email': {
            '':
              'Activation link is active within a week after creating an account. Please resend a new activation link to your email.',
          },
        },
        'We sent you a verification email to': 'We sent you a verification email to',
        'If you havent received verification email in a few minutes, please check the spam folder': {
          '': "If you haven't received verification email in a few minutes, please check the spam folder.",
        },
        'Congratulations! you have just verified your email address': {
          '': 'Congratulations! You have just verified your email address.',
        },
        'You are now able to access the web portal': { '': 'You are now able to access the web portal.' },
        'Invalid verification token received or the link is already expired': {
          '': 'Invalid verification token received or the link is already expired.',
        },
        "If you don't see the email, please check your spam folder or contact your Organization":
          "If you don't see the email, please check your spam folder or contact your Organization",
        'Overtime computation': 'Overtime computation',
        "Can't add pause bigger than work duration": { '': "Can't add pause bigger than work duration." },
        'Work duration must not overlap to other work logs': {
          '': 'Work duration must not overlap to other work logs.',
        },
        "Work durations shouldn't be negative": { '': "Work durations shouldn't be negative." },
        'Selected works have some users with multiple work logs on the same day':
          'Selected works have some users with multiple work logs on the same day',
        'Cannot confirm not ended work': 'Cannot confirm not ended work',
        "Start time can't be later than end time": { '': "Start time can't be later than end time." },
        'This absence request has already been declined': { '': 'This absence request has already been declined.' },
        'Overlapping paid absences': 'Overlapping paid absences',
        'Cannot request with overlapping paid absences': 'Cannot request with overlapping paid absences',
        'There is already ongoing unconfirmed request': 'There is already ongoing unconfirmed request',
        'Work has been deleted': 'Work has been deleted',
        'Can not found request': {
          ' Work log may have been deleted': { '': 'Can not found request. Work log may have been deleted.' },
        },
        'This bonus request has already been accepted': { '': 'This bonus request has already been accepted.' },
        'This bonus request has already been declined': { '': 'This bonus request has already been declined.' },
        "Pause start can't be earlier than work start": { '': "Pause start can't be earlier than work start." },
        'Work resumed already': { '': 'Work resumed already.' },
        'User is currently working': { '': 'User is currently working.' },
        'Overlapping work rounds': 'Overlapping work rounds',
        'There are some errors in exporting merit palk report': 'There are some errors in exporting merit palk report',
        'There are some errors in exporting netvisor report': 'There are some errors in exporting netvisor report',
        'Dimension updated unsuccessfully': 'Dimension updated unsuccessfully',
        'Notification cannot be posted if deleted': 'Notification cannot be posted if deleted',
        'Error fetching locales': { '': 'Error fetching locales.' },
        'This absence request has already been accepted': { '': 'This absence request has already been accepted.' },
        'Work paused already': { '': 'Work paused already.' },
        "This account doesn't exist": { '': "This account doesn't exist." },
        'Your account has been deleted': { '': 'Your account has been deleted.' },
        'Please verify your email': { '': 'Please verify your email.' },
        'Your account has been deactivated': { '': 'Your account has been deactivated.' },
        'Your company account has been disabled': { '': 'Your company account has been disabled.' },
        'Work start should not be greater than stop time': { '': 'Work start should not be greater than stop time.' },
        'Work stopped already': { '': 'Work stopped already.' },
        'Work not found': { '': 'Work not found.' },
        'You have attempted to login multiple times already': {
          ' Try resetting your password': {
            '': 'You have attempted to login multiple times already. Try resetting your password.',
          },
        },
        'Enter your email below and we will send a password reset link to your email': {
          '': 'Enter your email below and we will send a password reset link to your email',
        },
        'Token already expired': 'Token already expired',
        'Token is invalid': 'Token is invalid',
        'Token has already been used': 'Token has already been used',
        'User not found': 'User not found',
        'Password Reset Sent': 'Password Reset Sent',
        'You should receive the password reset email shortly once an account matching your username is found': {
          '': 'You should receive the password reset email shortly once an account matching your username is found.',
        },
        'Invalid credentials': 'Invalid credentials',
        'Please try again or ': 'Please try again or ',
        'Merit Palk Setting': 'Merit Palk Setting',
        'API Setting': 'API Setting',
        'API Key': 'API Key',
        'API ID': 'API ID',
        'Do not count as overtime': 'Do not count as overtime',
        'No notifications created': 'No notifications created',
        'Start by creating notifications and post them': { '': 'Start by creating notifications and post them.' },
        'Add new notification': 'Add new notification',
        'Default work type': 'Default work type',
        'Automatic start/end work with mobile': 'Automatic start/end work with mobile (Beta - testing)',
        Radius: 'Radius',
        'Set location': 'Set location',
        'People rate': 'People rate',
        'Worktype rate': 'Worktype rate',
        'Show worker number': 'Show worker number',
        'Reset Your Password': 'Reset Your Password',
        'Back to login': 'Back to login',
        'More details': 'More details',
        'Showing rows: :current out of :total': 'Showing rows: :current out of :total',
        'We use cookies to ensure you get the best experience on our site':
          'We use cookies to ensure you get the best experience on our site.',
        'Start by adding new allowed pause group': 'Start by adding new allowed pause group',
        'Group does not have an allowed pause item': 'Group does not have an allowed pause item',
        'Add allowed pause': 'Add allowed pause',
        'Allowed pause group': 'Allowed pause group',
        'Work pause': 'Work pause',
        'Work end': 'Work end',
        'Work start time': 'Work start time',
        'Round time': 'Round time',
        'Lunch time': 'Lunch time',
        minutes: 'minutes',
        'before work end time to round up': 'before work end time to round up',
        'after work end time to round down': 'after work end time to round down',
        'Your allowed pause group is empty': 'Your allowed pause group is empty',
        'Update doors': 'Update doors',
        'When exiting': 'When exiting',
        'Edit door': 'Edit door',
        'Door access': 'Door access',
        'Main door': 'Main door',
        'must be accessed to start work': 'must be accessed to start work',
        'Front door': 'Front door',
        'Change work type when entering': 'Change work type when entering',
        'Work resume when entering': 'Work resume when entering',
        Request: 'Request',
        'View Bonus': 'View Bonus',
        Pending: 'Pending',
        Approved: 'Approved',
        Declined: 'Declined',
        'Expired activation': 'Expired activation',
        'Inner Range Doors': 'Inner Range Doors',
        'Doors updated': 'Doors updated',
        'Door ID': 'Door ID',
        'Request Bonus': 'Request Bonus',
        'Can accept/edit/decline requests': 'Can accept/edit/decline requests',
        'Switching Account': 'Switching Account',
        'Past two weeks': 'Past two weeks',
        ':worker has requested a bonus of :bonus': ':worker has requested a bonus of :bonus',
        'has requested a paid absence': 'has requested a paid absence',
        ':manager has declined your request for a bonus': { '': ':manager has declined your request for a bonus.' },
        'Your work (:workspace - :worktype) has automatically paused at :datetime': {
          '': 'Your work (:workspace - :worktype) has automatically paused at :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically resumed at :datetime': {
          '': 'Your work (:workspace - :worktype) has automatically resumed at :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically ended at :datetime': {
          '': 'Your work (:workspace - :worktype) has automatically ended at :datetime.',
        },
        ':manager has :action a paid absence': { '': ':manager has :action a paid absence.' },
        'Congrats! :manager has :action a bonus for your greate work': {
          '': 'Congrats! :manager has :action a bonus for your greate work.',
        },
        ":worker has requested a paid absence ':type' (:start - :end)": {
          '': ":worker has requested a paid absence ':type' (:start - :end).",
        },
        ':name is working overtime with work (:workspace - :worktype) at this :datetime': {
          '': ':name is working overtime with work (:workspace - :worktype) at this :datetime.',
        },
        ':manager has declined your request for a paid absence': {
          '': ':manager has declined your request for a paid absence.',
        },
        'User preference': 'User preference',
        'Sorting order': 'Sorting order',
        'First name, Last name': 'First name, Last name',
        'Last name, First name': 'Last name, First name',
        'Allowed pauses': 'Allowed pauses',
        Late: 'Late',
        'You have been added to Hours time tracking': 'You have been added to Hours time tracking',
        'Fetch records': 'Fetch records',
        'has requested a bonus of': 'has requested a bonus of',
        Posted: 'Posted',
        'Ready for posting': 'Ready for posting',
        Draft: 'Draft',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces',
        'Draft notification message': 'Draft notification message',
        Requests: 'Requests',
        'No bonus requests yet': 'No bonus requests yet',
        'No paid absence requests yet': 'No paid absence requests yet',
        'Paid absence': 'Paid absence',
        'Bonus comment': 'Bonus comment',
        'Original requested amount': 'Original requested amount',
        'New amount': 'New amount',
        'Request paid absence': 'Request paid absence',
        'Requested at': 'Requested at',
        'Started at': 'Started at',
        'Ended at': 'Ended at',
        'Confirmed at': 'Confirmed at',
        'Confirmed by': 'Confirmed by',
        'Work date': 'Work date',
        'You have no rights to edit work time!': 'You have no rights to edit work time!',
        'Notify through push notification': 'Notify through push notification',
        'Notify through email': 'Notify through email',
        Notifications: 'Notifications',
        'Created by': 'Created by',
        'Posted at': 'Posted at',
        Title: 'Title',
        Repost: 'Repost',
        'Add notification': 'Add notification',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'when no workspace is selected, notifications will be sent to all users in the company',
        'Company notifications': 'Company notifications',
        'Worker is working overtime': 'Worker is working overtime',
        'Worker is asking for a bonus': 'Worker is asking for a bonus',
        'Worker is requesting for an absence': 'Worker is requesting for an absence',
        'Worker has arrived/left from the workspace': 'Worker has arrived/left from the workspace',
        'Manager has added/accepted/declined a bonus': 'Manager has added/accepted/declined a bonus',
        'Manager has added/accepted/declined a paid absence': 'Manager has added/accepted/declined a paid absence',
        'Work paused/ended automatically through workspace preference':
          'Work paused/ended automatically through workspace preference',
        'Start by adding a new box': { '': 'Start by adding a new box.' },
        Latest: 'Latest',
        'This week': 'This week',
        Older: 'Older',
        'You have declined this request!': 'You have declined this request!',
        'You have already approved this request!': 'You have already approved this request!',
        Accept: 'Accept',
        Decline: 'Decline',
        'Mark as read': 'Mark as read',
        'Turn off receiving this status': 'Turn off receiving this status',
        'Delete notification': 'Delete notification',
        'Mark all as read': 'Mark all as read',
        'View full feed': 'View full feed',
        'Clear all': 'Clear all',
        Read: 'Read',
        Close: 'Close',
        Clear: 'Clear',
        'No notifications yet': 'No notifications yet',
        'Turn on/off notifications': 'Turn on/off notifications',
        'Weekly working hours': 'Weekly working hours',
        'Show more': 'Show more',
        'Show less': 'Show less',
        'No email': 'No email',
        'Complete registration by choosing a password': 'Complete registration by choosing a password',
        'Your First Name': 'Your First Name',
        'Your Last Name': 'Your Last Name',
        'Your Password': 'Your Password',
        'Confirm Your Password': 'Confirm Your Password',
        "Please register by entering you're email": "Please register by entering you're email",
        Location: 'Location',
        'Must have at least 8 characters': 'Must have at least 8 characters',
        'Must start with alphanumeric character': 'Must start with alphanumeric character',
        'Must have at least one lowercase character': 'Must have at least one lowercase character',
        'Must have at least one uppercase character': 'Must have at least one uppercase character',
        'Must have at least one digit': 'Must have at least one digit',
        'Passwords must match': 'Passwords must match',
        'Must Accept Terms and Condition': 'Must Accept Terms and Condition',
        'This field is required': 'This field is required',
        ':field is required': ':field is required',
        by: 'by',
        'Total salary': 'Total salary',
        'Paid pause': 'Paid pause',
        'Work type extras': 'Work type extras',
        Basic: 'Basic',
        'Basic salary': 'Basic salary',
        Week: 'Week',
        Rate: 'Rate',
        'Worker is missing identification number': 'Worker is missing identification number',
        'Your paid absences is empty': 'Your paid absences is empty',
        'Start by adding new paid absences': 'Start by adding new paid absences',
        'Invite user': 'Invite users',
        'Group does not have a work round item': 'Group does not have a work round item',
        'Group does not have an overtime item': 'Group does not have an overtime item',
        'Upload Company Logo': 'Upload Company Logo',
        'Upload Profile Image': 'Upload Profile Image',
        'Drag and drop your image here or': 'Drag and drop your image here or',
        'upload a photo': 'upload a photo',
        'Single image must not exceed 5MB': 'Single image must not exceed 5MB',
        'Business Area': 'Business Area',
        'Start by adding new work round group': 'Start by adding new work round group',
        'Your work round group is empty': 'Your work round group is empty',
        'Your overtime group is empty': 'Your overtime group is empty',
        '\r\nStart by adding new overtime group': ['\r', 'Start by adding new overtime group'],
        File: 'File',
        "This does not affect users' existing paid absences": "This does not affect user's existing paid absences",
        'View document': 'View document',
        'Rename document': 'Rename document',
        'Delete document': 'Delete document',
        'File name': 'File name',
        'File type': 'File type',
        'File size': 'File size',
        'Last date modified': 'Last date modified',
        'accepts comma-separated input': 'accepts comma-separated input',
        "Press 'Enter'": "Press 'Enter'",
        Emails: 'Emails',
        'Invite users by email': 'Invite users by email',
        'Add work type': 'Add work type',
        'View work types': 'View work types',
        'User has been deleted': 'User has been deleted',
        'User information cannot be viewed': 'User information cannot be viewed',
        'Go back to People page': 'Go back to People page',
        'Something went wrong with your request': {
          ' Please try again later or refresh the page': {
            '': 'Something went wrong with your request. Please try again later or refresh the page.',
          },
        },
        'Something went wrong': {
          ' Please contact administrator': { '': 'Something went wrong. Please contact administrator.' },
        },
        'The work has been updated': { ' Please confirm to apply changes': 'Please confirm to apply changes' },
        'Start by adding new work type': 'Start by adding new work type',
        'Start by adding a new workina hours registration box': {
          '': 'Start by adding a new working hours registration box.',
        },
        'Account verification': 'Account verification',
        Hello: 'Hello',
        'Please click the button below to verify your email address': {
          '': 'Please click the button below to verify your email address',
        },
        'Verify Email Address': 'Verify Email Address',
        'If you did not create an account, no further action is required': {
          '': 'If you did not create an account, no further action is required',
        },
        'Best regards': 'Best regards',
        Team: 'Team',
        "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser":
          "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser",
        'All rights reserved': 'All rights reserved',
        'Complete registration': 'Complete registration',
        from: 'from',
        'have added you to Hours time tracking': { '': 'have added you to Hours time tracking' },
        'Please complete your registration by setting up a password': {
          '': 'Please complete your registration by setting up a password',
        },
        'Set Password': 'Set Password',
        'This set password link will expire 1 day from now': {
          '': 'This set password link will expire 1 day from now.',
        },
        'Download also Hours mobile apps': 'Download also Hours mobile apps',
        "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser":
          "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser",
        'Welcome to': 'Welcome to',
        'You are receiving this email because we received a reset password request for your account':
          'You are receiving this email because we received a reset password request for your account',
        'Reset Password': 'Reset Password',
        'If you did not request a password reset, no further action is required': {
          '': 'If you did not request a password reset, no further action is required.',
        },
        'This password reset link will expire 1 day from now': {
          '': 'This password reset link will expire 1 day from now.',
        },
        'Your work type is empty': 'Your work type is empty',
        'Dreaming of a new box?': 'Dreaming of a new box?',
        'Oops! Option already exist': { '': 'Oops! Option already exist.' },
        'Are you sure you want to update selected work logs?': 'Are you sure you want to update selected work logs?',
        'Round to nearest hour': 'Round to nearest hour',
        'Round to nearest half-hour': 'Round to nearest half-hour',
        'Round to nearest 10 minutes': 'Round to nearest 10 minutes',
        'Still having a trouble?  Please contact administrator':
          'Still having a trouble?  Please contact administrator',
        'Edit group': 'Edit group',
        'Overtime group': 'Overtime group',
        'Work round group': 'Work round group',
        'Are you sure you want to remove profile image?': 'Are you sure you want to remove profile image?',
        'Error on exporting :type': { '': 'Error on exporting :type.' },
        'Do not group': 'Do not group',
        'Grouped by people': 'Grouped by people',
        'Are you sure you want to bulk activate/deactivate?': 'Are you sure you want to bulk activate/deactivate?',
        'Please try shorter time frame or less people at the same time': {
          '': 'Please try shorter time frame or less people at the same time.',
        },
        'Some selected work logs are already confirmed!': 'Some selected work logs are already confirmed!',
        'Round work time': 'Round work time',
        Processing: { '': { '': { '': 'Processing...' } } },
        Subject: 'Subject',
        'Number of weeks': 'Number of weeks',
        'Number of months': 'Number of months',
        'Daily normal hours': 'Daily normal hours',
        'Weekly normal hours': 'Weekly normal hours',
        'Monthly normal hours': 'Monthly normal hours',
        'Are you sure want to switch as :type?': 'Are you sure want to switch as :type?',
        'This action cannot be undone!': 'This action cannot be undone!',
        'Work type workspaces': 'Work type workspaces',
        'People workspaces': 'People workspaces',
        'Working Period': 'Working Period',
        From: 'From',
        'Send copy': 'Send copy',
        'weekly overtime': 'weekly overtime',
        'Monthly overtime': 'Monthly overtime',
        'monthly overtime': 'monthly overtime',
        'working hours': 'working hours',
        total: 'total',
        hours: 'hours',
        days: 'days',
        'Total working days': 'Total working days',
        'Created at': 'Created at',
        Sunday: 'Sunday',
        Saturday: 'Saturday',
        Map: 'Map',
        History: 'History',
        Overview: 'Overview',
        Company: 'Company',
        'Average workday length': 'Average workday length',
        'Total hours during selected period': 'Total hours during selected period',
        report: 'report',
        'Work types': 'Work types',
        'Paid absences': 'Paid absences',
        'Work rounds': 'Work rounds',
        'Daily overtime': 'Daily overtime',
        'Weekly overtime': 'Weekly overtime',
        'Please wait while the :type is still generating': {
          '': { '': { '': 'Please wait while the :type is still generating...' } },
        },
        'Default pause length': 'Default pause length',
        'Include in overtime computation': 'Include in overtime computation',
        'Total time': 'Total time',
        'Add user': 'Add user',
        'Edit user': 'Edit user',
        'Edit users': 'Edit users',
        Pauses: 'Pauses',
        'Confirmation action': 'Confirmation action',
        'Edit Box': 'Edit Box',
        'Main Screen Type': 'Main Screen Type',
        Message: 'Message',
        'With worker list': 'With worker list',
        'Without worker list': 'Without worker list',
        'Add Box': 'Add Box',
        "Workspaces who's people are visible in the box": "Workspaces who's people are visible in the box",
        Day: 'Day',
        'Choose type': 'Choose type',
        Until: 'Until',
        'Do not count as workday': 'Do not count as workday',
        'Time unit': 'Time unit',
        Length: 'Length',
        'Edit Paid Absence': 'Edit Paid Absence',
        'New Paid Absence': 'New Paid Absence',
        'Add new': 'Add new',
        'Add new paid absence': 'Add new paid absence',
        'User ID': 'User ID',
        'User key': 'User key',
        'Division specific settings': 'Division specific settings',
        'Update Dimensions': 'Update Dimensions',
        'Dimensions updated': 'Dimensions updated',
        'Netvisor Dimensions': 'Netvisor Dimensions',
        'Dimension updated successfully': { '': 'Dimension updated successfully.' },
        'Netvisor settings updated successfully': { '': 'Netvisor settings updated successfully.' },
        'Connection Settings': 'Connection Settings',
        'Netvisor Settings': 'Netvisor Settings',
        'Business ID': 'Business ID',
        'User Profile': 'User Profile',
        'Workspace does not have a worker': 'Workspace does not have a worker',
        'Registry code': 'Registry code',
        'Contact Information': 'Contact Information',
        Phone: 'Phone',
        'Start from': 'Start from',
        'User type': 'User type',
        'General Rights': 'General Rights',
        'Can register work hour from login box': 'Can register work hour from login box',
        'Is visible on login box': 'Is visible on login box',
        'Start of employment': 'Start of employment',
        'End of employment': 'End of employment',
        'Netvisor export identification': 'Netvisor export identification',
        'Worker identification in Netvisor export': 'Worker identification in Netvisor export',
        'Worker number': 'Worker number',
        Information: 'Information',
        Rights: 'Rights',
        Personal: 'Personal',
        'Add another number': 'Add another number',
        'Add another address': 'Add another address',
        'ID Code': 'ID Code',
        'TAX Number': 'TAX Number',
        'Date of birth': 'Date of birth',
        Generic: 'Generic',
        Deleted: 'Deleted',
        Actions: 'Actions',
        'Add Paid Absence': 'Add Paid Absence',
        ':count worker is selected': ':count worker is selected',
        ':count workers are selected': ':count workers are selected',
        'Send workers data': 'Send workers data',
        'Send email to worker': 'Send email to worker',
        'Hide all days': 'Hide all days',
        'Show all days': 'Show all days',
        Dimensions: 'Dimensions',
        'Working hours are not confirmed': 'Working hours are not confirmed',
        'Product number': 'Product number',
        Hours: 'Hours',
        'Export as :type': 'Export as :type',
        'Collapse all rows': 'Collapse all rows',
        'Expand all rows': 'Expand all rows',
        All: 'All',
        'Confirmed hours': 'Confirmed hours',
        'Unconfirmed hours': 'Unconfirmed hours',
        'Group by': 'Group by',
        'Report options': 'Report options',
        'Total hours': 'Total hours',
        'Total days': 'Total days',
        'Average day': 'Average day',
        'Total paid absence': 'Total paid absence',
        Bonuses: 'Bonuses',
        Unconfirm: 'Unconfirm',
        Edited: 'Edited',
        Tags: 'Tags',
        Worktype: 'Worktype',
        'Are you sure you want to :status this work log?': 'Are you sure you want to :status this work log?',
        'Are you sure you want to delete this work log?': 'Are you sure you want to delete this work log?',
        'Are you sure you want to delete :name work log?': 'Are you sure you want to delete :name work log?',
        'Delete Timesheet': 'Delete Timesheet',
        'Are you sure you want to delete :name?': 'Are you sure you want to delete :name?',
        Amount: 'Amount',
        'Edit Bonus': 'Edit Bonus',
        'Are you sure you want to confirm :name work log?': 'Are you sure you want to confirm :name work log?',
        'Are you sure you want to unconfirm :name work log?': 'Are you sure you want to unconfirm :name work log?',
        'Worker Comment': 'Worker Comment',
        Bonus: 'Bonus',
        'Breaks Total': 'Breaks Total',
        'Add :label': 'Add :label',
        'No :label added': 'No :label added',
        'Warning: Updating these settings may affect ongoing works': {
          '': 'Warning: Updating these settings may affect ongoing works.',
        },
        'Can register own work hour through mobile': 'Can register work hours through mobile',
        'Location tracking is required by owner': 'Location tracking is required by owner',
        'Can manage user work hours': 'Can manage user work hours',
        'Edit Work type': 'Edit Work type',
        'Work name': 'Work name',
        'Parent Work type': 'Parent Work type',
        'Visma Product nr': 'Visma Product nr',
        'Work Price': 'Work Price',
        Hour: 'Hour',
        Piece: 'Piece',
        'Show separately on report': 'Show separately on report',
        'Cost Descriptions': 'Cost Descriptions',
        'Paid Absences': 'Paid Absences',
        ':count work items selected': ':count work items selected',
        Files: 'Files',
        Save: 'Save',
        'Add Bonus': 'Add Bonus',
        'Waiting activation': 'Waiting activation',
        Active: 'Active',
        Inactive: 'Inactive',
        'Search for user': 'Search for user',
        Manager: 'Manager',
        Worker: 'Worker',
        'Add manually': 'Add manually',
        'Invite user via email': 'Invite user via email',
        'Company Profile': 'Company Profile',
        Status: 'Status',
        'Add New': 'Add New',
        Filters: 'Filters',
        Submit: 'Submit',
        Reset: 'Reset',
        Workpace: 'Workpace',
        Name: 'Name',
        Type: 'Type',
        Period: 'Period',
        Working: 'Working',
        'Not Working': 'Not Working',
        'Just an empty canvas': 'Just an empty canvas',
        'Try a different date range or filters': { '': 'Try a different date range or filters.' },
        'Still nothing? Go ahead and track some time': { '': 'Still nothing? Go ahead and track some time.' },
        Pause: 'Pause',
        'End time': 'End time',
        'Start time': 'Start time',
        'Work type': 'Work type',
        Profile: 'Profile',
        Date: 'Date',
        Start: 'Start',
        Duration: 'Duration',
        Total: 'Total',
        Confirmed: 'Confirmed',
        Salary: 'Salary',
        'Add time': 'Add time',
        "Don't count overtime": "Don't count overtime",
        'No files added': 'No files added',
        'Add files': 'Add files',
        'Start work': 'Start work',
        'Remove end time': 'Remove end time',
        'Add end time': 'Add end time',
        'Pause length': 'Pause length',
        'Pause start': 'Pause start',
        'Work number': 'Work number',
        Comment: 'Comment',
        ':name :old_value changed to :new_value': ':name :old_value changed to :new_value',
        'Birth date': 'Birth date',
        'Document :filename is no longer available': 'Document :filename is no longer available',
        'No documents added': 'No documents added',
        'Total size of uploaded files:': 'Total size of uploaded files',
        'No bonuses added for this user': 'No bonuses added for this user',
        'No salary settings': 'No salary settings',
        'No paid absences added for this user': 'No paid absences added for this user',
        'To which workspaces can the employee register hours': 'To which workspaces can the employee register hours',
        '* when no workspace is selected, it is allowed to register hours to all workspaces':
          '* when no workspace is selected, it is allowed to register hours to all workspaces',
        'Can register own work time from computer': 'Can add working hours manually',
        '\r\nCan edit own work time': ['\r', 'Can edit own work time'],
        'Can register work hours from login box': 'Can register work hours from login box',
        'Can register work hours through mobile': 'Can register work hours through mobile',
        'Can generate reports': 'Can generate reports',
        '\r\nCan edit own profile': ['\r', 'Can edit own profile'],
        'User has Manager Rights': 'User has Manager Rights',
        '\r\nTo which workspaces these permissions apply': ['\r', 'To which workspaces these permissions apply'],
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* when no workspace is selected, these permissions apply to all workspaces',
        'Can edit preferences': 'Can edit preferences',
        'Can add/edit users': 'Can add/edit users',
        'Can generate reports for workers': 'Can generate reports for workers',
        '\r\nCan add/edit work hours of users': ['\r', 'Can add/edit work hours of users'],
        'Can manage workspaces': 'Can manage workspaces',
        'Can manage work types': 'Can manage work types',
        'Can manage salary extensions': 'Can manage salary extensions',
        'Login boxes management': 'Login boxes management',
        '\r\nCan confirm work hours': 'Can confirm work hours',
        'Add new group': 'Add new group',
        'View Details': 'View Details',
        'Send worker data': 'Send worker data',
        'Resend activation link': 'Resend activation link',
        Edit: 'Edit',
        Deactivate: 'Deactivate',
        Activate: 'Activate',
        Send: 'Send',
        'Are you sure you want to :status :name': 'Are you sure you want to :status :name',
        'Please register by entering your email': 'Please register by entering your email',
        'Repeat password': 'Repeat password',
        Password: 'Password',
        'Your E-mail': 'Your E-mail',
        Language: 'Language',
        'Sign up': 'Sign up',
        'Number of days worked': 'Number of days worked',
        'People not working': 'People not working',
        'People working': 'People working',
        'Edit Workspace': 'Edit Workspace',
        'Delete Workspace': 'Delete Workspace',
        'I agree to': 'I agree to',
        'Terms & Condition': 'Terms & Condition',
        about_login: 'About',
        sign_up: 'Sign up to Hours',
        first_name: 'First name',
        last_name: 'Last name',
        company_name: 'Company name',
        'I allow Hours to e-mail me notifications and updates regarding notifications, raports and services':
          'I allow Hours to e-mail me notifications and updates regarding notifications, raports and services',
        'I agree to Terms & Condition': 'I agree to Terms & Condition',
        login: 'Login',
        register: 'Register',
        welcome: 'Welcome to Hours',
        please_login: 'Please login by entering your email and password.',
        remember_me: 'Remember me',
        forgot_password: 'Forgot Password?',
        help: 'Help',
        contact: 'Contact',
        show_time_decimal: 'Show time in decimals',
        yesterday: 'Yesterday',
        this_week: 'This Week',
        this_month: 'This Month',
        previous_week: 'Previous Week',
        previous_month: 'Previous Month',
        apply: 'Apply',
        reset: 'Reset',
        cancel: 'Cancel',
        today: 'Today',
        week: 'Week',
        month: 'Month',
        waiting_activation: 'Waiting activation',
        send_email: 'Send email',
        worker_name: 'Worker name',
        delete_worklog_msg: 'Are you sure you want to delete selected work logs?',
        confirm_action: 'Confirm Action',
        confirm_worklog_msg: 'Are you sure you want to confirm selected work logs?',
        edit_timesheet: 'Edit Timesheet',
        success_delete_msg: 'Successfully deleted',
        success_update_msg: 'Successfully updated',
        delete: 'Delete',
        confirm: 'Confirm',
        add_user: 'Add user',
        add_workspace: 'Add workspace',
        start_timer: 'Start timer',
        people_working: 'People working',
        people_not_working: 'People not-working',
        settings: 'Settings',
        preferences: 'Preferences',
        work_types: 'Work types',
        vacation_types: 'Vacation types',
        boxes: 'Boxes',
        logout: 'Logout',
        general: 'General',
        overtime: 'Overtime',
        work_rounds: 'Work rounds',
        dashboard: 'Dashboard',
        timesheet: 'Timesheet',
        reports: 'Reports',
        people: 'People',
        workspaces: 'Workspaces',
        start_tracking: 'Start tracking and fill blanks pages',
        go_ahead: 'Go ahead and track some time.',
        monthly_working_hours: 'Monthly working hours',
        avg_workday_length: 'Average workday length',
        clients: 'Clients',
        logins: 'Logins',
        warnings: 'Warnings',
        about: 'About',
        Opportunities: 'Opportunities',
        use_same_preference: 'Use same preferences for all workspaces',
        auto_add_pause: 'Automatically add pause after',
        choose_lang: 'Choose language',
        auto_end: 'Automatically end work after',
        show_hours: 'Show hours in Decimals',
        round_start: 'Round start time',
        round_end: 'Round end time',
        name: 'Name',
        end: 'End',
        add_work_round: 'Add work round',
        add_overtime: 'Add overtime',
        telephone: 'Telephone',
        address: 'Address',
        status: 'Status',
        role: 'Role',
        company: 'Company',
        workspace: 'Workspace',
        email: 'Email',
        options: 'Options',
        filters: 'Filters',
        working_hours: 'Working hours',
        export: 'Export',
      },
    },
    ru: {
      translation: {
        'Total pauses during selected period': 'Общее количество пауз за выбранный период',
        "If you haven't received verification email in a few minutes, please check the spam folder": {
          '': 'Если вы не получили письмо с подтверждением в течение нескольких минут, проверьте папку со спамом.',
        },
        'Congratulations! You have just set your account password': {
          ' You are now able to access the web portal': {
            '':
              'Поздравляем! Вы только что установили пароль своей учетной записи. Теперь вы можете получить доступ к нашему веб-порталу.',
          },
        },
        'You are all set': { '': 'У вас все настроено.' },
        'Your email has been verified': { '': 'Ваш адрес электронной почты подтвержден.' },
        'Activation link is active within a week after creating an account': {
          ' Please resend a new activation link to your email': {
            '':
              'Ссылка активации активна в течение недели после создания учетной записи. Отправьте новую ссылку активации на вашу электронную почту.',
          },
        },
        'We sent you a verification email to': 'Мы отправили вам письмо для проверки на адрес',
        'If you havent received verification email in a few minutes, please check the spam folder': {
          '':
            'Если вы не получили письмо для проверки в течение нескольких минут, проверьте папку нежелательной почты.',
        },
        'Congratulations! you have just verified your email address': {
          '': 'Поздравляем! Вы только что подтвердили свой адрес электронной почты.',
        },
        'You are now able to access the web portal': { '': 'Теперь вы можете получить доступ к нашему веб-порталу.' },
        'Unable to verify your email': 'Не удалось подтвердить ваш адрес электронной почты',
        'Invalid verification token received or the link is already expired': {
          '': 'Получен недействительный токен подтверждения или срок действия ссылки уже истек.',
        },
        "If you don't see the email, please check your spam folder or contact your Organization":
          'Если вы не видите это письмо, проверьте папку нежелательной почты или обратитесь в свою организацию.',
        'Overtime computation': 'Расчет сверхурочной работы',
        "Can't add pause bigger than work duration": {
          '': 'Нельзя добавить паузу в работе длиннее продолжительности работы.',
        },
        'Work duration must not overlap to other work logs': {
          '': 'Продолжительность работы не должна пересекаться с другими рабочими журналами.',
        },
        "Work durations shouldn't be negative": { '': 'Продолжительность работы не должна быть отрицательной.' },
        'Selected works have some users with multiple work logs on the same day':
          'В выбранных работах есть несколько пользователей с несколькими рабочими журналами в один и тот же день.',
        'Cannot confirm not ended work': 'Нельзя подтвердить незавершенную работу',
        "Start time can't be later than end time": { '': 'Время начала не может быть позже времени окончания.' },
        'This absence request has already been declined': { '': 'Этот запрос на отсутствие уже отклонен.' },
        'Overlapping paid absences': 'Перекрывающееся оплачиваемое время отсутствия на рабочем месте',
        'Cannot request with overlapping paid absences':
          'Нельзя сделать запрос с перекрывающимся оплачиваемым временем отсутствия на рабочем месте',
        'There is already ongoing unconfirmed request': 'Уже есть неподтвержденный запрос',
        'Work has been deleted': 'Работа удалена',
        'Can not found request': {
          ' Work log may have been deleted': { '': 'Не удается найти запрос. Рабочий журнал мог быть удален.' },
        },
        'This bonus request has already been accepted': { '': 'Этот запрос на надбавку уже принят.' },
        'This bonus request has already been declined': { '': 'Этот запрос на надбавку уже отклонен.' },
        "Pause start can't be earlier than work start": {
          '': 'Начало паузы в работе не может быть раньше начала работы.',
        },
        'Work resumed already': { '': 'Работа уже возобновлена.' },
        'User is currently working': { '': 'Пользователь в настоящее время работает.' },
        'Overlapping work rounds': 'Перекрывающиеся рабочие циклы',
        'There are some errors in exporting merit palk report': 'При экспорте отчета о заслугах возникли ошибки.',
        'There are some errors in exporting netvisor report': 'При экспорте отчета Netvisor возникли ошибки.',
        'Dimension updated unsuccessfully': 'Ракурс не обновлен.',
        'Notification cannot be posted if deleted': 'Уведомление не будет опубликовано, если оно удалено',
        'Error fetching locales': { '': 'Ошибка при загрузке локализации.' },
        'This absence request has already been accepted': { '': 'Этот запрос на отсутствие уже принят.' },
        'Work paused already': { '': 'Работа уже приостановлена.' },
        "This account doesn't exist": { '': 'Эта учетная запись не существует.' },
        'Your account has been deleted': { '': 'Ваша учетная запись удалена.' },
        'Please verify your email': { '': 'Подтвердите свой адрес электронной почты.' },
        'Your account has been deactivated': { '': 'Ваша учетная запись деактивирована.' },
        'Your company account has been disabled': { '': 'Учетная запись вашей компании отключена.' },
        'Work start should not be greater than stop time': {
          '': 'Время начала работы не должно быть позже времени остановки.',
        },
        'Work stopped already': { '': 'Работа уже остановлена.' },
        'Work not found': { '': 'Работа не найдена.' },
        'You have attempted to login multiple times already': {
          ' Try resetting your password': { '': 'Вы уже пытались войти несколько раз. Попробуйте сбросить пароль.' },
        },
        'Enter your email below and we will send a password reset link to your email': {
          '':
            'Введите в следующем поле адрес электронной почты, и мы отправим вам ссылку для сброса пароля на этот адрес',
        },
        'Token already expired': 'Срок действия токена истек',
        'Token is invalid': 'Токен недействителен',
        'Token has already been used': 'Токен уже использован',
        'User not found': 'Пользователь не найден',
        'Password Reset Sent': 'Сброс пароля отправлен',
        'You should receive the password reset email shortly once an account matching your username is found': {
          '':
            'В скором времени вам должно прийти электронное письмо для сброса пароля, если будет найдена учетная запись, соответствующая вашему имени пользователя.',
        },
        'Invalid credentials': 'Недействительные учетные данные',
        'Please try again or ': 'Попробуйте еще раз, либо ',
        'Merit Palk Setting': 'Настройка заслуг',
        'API Setting': 'Настройка API',
        'API Key': 'API-ключ',
        'API ID': 'Идентификатор API',
        'Do not count as overtime': 'Не считать как сверхурочные',
        'No notifications created': 'Уведомления не созданы',
        'Start by creating notifications and post them': { '': 'Начните с создания уведомлений и опубликуйте их.' },
        'Add new notification': 'Добавить новое уведомление',
        'Default work type': 'Тип работы по умолчанию',
        'Automatic start/end work with mobile': 'Автоматический запуск/завершение работы с мобильного телефона',
        Radius: 'Радиус',
        'Set location': 'Установить местоположение',
        'People rate': 'Ставки сотрудников',
        'Worktype rate': 'Ставки по типам работы',
        'Show worker number': 'Показать номер сотрудника',
        'Reset Your Password': 'Сбросить пароль',
        'Back to login': 'Вернуться на страницу авторизации',
        'More details': 'Подробнее',
        'Showing rows: :current out of :total': 'Показаны строки: :current из :total',
        'We use cookies to ensure you get the best experience on our site':
          'Мы используем файлы cookie, чтобы сделать наш сайт максимально удобным для вас.',
        'Start by adding new allowed pause group': 'Начните с добавления новой группы разрешенных пауз в работе',
        'Group does not have an allowed pause item': 'В группе нет разрешенной паузы в работе',
        'Add allowed pause': 'Добавить разрешенную паузу',
        'Allowed pause group': 'Группа разрешенных пауз в работе',
        'Work pause': 'Пауза в работе',
        'Work end': 'Конец работы',
        'Work start time': 'Время начала работы',
        'Round time': 'Время цикла',
        'Lunch time': 'Время обеда',
        minutes: 'минуты',
        'before work end time to round up': 'до времени окончания работы с округлением в большую сторону',
        'after work end time to round down': 'после времени окончания работы с округлением в меньшую сторону',
        'Your allowed pause group is empty': 'Ваша группа разрешенных пауз в работе пуста',
        'Update doors': 'Обновить данные о дверях',
        'When exiting': 'При выходе',
        'Edit door': 'Изменить данные двери',
        'Door access': 'Доступ к двери',
        'Main door': 'Главная дверь',
        'must be accessed to start work': 'необходимо получить доступ для начала работы',
        'Front door': 'Передняя дверь',
        'Change work type when entering': 'Изменить тип работы при входе',
        'Work resume when entering': 'Возобновление работы при входе',
        Request: 'Запросить',
        'View Bonus': 'Посмотреть надбавку',
        Pending: 'В ожидании',
        Approved: 'Одобрено',
        Declined: 'Отклонено',
        'Expired activation': 'Просроченная активация',
        'Inner Range Doors': 'Двери внутреннего пространства',
        'Doors updated': 'Данные о дверях обновлены',
        'Door ID': 'Идентификатор двери',
        'Request Bonus': 'Запросить надбавку',
        'Can accept/edit/decline requests': 'Может принимать/редактировать/отклонять запросы',
        'Switching Account': 'Смена учетной записи',
        'Past two weeks': 'Последние две недели',
        ':worker has requested a bonus of :bonus': ':worker запросил надбавку в размере :bonus',
        'has requested a paid absence': 'запросил оплачиваемое отсутствие на рабочем месте',
        ':manager has declined your request for a bonus': { '': ':manager отклонил ваш запрос на получение надбавки.' },
        'Your work (:workspace - :worktype) has automatically paused at :datetime': {
          '': 'Ваша работа (:workspace - :worktype) автоматически приостановлена :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically resumed at :datetime': {
          '': 'Ваша работа (:workspace - :worktype) автоматически возобновилась :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically ended at :datetime': {
          '': 'Ваша работа (:workspace - :worktype) автоматически завершилась :datetime.',
        },
        ':manager has :action a paid absence': { '': ':manager :action оплачиваемое отсутствие на рабочем месте.' },
        'Congrats! :manager has :action a bonus for your greate work': {
          '': 'Поздравляем! :manager :action надбавку за вашу отличную работу.',
        },
        ":worker has requested a paid absence ':type' (:start - :end)": {
          '': ":worker запросил оплачиваемое отсутствие на рабочем месте ':type' (:start - :end).",
        },
        ':name is working overtime with work (:workspace - :worktype) at this :datetime': {
          '': ':name работает сверхурочно с (:workspace - :worktype) в :datetime.',
        },
        ':manager has declined your request for a paid absence': {
          '': ':manager отклонил ваш запрос на оплачиваемое отсутствие на рабочем месте.',
        },
        'User preference': 'Пользовательские настройки',
        'Sorting order': 'Порядок сортировки',
        'First name, Last name': 'Имя, фамилия',
        'Last name, First name': 'Фамилия, имя',
        'Allowed pauses': 'Разрешенные перерывы',
        Late: 'Просрочено',
        'You have been added to Hours time tracking': 'Вы добавлены в учет рабочего времени Hours',
        'Fetch records': 'Получить записи',
        'has requested a bonus of': 'запросил надбавку в размере',
        Posted: 'Опубликовано',
        'Ready for posting': 'Готово к публикации',
        Draft: 'Черновик',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'если пользователь не выбран, уведомления будут отправлены всем пользователям в компании или в выбранных рабочих областях',
        'Draft notification message': 'Черновик уведомления',
        Requests: 'Запросы',
        'No bonus requests yet': 'Запросов на надбавки пока нет',
        'No paid absence requests yet': 'Запросов на оплачиваемое время отсутствия на рабочем месте пока нет',
        'Paid absence': 'Оплачиваемое время отсутствия на рабочем месте',
        'Bonus comment': 'Комментарий к надбавке',
        'Original requested amount': 'Исходное запрошенное количество',
        'New amount': 'Новое количество',
        'Request paid absence': 'Запрос на оплачиваемое время отсутствия на рабочем месте',
        'Requested at': 'Запрошено в',
        'Started at': 'Началось в',
        'Ended at': 'Закончилось в',
        'Confirmed at': 'Подтверждено в',
        'Confirmed by': 'Подтверждено',
        'Work date': 'Дата работы',
        'You have no rights to edit work time!': 'У вас нет прав на редактирование рабочего времени!',
        'Notify through push notification': 'Оповещение через push-уведомление',
        'Notify through email': 'Оповещение по электронной почте',
        Notifications: 'Уведомления',
        'Created by': 'Создано',
        'Posted at': 'Опубликовано в',
        Title: 'Заголовок',
        Repost: 'Репост',
        'Add notification': 'Добавить уведомление',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'если рабочее пространство не выбрано, уведомления будут отправлены всем пользователям в компании',
        'Company notifications': 'Уведомления компании',
        'Worker is working overtime': 'Работник работает сверхурочно',
        'Worker is asking for a bonus': 'Работник просит надбавку',
        'Worker is requesting for an absence': 'Работник запрашивает время отсутствия на рабочем месте',
        'Worker has arrived/left from the workspace': 'Работник пришел/ушел с рабочего места',
        'Manager has added/accepted/declined a bonus': 'Менеджер добавил/принял/отклонил надбавку',
        'Manager has added/accepted/declined a paid absence':
          'Менеджер добавил/принял/отклонил оплачиваемое время отсутствия на рабочем месте',
        'Work paused/ended automatically through workspace preference':
          'Работа приостановлена/завершена автоматически через настройки рабочего пространства',
        'Start by adding a new box': { '': 'Начните с добавления нового поля.' },
        Latest: 'Самый последний',
        'This week': 'На этой неделе',
        Older: 'Более старые',
        'You have declined this request!': 'Вы отклонили этот запрос!',
        'You have already approved this request!': 'Вы уже одобрили этот запрос!',
        Accept: 'Принять',
        Decline: 'Отклонить',
        'Mark as read': 'Пометить как прочитанное',
        'Turn off receiving this status': 'Отключить получение этого статуса',
        'Delete notification': 'Удалить уведомление',
        'Mark all as read': 'Отметить все как прочитанные',
        'View full feed': 'Посмотреть полную ленту',
        'Clear all': 'Очистить все',
        Read: 'Читать',
        Close: 'Закрыть',
        Clear: 'Очистить',
        'No notifications yet': 'Уведомлений пока нет',
        'Turn on/off notifications': 'Включить/выключить уведомления',
        'Weekly working hours': 'Еженедельное рабочее время',
        'Show more': 'Показать больше',
        'Show less': 'Показать меньше',
        'No email': 'Нет электронной почты',
        'Complete registration by choosing a password': 'Завершите регистрацию, выбрав пароль',
        'Your First Name': 'Ваше имя',
        'Your Last Name': 'Ваша фамилия',
        'Your Password': 'Ваш пароль',
        'Confirm Your Password': 'Подтвердите ваш пароль',
        "Please register by entering you're email": 'Зарегистрируйтесь, указав свой адрес электронной почты',
        Location: 'Местоположение',
        'Must have at least 8 characters': 'Должен содержать не менее 8 символов',
        'Must start with alphanumeric character': 'Должен начинаться с буквенно-цифрового символа',
        'Must have at least one lowercase character': 'Должен содержать хотя бы один символ нижнего регистра',
        'Must have at least one uppercase character': 'Должен содержать хотя бы один символ верхнего регистра',
        'Must have at least one digit': 'Должен содержать хотя бы одну цифру',
        'Passwords must match': 'Пароли должны совпадать',
        'Must Accept Terms and Condition': 'Необходимо принять "Условия и положения"',
        'This field is required': 'Это поле обязательно к заполнению',
        ':field is required': ':field обязательно для заполнения',
        by: 'кем',
        'Total salary': 'Общая зарплата',
        'Paid pause': 'Оплачиваемая пауза',
        'Work type extras': 'Дополнения к типу работы',
        Basic: 'Основная',
        'Basic salary': 'Основная зарплата',
        Week: 'Неделя',
        Rate: 'Ставка',
        'Worker is missing identification number': 'У работника отсутствует идентификационный номер',
        'Your paid absences is empty': 'Ваши оплачиваемые отсутствия на рабочем месте не заполнены',
        'Start by adding new paid absences': 'Начните с добавления новых оплачиваемых отсутствий на рабочем месте',
        'Invite user': 'Пригласить пользователей',
        'Group does not have a work round item': 'В группе нет элемента рабочего цикла',
        'Group does not have an overtime item': 'В группе нет сверхурочной работы',
        'Upload Company Logo': 'Загрузить логотип компании',
        'Upload Profile Image': 'Загрузить изображение профиля',
        'Drag and drop your image here or': 'Перетащите изображение сюда или',
        'upload a photo': 'загрузите фото',
        'Single image must not exceed 5MB': 'Размер одного изображения не должен превышать 5 МБ.',
        'Business Area': 'Сфера бизнеса',
        'Start by adding new work round group': 'Начните с добавления новой группы рабочих циклов',
        'Your work round group is empty': 'Ваша группа рабочих циклов пуста',
        'Your overtime group is empty': 'Ваша группа сверхурочных пуста',
        '\r\nStart by adding new overtime group': 'Начните с добавления новой группы сверхурочной работы',
        File: 'файл',
        "This does not affect users' existing paid absences":
          'Это не влияет на существующие оплачиваемые отсутствия на рабочем месте пользователя.',
        'View document': 'Просмотреть документ',
        'Rename document': 'Переименовать документ',
        'Delete document': 'Удалить документ',
        'File name': 'Имя файла',
        'File type': 'Тип файла',
        'File size': 'Размер файла',
        'Last date modified': 'Дата последнего изменения',
        'accepts comma-separated input': 'принимает ввод с разделителями-запятыми',
        "Press 'Enter'": 'Нажмите "Ввод"',
        Emails: 'электронные письма',
        'Invite users by email': 'Отправить приглашение',
        'Add work type': 'Добавить тип работы',
        'View work types': 'Просмотреть типы работы',
        'User has been deleted': 'Пользователь удален',
        'User information cannot be viewed': 'Невозможно просмотреть данные пользователя',
        'Go back to People page': 'Вернуться на страницу "Люди"',
        'Something went wrong with your request': {
          ' Please try again later or refresh the page': {
            '': 'Произошла ошибка при обработке вашего запроса. Попробуйте еще раз или обновите эту страницу.',
          },
        },
        'Something went wrong': {
          ' Please contact administrator': { '': 'Произошла ошибка. Свяжитесь с администратором.' },
        },
        'The work has been updated': {
          ' Please confirm to apply changes': 'Данные о работе изменены. Подтвердите изменения.',
        },
        'Start by adding new work type': 'Начните с добавления нового типа работы',
        'Start by adding a new workina hours registration box': {
          '': 'Начните с добавления нового поля регистрации рабочего времени.',
        },
        'Account verification': 'Верификация учетной записи',
        Hello: 'Здравствуйте',
        'Please click the button below to verify your email address': {
          '': 'Чтобы подтвердить свой адрес электронной почты, нажмите кнопку ниже.',
        },
        'Verify Email Address': 'Подтвердить адрес электронной почты',
        'If you did not create an account, no further action is required': {
          '': 'Если вы не создавали учетную запись, никаких дальнейших действий не требуется.',
        },
        'Best regards': 'С уважением,',
        Team: 'Команда',
        "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser":
          'Если у вас возникли проблемы с нажатием кнопки «Подтвердить адрес электронной почты», скопируйте и вставьте приведенный ниже URL-адрес в свой веб-браузер.',
        'All rights reserved': 'Все права защищены',
        'Complete registration': 'Завершить регистрацию',
        from: 'из',
        'have added you to Hours time tracking': { '': 'добавил(а) вас в программу отслеживания времени Hours' },
        'Please complete your registration by setting up a password': {
          '': 'Установите пароль и завершите регистрацию',
        },
        'Set Password': 'Установить пароль',
        'This set password link will expire 1 day from now': {
          '': 'Срок действия этой ссылки для установки пароля истекает через 1 день.',
        },
        'Download also Hours mobile apps': 'Скачайте также мобильные приложения Hours',
        "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser":
          'Если у вас возникли проблемы с нажатием кнопки «Установить пароль», скопируйте и вставьте приведенный ниже URL-адрес в свой веб-браузер.',
        'Welcome to': 'Добро пожаловать в',
        'You are receiving this email because we received a reset password request for your account':
          'Вы получили это письмо, потому что нам поступил запрос на сброс пароля для вашей учетной записи.',
        'Reset Password': 'Сбросить пароль',
        'If you did not request a password reset, no further action is required': {
          '': 'Если вы не запрашивали сброс пароля, никаких дальнейших действий не требуется.',
        },
        'This password reset link will expire 1 day from now': {
          '': 'Срок действия этой ссылки для сброса пароля истекает через 1 день.',
        },
        'Your work type is empty': 'Ваш тип работы не задан',
        'Dreaming of a new box?': 'Задумываетесь о новом поле?',
        'Oops! Option already exist': { '': 'Ошибка! Такой вариант уже существует.' },
        'Are you sure you want to update selected work logs?':
          'Вы уверены, что хотите обновить выбранные рабочие журналы?',
        'Round to nearest hour': 'Округлить до ближайшего часа',
        'Round to nearest half-hour': 'Округлить до ближайшего получаса',
        'Round to nearest 10 minutes': 'Округлить до ближайших 10 минут',
        'Still having a trouble?  Please contact administrator': 'Все еще есть проблемы?  Свяжитесь с администратором',
        'Edit group': 'Изменить параметры группы',
        'Overtime group': 'Группа сверхурочных',
        'Work round group': 'Группа рабочего цикла',
        'Are you sure you want to remove profile image?': 'Вы уверены, что хотите удалить изображение профиля?',
        'Error on exporting :type': { '': 'Ошибка при экспорте :type.' },
        'Do not group': 'Не группировать',
        'Grouped by people': 'Сгруппировано по людям',
        'Are you sure you want to bulk activate/deactivate?':
          'Вы уверены, что хотите выполнить массовую активацию/деактивацию?',
        'Please try shorter time frame or less people at the same time': {
          '': 'Попробуйте более короткие временные рамки или меньшее количество людей одновременно.',
        },
        'Some selected work logs are already confirmed!': 'Некоторые выбранные рабочие журналы уже подтверждены!',
        'Round work time': 'Округленное время работы',
        Processing: { '': { '': { '': 'Обработка...' } } },
        Subject: 'Объект',
        'Number of weeks': 'Количество недель',
        'Number of months': 'Количество месяцев',
        'Daily normal hours': 'Обычные часы за день',
        'Weekly normal hours': 'Обычные часы за неделю',
        'Monthly normal hours': 'Обычные часы за месяц',
        'Are you sure want to switch as :type?': 'Вы уверены, что хотите переключиться на :type?',
        'This action cannot be undone!': 'Это действие нельзя отменить!',
        'Work type workspaces': 'Рабочие места по типам работы',
        'People workspaces': 'Рабочие места по сотрудникам',
        'Working Period': 'Рабочий период',
        From: 'От',
        'Send copy': 'Отправить копию',
        'weekly overtime': 'сверхурочная работа за неделю',
        'Monthly overtime': 'Сверхурочная работа за месяц',
        'monthly overtime': 'сверхурочная работа за месяц',
        'working hours': 'рабочее время',
        total: 'всего',
        hours: 'часов',
        days: 'дней',
        'Total working days': 'Всего рабочих дней',
        'Created at': 'Создано в',
        Sunday: 'Воскресенье',
        Saturday: 'Суббота',
        Map: 'Карта',
        History: 'История',
        Overview: 'Обзор',
        Company: 'Организация',
        'Average workday length': 'Средняя продолжительность рабочего дня',
        'Total hours during selected period': 'Всего часов за выбранный период',
        report: 'отчет',
        'Work types': 'Типы работы',
        'Paid absences': 'Оплачиваемое отсутствие на рабочем месте',
        'Work rounds': 'Рабочие циклы',
        'Daily overtime': 'Сверхурочная работа за день',
        'Weekly overtime': 'Сверхурочная работа за неделю',
        'Please wait while the :type is still generating': { '': { '': { '': 'Подождите, пока создается :type...' } } },
        'Default pause length': 'Длина паузы по умолчанию',
        'Include in overtime computation': 'Включить в расчет сверхурочной работы',
        'Total time': 'Общее время',
        'Add user': 'Добавить пользователя',
        'Edit user': 'Изменить пользователя',
        'Edit users': 'Изменить пользователей',
        Pauses: 'Паузы',
        'Confirmation action': 'Подтверждение действия',
        'Edit Box': 'Изменить поле',
        'Main Screen Type': 'Тип основного экрана',
        Message: 'Сообщение',
        'With worker list': 'Со списком сотрудников',
        'Without worker list': 'Без списка сотрудников',
        'Add Box': 'Добавить поле',
        "Workspaces who's people are visible in the box": 'Рабочие места, сотрудники на которых видны в поле',
        Day: 'День',
        'Choose type': 'Выбрать тип',
        Until: 'До',
        'Do not count as workday': 'Не считать рабочим днем',
        'Time unit': 'Единица времени',
        Length: 'Длина',
        'Edit Paid Absence': 'Изменить оплачиваемое время отсутствия на рабочем месте',
        'New Paid Absence': 'Новое оплачиваемое время отсутствия на рабочем месте',
        'Add new': 'Добавить новое',
        'Add new paid absence': 'Добавить новое оплачиваемое время отсутствия на рабочем месте',
        'User ID': 'Идентификатор пользователя',
        'User key': 'Ключ пользователя',
        'Division specific settings': 'Настройки на уровне отдела',
        'Update Dimensions': 'Обновить ракурсы',
        'Dimensions updated': 'Ракурсы обновлены',
        'Netvisor Dimensions': 'Ракурсы Netvisor',
        'Dimension updated successfully': { '': 'Размер успешно обновлен.' },
        'Netvisor settings updated successfully': { '': 'Настройки Netvisor успешно обновлены.' },
        'Connection Settings': 'Настройки соединения',
        'Netvisor Settings': 'Настройки Netvisor',
        'Business ID': 'Идентификатор компании',
        'User Profile': 'Профиль пользователя',
        'Workspace does not have a worker': 'На рабочем месте нет сотрудника',
        'Registry code': 'Регистрационный код',
        'Contact Information': 'Контактная информация',
        Phone: 'Телефон',
        'Start from': 'Начать с',
        'User type': 'Тип пользователя',
        'General Rights': 'Общие права',
        'Can register work hour from login box': 'Может зарегистрировать рабочее время из модуля входа в систему',
        'Is visible on login box': 'Отображается в модуле входа в систему',
        'Start of employment': 'Дата приема на работу',
        'End of employment': 'Дата увольнения',
        'Netvisor export identification': 'Идентификация экспорта Netvisor',
        'Worker identification in Netvisor export': 'Идентификация сотрудника в экспорте Netvisor',
        'Worker number': 'Номер сотрудника',
        Information: 'Информация',
        Rights: 'Права',
        Personal: 'Персонально',
        'Add another number': 'Добавить еще один номер',
        'Add another address': 'Добавить еще один адрес',
        'ID Code': 'Идентификационный код',
        'TAX Number': 'ИНН',
        'Date of birth': 'Дата рождения',
        Generic: 'Общее',
        Deleted: 'Удалено',
        Actions: 'Действия',
        'Add Paid Absence': 'Добавить оплачиваемое отсутствие на рабочем месте',
        ':count worker is selected': 'Выбрано сотрудников: :count',
        ':count workers are selected': 'Выбрано сотрудников: :count',
        'Send workers data': 'Отправить данные о сотрудниках',
        'Send email to worker': 'Отправить сотруднику электронное письмо',
        'Hide all days': 'Скрыть все дни',
        'Show all days': 'Показать все дни',
        Dimensions: 'Ракурсы',
        'Working hours are not confirmed': 'График работы не подтвержден',
        'Product number': 'Номер продукта',
        Hours: 'Часы',
        'Export as :type': 'Экспортировать как :type',
        'Collapse all rows': 'Свернуть все строки',
        'Expand all rows': 'Развернуть все строки',
        All: 'Все',
        'Confirmed hours': 'Подтвержденные часы',
        'Unconfirmed hours': 'Неподтвержденные часы',
        'Group by': 'Группировать по',
        'Report options': 'Параметры отчета',
        'Total hours': 'Всего часов',
        'Total days': 'Всего дней',
        'Average day': 'Обычный день',
        'Total paid absence': 'Общая сумма оплачиваемого времени отсутствия на рабочем месте',
        Bonuses: 'Бонусы',
        Unconfirm: 'Отменить подтверждение',
        Edited: 'Изменено',
        Tags: 'Метки',
        Worktype: 'Тип работы',
        'Are you sure you want to :status this work log?': 'Вы уверены, что хотите :status этот рабочий журнал?',
        'Are you sure you want to delete this work log?': 'Вы уверены, что хотите удалить этот рабочий журнал?',
        'Are you sure you want to delete :name work log?':
          'Вы уверены, что хотите удалить рабочий журнал сотрудника :name?',
        'Delete Timesheet': 'Удалить табель учета рабочего времени',
        'Are you sure you want to delete :name?': 'Вы уверены, что хотите удалить сотрудника :name?',
        Amount: 'Количество',
        'Edit Bonus': 'Изменить надбавку',
        'Are you sure you want to confirm :name work log?':
          'Вы уверены, что хотите подтвердить рабочий журнал сотрудника :name?',
        'Are you sure you want to unconfirm :name work log?':
          'Вы уверены, что хотите отменить подтверждение рабочего журнала сотрудника :name?',
        'Worker Comment': 'Комментарий сотрудника',
        Bonus: 'Надбавка',
        'Breaks Total': 'Всего перерывов',
        'Add :label': 'Добавить :label',
        'No :label added': 'Не добавлено :label',
        'Warning: Updating these settings may affect ongoing works': {
          '': 'Предупреждение: Изменение этих настроек может повлиять на выполняемую работу.',
        },
        'Can register own work hour through mobile':
          'Может зарегистрировать свое рабочее время через мобильный телефон',
        'Location tracking is required by owner': 'Отслеживание местоположения затребовано владельцем',
        'Can manage user work hours': 'Может управлять рабочим временем пользователя',
        'Edit Work type': 'Изменить тип работы',
        'Work name': 'Название работы',
        'Parent Work type': 'Исходный тип работы',
        'Visma Product nr': 'Номер продукта Visma',
        'Work Price': 'Стоимость работы',
        Hour: 'Часы',
        Piece: 'Часть',
        'Show separately on report': 'Показывать отдельно в отчете',
        'Cost Descriptions': 'Описание стоимости',
        'Paid Absences': 'Оплачиваемое отсутствие на рабочем месте',
        ':count work items selected': 'Выбрано рабочих элементов: :count',
        Files: 'Файлы',
        Save: 'Сохранить',
        'Add Bonus': 'Добавить надбавку',
        'Waiting activation': 'Ожидание активации',
        Active: 'Активно',
        Inactive: 'Неактивно',
        'Search for user': 'Поиск пользователя',
        Manager: 'Менеджер',
        Worker: 'Сотрудник',
        'Add manually': 'Добавить вручную',
        'Invite user via email': 'Пригласить пользователя по электронной почте',
        'Company Profile': 'Профиль организации',
        Status: 'Статус',
        'Add New': 'Добавить новый',
        Filters: 'Фильтры',
        Submit: 'Представить',
        Reset: 'Сброс настроек',
        Workpace: 'Рабочее место',
        Name: 'Имя',
        Type: 'Тип',
        Period: 'Период',
        Working: 'Работает',
        'Not Working': 'Не работает',
        'Just an empty canvas': 'Просто пустой лист',
        'Try a different date range or filters': { '': 'Попробуйте изменить диапазон дат или фильтры.' },
        'Still nothing? Go ahead and track some time': {
          '': 'Все еще нет данных? Продолжать дальше и отслеживать некоторое время.',
        },
        Pause: 'Пауза',
        'End time': 'Время окончания',
        'Start time': 'Время начала',
        'Work type': 'Тип работы',
        Profile: 'Профиль',
        Date: 'Дата',
        Start: 'Начало',
        Duration: 'Продолжительность',
        Total: 'Всего',
        Confirmed: 'Подтверждено',
        Salary: 'Зарплата',
        'Add time': 'Добавить время',
        "Don't count overtime": 'Не учитывать сверхурочные',
        'No files added': 'Файлы не добавлены',
        'Add files': 'Добавить файлы',
        'Start work': 'Начало работы',
        'Remove end time': 'Удалить время окончания',
        'Add end time': 'Добавить время окончания',
        'Pause length': 'Длина паузы',
        'Pause start': 'Начало паузы',
        'Work number': 'Рабочий номер',
        Comment: 'Комментарий',
        ':name :old_value changed to :new_value': ':name :old_value изменено на :new_value',
        'Birth date': 'Дата рождения',
        'Document :filename is no longer available': 'Документ :filename больше не доступен',
        'No documents added': 'Документы не добавлены',
        'Total size of uploaded files:': 'Общий размер загружаемых файлов',
        'No bonuses added for this user': 'Не добавлены надбавки для этого пользователя',
        'No salary settings': 'Не заданы параметры зарплаты',
        'No paid absences added for this user':
          'Для этого пользователя не добавлено ни одного оплачиваемого отсутствия на рабочем месте',
        'To which workspaces can the employee register hours': 'На какие рабочие места сотрудник может записывать часы',
        '* when no workspace is selected, it is allowed to register hours to all workspaces':
          '* когда рабочее место не выбрано, разрешается регистрировать часы для всех рабочих мест',
        'Can register own work time from computer': 'Может регистрировать свое рабочее время с компьютера',
        '\r\nCan edit own work time': ['', 'Может изменять свое рабочее время'],
        'Can register work hours from login box': 'Может зарегистрировать рабочее время из модуля входа в систему',
        'Can register work hours through mobile': 'Может зарегистрировать рабочее время с мобильного устройства',
        'Can generate reports': 'Может создавать отчеты',
        '\r\nCan edit own profile': ['', 'Может изменять свой профиль'],
        'User has Manager Rights': 'Пользователь имеет права менеджера',
        '\r\nTo which workspaces these permissions apply': ['', 'К каким рабочим местам применяются эти разрешения'],
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* когда рабочее место не выбрано, эти разрешения применяются ко всем рабочим местам',
        'Can edit preferences': 'Может изменять настройки',
        'Can add/edit users': 'Может добавлять/изменять параметры пользователей',
        'Can generate reports for workers': 'Может создавать отчеты для сотрудников',
        '\r\nCan add/edit work hours of users': 'Может добавлять/изменять часы работы пользователей',
        'Can manage workspaces': 'Может управлять рабочими местами',
        'Can manage work types': 'Может управлять типами работы',
        'Can manage salary extensions': 'Может управлять надбавками к заработной плате',
        'Login boxes management': 'Управление модулем входа в систему',
        '\r\nCan confirm work hours': 'Может подтвердить часы работы',
        'Add new group': 'Добавить новую группу',
        'View Details': 'Подробные сведения',
        'Send worker data': 'Отправить данные о сотруднике',
        'Resend activation link': 'Повторно отправить ссылку активации',
        Edit: 'Изменить',
        Deactivate: 'Отключить',
        Activate: 'Активировать',
        Send: 'Отправить',
        'Are you sure you want to :status :name': 'Вы уверены, что хотите :status :name',
        'Please register by entering your email': 'Зарегистрируйтесь, указав свой адрес электронной почты',
        'Repeat password': 'Повторите пароль',
        Password: 'Пароль',
        'Your E-mail': 'Ваш адрес электронной почты',
        Language: 'Язык',
        'Sign up': 'Зарегистрироваться',
        'Number of days worked': 'Количество отработанных дней',
        'People not working': 'Неработающие люди',
        'People working': 'Работающие люди',
        'Edit Workspace': 'Изменить параметры рабочего места',
        'Delete Workspace': 'Удалить рабочее место',
        'I agree to': 'Я соглашаюсь с',
        'Terms & Condition': '"Условиями и положениями"',
        about_login: 'О программе',
        sign_up: 'Зарегистрироваться в программе Hours',
        first_name: 'Имя',
        last_name: 'Фамилия',
        company_name: 'Название организации',
        'I allow Hours to e-mail me notifications and updates regarding notifications, raports and services':
          'Я разрешаю Hours присылать мне по электронной почте уведомления и обновления, касающиеся уведомлений, отчетов и услуг',
        'I agree to Terms & Condition': 'Я соглашаюсь с "Условиями и положениями"',
        login: 'Авторизоваться',
        register: 'Зарегистрироваться',
        welcome: 'Добро пожаловать в Hours',
        please_login: 'Чтобы авторизоваться, введите свой адрес электронной почты и пароль.',
        remember_me: 'Запомнить меня',
        forgot_password: 'Забыли пароль?',
        help: 'Справка',
        contact: 'Контакты',
        show_time_decimal: 'Показывать время в десятичном формате',
        yesterday: 'Вчера',
        this_week: 'На этой неделе',
        this_month: 'В этом месяце',
        previous_week: 'Прошедшая неделя',
        previous_month: 'Прошедший месяц',
        apply: 'Применить',
        reset: 'Сброс настроек',
        cancel: 'Отмена',
        today: 'Сегодня',
        week: 'Неделя',
        month: 'Месяц',
        waiting_activation: 'Ожидание активации',
        send_email: 'Отправить электронное письмо',
        worker_name: 'Имя сотрудника',
        delete_worklog_msg: 'Вы уверены, что хотите удалить выбранные рабочие журналы?',
        confirm_action: 'Подтвердить действие',
        confirm_worklog_msg: 'Вы уверены, что хотите подтвердить выбранные рабочие журналы?',
        edit_timesheet: 'Редактировать табель учета рабочего времени',
        success_delete_msg: 'Удаление выполнено успешно',
        success_update_msg: 'Обновление выполнено успешно',
        delete: 'Удалить',
        confirm: 'Подтвердить',
        add_user: 'Добавить пользователя',
        add_workspace: 'Добавить рабочее место',
        start_timer: 'Запустить таймер',
        people_working: 'Работающие люди',
        people_not_working: 'Неработающие люди',
        settings: 'Настройки',
        preferences: 'Предпочтения',
        work_types: 'Типы работы',
        vacation_types: 'Виды отпуска',
        boxes: 'Поля',
        logout: 'Завершение работы',
        general: 'Стандартное',
        overtime: 'Сверхурочное',
        work_rounds: 'Рабочие циклы',
        dashboard: 'Панель информации',
        timesheet: 'Табель учета рабочего времени',
        reports: 'Отчеты',
        people: 'Люди',
        workspaces: 'Рабочие места',
        start_tracking: 'Начните отслеживать и заполнять пустые страницы',
        go_ahead: 'Продолжайте дальше и ведите отслеживание некоторое время.',
        monthly_working_hours: 'Рабочее время за месяц',
        avg_workday_length: 'Средняя продолжительность рабочего дня',
        clients: 'Клиенты',
        logins: 'Входы в систему',
        warnings: 'Предупреждения',
        about: 'О программе',
        Opportunities: 'Возможности',
        use_same_preference: 'Использовать одинаковые настройки для всех рабочих мест',
        auto_add_pause: 'Автоматически добавлять паузу после',
        choose_lang: 'Выбрать язык',
        auto_end: 'Автоматически завершать работу после',
        show_hours: 'Показывать часы в десятичном формате',
        round_start: 'Время начала цикла',
        round_end: 'Время окончания цикла',
        name: 'Имя',
        end: 'Конец',
        add_work_round: 'Добавить рабочий цикл',
        add_overtime: 'Добавить сверхурочные',
        telephone: 'Телефон',
        address: 'Адрес',
        status: 'Статус',
        role: 'Роль',
        company: 'Организация',
        workspace: 'Рабочее место',
        email: 'Электронная почта',
        options: 'Параметры',
        filters: 'Фильтры',
        working_hours: 'Рабочее время',
        export: 'Экспорт',
      },
    },
    sv: {
      translation: {
        'Save shift': 'Spara skift',
        'Saved shifts': 'Sparade skift',
        'Type here': 'Skriv här',
        'No users found': 'Inga användare hittades',
        'Show events as full-day': 'Visa händelser som heldag',
        'Show holidays': 'Visa helgdagar',
        'No shifts added': 'Inga skift tillagda',
        'Edit shift': 'Redigera skift',
        'is missing from work': ':name saknas från arbetet',
        Copy: 'Kopiera',
        'No presets added': 'Inga förinställningar tillagda',
        'Add task': 'Lägg till uppgift',
        'Edit repeating task': 'Redigera upprepad uppgift',
        'Edit task': 'Redigera uppgift',
        'Add shift': 'Lägg till skift',
        Shifts: 'Skift',
        'Add new task': 'Lägg till ny uppgift',
        'New task': 'Ny uppgift',
        'Task name': 'Uppgiftsnamn',
        Task: 'Uppgift',
        'Time schedules': 'Tidsplaner',
        Add: 'Lägg till',
        'Remove recurring event': 'Ta bort återkommande händelse',
        'This event': 'Denna händelse',
        'This and following unconfirmed events': 'Denna och följande obekräftade händelser',
        'All unconfirmed events': 'Alla obekräftade händelser',
        Remove: 'Ta bort',
        'Add new shift': 'Lägg till nytt skift',
        'Add Schedule': 'Lägg till schema',
        'Add paid absence': 'Lägg till betald frånvaro',
        Absence: 'Frånvaro',
        'Saved presets': 'Sparade förinställningar',
        'New shift': 'Nytt skift',
        'Shift name': 'Skiftnamn',
        Repeating: 'Upprepar',
        'Save preset': 'Spara förinställning',
        Daily: 'Dagligen',
        Weekly: 'Veckovis',
        Byweekly: 'Varannan vecka',
        Monthly: 'Månadsvis',
        Yearly: 'Årligen',
        Monday: 'Måndag',
        Tuesday: 'Tisdag',
        Wednesday: 'Onsdag',
        Thursday: 'Torsdag',
        Friday: 'Fredag',
        Shift: 'Skift',
        'All day': 'Hela dagen',
        'Total pauses during selected period': 'Totalt pauser under vald period',
        Residue: 'Rest',
        'Pause end': 'Paus slut',
        'Ended work using fingerprint': 'Avslutade arbete med fingeravtryck',
        'Started work using fingerprint': 'Startade arbete med fingeravtryck',
        'Success!': 'Lyckades!',
        'An email has been sent to your registered email address': {
          ' Please check your inbox to ensure receipt': {
            " Don't forget to check your spam/junk folder if you can't find it": {
              ' Thank you!':
                'Ett e-postmeddelande har skickats till din registrerade e-postadress. Kontrollera din inkorg för att säkerställa mottagandet. Glöm inte att kontrollera din skräppost/spam-mapp om du inte kan hitta den. Tack!',
            },
          },
        },
        'Account manager': 'Kontohanterare',
        'Change your email': 'Ändra din e-postadress',
        'Enter your phone number': 'Ange ditt telefonnummer',
        'Contact us': 'Kontakta oss',
        "Let's talk": 'Låt oss prata!',
        'Schedule online meeting': 'Boka online-möte',
        'Request callback': 'Begär återuppringning',
        'Price list': 'Prislista',
        'Work is started with full-auto function': 'Arbetet startas med fullautomatisk funktion',
        'Work is ended with full-auto function': 'Arbetet avslutas med fullautomatisk funktion',
        'Work is started with semi-auto function': 'Arbetet startas med halvautomatisk funktion',
        'Work is ended with semi-auto function': 'Arbetet avslutas med halvautomatisk funktion',
        'Manually added work using web': 'Manuellt tillagt arbete via webben',
        'Manually added work using mobile': 'Manuellt tillagt arbete via mobil',
        Outside: 'Utomhus',
        'You have no inactive workers yet': 'Du har inga inaktiva arbetare ännu',
        'Show other locations': 'Visa andra platser',
        'Search address': 'Sök adress',
        'Reset to current location': 'Återställ till aktuell plats',
        'Must be greater than or equal to 100 meters': 'Måste vara större än eller lika med 100 meter',
        'Daily allowance group': 'Grupp för dagtraktamente',
        Invites: 'Inbjudningar',
        'Conflicting emails': 'Konflikterande e-postmeddelanden',
        Text: 'Text',
        Data: 'Data',
        'workers information': 'arbetares information',
        'This email is sent by :companyName from HOURS management software':
          'Detta e-postmeddelande skickas av :companyName från HOURS hanteringsprogramvara',
        'To answer this email, please use :managerEmail':
          'För att svara på detta e-postmeddelande, använd :managerEmail',
        'Please make sure that your password': 'Vänligen se till att ditt lösenord',
        'New work type': 'Ny arbets typ',
        'Select logo': 'Välj logotyp',
        'or drag one here': 'eller dra en hit',
        'Workspace name': 'Arbetsytans namn',
        'Phone number': 'Telefonnummer',
        'Do you want to change your email for verification?': 'Vill du ändra din e-post för verifiering?',
        'Resend verification': 'Skicka verifieringen igen',
        Refresh: 'Uppdatera',
        'Already have an account?': 'Har du redan ett konto?',
        'Log in here': 'Logga in här',
        'By signing up, you agree to our': 'Genom att registrera dig godkänner du vår',
        'terms of service': 'användarvillkor',
        'privacy policy': 'integritetspolicy',
        'and to receiving marketing communication from Hours':
          'och att ta emot marknadsföringskommunikation från Hours',
        'You can opt out anytime': 'Du kan avböja när som helst',
        'Check your email for the activation link or': 'Kontrollera din e-post för aktiveringslänken eller',
        'Work resumed from being idle at': 'Arbetet återupptogs från att ha varit inaktiv vid',
        'Office door setting': 'Inställning för kontorsdörr',
        'Automatically stop work after': 'Stoppa arbetet automatiskt efter',
        'minutes of idle time': 'minuters inaktivitet',
        '* when idle time is not set, work will automatically stop after 60 minutes':
          '* när inaktiv tid inte är inställd kommer arbetet automatiskt att stoppas efter 60 minuter',
        'Work was idle starting at': 'Arbetet var inaktivt från',
        None: 'Ingen',
        'Normal workday length': 'Normal arbetsdagslängd',
        'National holiday settings': 'Inställningar för nationella helgdagar',
        Calendar: 'Kalender',
        'The previous working day before a national holiday will be shortened':
          'Arbetsdagen före en nationell helgdag kommer att förkortas',
        'The following fields are missing: ': 'Följande fält saknas: ',
        main: 'Huvud',
        'Visma Settings': 'Visma Inställningar',
        'Show weekend hours separately': 'Visa helgtimmar separat',
        'Show columns': 'Visa kolumner',
        'Weekend hours': 'Helgtimmar',
        'User does not have work type salaries': 'Användaren har inte arbets typslöner',
        'Updated daily allowance': 'Uppdaterat dagtraktamente',
        'Deleted pauses': 'Raderade pauser',
        'Round to nearest 15 minutes': 'Avrunda till närmaste 15 minuter',
        'Add to login box': 'Lägg till i inloggningsboxen',
        'Work end time': 'Arbetssluttid',
        'Aloita lisäämällä uusi päiväraharyhmä': 'Börja med att lägga till en ny dagpenninggrupp',
        'Your daily allowance group is empty': 'Din dagpenninggrupp är tom',
        'Use in daily allowance calculation': 'Används i beräkning av dagtraktamente',
        'View notification': 'Visa notifikation',
        'No allowance': 'Inget tillägg',
        'Do nothing': 'Gör ingenting',
        Unsubscribe: 'Avsluta prenumeration',
        "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser":
          "Om du har problem med att klicka på 'Visa notifikation'-knappen, kopiera och klistra in URL:en nedan i din webbläsare",
        'You have a new notification': 'Du har en ny notifikation',
        'Email verification': 'E-postverifiering',
        End: 'Slut',
        'Your Hours': {
          'ee invoice is unpaid': {
            ' If you do not pay the bill, your account will be closed on :bill':
              'Din Hours.ee faktura är obetald. Om du inte betalar fakturan kommer ditt konto att stängas den :bill.',
          },
        },
        Equal: 'Lika',
        Down: 'Ned',
        Up: 'Upp',
        'Daily allowance': 'Dagtraktamente',
        Post: 'Posta',
        'Group does not have a daily allowance item': 'Gruppen har inte en dagtraktamentepost',
        'Add daily allowance': 'Lägg till dagtraktamente',
        'Daily allowances': 'Dagtraktamenten',
        View: 'Visa',
        'Total:': 'Totalt:',
        'User preference updated successfully': 'Användarpreferenser uppdaterades framgångsrikt',
        'Work log updated successfully': 'Arbetslogg uppdaterad framgångsrikt',
        'Work paused successfully': 'Arbete pausat framgångsrikt',
        'Work resumed successfully': 'Arbete återupptaget framgångsrikt',
        'Work stopped successfully': 'Arbete stoppat framgångsrikt',
        'Paid absence created successfully': 'Betalad frånvaro skapad framgångsrikt',
        'Fully-automatic (automatically start/end work)': 'Helt automatisk (starta/avsluta arbete automatiskt)',
        'User language updated successfully': 'Användarspråk uppdaterat framgångsrikt',
        'Automatic start/end work with Mobile App': 'Automatisk start/slut på arbete med mobilapp',
        'When entering/leaving workspace': 'När man går in/ut från arbetsutrymmet',
        'This set password link will expire 1 week from now':
          'Denna länk för att sätta lösenord kommer att utgå om 1 vecka',
        'Notify manager': 'Meddela chefen',
        'Work log confirmed successfully': 'Arbetslogg bekräftades framgångsrikt',
        'Options updated successfully': 'Alternativ uppdaterades framgångsrikt',
        'Add a pause': 'Lägg till en paus',
        'The entered working time is more than 24 hours': {
          ' Are you sure you want to add it?':
            'Den inmatade arbetstiden är mer än 24 timmar. Är du säker på att du vill lägga till den?',
        },
        'User registered successfully': 'Användare registrerad framgångsrikt',
        'Reset password link sent successfully': 'Länk för återställning av lösenord skickades framgångsrikt',
        'Added lunch time using general preference': 'Lagt till lunchtid med allmän preferens',
        'Changed pause time to': 'Ändrade paustid till',
        'Changed resume time to': 'Ändrade återupptagningstid till',
        Percentage: 'Procent',
        added: 'tillagd',
        accepted: 'accepterad',
        declined: 'avböjd',
        Doors: 'Dörrar',
        'Merit Palk Salary type nr': 'Merit Palk lön typ nr',
        'Conflicting times': 'Konflikterande tider',
        'Sent to Netvisor': 'Skickat till Netvisor',
        'Sent to': 'Skickat till',
        'Rounded using general preference': 'Avrundat enligt allmänna inställningar',
        'Rounded end time to': 'Avrundat sluttid till',
        'Added using general preference': 'Tillagt enligt allmänna inställningar',
        'Updated pauses': 'Uppdaterade pauser',
        'Confirmed work at': 'Arbete bekräftat vid',
        'Uncomfirmed work at': 'Arbete obekräftat vid',
        'Added workspace': 'Arbetsyta tillagd',
        'Added work type': 'Arbetstyp tillagd',
        'Added address': 'Adress tillagd',
        'Added pause': 'Paus tillagd',
        'Added comment': 'Kommentar tillagd',
        'Added bonus': 'Bonus tillagd',
        'Added bonus comment': 'Bonuskommentar tillagd',
        to: 'till',
        'Work edited': 'Arbete redigerat',
        'Add work type extra': 'Lägg till extra arbetstyp',
        'Ends at': 'Slutar vid',
        'Out of door at': 'Utgick från dörr vid',
        'Resumed work at': 'Återupptog arbete vid',
        'Ended work at': 'Avslutade arbete vid',
        'Ended work using door': 'Avslutade arbete med dörr',
        'Ended work using box': 'Avslutade arbete med box',
        'Ended work using mobile': 'Avslutade arbete med mobil',
        'Ended work using web': 'Avslutade arbete via webb',
        'Rounded start time to': 'Avrundad starttid till',
        'Started work using box': 'Startade arbete med box',
        'Started work using door': 'Startade arbete med dörr',
        'Started work using mobile': 'Startade arbete med mobil',
        'Started work using web': 'Startade arbete via webb',
        'Started work at': 'Startade arbete vid',
        'Entered door': 'Gick in genom dörr',
        'Entered door at': 'Gick in genom dörr vid',
        'Paused work at': 'Pausade arbete vid',
        'Out of door': 'Gick ut genom dörr',
        'We sent you a verification email to': 'Vi skickade ett verifieringsmail till',
        "If you don't see the email, please check your spam folder or contact your Organization":
          'Om du inte ser mailet, kontrollera din skräppostmapp eller kontakta din organisation',
        'Overtime computation': 'Beräkning av övertid',
        'Selected works have some users with multiple work logs on the same day':
          'Valda arbeten har vissa användare med flera arbetsloggar samma dag',
        'Cannot confirm not ended work': 'Kan inte bekräfta icke avslutat arbete',
        'Overlapping paid absences': 'Överlappande betald frånvaro',
        'Cannot request with overlapping paid absences': 'Kan inte begära med överlappande betald frånvaro',
        'There is already ongoing unconfirmed request': 'Det finns redan en pågående obekräftad förfrågan',
        'Work has been deleted': 'Arbetet har raderats',
        'Overlapping work rounds': 'Överlappande arbetspass',
        'There are some errors in exporting merit palk report':
          'Det finns några fel i exporten av merit palk-rapporten',
        'There are some errors in exporting netvisor report': 'Det finns några fel i exporten av netvisor-rapporten',
        'Dimension updated unsuccessfully': 'Dimensionen uppdaterades inte framgångsrikt',
        'Notification cannot be posted if deleted': 'Meddelandet kan inte postas om det har raderats',
        'Token already expired': 'Token har redan löpt ut',
        'Token is invalid': 'Token är ogiltigt',
        'Token has already been used': 'Token har redan använts',
        'User not found': 'Användaren hittades inte',
        'Password Reset Sent': 'Återställning av lösenord skickat',
        'Invalid credentials': 'Ogiltiga inloggningsuppgifter',
        'Please try again or ': 'Vänligen försök igen eller ',
        'Merit Palk Setting': 'Merit Palk-inställningar',
        'API Setting': 'API-inställningar',
        'API Key': 'API-nyckel',
        'API ID': 'API-ID',
        'Do not count as overtime': 'Räknas inte som övertid',
        'No notifications created': 'Inga meddelanden skapade',
        'Add new notification': 'Lägg till nytt meddelande',
        'Default work type': 'Standardarbets typ',
        'Automatic start/end work with mobile':
          'Automatisk start/slut på arbete med mobil när du anländer till arbetsområdet (Beta - testning)',
        Radius: 'Radie',
        'Set location': 'Ange plats',
        'People rate': 'Användarbetyg',
        'Worktype rate': 'Arbetstypsbetyg',
        'Show worker number': 'Visa arbetarnummer',
        'Reset Your Password': 'Återställ ditt lösenord',
        'Back to login': 'Tillbaka till inloggning',
        'More details': 'Mer detaljer',
        'Showing rows: :current out of :total': 'Visar rader: :current av :total',
        'We use cookies to ensure you get the best experience on our site':
          'Vi använder kakor för att säkerställa att du får den bästa upplevelsen på vår webbplats.',
        'Start by adding new allowed pause group': 'Börja med att lägga till en ny tillåten pausgrupp',
        'Group does not have an allowed pause item': 'Gruppen har inte ett tillåtet pausalternativ',
        'Add allowed pause': 'Lägg till tillåten paus',
        'Allowed pause group': 'Tillåten pausgrupp',
        'Work pause': 'Arbetspaus',
        'Work end': 'Arbetsslut',
        'Work start time': 'Arbetsstarttid',
        'Round time': 'Avrunda tid',
        'Lunch time': 'Lunchtid',
        minutes: 'minuter',
        'before work end time to round up': 'före arbetsslut för att avrunda uppåt',
        'after work end time to round down': 'efter arbetsslut för att avrunda nedåt',
        'Your allowed pause group is empty': 'Din tillåtna pausgrupp är tom',
        'Update doors': 'Uppdatera dörrar',
        'When exiting': 'När du lämnar',
        'Edit door': 'Redigera dörr',
        'Door access': 'Dörråtkomst',
        'Main door': 'Huvuddörr',
        'must be accessed to start work': 'måste användas för att starta arbete',
        'Front door': 'Framdörr',
        'Change work type when entering': 'Ändra arbets typ vid ingång',
        'Work resume when entering': 'Återuppta arbete vid ingång',
        Request: 'Förfrågan',
        'View Bonus': 'Visa Bonus',
        Pending: 'Väntande',
        Approved: 'Godkänd',
        Declined: 'Avslagen',
        'Expired activation': 'Utgången aktivering',
        'Inner Range Doors': 'Inner Range-dörrar',
        'Doors updated': 'Dörrar uppdaterade',
        'Door ID': 'Dörr-ID',
        'Request Bonus': 'Begär Bonus',
        'Can accept/edit/decline requests': 'Kan acceptera/redigera/avslå förfrågningar',
        'Switching Account': 'Byter Konto',
        'Past two weeks': 'Senaste två veckorna',
        ':worker has requested a bonus of :bonus': ':worker har begärt en bonus på :bonus',
        'has requested a paid absence': 'har begärt betald frånvaro',
        'User preference': 'Användarinställningar',
        'Sorting order': 'Sorteringsordning',
        'First name, Last name': 'Förnamn, Efternamn',
        'Last name, First name': 'Efternamn, Förnamn',
        'Allowed pauses': 'Tillåtna pauser',
        Late: 'Försenad',
        'You have been added to Hours time tracking': 'Du har lagts till i Hours tidsregistrering.',
        'Fetch records': 'Hämta poster',
        'has requested a bonus of': 'har begärt en bonus på',
        Posted: 'Publicerad',
        'Ready for posting': 'Klar för publicering',
        Draft: 'Utkast',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'när ingen användare är vald kommer notifikationer att skickas till alla användare i företaget eller i de valda arbetsytorna.',
        'Draft notification message': 'Utkast till notifikationsmeddelande',
        Requests: 'Förfrågningar',
        'No bonus requests yet': 'Inga bonusförfrågningar ännu',
        'No paid absence requests yet': 'Inga förfrågningar om betald frånvaro ännu',
        'Paid absence': 'Betald frånvaro',
        'Bonus comment': 'Bonuskommentar',
        'Original requested amount': 'Ursprungligt begärt belopp',
        'New amount': 'Nytt belopp',
        'Request paid absence': 'Begär betald frånvaro',
        'Requested at': 'Begärd vid',
        'Started at': 'Startade vid',
        'Ended at': 'Slutade vid',
        'Confirmed at': 'Bekräftad vid',
        'Confirmed by': 'Bekräftad av:',
        'Work date': 'Arbetsdatum',
        'You have no rights to edit work time!': 'Du har inte rätt att redigera arbetstid!',
        'Notify through push notification': 'Meddela via push-notifikation',
        'Notify through email': 'Meddela via e-post',
        Notifications: 'Notifikationer',
        'Created by': 'Skapad av',
        'Posted at': 'Publicerad den',
        Title: 'Titel',
        Repost: 'Publicera igen',
        'Add notification': 'Lägg till notifikation',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'när ingen arbetsyta är vald kommer notifikationer att skickas till alla användare i företaget',
        'Company notifications': 'Företagsnotifikationer',
        'Worker is working overtime': 'Arbetaren arbetar övertid',
        'Worker is asking for a bonus': 'Arbetaren begär en bonus',
        'Worker is requesting for an absence': 'Arbetaren begär frånvaro',
        'Worker has arrived/left from the workspace': 'Arbetaren har anlänt/lämnat arbetsplatsen',
        'Manager has added/accepted/declined a bonus': 'Chefen har lagt till/godkänt/avslagit en bonus',
        'Manager has added/accepted/declined a paid absence':
          'Chefen har lagt till/godkänt/avslagit en betald frånvaro',
        'Work paused/ended automatically through workspace preference':
          'Arbetet pausades/avslutades automatiskt genom arbetsytans preferenser',
        Latest: 'Senaste',
        'This week': 'Denna vecka',
        Older: 'Äldre',
        'You have declined this request!': 'Du har avslagit denna förfrågan!',
        'You have already approved this request!': 'Du har redan godkänt denna förfrågan!',
        Accept: 'Godkänn',
        Decline: 'Avböj',
        'Mark as read': 'Markera som läst',
        'Turn off receiving this status': 'Stäng av mottagandet av denna status',
        'Delete notification': 'Radera notifikation',
        'Mark all as read': 'Markera alla som lästa',
        'View full feed': 'Visa fullständigt flöde',
        'Clear all': 'Rensa alla',
        Read: 'Läs',
        Close: 'Stäng',
        Clear: 'Rensa',
        'No notifications yet': 'Inga notifikationer ännu',
        'Turn on/off notifications': 'Aktivera/inaktivera notifikationer',
        'Weekly working hours': 'Veckoarbetstimmar',
        'Show more': 'Visa mer',
        'Show less': 'Visa mindre',
        'No email': 'Ingen e-post',
        'Complete registration by choosing a password': 'Slutför registreringen genom att välja ett lösenord',
        'Your First Name': 'Ditt förnamn',
        'Your Last Name': 'Ditt efternamn',
        'Your Password': 'Ditt lösenord',
        'Confirm Your Password': 'Bekräfta ditt lösenord',
        "Please register by entering you're email": 'Vänligen registrera dig genom att ange din e-post',
        Location: 'Plats',
        'Must have at least 8 characters': 'Måste innehålla minst 8 tecken',
        'Must start with alphanumeric character': 'Måste börja med alfanumeriskt tecken',
        'Must have at least one lowercase character': 'Måste innehålla minst en liten bokstav',
        'Must have at least one uppercase character': 'Måste innehålla minst en stor bokstav',
        'Must have at least one digit': 'Måste innehålla minst en siffra',
        'Passwords must match': 'Lösenorden måste stämma överens',
        'Must Accept Terms and Condition': 'Måste acceptera villkoren',
        'This field is required': 'Detta fält är obligatoriskt',
        ':field is required': ':field är obligatoriskt',
        by: 'av',
        'Total salary': 'Total lön',
        'Paid pause': 'Betald paus',
        'Work type extras': 'Arbetstypstillägg',
        Basic: 'Grundläggande',
        'Basic salary': 'Grundlön',
        Week: 'Vecka',
        Rate: 'Timpris',
        'Worker is missing identification number':
          'Arbetaren saknar identifikationsnummer. Vänligen lägg till arbetarens nummer i användarprofilen',
        'Your paid absences is empty': 'Du har inga registrerade betalda frånvaron',
        'Start by adding new paid absences': 'Börja med att lägga till nya betalda frånvaron',
        'Invite user': 'Bjud in användare',
        'Group does not have a work round item': 'Gruppen har inte ett arbetspassobjekt',
        'Group does not have an overtime item': 'Gruppen har inte en övertidsobjekt',
        'Upload Company Logo': 'Ladda upp företagslogotyp',
        'Upload Profile Image': 'Ladda upp profilbild',
        'Drag and drop your image here or': 'Dra och släpp din bild här eller',
        'upload a photo': 'ladda upp en bild',
        'Single image must not exceed 5MB': 'Enskild bild får inte överstiga 5MB',
        'Business Area': 'Affärsområde',
        'Start by adding new work round group': 'Börja med att lägga till en ny arbetspassgrupp',
        'Your work round group is empty': 'Din arbetspassgrupp är tom',
        'Your overtime group is empty': 'Din övertidsgrupp är tom',
        File: 'Fil',
        "This does not affect users' existing paid absences":
          'Detta påverkar inte användares befintliga betalda frånvaro',
        'View document': 'Visa dokument',
        'Rename document': 'Byt namn på dokument',
        'Delete document': 'Radera dokument',
        'File name': 'Filnamn',
        'File type': 'Filtyp',
        'File size': 'Filstorlek',
        'Last date modified': 'Senast ändrad',
        'accepts comma-separated input': 'accepterar kommaseparerad inmatning',
        "Press 'Enter'": "Tryck 'Enter'",
        Emails: 'E-postadresser',
        'Invite users by email': 'Bjud in användare via e-post',
        'Add work type': 'Lägg till arbetstyp',
        'View work types': 'Visa arbetstyper',
        'User has been deleted': 'Användaren har raderats',
        'User information cannot be viewed': 'Användarinformation kan inte visas',
        'Go back to People page': 'Gå tillbaka till Personalsidan',
        'The work has been updated': {
          ' Please confirm to apply changes': 'Är du säker på att du vill ändra arbetstiden?',
        },
        'Start by adding new work type': 'Börja med att lägga till en ny arbets typ',
        'Account verification': 'Kontoverifiering',
        Hello: 'Hej',
        'Verify Email Address': 'Verifiera e-postadress',
        'Best regards': 'Vänliga hälsningar',
        Team: 'Team',
        "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser":
          "Om du har problem med att klicka på 'Verifiera e-postadress'-knappen, kopiera och klistra in URL:en nedan i din webbläsare",
        'All rights reserved': 'Alla rättigheter förbehållna',
        'Complete registration': 'Slutför registrering',
        from: 'från',
        'Set Password': 'Välj lösenord',
        'Download also Hours mobile apps': 'Ladda även ner Hours mobilappar',
        "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser":
          "Om du har problem med att klicka på 'Välj lösenord'-knappen, kopiera och klistra in URL:en nedan i din webbläsare",
        'Welcome to': 'Välkommen till',
        'You are receiving this email because we received a reset password request for your account':
          'Du får detta e-postmeddelande eftersom vi mottog en begäran om att återställa lösenordet för ditt konto',
        'Reset Password': 'Återställ lösenord',
        'Your work type is empty': 'Du har ingen arbets typ',
        'Dreaming of a new box?': 'Drömmer du om en ny terminal?',
        'Are you sure you want to update selected work logs?':
          'Är du säker på att du vill uppdatera valda arbetsloggar?',
        'Round to nearest hour': 'Avrunda till närmaste timme',
        'Round to nearest half-hour': 'Avrunda till närmaste halvtimme',
        'Round to nearest 10 minutes': 'Avrunda till närmaste 10 minuter',
        'Edit group': 'Redigera grupp',
        'Overtime group': 'Övertidsgrupp',
        'Work round group': 'Arbetspassgrupp',
        'Are you sure you want to remove profile image?': 'Är du säker på att du vill ta bort profilbilden?',
        'Do not group': 'Gruppera inte',
        'Grouped by people': 'Grupperad efter personer',
        'Are you sure you want to bulk activate/deactivate?': 'Är du säker på att du vill massaktivera/inaktivera?',
        'Some selected work logs are already confirmed!': 'Några av de valda arbetsloggarna är redan bekräftade!',
        'Round work time': 'Avrunda arbetstid',
        Subject: 'Ämne',
        'Number of weeks': 'Veckor',
        'Number of months': 'Månader',
        'Daily normal hours': 'Daglig normal arbetstid',
        'Weekly normal hours': 'Veckovis normal arbetstid',
        'Monthly normal hours': 'Månadsvis normal arbetstid',
        'Are you sure want to switch as :type?': 'Är du säker på att du vill byta till :type?',
        'This action cannot be undone!': 'Den här åtgärden kan inte ångras!',
        'Work type workspaces': 'Arbetstypens arbetsrum',
        'People workspaces': 'Användarnas arbetsrum',
        'Working Period': 'Arbetsperiod',
        From: 'Från',
        'Send copy': 'Skicka kopia',
        'weekly overtime': 'veckans övertid',
        'Monthly overtime': 'Månadens övertid',
        'monthly overtime': 'månadens övertid',
        'working hours': 'arbetstimmar',
        total: 'totalt',
        hours: 'timmar',
        days: 'dagar',
        'Total working days': 'Totalt antal arbetsdagar',
        'Created at': 'Skapad den',
        Sunday: 'Söndag',
        Saturday: 'Lördag',
        Map: 'Karta',
        History: 'Historik',
        Overview: 'Översikt',
        Company: 'Företag',
        'Average workday length': 'Genomsnittlig arbetsdagslängd denna månad',
        'Total hours during selected period': 'Totala timmar under vald period',
        report: 'rapport',
        'Work types': 'Arbetstyper',
        'Paid absences': 'Betalda frånvaron',
        'Work rounds': 'Arbetspass',
        'Daily overtime': 'Daglig övertid',
        'Weekly overtime': 'Veckans övertid',
        'Default pause length': 'Standardpauslängd',
        'Include in overtime computation': 'Inkludera i beräkning av övertid',
        'Total time': 'Total tid',
        'Add user': 'Lägg till användare',
        'Edit user': 'Redigera användare',
        'Edit users': 'Redigera användare',
        Pauses: 'Pausar',
        'Confirmation action': 'Bekräftelseåtgärd',
        'Edit Box': 'Redigera Box',
        'Main Screen Type': 'Huvudskärmstyp',
        Message: 'Meddelande',
        'With worker list': 'Med arbetarlista',
        'Without worker list': 'Utan arbetarlista',
        'Add Box': 'Lägg till Box',
        "Workspaces who's people are visible in the box": 'Arbetsrum vars personer är synliga i boxen',
        Day: 'Dag',
        'Choose type': 'Välj typ',
        Until: 'Till',
        'Do not count as workday': 'Räknas inte som arbetsdag',
        'Time unit': 'Tidsenhet',
        Length: 'Längd',
        'Edit Paid Absence': 'Redigera betald frånvaro',
        'New Paid Absence': 'Ny betald frånvaro',
        'Add new': 'Lägg till ny',
        'Add new paid absence': 'Lägg till ny betald frånvaro',
        'User ID': 'Användar-ID',
        'User key': 'Användarnyckel',
        'Division specific settings': 'Avdelningsspecifika inställningar',
        'Update Dimensions': 'Uppdatera Dimensioner',
        'Dimensions updated': 'Dimensioner uppdaterade',
        'Netvisor Dimensions': 'Netvisor Dimensioner',
        'Connection Settings': 'Anslutningsinställningar',
        'Netvisor Settings': 'Netvisor Inställningar',
        'Business ID': 'Organisationsnummer',
        'User Profile': 'Användarprofil',
        'Workspace does not have a worker': 'Arbetsrummet har ingen arbetare',
        'Registry code': 'Organisationsnummer',
        'Contact Information': 'Kontaktinformation',
        Phone: 'Telefon',
        'Start from': 'Starta från',
        'User type': 'Användartyp',
        'General Rights': 'Allmänna rättigheter',
        'Can register work hour from login box': 'Kan registrera arbetstid från inloggningsbox',
        'Is visible on login box': 'Är synlig i inloggningsboxen',
        'Start of employment': 'Anställningens början',
        'End of employment': 'Anställningens slut',
        'Netvisor export identification': 'Netvisor exportidentifiering',
        'Worker identification in Netvisor export': 'Arbetaridentifiering vid Netvisor-export',
        'Worker number': 'Arbetarnummer',
        Information: 'Information',
        Rights: 'Rättigheter',
        Personal: 'Personligt',
        'Add another number': 'Lägg till ett annat nummer',
        'Add another address': 'Lägg till en annan adress',
        'ID Code': 'ID-kod',
        'TAX Number': 'Skattenummer',
        'Date of birth': 'Födelsedatum',
        Generic: 'Generell',
        Deleted: 'Raderad',
        Actions: 'Åtgärder',
        'Add Paid Absence': 'Lägg till betald frånvaro',
        ':count worker is selected': ':count användare vald',
        ':count workers are selected': ':count användare valda',
        'Send workers data': 'Skicka användardata',
        'Send email to worker': 'Skicka e-post till användarna',
        'Hide all days': 'Dölj alla dagar',
        'Show all days': 'Visa alla dagar',
        Dimensions: 'Kostnadsställen',
        'Working hours are not confirmed': 'Arbetstimmarna är inte bekräftade',
        'Product number': 'Produktnummer',
        Hours: 'Timmar',
        'Export as :type': 'Exportera som :type',
        'Collapse all rows': 'Kollapsa alla rader',
        'Expand all rows': 'Expandera alla rader',
        All: 'Alla',
        'Confirmed hours': 'Bekräftade timmar',
        'Unconfirmed hours': 'Obekräftade timmar',
        'Group by': 'Gruppera efter',
        'Report options': 'Rapportalternativ',
        'Total hours': 'Totala timmar',
        'Total days': 'Totala dagar',
        'Average day': 'Genomsnittlig dag',
        'Total paid absence': 'Total betald frånvaro',
        Bonuses: 'Bonusar',
        Unconfirm: 'Avbekräfta',
        Edited: 'Redigerad',
        Tags: 'Taggar',
        Worktype: 'Arbetstyp',
        'Are you sure you want to :status this work log?': 'Är du säker på att du vill :status denna arbetslogg?',
        'Are you sure you want to delete this work log?': 'Är du säker på att du vill radera denna arbetslogg?',
        'Are you sure you want to delete :name work log?': 'Är du säker på att du vill radera :name arbetslogg?',
        'Delete Timesheet': 'Radera tidsrapport',
        'Are you sure you want to delete :name?': 'Är du säker på att du vill radera :name?',
        Amount: 'Belopp',
        'Edit Bonus': 'Redigera bonus',
        'Are you sure you want to confirm :name work log?': 'Är du säker på att du vill bekräfta :name arbetslogg?',
        'Are you sure you want to unconfirm :name work log?': 'Är du säker på att du vill avbekräfta :name arbetslogg?',
        'Worker Comment': 'Användarkommentar',
        Bonus: 'Bonus',
        'Breaks Total': 'Totala pauser',
        'Add :label': 'Lägg till :label',
        'No :label added': 'Ingen :label tillagd',
        'Can register own work hour through mobile': 'Kan registrera egna arbetstimmar via mobil',
        'Location tracking is required by owner': 'Platsuppföljning krävs av ägaren',
        'Can manage user work hours': 'Kan hantera användares arbetstimmar',
        'Edit Work type': 'Redigera arbetstyp',
        'Work name': 'Arbetsnamn',
        'Parent Work type': 'Överordnad arbetstyp',
        'Visma Product nr': 'Visma produktnummer',
        'Work Price': 'Arbetspris',
        Hour: 'Timme',
        Piece: 'Styck',
        'Show separately on report': 'Visa separat på rapport',
        'Cost Descriptions': 'Kostnadsbeskrivningar',
        'Paid Absences': 'Betald frånvaro',
        ':count work items selected': ':count arbetsposter valda',
        Files: 'Filer',
        Save: 'Spara',
        'Add Bonus': 'Lägg till bonus',
        'Waiting activation': 'Väntar på aktivering',
        Active: 'Aktiv',
        Inactive: 'Inaktiv',
        'Search for user': 'Sök efter användare',
        Manager: 'Chef',
        Worker: 'Arbetare',
        'Add manually': 'Lägg till manuellt',
        'Invite user via email': 'Bjud in användare via e-post',
        'Company Profile': 'Företagsprofil',
        Status: 'Status',
        'Add New': 'Lägg till ny',
        Filters: 'Filter',
        Submit: 'Skicka in',
        Reset: 'Återställ',
        Workpace: 'Arbetsplats',
        Name: 'Namn',
        Type: 'Typ',
        Period: 'Period',
        Working: 'Arbetar',
        'Not Working': 'Inte arbetar',
        'Just an empty canvas': 'Bara en tom canvas',
        Pause: 'Paus',
        'End time': 'Sluttid',
        'Start time': 'Starttid',
        'Work type': 'Arbetstyp',
        Profile: 'Profil',
        Date: 'Datum',
        Start: 'Start',
        Duration: 'Varaktighet',
        Total: 'Totalt',
        Confirmed: 'Bekräftad',
        Salary: 'Lön',
        'Add time': 'Lägg till tid',
        "Don't count overtime": 'Räkna inte övertid',
        'No files added': 'Inga filer tillagda',
        'Add files': 'Lägg till filer',
        'Start work': 'Börja arbeta',
        'Remove end time': 'Ta bort sluttid',
        'Add end time': 'Lägg till sluttid',
        'Pause length': 'Pauslängd',
        'Pause start': 'Pausstart',
        'Work number': 'Arbetsnummer',
        Comment: 'Kommentar',
        'Can register own work time from computer': 'Kan registrera egna arbetstider från dator',
        'Can register work hours from login box': 'Kan registrera arbetstider från inloggningsterminal',
        'Can register work hours through mobile': 'Kan registrera arbetstider via mobil',
        'Can generate reports': 'Kan generera rapporter',
        'User has Manager Rights': 'Användaren har administratörsrättigheter',
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* när inget arbetsrum är valt tillämpas dessa rättigheter på alla arbetsrum',
        'Can edit preferences': 'Kan redigera inställningar',
        'Can add/edit users': 'Kan lägga till/redigera användare',
        'Can generate reports for workers': 'Kan generera rapporter för arbetare',
        'Can manage workspaces': 'Kan hantera arbetsrum',
        'Can manage work types': 'Kan hantera arbetstyper',
        'Can manage salary extensions': 'Rättighet att hantera löneintegrationer',
        'Login boxes management': 'Rättighet att hantera inloggningsboxar',
        'Add new group': 'Lägg till ny grupp',
        'View Details': 'Visa detaljer',
        'Send worker data': 'Skicka arbetardata',
        'Resend activation link': 'Skicka aktiveringslänk igen',
        Edit: 'Redigera',
        Deactivate: 'Inaktivera',
        Activate: 'Aktivera',
        Send: 'Skicka',
        'Are you sure you want to :status :name': 'Är du säker på att du vill :status :name',
        'Please register by entering your email': 'Vänligen registrera dig genom att ange din e-post',
        'Repeat password': 'Upprepa lösenord',
        Password: 'Lösenord',
        'Your E-mail': 'Din e-post',
        Language: 'Språk',
        'Sign up': 'Registrera dig',
        'Number of days worked': 'Antal arbetade dagar denna månad',
        'People not working': 'Personer som inte arbetar',
        'People working': 'Personer som arbetar',
        'Edit Workspace': 'Redigera arbetsområde',
        'Delete Workspace': 'Radera arbetsområde',
        'I agree to': 'Jag godkänner',
        'Terms & Condition': 'Villkor',
        about_login: 'Om inloggning',
        sign_up: 'Registrera',
        first_name: 'Förnamn',
        last_name: 'Efternamn',
        company_name: 'Företagsnamn',
        'I agree to Terms & Condition': 'Jag godkänner villkoren',
        login: 'Logga in',
        register: 'Registrera',
        welcome: 'Välkommen till Hours',
        please_login: 'Vänligen logga in genom att ange din e-post och lösenord',
        remember_me: 'Kom ihåg mig',
        forgot_password: 'Glömt lösenord?',
        help: 'Hjälp',
        contact: 'Kontakt',
        show_time_decimal: 'Visa tid i decimalform',
        yesterday: 'Igår',
        this_week: 'Denna vecka',
        this_month: 'Denna månad',
        previous_week: 'Föregående vecka',
        previous_month: 'Föregående månad',
        apply: 'Tillämpa',
        reset: 'Återställ',
        cancel: 'Avbryt',
        today: 'Idag',
        week: 'Vecka',
        month: 'Månad',
        waiting_activation: 'Väntar på aktivering',
        send_email: 'Skicka e-post',
        worker_name: 'Arbetarens namn',
        delete_worklog_msg: 'Är du säker på att du vill radera valt arbete?',
        confirm_action: 'Bekräfta',
        confirm_worklog_msg: 'Är du säker på att du vill bekräfta det valda arbetet?',
        edit_timesheet: 'Redigera arbete',
        success_delete_msg: 'Raderad',
        success_update_msg: 'Uppdaterad',
        delete: 'Radera',
        confirm: 'Bekräfta',
        add_user: 'Lägg till användare',
        add_workspace: 'Lägg till arbetsyta',
        start_timer: 'Starta arbete',
        people_working: 'Arbetar',
        people_not_working: 'Arbetar inte',
        settings: 'Inställningar',
        preferences: 'Preferenser',
        work_types: 'Arbetstyper',
        vacation_types: 'Semestertyper',
        boxes: 'Terminaler',
        logout: 'Logga ut',
        general: 'Allmän',
        overtime: 'Övertid',
        work_rounds: 'Arbetspass',
        dashboard: 'Instrumentbräda',
        timesheet: 'Tidrapport',
        reports: 'Rapporter',
        people: 'Användare',
        workspaces: 'Arbetsytor',
        start_tracking: 'Börja arbeta och fyll i tomma blad',
        go_ahead: 'Kom igång och logga tider',
        monthly_working_hours: 'Månadens arbetstimmar',
        avg_workday_length: 'Genomsnittlig arbetsdagslängd denna månad',
        clients: 'Klienter',
        logins: 'Inloggningar',
        warnings: 'Varningar',
        about: 'Om oss',
        Opportunities: 'Möjligheter',
        use_same_preference: 'Använd samma inställningar för alla arbetsytor',
        auto_add_pause: 'Lägg till paus automatiskt efter',
        choose_lang: 'Välj språk',
        auto_end: 'Avsluta arbetsdagen automatiskt efter',
        show_hours: 'Visa timmar som decimaler',
        round_start: 'Avrunda starttid',
        round_end: 'Avrunda sluttid',
        name: 'Namn',
        end: 'Slut',
        add_work_round: 'Lägg till skift',
        add_overtime: 'Lägg till övertidsinställning',
        telephone: 'Telefon',
        address: 'Adress',
        status: 'Status',
        role: 'Roll',
        company: 'Företag',
        workspace: 'Arbetsyta',
        email: 'E-post',
        options: 'Alternativ',
        filters: 'Filter',
        working_hours: 'Arbetstimmar',
        export: 'Exportera',
      },
    },
    fi: {
      translation: {
        'Starting month of overtime calculation of a period': 'Ylityölaskennan aloituskuukausi jaksona',
        'Overtime multiplier': 'Ylityökerroin',
        'Type here': 'Kirjoita tähän',
        'No users found': 'Käyttäjiä ei löydetty',
        'Show events as full-day': 'Näytä tapahtumat koko päivän kestävinä',
        'Show holidays': 'Näytä juhlapäivä',
        'Total pauses during selected period': 'Tauot valitulla ajanjaksolla',
        Residue: 'Jäännös',
        'Pause end': 'Tauon loppu',
        'Ended work using fingerprint': 'Lopetettiin työskentely sormenjäljen avulla',
        'Started work using fingerprint': 'Aloitettiin työskentely sormenjäljen avulla',
        'Success!': 'Onnistui!',
        'Your request has been received': {
          ' We will be calling you at the registered phone number shortly': {
            ' Please ensure that your phone is on and within reach': {
              ' Thank you for your patience': {
                '':
                  'Pyyntösi on vastaanotettu. Soitamme sinulle rekisteröityyn puhelinnumeroosi pian. Varmista, että puhelimesi on päällä ja ulottuvillasi. Kiitos kärsivällisyydestäsi.',
              },
            },
          },
        },
        'An email has been sent to your registered email address': {
          ' Please check your inbox to ensure receipt': {
            " Don't forget to check your spam/junk folder if you can't find it": {
              ' Thank you!':
                'Sähköposti on lähetetty rekisteröityyn sähköpostiosoitteeseesi. Tarkista postilaatikkosi varmistaaksesi, että olet vastaanottanut sen. Älä unohda tarkistaa roskapostikansiota, jos et löydä sitä. Kiitos!',
            },
          },
        },
        'Account manager': 'Tilinhoitaja',
        'Your email address has not been confirmed': { '': 'Sähköpostiosoitettasi ei ole vahvistettu.' },
        'Change your email': 'Vaihda sähköpostiosoitteesi',
        'Enter your phone number': 'Anna puhelinnumerosi',
        'Contact us': 'Ota yhteyttä',
        "Let's talk": 'Puhumme mielellämme!',
        'Schedule online meeting': 'Varaa verkkotapaaminen',
        'Request callback': 'Pyydä takaisinsoittoa',
        'Price list': 'Hinnasto',
        'Work is started with full-auto function': 'Työ aloitettiin täysautomaattitoiminnolla',
        'Work is ended with full-auto function': 'Työ lopetettiin täysautomaattitoiminnolla',
        'Work is started with semi-auto function': 'Työ aloitettiin puoliautomaattitoiminnolla',
        'Work is ended with semi-auto function': 'Työ lopetettiin puoliautomaattitoiminnolla',
        'Manually added work using web': 'Manuaalisesti lisätty työ webin kautta',
        'Manually added work using mobile': 'Manuaalisesti lisätty työ mobiililaitteella',
        Outside: 'Ulkopuolella',
        'You have no deleted workers vet': 'Sinulla ei ole vielä poistettuja työntekijöitä',
        'You have no inactive workers yet': 'Sinulla ei ole vielä passiivisia työntekijöitä',
        'Show other locations': 'Näytä muut sijainnit',
        'Search address': 'Etsi osoite',
        'Reset to current location': 'Palauta nykyiseen sijaintiin',
        'Must be greater than or equal to 100 meters': 'Täytyy olla suurempi tai yhtä suuri kuin 100 metriä',
        'Daily allowance group': 'Päiväraharyhmä',
        Invites: 'Kutsu',
        'The following email addresses are already associated with an account in our system and could not be invited again': {
          '':
            'Seuraavat sähköpostiosoitteet on jo liitetty tiliin järjestelmässämme, eikä niitä voitu kutsua uudelleen.',
        },
        'The other invitations have been sent successfully': {
          ' If you need further assistance, please contact our support team': {
            '': 'Muut kutsut on lähetetty onnistuneesti. Jos tarvitset lisäapua, ota yhteyttä tukitiimiimme.',
          },
        },
        'Conflicting emails': 'Ristiriitaiset sähköpostit',
        Text: 'Viesti',
        Data: 'Tiedot',
        'workers information': 'työntekijöiden tiedot',
        'This email is sent by :companyName from HOURS management software':
          'Tämä sähköposti on lähetetty Hours työajan hallintaohjelmista :companyName puolesta',
        'To answer this email, please use :managerEmail':
          'Vastataksesi tähän sähköpostiin, käytä seuraavaa: :managerEmail',
        'Please make sure that your password': 'Varmista, että salasanasi sisältää',
        'New work type': 'Uusi työ',
        'Select logo': 'Valitse logo',
        'or drag one here': 'tai vedä yksi tänne',
        'Workspace name': 'Työtilan nimi',
        'Phone number': 'Puhelinnumero',
        'Do you want to change your email for verification?':
          'Haluatko vaihtaa sähköpostiosoitteesi vahvistusta varten?',
        'Your email is unverified and your trial has ended': {
          ' Verify your email if you want to continue using the Hours software': {
            '':
              'Sähköpostisi on vahvistamaton ja kokeilujaksosi on päättynyt. Vahvista sähköpostiosoitteesi, jos haluat jatkaa Hours-ohjelmiston käyttöä.',
          },
        },
        'Resend verification': 'Lähetä vahvistus uudelleen',
        Refresh: 'Päivitä',
        'Already have an account?': 'Onko sinulla jo tili?',
        'Log in here': 'Kirjaudu sisään tästä',
        'By signing up, you agree to our': 'Rekisteröitymällä hyväksyt meidän',
        'terms of service': 'käyttöehdot',
        'privacy policy': 'tietosuojakäytäntö',
        'and to receiving marketing communication from Hours': 'ja markkinointiviestinnän vastaanottamiseen Hoursilta',
        'You can opt out anytime': 'Voit kieltäytyä käytöstä milloin tahansa',
        'Your email address has not been verified': { '': 'Sähköpostiosoitettasi ei ole vahvistettu.' },
        'Check your email for the activation link or': 'Tarkista sähköpostistasi aktivointilinkki tai',
        None: 'Ei käytössä',
        'This email is already in use': { '': 'Tämä sähköpostiosoite on jo käytössä.' },
        'RFID is already in use': { '': 'Rfid lätkä on jo käytössä.' },
        'Normal workday length': 'Normaali työpäivän pituus',
        'National holiday settings': 'Kansallisjuhlan asetukset',
        Calendar: 'Kalenteri',
        'The previous working day before a national holiday will be shortened':
          'Kansallista vapaapäivää edeltävää työpäivää lyhennetään',
        'The following fields are missing: ': 'Seuraavat kentät puuttuvat',
        main: 'Pää',
        'Visma Settings': 'Netvisorin asetukset',
        'Show weekend hours separately': 'Näytä viikonlopun tunnit erikseen',
        'Show columns': 'Sarakkeet',
        'Weekend hours': 'Viikonlopputunnit',
        'User does not have work type salaries': 'Käyttäjällä ei ole työtyyppisiä palkkoja',
        'Updated daily allowance': 'Päivitetty päiväraha',
        'Deleted pauses': 'Poistettu tauot',
        'Round to nearest 15 minutes': 'Pyöristää lähimpään 15 minuuttiin',
        'Add to login box': 'Lisää työ leimauslaitteeseen',
        'Work end time': 'Työn päättymisaika',
        'Aloita lisäämällä uusi päiväraharyhmä': 'Aloita lisäämällä uusi päiväraharyhmä',
        'Your daily allowance group is empty': 'Päiväraharyhmäsi on tyhjä',
        'Use in daily allowance calculation': 'Käytä päivärahan laskennassa',
        'View notification': 'Näytä ilmoitus',
        'No allowance': 'Ei päivarahoja',
        "If you haven't received verification email in a few minutes, please check the spam folder": {
          '': 'Jos et ole saanut vahvistussähköpostia muutamaan minuuttiin, tarkista roskapostikansio.',
        },
        'Do nothing': 'Älä tee mitään',
        Unsubscribe: 'Lopeta tilaus',
        "If you don't want to receive notifications from Hours to your email, then you can unsubscribe": {
          '': 'Jos et halua saada ilmoituksia Hoursilta sähköpostiisi, voit peruuttaa tilauksen.',
        },
        "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser":
          'Jos sinulla on vaikeuksia napsauttaa Näytä ilmoitus -painiketta, kopioi ja liitä alla oleva URL-osoite verkkoselaimeen',
        'You have a new notification': 'Sinulla on uusi ilmoitus',
        'Your Hours trial will end in :trial days': { '': 'Hours-kokeilujaksosi päättyy :trial päivän kuluttua.' },
        'By continuing using the Hours software after trial, you will be billed by Hours price list': {
          '': 'Jos jatkat Hours-ohjelmiston käyttöä kokeilun jälkeen, sinua laskutetaan Hours-hinnaston mukaan.',
        },
        'Email verification': 'Sähköpostivarmistus',
        'Unable to verify your email': { '': 'Sähköpostiosoitettasi ei voida vahvistaa' },
        End: 'Loppu',
        'Your Hours': {
          'ee invoice is unpaid': {
            ' If you do not pay the bill, your account will be closed on :bill':
              'Hours.fi-laskusi on maksamaton. Jos et maksa laskua, tilisi suljetaan :bill',
          },
        },
        Equal: 'Veroinen',
        Down: 'Alas',
        Up: 'Ylös',
        'Daily allowance': 'Päiväraha',
        Post: 'Lähettää',
        'Group does not have a daily allowance item': 'Päivaraharyhmäsi on tyhjä',
        'Add daily allowance': 'Lisää päiväraha',
        'Daily allowances': 'Päivarahat',
        View: 'Näytä',
        'Generating Report': { ' Please wait': { '': { '': { '': 'Raportin luominen. Odota...' } } } },
        'Total:': 'Yhteensä',
        'Add a pause': 'Lisää tauko',
        'The entered working time is more than 24 hours': {
          ' Are you sure you want to add it?': 'Syötetty työaika on yli 24 tuntia. Haluatko varmasti lisätä sen?',
        },
        'There are conflicting times for this timeframe': {
          ' Please review previous entries': {
            '': 'Tietyllä ajanjaksolla on päällekkäisiä työaikoja. Tarkista aikaisemmat merkinnät.',
          },
        },
        'Conflicting times': 'Päällekkäiset työajat',
        'Sent to Netvisor': 'Lähetetty Netvisorin',
        'Sent to': 'Lähetetty',
        'Rounded using general preference': 'Pyöristetty yleisten asetusten mukaan',
        'Rounded end time to': 'Pyöristetty päättymisaika',
        'Added using general preference': 'Lisätty yleisillä asetuksilla',
        'Updated pauses': 'Päivitetty taukoja',
        'Confirmed work at': 'Hyväksytty työ',
        'Added workspace': 'Lisätty työtila',
        'Added work type': 'Lisätty työtyyppi',
        'Added address': 'Lisätty osoite',
        'Added pause': 'Lisätty tauko',
        'Added comment': 'Lisätty kommentti',
        'Added bonus': 'Lisätty bonus',
        'Added bonus comment': 'Lisätty bonuskommentti',
        'Work edited': 'Työtä muokattu',
        'Add work type extra': 'Lisää työtyyppi lisä',
        'Ends at': 'Päättyy',
        'Out of door at': 'Ovelta klo',
        'Resumed work at': 'Töitä jatkettiin klo',
        'Ended work at': 'Työ päättyi klo',
        'Ended work using door': 'Työ päättyi oven avulla',
        'Ended work using box': 'Työ lopetettu leimauslaitteella',
        'Ended work using mobile': 'Työ lopetettu mobiililla',
        'Ended work using web': 'Työ lopetettu webin kautta',
        'Rounded start time to': 'Pyöristetty aloitusaika',
        'Started work using box': 'Aloitti työskentelyn leimauslaitteella',
        'Started work using door': 'Työt aloitettiin oven avulla',
        'Started work using mobile': 'Aloitti työskentelyn mobiililla',
        'Started work using web': 'Aloitti työskentelyn webin avulla',
        'Started work at': 'Aloitti työt klo',
        'Entered door': 'Sisään ovesta',
        'Entered door at': 'Sisään ovesta klo',
        'Paused work at': 'Työ keskeytetty klo',
        'Out of door': 'Ulkona ovesta',
        'Congratulations! You have just set your account password': {
          ' You are now able to access the web portal': {
            '': 'Onneksi olkoon! Olet juuri asettanut salasanan tilillesi. Voit nyt käyttää verkkoportaalia.',
          },
        },
        'You are all set': { '': 'Kaikki on valmista.' },
        'Your email has been verified': { '': 'Sähköpostiosoitteesi on vahvistettu.' },
        'Activation link is active within a week after creating an account': {
          ' Please resend a new activation link to your email': {
            '':
              'Aktivointilinkki pysyy aktiivisena viikon ajan tilin luomisen jälkeen. Lähetä uusi aktivointilinkki sähköpostiisi.',
          },
        },
        'We sent you a verification email to': 'Lähetimme vahvistussähköpostin osoitteeseen',
        'If you havent received verification email in a few minutes, please check the spam folder': {
          '': 'Jos et saa vahvistussähköpostia muutaman minuutin kuluessa, tarkista roskapostikansio.',
        },
        'Congratulations! you have just verified your email address': {
          '': 'Onneksi olkoon! Olet juuri vahvistanut sähköpostiosoitteesi.',
        },
        'You are now able to access the web portal': { '': 'Pääset nyt käyttämään verkkoportaalia.' },
        'Invalid verification token received or the link is already expired': {
          '': 'Virheellinen vahvistustunnus vastaanotettu tai linkki on jo vanhentunut.',
        },
        "If you don't see the email, please check your spam folder or contact your Organization":
          'Jos et näe sähköpostia, tarkista roskapostikansiosi tai ota yhteyttä organisaatioosi',
        'Overtime computation': 'Ylityön laskenta',
        "Can't add pause bigger than work duration": { '': 'Työn kestoa pidempää taukoa ei voida lisätä.' },
        'Work duration must not overlap to other work logs': {
          '': 'Työn kesto ei saa osua päällekkäin muiden töiden kanssa.',
        },
        "Work durations shouldn't be negative": { '': 'Työn kesto ei saa olla negatiivinen.' },
        'Selected works have some users with multiple work logs on the same day':
          'Valituissa töissä on joitakin käyttäjiä, joilla on useita töitä samalle päivälle',
        'Cannot confirm not ended work': 'Työtä, jota ei ole lopetettu, ei voida vahvistaa',
        "Start time can't be later than end time": { '': 'Aloitusaika ei voi olla myöhäisempi kuin lopetusaika.' },
        'This absence request has already been declined': { '': 'Tämä poissaolopyyntö on jo hylätty.' },
        'Overlapping paid absences': 'Päällekkäisiä palkallisia poissaoloja',
        'Cannot request with overlapping paid absences': 'Ei voida pyytää, jos palkallisia poissaoloja on päällekkäin',
        'There is already ongoing unconfirmed request': 'Vahvistamaton pyyntö on jo meneillään',
        'Work has been deleted': 'Työ on poistettu',
        'Can not found request': {
          ' Work log may have been deleted': { '': 'Pyyntöä ei löydy. Työ on saatettu poistaa.' },
        },
        'This bonus request has already been accepted': { '': 'Tämä bonuspyyntö on jo hyväksytty.' },
        'This bonus request has already been declined': { '': 'Tämä bonuspyyntö on jo hylätty.' },
        "Pause start can't be earlier than work start": {
          '': 'Tauon alkamisaika ei voi olla aikaisempi kuin työn alkamisaika.',
        },
        'Work resumed already': { '': 'Työ on jo jatkunut.' },
        'User is currently working': { '': 'Käyttäjä työskentelee tällä hetkellä.' },
        'Overlapping work rounds': 'Päällekkäisiä työvuoroja',
        'There are some errors in exporting merit palk report': 'Merit Palk -raportin viennissä on virheitä',
        'There are some errors in exporting netvisor report': 'Netvisor-raportin viennissä on virheitä',
        'Dimension updated unsuccessfully': 'Laskentakohteiden päivitys ei onnistunut',
        'Notification cannot be posted if deleted': 'Ilmoitusta ei voida julkaista, jos se poistetaan',
        'Error fetching locales': { '': 'Paikkojen noudossa tapahtui virhe.' },
        'This absence request has already been accepted': { '': 'Tämä poissaolopyyntö on jo hyväksytty.' },
        'Work paused already': { '': 'Työ on jo tauotettu.' },
        "This account doesn't exist": { '': 'Tämä tili ei ole olemassa.' },
        'Your account has been deleted': { '': 'Tilisi on poistettu.' },
        'Please verify your email': { '': 'Vahvista sähköpostiosoitteesi.' },
        'Your account has been deactivated': { '': 'Tilisi aktivointi on poistettu.' },
        'Your company account has been disabled': { '': 'Yritystilisi on poistettu käytöstä.' },
        'Work start should not be greater than stop time': {
          '': 'Työn aloitusaika ei voi olla myöhäisempi kuin lopetusaika.',
        },
        'Work stopped already': { '': 'Työ on jo lopetettu.' },
        'Work not found': { '': 'Työtä ei löytynyt.' },
        'You have attempted to login multiple times already': {
          ' Try resetting your password': { '': 'Olet jo yrittänyt kirjautua sisään monta kertaa. Vaihda salasanasi.' },
        },
        'Enter your email below and we will send a password reset link to your email': {
          '': 'Kirjoita alle sähköpostiosoitteesi ja lähetämme siihen salasanan vaihtolinkin',
        },
        'Token already expired': 'Tunnus on jo vanhentunut',
        'Token is invalid': 'Tunnus on virheellinen',
        'Token has already been used': 'Tunnus on jo käytetty',
        'User not found': 'Käyttäjää ei löytynyt',
        'Password Reset Sent': 'Salasanan vaihtoviesti on lähetetty',
        'You should receive the password reset email shortly once an account matching your username is found': {
          '':
            'Salasanan vaihtoviestin pitäisi saapua pian sen jälkeen kun käyttäjätunnustasi vastaava tili on löytynyt.',
        },
        'Invalid credentials': 'Virheelliset kirjautumistiedot',
        'Please try again or ': 'Yritä uudelleen tai ',
        'Merit Palk Setting': 'Merit Palk -asetus',
        'API Setting': 'API-asetus',
        'API Key': 'API-avain',
        'API ID': 'API-tunnus',
        'Do not count as overtime': 'Älä laske ylityöksi',
        'No notifications created': 'Ilmoituksia ei ole luotu',
        'Start by creating notifications and post them': { '': 'Aloita luomalla ilmoituksia ja julkaisemalla ne.' },
        'Add new notification': 'Lisää uusi ilmoitus',
        'Default work type': 'Työn oletuslaji',
        'Automatic start/end work with mobile': 'Automaattinen aloitus/lopetus mobiililla (Beta-testaus)',
        Radius: 'Säde',
        'Set location': 'Aseta sijainti',
        'People rate': 'Henkilöiden tuntihinta',
        'Worktype rate': 'Työlajin tuntihinta',
        'Show worker number': 'Näytä työntekijän numero',
        'Reset Your Password': 'Vaihda salasanasi',
        'Back to login': 'Takaisin kirjautumiseen',
        'More details': 'Lisätietoja',
        'Showing rows: :current out of :total': 'Näytetään rivit: :current/:total',
        'We use cookies to ensure you get the best experience on our site':
          'Varmistamme evästeitä käyttämällä, että saat parhaan kokemuksen sivustollamme',
        'Start by adding new allowed pause group': 'Aloita lisäämällä uusi sallittu taukoryhmä',
        'Group does not have an allowed pause item': 'Ryhmällä ei ole sallittua taukokohdetta',
        'Add allowed pause': 'Lisää sallittu tauko',
        'Allowed pause group': 'Sallittu taukoryhmä',
        'Work pause': 'Työtauko',
        'Work end': 'Työn lopetus',
        'Work start time': 'Työn aloitusaika',
        'Round time': 'Pyöristä aika',
        'Lunch time': 'Lounastauko',
        minutes: 'minuuttia',
        'before work end time to round up': 'ennen työn lopetusaikaa ylöspäin pyöristämistä varten',
        'after work end time to round down': 'työn lopetusajan jälkeen alaspäin pyöristämistä varten',
        'Your allowed pause group is empty': 'Sallittu taukoryhmäsi on tyhjä',
        'Update doors': 'Päivitä ovet',
        'When exiting': 'Poistuttaessa',
        'Edit door': 'Muokkaa ovea',
        'Door access': 'Sisäänkäynti ovesta',
        'Main door': 'Pääovi',
        'must be accessed to start work': 'on avattava, jotta työn voi aloittaa',
        'Front door': 'Etuovi',
        'Change work type when entering': 'Vaihda työlaji astuttaessa sisään',
        'Work resume when entering': 'Työ jatkuu astuttaessa sisään',
        Request: 'Pyyntö',
        'View Bonus': 'Näytä bonus',
        Pending: 'Odottaa',
        Approved: 'Hyväksytty',
        Declined: 'Hylätty',
        'Expired activation': 'Vanhentunut aktivointi',
        'Inner Range Doors': 'Inner Range -ovet',
        'Doors updated': 'Ovet on päivitetty',
        'Door ID': 'Oven tunnus',
        'Request Bonus': 'Pyydä bonusta',
        'Can accept/edit/decline requests': 'Voi hyväksyä/muokata/hylätä pyyntöjä',
        'Switching Account': 'Tilin vaihto',
        'Past two weeks': '2 viikon palkkajakso',
        ':worker has requested a bonus of :bonus': ':worker on pyytänyt bonusta, jonka suuruus on :bonus',
        'has requested a paid absence': 'on pyytänyt palkallista poissaoloa',
        ':manager has declined your request for a bonus': { '': ':manager on hylännyt bonuspyyntösi.' },
        'Your work (:workspace - :worktype) has automatically paused at :datetime': {
          '': 'Työsi (:workspace - :worktype) tauotettiin automaattisesti :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically resumed at :datetime': {
          '': 'Työsi (:workspace - :worktype) jatkui automaattisesti :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically ended at :datetime': {
          '': 'Työsi (:workspace - :worktype) lopetettiin automaattisesti :datetime.',
        },
        ':manager has :action a paid absence': { '': ':manager on :action palkallisen poissaolon.' },
        'Congrats! :manager has :action a bonus for your greate work': {
          '': 'Onneksi olkoon! :manager on :action bonuksen loistavan työsi vuoksi.',
        },
        ":worker has requested a paid absence ':type' (:start - :end)": {
          '': ":worker on pyytänyt palkallista poissaoloa ':type' (:start - :end).",
        },
        ':name is working overtime with work (:workspace - :worktype) at this :datetime': {
          '': ':name tekee ylitöitä työssä (:workspace - :worktype) tällä hetkellä, :datetime.',
        },
        ':manager has declined your request for a paid absence': {
          '': ':manager on hylännyt pyyntösi palkallista poissaoloa varten.',
        },
        'User preference': 'Käyttäjän asetukset',
        'Sorting order': 'Lajittelujärjestys',
        'First name, Last name': 'Etunimi, Sukunimi',
        'Last name, First name': 'Sukunimi, Etunimi',
        'Allowed pauses': 'Sallitut tauot',
        Late: 'Myöhässä',
        'You have been added to Hours time tracking': 'Sinut on lisätty Hours-ajanseurantaan',
        'Fetch records': 'Nouda InnerRange',
        'has requested a bonus of': 'on pyytänyt bonusta',
        Posted: 'Lähetetty',
        'Ready for posting': 'Valmiina lähetettäväksi',
        Draft: 'Luonnos',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'kun käyttäjää ei ole valittu, ilmoitukset lähetetään yrityksen tai valittujen työtilojen kaikille käyttäjille',
        'Draft notification message': 'Ilmoitusviestin luonnos',
        Requests: 'Pyynnöt',
        'No bonus requests yet': 'Bonuspyyntöjä ei vielä ole',
        'No paid absence requests yet': 'Palkallisia poissaolopyyntöjä ei vielä ole',
        'Paid absence': 'Palkallinen poissaolo',
        'Bonus comment': 'Bonusta koskeva kommentti',
        'Original requested amount': 'Alkuperäisen pyynnön summa',
        'New amount': 'Uusi summa',
        'Request paid absence': 'Pyydä palkallista poissaoloa',
        'Requested at': 'Pyydetty:',
        'Started at': 'Alkamisaika:',
        'Ended at': 'Päättymisaika:',
        'Confirmed at': 'Vahvistettu:',
        'Confirmed by': 'Vahvistaja',
        'Work date': 'Työn päivämäärä',
        'You have no rights to edit work time!': 'Sinulla ei ole oikeutta muokata työaikaa!',
        'Notify through push notification': 'Ilmoita push-ilmoituksella',
        'Notify through email': 'Ilmoita sähköpostitse',
        Notifications: 'Ilmoitukset',
        'Created by': 'Luonut:',
        'Posted at': 'Julkaistu:',
        Title: 'Otsikko',
        Repost: 'Julkaise uudelleen',
        'Add notification': 'Lisää ilmoitus',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'kun työtilaa ei ole valittu, ilmoitukset lähetetään yrityksen kaikille käyttäjille',
        'Company notifications': 'Yrityksen ilmoitukset',
        'Worker is working overtime': 'Työntekijä tekee ylitöitä',
        'Worker is asking for a bonus': 'Työntekijä pyytää bonusta',
        'Worker is requesting for an absence': 'Työntekijä pyytää poissoloa',
        'Worker has arrived/left from the workspace': 'Työntekijä on saapunut työtilaan / poistunut työtilasta',
        'Manager has added/accepted/declined a bonus': 'Esimies on lisännyt/hyväksynyt/hylännyt bonuksen',
        'Manager has added/accepted/declined a paid absence':
          'Esimies on lisännyt/hyväksynyt/hylännyt palkallisen poissaolon',
        'Work paused/ended automatically through workspace preference':
          'Työ keskeytetty/lopetettu automaattisesti työtilan asetuksen kautta',
        'Start by adding a new box': { '': 'Aloita lisäämällä uusi leimauslaite.' },
        Latest: 'Uusimmat',
        'This week': 'Tämä viikko',
        Older: 'Aikaisemmat',
        'You have declined this request!': 'Olet hylännyt tämän pyynnön!',
        'You have already approved this request!': 'Olet jo hyväksynyt tämän pyynnön!',
        Accept: 'Hyväksy',
        Decline: 'Hylkää',
        'Mark as read': 'Merkitse luetuksi',
        'Turn off receiving this status': 'Kytke pois tämän tilan vastaanottaminen',
        'Delete notification': 'Poista ilmoitus',
        'Mark all as read': 'Merkitse kaikki luetuiksi',
        'View full feed': 'Näytä koko syöte',
        'Clear all': 'Poista kaikki',
        Read: 'Lue',
        Close: 'Sulje',
        Clear: 'Tyhjennä',
        'No notifications yet': 'Ilmoituksia ei vielä ole',
        'Turn on/off notifications': 'Ota käyttöön / poista käytöstä ilmoitukset',
        'Weekly working hours': 'Viikoittaiset työtunnit',
        'Show more': 'Näytä enemmän',
        'Show less': 'Näytä vähemmän',
        'No email': 'Ei sähköpostia',
        'Complete registration by choosing a password': 'Viimeistele rekisteröinti valitsemalla salasana',
        'Your First Name': 'Sinun etunimi',
        'Your Last Name': 'Sinun sukunimi',
        'Your Password': 'Sinun salasana',
        'Confirm Your Password': 'Vahvista salasana',
        "Please register by entering you're email": 'Rekisteröidy kirjoittamalla sähköpostiosoitteesi',
        Location: 'Sijainti',
        'Must have at least 8 characters': 'On oltava vähintään 8 merkkiä',
        'Must start with alphanumeric character': 'On aloitettava aakkosnumeerisella merkillä',
        'Must have at least one lowercase character': 'On oltava vähintään yksi pieni kirjain',
        'Must have at least one uppercase character': 'On oltava vähintään yksi iso kirjain',
        'Must have at least one digit': 'On oltava vähintään yksi numero',
        'Passwords must match': 'Salasanojen täytyy täsmätä',
        'Must Accept Terms and Condition': 'On hyväksyttävä käyttöehdot',
        'This field is required': 'Tämä kenttä on pakollinen',
        ':field is required': 'kenttä on pakollinen',
        by: ',',
        'Total salary': 'Palkka yhteensä',
        'Paid pause': 'Tauko on maksullinen',
        'Work type extras': 'Työlajien lisäät',
        Basic: 'Perus',
        'Basic salary': 'Peruspalkka',
        Week: 'Viiko',
        Rate: 'Tuntihinta',
        'Worker is missing identification number': 'Työntekijältä puuttuu tunnusnumero',
        'Your paid absences is empty': 'Palkallisia poissaoloja ei ole',
        'Start by adding new paid absences': 'Aloita lisäämällä uusia palkallisia poissaoloja',
        'Invite user': 'Lähetää kutsu',
        'Group does not have a work round item': 'Ryhmällä ei ole työvuorokohdetta',
        'Group does not have an overtime item': 'Ryhmällä ei ole ylityökohdetta',
        'Upload Company Logo': 'Lataa palveluun yrityksen logo',
        'Upload Profile Image': 'Lataa palveluun profiilikuva',
        'Drag and drop your image here or': 'Vedä ja pudota kuva tähän tai',
        'upload a photo': 'lataa palveluun valokuva',
        'Single image must not exceed 5MB': 'Minkään kuvan koko ei saa olla suurempi kuin 5 Mt',
        'Business Area': 'Liiketoiminta-alue',
        'Start by adding new work round group': 'Aloita lisäämällä uusi työvuororyhmä',
        'Your work round group is empty': 'Työvuororyhmäsi on tyhjä',
        'Your overtime group is empty': 'Ylityöryhmäsi on tyhjä',
        '\r\nStart by adding new overtime group': 'Aloita lisäämällä uusi ylityöryhmä',
        File: 'Tiedosto',
        "This does not affect users' existing paid absences":
          'Tämä ei vaikuta käyttäjän olemassa oleviin palkallisiin poissaoloihin',
        'View document': 'Näytä asiakirja',
        'Rename document': 'Nimeä asiakirja uudelleen',
        'Delete document': 'Poista asiakirja',
        'File name': 'Tiedoston nimi',
        'File type': 'Tiedoston tyyppi',
        'File size': 'Tiedoston koko',
        'Last date modified': 'Viimeisin muokkauspäivä',
        'accepts comma-separated input': 'hyväksyy pilkulla erotettuja syötteitä',
        "Press 'Enter'": 'Paina "Enter"',
        Emails: 'Sähköpostit',
        'Invite users by email': 'Lähetää kutsu',
        'Add work type': 'Lisää työlaji',
        'View work types': 'Katso työlajieja',
        'User has been deleted': 'Käyttäjä on poistettu',
        'User information cannot be viewed': 'Käyttäjätietoja ei voida tarkastella',
        'Go back to People page': 'Palaa henkilöiden-sivulle',
        'Something went wrong with your request': {
          ' Please try again later or refresh the page': {
            '': 'Jotain meni pieleen. Yritä myöhemmin uudelleen tai päivitä sivu.',
          },
        },
        'Something went wrong': {
          ' Please contact administrator': { '': 'Jotain meni pieleen. Ota yhteyttä ylläpitäjään.' },
        },
        'The work has been updated': { ' Please confirm to apply changes': 'Vahvista ottaaksesi muutokset käyttöön' },
        'Start by adding new work type': 'Aloita lisäämällä uusi työlaji',
        'Start by adding a new workina hours registration box': {
          '': 'Aloita lisäämällä uusi työtuntien leimauslaite.',
        },
        'Account verification': 'Tilin vahvistus',
        Hello: 'Hei',
        'Please click the button below to verify your email address': {
          '': 'Vahvista sähköpostiosoitteesi napsauttamalla alla olevaa painiketta.',
        },
        'Verify Email Address': 'Vahvista sähköpostiosoite',
        'If you did not create an account, no further action is required': {
          '': 'Jos et ole luonut tiliä, sinun ei tarvitse tehdä mitään.',
        },
        'Best regards': 'Ystävällisin terveisin',
        Team: '-tiimi',
        "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser":
          'Jos "Vahvista sähköpostiosoite" -painikkeen napsauttaminen ei toimi, kopioi ja liitä alla oleva URL-osoite verkkoselaimeesi',
        'All rights reserved': 'Kaikki oikeudet pidätetään',
        'Complete registration': 'Viimeistele rekisteröityminen',
        from: 'yrityksestä',
        'have added you to Hours time tracking': { '': 'on lisännyt sinut Hours-ajanseurantaan' },
        'Please complete your registration by setting up a password': {
          '': 'Viimeistele rekisteröitymisesi määrittämällä salasana',
        },
        'Set Password': 'Määritä salasana',
        'This set password link will expire 1 day from now': {
          '': 'Tämä salasanan määrityslinkki vanhenee 1 päivän kuluttua tästä hetkestä.',
        },
        'Download also Hours mobile apps': 'Lataa myös Hours-mobiilisovellukset',
        "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser":
          'Jos "Määritä salasana" -painikkeen napsauttaminen ei toimi, kopioi ja liitä alla oleva URL-osoite verkkoselaimeesi',
        'Welcome to': 'Tervetuloa järjestelmä',
        'You are receiving this email because we received a reset password request for your account':
          'Sait tämän sähköpostiviestin, koska olemme vastaanottaneet tiliäsi koskevan salasanan nollauspyynnön',
        'Reset Password': 'Nollaa salasana',
        'If you did not request a password reset, no further action is required': {
          '': 'Jos et ole pyytänyt salasanan nollaamista, sinun ei tarvitse tehdä mitään.',
        },
        'This password reset link will expire 1 day from now': {
          '': 'Tämä salasanan nollauslinkki vanhenee 1 päivän kuluttua tästä hetkestä.',
        },
        'Your work type is empty': 'Työlajisi on tyhjä',
        'Dreaming of a new box?': 'Unelmoitko uudesta leimauslaitteesta?',
        'Oops! Option already exist': { '': 'Hups! Vaihtoehto on jo luotu.' },
        'Are you sure you want to update selected work logs?': 'Haluatko varmasti päivittää valitut työt?',
        'Round to nearest hour': 'Pyöristää lähimpään tuntiin',
        'Round to nearest half-hour': 'Pyöristää lähimpään puoleen tuntiin',
        'Round to nearest 10 minutes': 'Pyöristää lähimpään 10 minuuttiin',
        'Still having a trouble?  Please contact administrator':
          'Onko sinulla edelleen ongelmia? Ota yhteyttä ylläpitäjään',
        'Edit group': 'Muokkaa ryhmää',
        'Overtime group': 'Ylityöryhmä',
        'Work round group': 'Työvuoro ryhmä',
        'Are you sure you want to remove profile image?': 'Haluatko varmasti poistaa profiilikuvan?',
        'Error on exporting :type': { '': 'Virhe viennissä :type.' },
        'Do not group': 'Älä ryhmittele',
        'Grouped by people': 'Ryhmitetty henkilön mukaan',
        'Are you sure you want to bulk activate/deactivate?': 'Haluatko varmasti joukkoaktivoida/deaktivoida?',
        'Please try shorter time frame or less people at the same time': {
          '': 'Kokeile lyhyempää aikaväliä tai vähemmän ihmisiä samaan aikaan.',
        },
        'Some selected work logs are already confirmed!': 'Jotkut valitut työt on jo vahvistettu!',
        'Round work time': 'Pyöreä työaika',
        Processing: { '': { '': { '': 'Käsitellään...' } } },
        Subject: 'Aihe',
        'Number of weeks': 'Viikkojen lukumäärä',
        'Number of months': 'Kuukausien lukumäärä',
        'Daily normal hours': 'Päivittäiset normaalit tunnit',
        'Weekly normal hours': 'Normaalit viikkotunnit',
        'Monthly normal hours': 'Kuukauden normaalit aukioloajat',
        'Are you sure want to switch as :type?': 'Haluatko vaihtaa :type puolelle?',
        'This action cannot be undone!': 'Tätä toimintoa ei voi kumota!',
        'Work type workspaces': 'Työlaji työtila',
        'People workspaces': 'Henkilön työtila',
        'Working Period': 'Työjakso',
        From: 'Alkaen',
        'Send copy': 'Lähetä kopio',
        'weekly overtime': 'viikoittainen ylityö',
        'Monthly overtime': 'Kuukausittaiset ylityöt',
        'monthly overtime': 'kuukausittaiset ylityöt',
        'working hours': 'työtunnit',
        total: 'yhteensä',
        hours: 'tunnit',
        days: 'päivää',
        'Total working days': 'Työpäiviä yhteensä',
        'Created at': 'Luotu',
        Sunday: 'Sunnuntai',
        Saturday: 'Lauantai',
        Map: 'Kartta',
        History: 'Historia',
        Overview: 'Yleiskatsaus',
        Company: 'Yritys',
        'Average workday length': 'Keskimääräinen työpäivän pituus',
        'Total hours during selected period': 'Työtuntijen kokonaismäärä valitun ajanjakson aikana',
        report: 'raportti',
        'Work types': 'Työlajit',
        'Paid absences': 'Poissaolot',
        'Work rounds': 'Työvuorot',
        'Daily overtime': 'Päivittäiset ylityöt',
        'Weekly overtime': 'Viikoittainen ylityö',
        'Please wait while the :type is still generating': {
          '': { '': { '': 'Odota, kun :type tiedostoa vielä luodaan...' } },
        },
        'Default pause length': 'Tauon oletuspituus',
        'Include in overtime computation': 'Sisällytä ylityölaskennassa',
        'Total time': 'Kesto',
        'Add user': 'Lisää käyttäjä',
        'Edit user': 'Muokkaa käyttäjää',
        'Edit users': 'Muokkaa käyttäjiä',
        Pauses: 'Tauot',
        'Confirmation action': 'Hyväksy',
        'Edit Box': 'Muokkaa leimauslaitetta',
        'Main Screen Type': 'Päänäkymä',
        Message: 'Viesti',
        'With worker list': 'Päänäkymä sisältää työntekijäluettelon',
        'Without worker list': 'Päänäkymä ei sisällä työntekijäluetteloa',
        'Add Box': 'Lisää leimauslaite',
        "Workspaces who's people are visible in the box": 'Työtilat joiden henkilöt näkyvät leimauslaitteessa',
        Day: 'Päivä',
        'Choose type': 'Valitse tyyppi',
        Until: 'Asti',
        'Do not count as workday': 'Älä laske työpäiväksi',
        'Time unit': 'Aikayksikkö',
        Length: 'Arvo',
        'Edit Paid Absence': 'Muuta palkallista poissaoloa',
        'New Paid Absence': 'Uusi palkallinen poissaolo',
        'Add new': 'Lisää uusi',
        'Add new paid absence': 'Lisää uusi palkallinen poissaolo',
        'User ID': 'Käyttäjä ID',
        'User key': 'Käyttäjäavain',
        'Division specific settings': 'Työtilan asetukset',
        'Update Dimensions': 'Päivitä laskentakohteita',
        'Dimensions updated': 'Laskentakohteet päivitetty',
        'Netvisor Dimensions': 'Netvisorin laskentakohteet',
        'Dimension updated successfully': { '': 'Laskentakohteiden päivitys onnistui' },
        'Netvisor settings updated successfully': { '': 'Netvisorin asetusten päivitys onnistui' },
        'Connection Settings': 'Yhteyden asetukset',
        'Netvisor Settings': 'Netvisorin asetukset',
        'Business ID': 'Y-tunnus',
        'User Profile': 'Käyttäjäprofiili',
        'Workspace does not have a worker': 'Työtilassa ei ole työntekijöitä',
        'Registry code': 'Y-tunnus',
        'Contact Information': 'Yhteystiedot',
        Phone: 'Puhelin',
        'Start from': 'Aloitus',
        'User type': 'Käyttäjätyyppi',
        'General Rights': 'Yleiset oikeudet',
        'Can register work hour from login box': 'Oikeudet rekisteröidä työtunteja leimauslaitteen kautta',
        'Is visible on login box': 'Näkyy leimauslaitteessa',
        'Start of employment': 'Työsopimuksen alkamispäivä',
        'End of employment': 'Työsopimuksen päättymispäivä',
        'Netvisor export identification': 'Netvisorin tunnistautuminen',
        'Worker identification in Netvisor export': 'Työntekijän tunnistautuminen Netvisorin vienissä',
        'Worker number': 'Työntekijän numero',
        Information: 'Informaatio',
        Rights: 'Oikeudet',
        Personal: 'Henkilökohtainen',
        'Add another number': 'Lisää uusi numero',
        'Add another address': 'Lisää uusi osoite',
        'ID Code': 'Henkilötunnus',
        'TAX Number': 'Veronumero',
        'Date of birth': 'Syntymäaika',
        Generic: 'Yleiset',
        Deleted: 'Poistettu',
        Actions: 'Toiminnot',
        'Add Paid Absence': 'Lisää palkallinen vapaa',
        ':count worker is selected': ':count työntekijä valittu',
        ':count workers are selected': ':count työntekijät valittu',
        'Send workers data': 'Lähetä työntekijän tiedot',
        'Send email to worker': 'Lähetä sähköposti työntekijälle',
        'Hide all days': 'Piilota kaikki päivät',
        'Show all days': 'Näytä kaikki päivät',
        Dimensions: 'Laskentakohteet',
        'Working hours are not confirmed': 'Työtunteja ei ole vahvistettu',
        'Product number': 'Tuotenumero',
        Hours: 'Tunnit',
        'Export as :type': 'Vie :type',
        'Collapse all rows': 'Sulje kaikki rivit',
        'Expand all rows': 'Näytä kaikki rivit',
        All: 'Kaikki',
        'Confirmed hours': 'Hyväksytyt tunnit',
        'Unconfirmed hours': 'Vahvistamattomat tunnit',
        'Group by': 'Ryhmitä',
        'Report options': 'Raportoi valinnat',
        'Total hours': 'Tunnit yhteensä',
        'Total days': 'Työpäivät yhteensä',
        'Average day': 'Keskivertopäivä',
        'Total paid absence': 'Palkalliset poissaolot yhteensä',
        Bonuses: 'Bonukset',
        Unconfirm: 'Kumoa',
        Edited: 'Muokattu',
        Tags: 'Tags',
        Worktype: 'Työlaji',
        'Are you sure you want to :status this work log?': 'Haluatko varmasti :status tämän työn?',
        'Are you sure you want to delete this work log?': 'Haluatko varmasti poistaa tämän työn?',
        'Are you sure you want to delete :name work log?': 'Haluatko varmasti poistaa :name työn?',
        'Delete Timesheet': 'Poista työ',
        'Are you sure you want to delete :name?': 'Haluatko varmasti poistaa :name?',
        Amount: 'Määrä',
        'Edit Bonus': 'Muokkaa bonusta',
        'Are you sure you want to confirm :name work log?': 'Haluatko varmasti vahvistaa :name työn?',
        'Are you sure you want to unconfirm :name work log?': 'Haluatko varmasti poistaa vahvistuksen :name työstä?',
        'Worker Comment': 'Työntekijän kommentti',
        Bonus: 'Bonus',
        'Breaks Total': 'Tauot yhteensä ',
        'Add :label': 'Lisää :label',
        'No :label added': ':label ei ole lisätty',
        'Warning: Updating these settings may affect ongoing works': {
          '': 'Varoitus: Näiden asetusten päivittäminen voi vaikuttaa meneillä oleviin töihin.',
        },
        'Can register own work hour through mobile': 'Tunteja voi kirjata älypuhelimen kautta',
        'Location tracking is required by owner': 'Sijainnin seuranta vaaditaan esimiehen puolesta',
        'Can manage user work hours': 'Voi hallita käyttäjän työtunteja',
        'Edit Work type': 'Muokkaa työlaijia',
        'Work name': 'Työn nimi',
        'Parent Work type': 'Korkeampi työlaiji',
        'Visma Product nr': 'Netvisorin tuotenumero',
        'Work Price': 'Työn hinta',
        Hour: 'Tunti',
        Piece: 'Kappale',
        'Show separately on report': 'Näytä erikseen raportilla',
        'Cost Descriptions': 'Kustannuskuvaukset',
        'Paid Absences': 'Palkallinen poissaolo',
        ':count work items selected': ':count työkohteet valittu',
        Files: 'Tiedostot',
        Save: 'Tallenna',
        'Add Bonus': 'Lisää bonus',
        'Waiting activation': 'Odottaa aktivointia',
        Active: 'Aktiivinen',
        Inactive: 'Inaktiivinen',
        'Search for user': 'Etsi käyttäjä',
        Manager: 'Järjestelmänvalvoja',
        Worker: 'Työntekijä',
        'Add manually': 'Lisää manuaalisesti',
        'Invite user via email': 'Lähetä kutsu',
        'Company Profile': 'Yhtiön profiili',
        Status: 'Tila',
        'Add New': 'Lisää uusi',
        Filters: 'Suodattimet',
        Submit: 'Tallenna',
        Reset: 'Nollaa',
        Workpace: 'Työtila',
        Name: 'Nimi',
        Type: 'Tyyppi',
        Period: 'Jakso',
        Working: 'Töissä',
        'Not Working': 'Ei töissä',
        'Just an empty canvas': 'Vain tyhjä sivu',
        'Try a different date range or filters': { '': 'Kokeile toista päivämäärää tai suodattimia' },
        'Still nothing? Go ahead and track some time': { '': 'Ei vieläkään mitään? Aloita ja seuraa aikoja.' },
        Pause: 'Tauko',
        'End time': 'Loppu',
        'Start time': 'Aloitus',
        'Work type': 'Työlaji',
        Profile: 'Profiili',
        Date: 'Päivämäärä',
        Start: 'Alkaa',
        Duration: 'Kesto',
        Total: 'Yhteensä',
        Confirmed: 'Hyväksytty',
        Salary: 'Palkka',
        'Add time': 'Lisää aikaa',
        "Don't count overtime": 'Älä laske ylitöitä',
        'No files added': 'Tiedostoja ei lisätty',
        'Add files': 'Lisää tiedostoja',
        'Start work': 'Aloita työskentely',
        'Remove end time': 'Poista lopetusaika',
        'Add end time': 'Lisää lopetusaika',
        'Pause length': 'Tauon pituus',
        'Pause start': 'Tauon alku',
        'Work number': 'Työnumero',
        Comment: 'Kommentti',
        ':name :old_value changed to :new_value': ':name :old_value muutettiin :new_value',
        'Birth date': 'Syntymäaika',
        'Document :filename is no longer available': 'Tiedosto :filename ei ole enää saatavilla',
        'No documents added': 'Ei tiedostoja lisätty',
        'Total size of uploaded files:': 'Ladattujen tiedostojen kokonaiskoko',
        'No bonuses added for this user': 'Ei bonuksia lisätty',
        'No salary settings': 'Ei palkkaasetuksia',
        'No paid absences added for this user': 'Ei palkallisia poissaoloja lisätty',
        'To which workspaces can the employee register hours': 'Mihin työtiloihin voi työntekijä ilmoittaa tunnit',
        '* when no workspace is selected, it is allowed to register hours to all workspaces':
          'Kun työtilaa ei ole valittu, voi tunnit rekisteröidä kaikkiin työtiloihin',
        'Can register own work time from computer': 'Työaikoja voi lisätä manuaalisesti',
        '\r\nCan edit own work time': 'Työtunteja voi muuttaa',
        'Can register work hours from login box': 'Työtunteja voi lisätä leimauslaitteella',
        'Can register work hours through mobile': 'Työtunteja voi lisätä älypuhelimella',
        'Can generate reports': 'Voi luoda raportteja',
        '\r\nCan edit own profile': 'Voi muokata omaa profiilia',
        'User has Manager Rights': 'Käyttäjällä on ylläpitäjä oikeudet',
        '\r\nTo which workspaces these permissions apply': 'Mitä työtiloja nämä oikeudet koskevat',
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* kun yhtään työtilaa ei ole valittu, koskevat nämä oikeudet kaikkia työtiloja',
        'Can edit preferences': 'Voi muuttaa asetuksia',
        'Can add/edit users': 'Voi lisätä/muuttaa käyttäjiä',
        'Can generate reports for workers': 'Voi luoda raportteja',
        '\r\nCan add/edit work hours of users': 'Oikeus lisätä/muuttaa käyttäjien työaikoja',
        'Can manage workspaces': 'Oikeus hallita työtiloja',
        'Can manage work types': 'Oikeus hallita työlajieja',
        'Can manage salary extensions': 'Oikeus hallita palkanlaskentaa',
        'Login boxes management': 'Leimauslaitteiden hallinta',
        '\r\nCan confirm work hours': 'Oikeus hyväksyy työtunteja',
        'Add new group': 'Lisää uusi ryhmä',
        'View Details': 'Tarkemmat tiedot',
        'Send worker data': 'Lähetä työntekijän tiedot',
        'Resend activation link': 'Lähetä aktivointilinkki uudestaan',
        Edit: 'Muokkaa',
        Deactivate: 'Inaktiivinen',
        Activate: 'Aktiivinen',
        Send: 'Lähetä',
        'Are you sure you want to :status :name': 'Oletko varma, että haluat :status :name',
        'Please register by entering your email': 'Ole hyvä ja rekisteröidy syöttämällä sähköpostiosoitteesi',
        'Repeat password': 'Toista salasana',
        Password: 'Salasana',
        'Your E-mail': 'Sähköpostinne',
        Language: 'Kieli',
        'Sign up': 'Rekisteröidy',
        'Number of days worked': 'Työpäivien määrä',
        'People not working': 'Ei töissä',
        'People working': 'Töissä',
        'Edit Workspace': 'Muokkaa työtilaa',
        'Delete Workspace': 'Poista työtila',
        'I agree to': 'Hyväksyn',
        'Terms & Condition': 'Käyttöehdot',
        about_login: 'Meistä',
        sign_up: 'Rekisteröidy',
        first_name: 'Etunimi',
        last_name: 'Sukunimi',
        company_name: 'Yrityksen nimi',
        'I allow Hours to e-mail me notifications and updates regarding notifications, raports and services':
          'Annan suostumukseni Houersin sähköposti-ilmoituksille',
        'I agree to Terms & Condition': 'Hyväksyn käyttöehdot',
        login: 'Kirjaudu sisään',
        register: 'Rekisteröidy',
        welcome: 'Tervetuloa Houersiin',
        please_login: 'Ole hyvä ja kirjaudu sisään syöttämällä sähköpostiosoite ja salasana',
        remember_me: 'Muista minut',
        forgot_password: 'Unohduiko salasana?',
        help: 'Apua',
        contact: 'Ota yhteyttä',
        show_time_decimal: 'Näytä aika desimaaleina',
        yesterday: 'Eilen',
        this_week: 'Tämä viikko',
        this_month: 'Tämä kuukausi',
        previous_week: 'Edellinen viikko',
        previous_month: 'Edellinen kuukausi',
        apply: 'Käytä',
        reset: 'Nollaa',
        cancel: 'Peru',
        today: 'Tänään',
        week: 'Viikko',
        month: 'Kuukausi',
        waiting_activation: 'Odotta aktivointia',
        send_email: 'Lähetä sähköposti',
        worker_name: 'Työntekijän nimi',
        delete_worklog_msg: 'Haluatko varmasti poistaa valitut työt?',
        confirm_action: 'Hyväksy',
        confirm_worklog_msg: 'Haluatko varmasti hyväksyä valitut työt?',
        edit_timesheet: 'Muokkaa työtä',
        success_delete_msg: 'Poistettu',
        success_update_msg: 'Päivitetty',
        delete: 'Poista',
        confirm: 'Vahvista',
        add_user: 'Lisää käyttäjä',
        add_workspace: 'Lisää työtila',
        start_timer: 'Aloita työ',
        people_working: 'Töissä',
        people_not_working: 'Ei töissä',
        settings: 'Asetukset',
        preferences: 'Asetukset',
        work_types: 'Työlajit',
        vacation_types: 'Palkalliset vapaat',
        boxes: 'Leimauslaitteet',
        logout: 'Kirjaudu ulos',
        general: 'Yleinen',
        overtime: 'Ylityö',
        work_rounds: 'Työvuorot',
        dashboard: 'Työpöytä',
        timesheet: 'Tuntilista',
        reports: 'Raportit',
        people: 'Henkilöt',
        workspaces: 'Työtilat',
        start_tracking: 'Aloita seuranta ja täytä tyhjät sivut',
        go_ahead: 'Aloita ja seuraa aikoja',
        monthly_working_hours: 'Kuukausittaiset työajat',
        avg_workday_length: 'Keskimääräinen työpäivän pituus',
        clients: 'Asiakkaat',
        logins: 'Kirjautumiset',
        warnings: 'Varoitukset',
        about: 'Meistä',
        Opportunities: 'Mahdollisuudet',
        use_same_preference: 'Käytä samoja asetuksia kaikissa työtiloissa',
        auto_add_pause: 'Aloita tauko automaattisesti jos työ on pidempi kuin',
        choose_lang: 'Valitse kieli',
        auto_end: 'Lopeta työ automaattisesti jos työ on pidempi kuin',
        show_hours: 'Näytä tunnit desimaaleina',
        round_start: 'Pyöristä työn aloitusaika',
        round_end: 'Pyöristä työn lopetusaika',
        name: 'Nimi',
        end: 'Loppu',
        add_work_round: 'Lisää työvuoro',
        add_overtime: 'Lisää ylityöasetus',
        telephone: 'Puhelin',
        address: 'Osoite',
        status: 'Tila',
        role: 'Rooli',
        company: 'Yritys',
        workspace: 'Työtila',
        email: 'Sähköposti',
        options: 'Vaihtoehdot',
        filters: 'Suodattimet',
        working_hours: 'Työtunnit',
        export: 'Vie',
      },
    },
    es: {
      translation: {
        'Save shift': 'Guardar turno',
        'Saved shifts': 'Turnos guardados',
        'Type here': 'Escribe aquí',
        'No users found': 'No se encontraron usuarios',
        'Show events as full-day': 'Mostrar eventos como todo el día',
        'Show holidays': 'Mostrar festivos',
        'No shifts added': 'No se añadieron turnos',
        'Edit shift': 'Editar turno',
        'is missing from work': ':name falta al trabajo.',
        Copy: 'Copiar',
        'No presets added': 'No se añadieron preconfiguraciones',
        'Add task': 'Añadir tarea',
        'Edit repeating task': 'Editar tarea repetitiva',
        'Edit task': 'Editar tarea',
        'Add shift': 'Añadir turno',
        Shifts: 'Turnos',
        'Add new task': 'Añadir nueva tarea',
        'New task': 'Nueva tarea',
        'Task name': 'Nombre de la tarea',
        Task: 'Tarea',
        'Time schedules': 'Horarios',
        Add: 'Añadir',
        'Remove recurring event': 'Eliminar evento recurrente',
        'This event': 'Este evento',
        'This and following unconfirmed events': 'Este y los siguientes eventos sin confirmar',
        'All unconfirmed events': 'Todos los eventos sin confirmar',
        Remove: 'Eliminar',
        'Add new shift': 'Añadir nuevo turno',
        'Add Schedule': 'Añadir horario',
        'Add paid absence': 'Añadir ausencia remunerada',
        Absence: 'Ausencia',
        'Saved presets': 'Preconfiguraciones guardadas',
        'New shift': 'Nuevo turno',
        'Shift name': 'Nombre del turno',
        Repeating: 'Repetir',
        'Save preset': 'Guardar preconfiguración',
        Daily: 'Diario',
        Weekly: 'Semanal',
        Byweekly: 'Quincenal',
        Monthly: 'Mensual',
        Yearly: 'Anual',
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Shift: 'Turno',
        'All day': 'Todo el día',
        'Total pauses during selected period': 'Pausas totales en el período seleccionado',
        Residue: 'Residuo',
        'Pause end': 'Fin de la pausa',
        'Ended work using fingerprint': 'Finalizó el trabajo con huella digital',
        'Started work using fingerprint': 'Comenzó el trabajo con huella digital',
        'Success!': '¡Éxito!',
        'An email has been sent to your registered email address': {
          ' Please check your inbox to ensure receipt': {
            " Don't forget to check your spam/junk folder if you can't find it": {
              ' Thank you!':
                'Se ha enviado un correo electrónico a su dirección de correo electrónico registrada. Por favor, revise su bandeja de entrada para asegurarse de que lo ha recibido. Si no lo encuentra, no olvide revisar su carpeta de spam. ¡Gracias!',
            },
          },
        },
        'Account manager': 'Gerente de cuenta',
        'Change your email': 'Cambie su dirección de correo electrónico',
        'Enter your phone number': 'Ingrese su número de teléfono',
        'Contact us': 'Contáctenos',
        "Let's talk": 'Hablemos',
        'Schedule online meeting': 'Programar reunión en línea',
        'Request callback': 'Solicitar devolución de llamada',
        'Price list': 'Lista de precios',
        'Work is started with full-auto function': 'El trabajo se inicia con función completamente automática',
        'Work is ended with full-auto function': 'El trabajo se finaliza con función completamente automática',
        'Work is started with semi-auto function': 'El trabajo se inicia con función semiautomática',
        'Work is ended with semi-auto function': 'El trabajo se finaliza con función semiautomática',
        'Manually added work using web': 'Trabajo añadido manualmente usando la web',
        'Manually added work using mobile': 'Trabajo añadido manualmente usando móvil',
        Outside: 'Afuera',
        'You have no inactive workers yet': 'Aún no tiene trabajadores inactivos',
        'Show other locations': 'Mostrar otras ubicaciones',
        'Search address': 'Buscar dirección',
        'Reset to current location': 'Restablecer a la ubicación actual',
        'Must be greater than or equal to 100 meters': 'Debe ser mayor o igual a 100 metros',
        'Daily allowance group': 'Grupo de asignación diaria',
        Invites: 'Invitaciones',
        'Conflicting emails': 'Correos electrónicos conflictivos',
        Text: 'Texto',
        Data: 'Datos',
        'workers information': 'información de los trabajadores',
        'This email is sent by :companyName from HOURS management software':
          'Este correo electrónico ha sido enviado por :companyName utilizando el software de gestión HOURS',
        'To answer this email, please use :managerEmail':
          'Para responder a este correo electrónico, por favor use :managerEmail',
        'Please make sure that your password': 'Por favor, asegúrese de que su contraseña',
        'New work type': 'Nuevo tipo de trabajo',
        'Select logo': 'Seleccionar logo',
        'or drag one here': 'o arrastre uno aquí',
        'Workspace name': 'Nombre del espacio de trabajo',
        'Phone number': 'Número de teléfono',
        'Do you want to change your email for verification?':
          '¿Desea cambiar su correo electrónico para la verificación?',
        'Resend verification': 'Reenviar verificación',
        Refresh: 'Actualizar',
        'Already have an account?': '¿Ya tienes una cuenta?',
        'Log in here': 'Inicia sesión aquí',
        'By signing up, you agree to our': 'Al registrarte, aceptas nuestro',
        'terms of service': 'términos de servicio',
        'privacy policy': 'política de privacidad',
        'and to receiving marketing communication from Hours': 'y a recibir comunicaciones de marketing de Hours',
        'You can opt out anytime': 'Puedes darte de baja en cualquier momento',
        'Check your email for the activation link or': 'Revisa tu correo electrónico para el enlace de activación o',
        'Work resumed from being idle at': 'El trabajo se reanudó después de estar inactivo en',
        'Office door setting': 'Configuración de la puerta de la oficina',
        'Automatically stop work after': 'Detener el trabajo automáticamente después de',
        'minutes of idle time': 'minutos de inactividad',
        '* when idle time is not set, work will automatically stop after 60 minutes':
          '* cuando el tiempo de inactividad no está configurado, el trabajo se detendrá automáticamente después de 60 minutos',
        'Work was idle starting at': 'El trabajo estuvo inactivo comenzando en',
        None: 'Ninguno',
        'Normal workday length': 'Longitud normal del día laboral',
        'National holiday settings': 'Configuraciones de días festivos nacionales',
        Calendar: 'Calendario',
        'The previous working day before a national holiday will be shortened':
          'El día laboral anterior a un feriado nacional será acortado',
        'The following fields are missing: ': 'Faltan los siguientes campos: ',
        main: 'principal',
        'Visma Settings': 'Configuraciones de Visma',
        'Show weekend hours separately': 'Mostrar las horas de fin de semana por separado',
        'Show columns': 'Mostrar columnas',
        'Weekend hours': 'Horas de fin de semana',
        'User does not have work type salaries': 'El usuario no tiene salarios por tipo de trabajo',
        'Updated daily allowance': 'Subsidio diario actualizado',
        'Deleted pauses': 'Pausas eliminadas',
        'Round to nearest 15 minutes': 'Redondear al 15 minutos más cercano',
        'Add to login box': 'Añadir al cuadro de inicio de sesión',
        'Work end time': 'Hora de fin del trabajo',
        'Aloita lisäämällä uusi päiväraharyhmä': 'Comience añadiendo un nuevo grupo de asignación diaria',
        'Your daily allowance group is empty': 'Su grupo de asignación diaria está vacío',
        'Use in daily allowance calculation': 'Usar en el cálculo de la asignación diaria',
        'View notification': 'Ver notificación',
        'No allowance': 'Sin asignación',
        'Do nothing': 'No hacer nada',
        Unsubscribe: 'Darse de baja',
        "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser":
          "Si tiene problemas para hacer clic en el botón 'Ver notificación', copie y pegue la URL a continuación en su navegador web",
        'You have a new notification': 'Tienes una nueva notificación',
        'Email verification': 'Verificación de correo electrónico',
        End: 'Fin',
        'Your Hours': {
          'ee invoice is unpaid': {
            ' If you do not pay the bill, your account will be closed on :bill':
              'Tu factura de Hours.ee está sin pagar. Si no pagas la factura, tu cuenta se cerrará el :bill.',
          },
        },
        Equal: 'Igual',
        Down: 'Abajo',
        Up: 'Arriba',
        'Daily allowance': 'Dieta diaria',
        Post: 'Publicar',
        'Group does not have a daily allowance item': 'El grupo no tiene configurado un ítem de dieta diaria',
        'Add daily allowance': 'Agregar configuración de dieta diaria',
        'Daily allowances': 'Dietas diarias',
        View: 'Ver',
        'Total:': 'Total:',
        'User preference updated successfully': 'Preferencias del usuario actualizadas con éxito',
        'Work log updated successfully': 'Registro de trabajo actualizado con éxito',
        'Work paused successfully': 'Trabajo pausado con éxito',
        'Work resumed successfully': 'Trabajo reanudado con éxito',
        'Work stopped successfully': 'Trabajo detenido con éxito',
        'Paid absence created successfully': 'Ausencia remunerada creada con éxito',
        'Semi-automatic (notify user before starting /ending work)':
          'Semiautomático (notificar al usuario antes de comenzar / terminar el trabajo)',
        'Fully-automatic (automatically start/end work)':
          'Totalmente automático (comenzar/terminar el trabajo automáticamente)',
        'User language updated successfully': 'Idioma del usuario actualizado con éxito',
        'Automatic start/end work with Mobile App': 'Inicio/fin de trabajo automático con la App móvil (Beta)',
        'When entering/leaving workspace': 'Al entrar/salir del espacio de trabajo',
        'This set password link will expire 1 week from now':
          'El enlace para establecer la contraseña expirará en 1 semana',
        'Notify manager': 'Notificar al gerente',
        'Work log confirmed successfully': 'Registro de trabajo confirmado con éxito',
        'Options updated successfully': 'Opciones actualizadas con éxito',
        'Add a pause': 'Añadir una pausa',
        'The entered working time is more than 24 hours': {
          ' Are you sure you want to add it?':
            'El tiempo de trabajo ingresado es más de 24 horas. ¿Estás seguro de que quieres añadirlo?',
        },
        'User registered successfully': 'Usuario registrado con éxito',
        'Reset password link sent successfully': 'Enlace para restablecer contraseña enviado con éxito',
        'Added lunch time using general preference': 'Hora de almuerzo añadida usando la preferencia general',
        'Changed pause time to': 'Tiempo de pausa cambiado a',
        'Changed resume time to': 'Tiempo de reanudación cambiado a',
        Percentage: 'Porcentaje',
        added: 'añadido',
        accepted: 'aceptado',
        declined: 'rechazado',
        Doors: 'Puertas',
        'Merit Palk Salary type nr': 'Número de tipo de salario Merit Palk',
        'Conflicting times': 'Tiempos conflictivos',
        'Sent to Netvisor': 'Enviado a Netvisor',
        'Sent to': 'Enviado a',
        'Rounded using general preference': 'Redondeado usando la preferencia general',
        'Rounded end time to': 'Hora de fin redondeada a',
        'Added using general preference': 'Añadido usando la preferencia general',
        'Updated pauses': 'Pausas actualizadas',
        'Confirmed work at': 'Trabajo confirmado en',
        'Uncomfirmed work at': 'Trabajo no confirmado en',
        'Added workspace': 'Espacio de trabajo añadido',
        'Added work type': 'Tipo de trabajo añadido',
        'Added address': 'Dirección añadida',
        'Added pause': 'Pausa añadida',
        'Added comment': 'Comentario añadido',
        'Added bonus': 'Bono añadido',
        'Added bonus comment': 'Comentario de bono añadido',
        to: 'a',
        'Work edited': 'Trabajo editado',
        'Add work type extra': 'Añadir extra de tipo de trabajo',
        'Ends at': 'Termina en',
        'Out of door at': 'Fuera de la puerta en',
        'Resumed work at': 'Trabajo reanudado en',
        'Ended work at': 'Trabajo terminado en',
        'Ended work using door': 'Trabajo terminado usando puerta',
        'Ended work using box': 'Trabajo terminado usando caja',
        'Ended work using mobile': 'Trabajo terminado usando móvil',
        'Ended work using web': 'Trabajo terminado usando web',
        'Rounded start time to': 'Hora de inicio redondeada a',
        'Started work using box': 'Trabajo iniciado usando caja',
        'Started work using door': 'Trabajo iniciado usando puerta',
        'Started work using mobile': 'Trabajo iniciado usando móvil',
        'Started work using web': 'Trabajo iniciado usando web',
        'Started work at': 'Trabajo iniciado en',
        'Entered door': 'Puerta ingresada',
        'Entered door at': 'Ingresó por la puerta en',
        'Paused work at': 'Trabajo pausado en',
        'Out of door': 'Fuera de la puerta',
        'We sent you a verification email to': 'Te hemos enviado un correo electrónico de verificación a',
        "If you don't see the email, please check your spam folder or contact your Organization":
          'Si no ves el correo electrónico, por favor revisa tu carpeta de spam o contacta a tu Organización',
        'Overtime computation': 'Cálculo de horas extras',
        'Selected works have some users with multiple work logs on the same day':
          'Los trabajos seleccionados tienen algunos usuarios con múltiples registros de trabajo en el mismo día',
        'Cannot confirm not ended work': 'No se puede confirmar el trabajo no finalizado',
        'Overlapping paid absences': 'Ausencias pagadas que se solapan',
        'Cannot request with overlapping paid absences': 'No se puede solicitar con ausencias pagadas que se solapan',
        'There is already ongoing unconfirmed request': 'Ya hay una solicitud sin confirmar en curso',
        'Work has been deleted': 'El trabajo ha sido eliminado',
        'Overlapping work rounds': 'Turnos de trabajo que se solapan',
        'There are some errors in exporting merit palk report':
          'Hay algunos errores al exportar el informe de merit palk',
        'There are some errors in exporting netvisor report': 'Hay algunos errores al exportar el informe de netvisor',
        'Dimension updated unsuccessfully': 'La actualización de la dimensión fue fallida',
        'Notification cannot be posted if deleted': 'La notificación no se puede publicar si se ha eliminado',
        'Token already expired': 'El token ya ha expirado',
        'Token is invalid': 'El token es inválido',
        'Token has already been used': 'El token ya ha sido utilizado',
        'User not found': 'Usuario no encontrado',
        'Password Reset Sent': 'Restablecimiento de Contraseña Enviado',
        'Invalid credentials': 'Credenciales inválidas',
        'Please try again or ': 'Por favor, intenta de nuevo o ',
        'Merit Palk Setting': 'Configuración de Merit Palk',
        'API Setting': 'Configuración de API',
        'API Key': 'Clave API',
        'API ID': 'ID de API',
        'Do not count as overtime': 'No contar como horas extra',
        'No notifications created': 'No se han creado notificaciones',
        'Add new notification': 'Añadir nueva notificación',
        'Default work type': 'Tipo de trabajo predeterminado',
        'Automatic start/end work with mobile':
          'Inicio/fin automático del trabajo con móvil al llegar a la zona de trabajo (Beta - Prueba)',
        Radius: 'Radio',
        'Set location': 'Establecer ubicación',
        'People rate': 'Calificación de usuario',
        'Worktype rate': 'Calificación del tipo de trabajo',
        'Show worker number': 'Mostrar número de trabajador',
        'Reset Your Password': 'Restablecer tu contraseña',
        'Back to login': 'Volver al inicio de sesión',
        'More details': 'Más detalles',
        'Showing rows: :current out of :total': 'Mostrando filas: :current de :total',
        'We use cookies to ensure you get the best experience on our site':
          'Usamos cookies para asegurarnos de que obtengas la mejor experiencia en nuestro sitio.',
        'Start by adding new allowed pause group': 'Comienza añadiendo un nuevo grupo de pausas permitidas',
        'Group does not have an allowed pause item': 'El grupo no tiene un ítem de pausa permitido',
        'Add allowed pause': 'Añadir pausa permitida',
        'Allowed pause group': 'Grupo de pausas permitidas',
        'Work pause': 'Pausa de trabajo',
        'Work end': 'Fin del trabajo',
        'Work start time': 'Hora de inicio del trabajo',
        'Round time': 'Redondear tiempo',
        'Lunch time': 'Hora de almuerzo',
        minutes: 'minutos',
        'before work end time to round up': 'antes de la hora de fin de trabajo para redondear hacia arriba',
        'after work end time to round down': 'después de la hora de fin de trabajo para redondear hacia abajo',
        'Your allowed pause group is empty': 'Tu grupo de pausas permitidas está vacío',
        'Update doors': 'Actualizar puertas',
        'When exiting': 'Al salir',
        'Edit door': 'Editar puerta',
        'Door access': 'Acceso por puerta',
        'Main door': 'Puerta principal',
        'must be accessed to start work': 'debe accederse para comenzar el trabajo',
        'Front door': 'Puerta delantera',
        'Change work type when entering': 'Cambiar tipo de trabajo al entrar',
        'Work resume when entering': 'Reanudar trabajo al entrar',
        Request: 'Solicitud',
        'View Bonus': 'Ver Bono',
        Pending: 'Pendiente',
        Approved: 'Aprobado',
        Declined: 'Rechazado',
        'Expired activation': 'Activación expirada',
        'Inner Range Doors': 'Puertas de Alcance Interno',
        'Doors updated': 'Puertas actualizadas',
        'Door ID': 'ID de la puerta',
        'Request Bonus': 'Solicitar Bono',
        'Can accept/edit/decline requests': 'Puede aceptar/editar/rechazar solicitudes',
        'Switching Account': 'Cambiando de Cuenta',
        'Past two weeks': 'Últimas dos semanas',
        ':worker has requested a bonus of :bonus': ':worker ha solicitado un bono de :bonus',
        'has requested a paid absence': 'ha solicitado una ausencia remunerada',
        'User preference': 'Preferencias del usuario',
        'Sorting order': 'Orden de clasificación',
        'First name, Last name': 'Nombre, Apellido',
        'Last name, First name': 'Apellido, Nombre',
        'Allowed pauses': 'Pausas permitidas',
        Late: 'Tarde',
        'You have been added to Hours time tracking':
          'Has sido añadido a la plataforma de seguimiento de tiempo Hours.',
        'Fetch records': 'Obtener registros',
        'has requested a bonus of': 'ha solicitado un bono de',
        Posted: 'Publicado',
        'Ready for posting': 'Listo para publicar',
        Draft: 'Borrador',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'cuando no se selecciona ningún usuario, las notificaciones se enviarán a todos los usuarios de la empresa o en los espacios de trabajo seleccionados.',
        'Draft notification message': 'Guardar el mensaje de notificación como borrador',
        Requests: 'Solicitudes',
        'No bonus requests yet': 'Aún no hay solicitudes de bono',
        'No paid absence requests yet': 'Aún no hay solicitudes de ausencia remunerada',
        'Paid absence': 'Ausencia remunerada',
        'Bonus comment': 'Comentario del bono',
        'Original requested amount': 'Cantidad solicitada original',
        'New amount': 'Nueva cantidad',
        'Request paid absence': 'Solicitar ausencia remunerada',
        'Requested at': 'Solicitado en',
        'Started at': 'Comenzado en',
        'Ended at': 'Finalizado en',
        'Confirmed at': 'Confirmado en',
        'Confirmed by': 'Confirmado por',
        'Work date': 'Fecha de trabajo',
        'You have no rights to edit work time!': '¡No tienes derechos para editar el tiempo de trabajo!',
        'Notify through push notification': 'Notificar mediante notificación push',
        'Notify through email': 'Notificar mediante correo electrónico',
        Notifications: 'Notificaciones',
        'Created by': 'Creado por',
        'Posted at': 'Publicado en',
        Title: 'Título',
        Repost: 'Re-publicar',
        'Add notification': 'Añadir notificación',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'cuando no se selecciona ningún espacio de trabajo, las notificaciones se enviarán a todos los usuarios de la empresa',
        'Company notifications': 'Notificaciones de la empresa',
        'Worker is working overtime': 'El trabajador está haciendo horas extra',
        'Worker is asking for a bonus': 'El trabajador está solicitando un bono',
        'Worker is requesting for an absence': 'El trabajador está solicitando una ausencia',
        'Worker has arrived/left from the workspace': 'El trabajador ha llegado/salido del espacio de trabajo',
        'Manager has added/accepted/declined a bonus': 'El gerente ha añadido/aceptado/rechazado un bono',
        'Manager has added/accepted/declined a paid absence':
          'El gerente ha añadido/aceptado/rechazado una ausencia remunerada',
        'Work paused/ended automatically through workspace preference':
          'El trabajo se ha pausado/finalizado automáticamente a través de la preferencia del espacio de trabajo',
        Latest: 'Más reciente',
        'This week': 'Esta semana',
        Older: 'Más antiguo',
        'You have declined this request!': '¡Has rechazado esta solicitud!',
        'You have already approved this request!': '¡Ya has aprobado esta solicitud!',
        Accept: 'Aceptar',
        Decline: 'Rechazar',
        'Mark as read': 'Marcar como leído',
        'Turn off receiving this status': 'Desactivar la recepción de este estado',
        'Delete notification': 'Eliminar notificación',
        'Mark all as read': 'Marcar todo como leído',
        'View full feed': 'Ver el feed completo',
        'Clear all': 'Limpiar todo',
        Read: 'Leer',
        Close: 'Cerrar',
        Clear: 'Limpiar',
        'No notifications yet': 'Aún no hay notificaciones',
        'Turn on/off notifications': 'Activar/desactivar notificaciones',
        'Weekly working hours': 'Horas de trabajo semanales',
        'Show more': 'Mostrar más',
        'Show less': 'Mostrar menos',
        'No email': 'Sin correo electrónico',
        'Complete registration by choosing a password': 'Completa el registro eligiendo una contraseña',
        'Your First Name': 'Tu nombre',
        'Your Last Name': 'Tu apellido',
        'Your Password': 'Tu contraseña',
        'Confirm Your Password': 'Confirma tu contraseña',
        "Please register by entering you're email": 'Por favor, registra ingresando tu correo electrónico',
        Location: 'Ubicación',
        'Must have at least 8 characters': 'Debe tener al menos 8 caracteres',
        'Must start with alphanumeric character': 'Debe comenzar con un carácter alfanumérico',
        'Must have at least one lowercase character': 'Debe tener al menos un carácter en minúscula',
        'Must have at least one uppercase character': 'Debe tener al menos un carácter en mayúscula',
        'Must have at least one digit': 'Debe tener al menos un dígito',
        'Passwords must match': 'Las contraseñas deben coincidir',
        'Must Accept Terms and Condition': 'Debe aceptar los Términos y Condiciones',
        'This field is required': 'Este campo es obligatorio',
        ':field is required': 'el campo es obligatorio',
        by: 'por',
        'Total salary': 'Salario total',
        'Paid pause': 'Pausa pagada',
        'Work type extras': 'Extras de tipo de trabajo',
        Basic: 'Básico',
        'Basic salary': 'Salario básico',
        Week: 'Semana',
        Rate: 'Tarifa',
        'Worker is missing identification number':
          'Al trabajador le falta el número de identificación. Por favor, añade el número del trabajador en el perfil del usuario',
        'Your paid absences is empty': 'No tienes ausencias remuneradas',
        'Start by adding new paid absences': 'Comienza añadiendo nuevas ausencias remuneradas',
        'Invite user': 'Invitar usuario',
        'Group does not have a work round item': 'El grupo no tiene un ítem de ronda de trabajo',
        'Group does not have an overtime item': 'El grupo no tiene un ítem de horas extra',
        'Upload Company Logo': 'Subir Logo de la Empresa',
        'Upload Profile Image': 'Subir Imagen de Perfil',
        'Drag and drop your image here or': 'Arrastra y suelta tu imagen aquí o',
        'upload a photo': 'sube una foto',
        'Single image must not exceed 5MB': 'La imagen no debe exceder de 5MB',
        'Business Area': 'Área de Negocio',
        'Start by adding new work round group': 'Comienza añadiendo un nuevo grupo de ronda de trabajo',
        'Your work round group is empty': 'Tu grupo de ronda de trabajo está vacío',
        'Your overtime group is empty': 'Tu grupo de horas extra está vacío',
        File: 'Archivo',
        "This does not affect users' existing paid absences":
          'Esto no afecta las ausencias remuneradas existentes de los usuarios',
        'View document': 'Ver documento',
        'Rename document': 'Renombrar documento',
        'Delete document': 'Eliminar documento',
        'File name': 'Nombre del archivo',
        'File type': 'Tipo de archivo',
        'File size': 'Tamaño del archivo',
        'Last date modified': 'Última fecha de modificación',
        'accepts comma-separated input': 'acepta entrada separada por comas',
        "Press 'Enter'": "Presiona 'Enter'",
        Emails: 'Correos electrónicos',
        'Invite users by email': 'Invitar usuarios por correo electrónico',
        'Add work type': 'Añadir tipo de trabajo',
        'View work types': 'Ver tipos de trabajo',
        'User has been deleted': 'El usuario ha sido eliminado',
        'User information cannot be viewed': 'La información del usuario no puede ser vista',
        'Go back to People page': 'Volver a la página de Personas',
        'The work has been updated': {
          ' Please confirm to apply changes':
            'El trabajo ha sido actualizado. Por favor, confirma para aplicar los cambios',
        },
        'Start by adding new work type': 'Comienza añadiendo un nuevo tipo de trabajo',
        'Account verification': 'Verificación de cuenta',
        Hello: 'Hola',
        'Verify Email Address': 'Verificar dirección de correo electrónico',
        'Best regards': 'Saludos cordiales',
        Team: 'Equipo',
        'All rights reserved': 'Todos los derechos reservados',
        'Complete registration': 'Completa el registro',
        from: 'de',
        'Set Password': 'Establecer contraseña',
        'Download also Hours mobile apps': 'Descarga también las aplicaciones móviles de Hours',
        'Welcome to': 'Bienvenido a',
        'You are receiving this email because we received a reset password request for your account':
          'Estás recibiendo este correo electrónico porque recibimos una solicitud de restablecimiento de contraseña para tu cuenta',
        'Reset Password': 'Restablecer contraseña',
        'Your work type is empty': 'Tu tipo de trabajo está vacío',
        'Dreaming of a new box?': '¿Soñando con una nueva caja?',
        'Are you sure you want to update selected work logs?':
          '¿Estás seguro de que quieres actualizar los registros de trabajo seleccionados?',
        'Round to nearest hour': 'Redondear a la hora más cercana',
        'Round to nearest half-hour': 'Redondear a la media hora más cercana',
        'Round to nearest 10 minutes': 'Redondear a los 10 minutos más cercanos',
        'Still having a trouble?  Please contact administrator':
          '¿Sigues teniendo problemas? Por favor, contacta al administrador.',
        'Edit group': 'Editar grupo',
        'Overtime group': 'Grupo de horas extra',
        'Work round group': 'Grupo de ronda de trabajo',
        'Are you sure you want to remove profile image?': '¿Estás seguro de que quieres eliminar la imagen de perfil?',
        'Do not group': 'No agrupar',
        'Grouped by people': 'Agrupado por personas',
        'Are you sure you want to bulk activate/deactivate?':
          '¿Estás seguro de que quieres activar/desactivar en masa?',
        'Some selected work logs are already confirmed!':
          '¡Algunos de los registros de trabajo seleccionados ya están confirmados!',
        'Round work time': 'Redondear tiempo de trabajo',
        Subject: 'Asunto',
        'Number of weeks': 'Número de semanas',
        'Number of months': 'Número de meses',
        'Daily normal hours': 'Horas normales diarias',
        'Weekly normal hours': 'Horas normales semanales',
        'Monthly normal hours': 'Horas normales mensuales',
        'Are you sure want to switch as :type?': '¿Estás seguro de querer cambiar como :type?',
        'This action cannot be undone!': '¡Esta acción no se puede deshacer!',
        'Work type workspaces': 'Espacios de trabajo de tipo de trabajo',
        'People workspaces': 'Espacios de trabajo de personas',
        'Working Period': 'Período de trabajo',
        From: 'Desde',
        'Send copy': 'Enviar copia',
        'weekly overtime': 'horas extra semanales',
        'Monthly overtime': 'Horas extra mensuales',
        'monthly overtime': 'horas extra mensuales',
        'working hours': 'horas de trabajo',
        total: 'total',
        hours: 'horas',
        days: 'días',
        'Total working days': 'Días laborales totales',
        'Created at': 'Creado en',
        Sunday: 'Domingo',
        Saturday: 'Sábado',
        Map: 'Mapa',
        History: 'Historia',
        Overview: 'Resumen',
        Company: 'Empresa',
        'Average workday length': 'Longitud promedio del día laboral este mes',
        'Total hours during selected period': 'Horas totales durante el período seleccionado',
        report: 'informe',
        'Work types': 'Tipos de trabajo',
        'Paid absences': 'Ausencias pagadas',
        'Work rounds': 'Rondas de trabajo',
        'Daily overtime': 'Horas extra diarias',
        'Weekly overtime': 'Horas extra semanales',
        'Default pause length': 'Longitud de pausa predeterminada',
        'Include in overtime computation': 'Incluir en el cálculo de horas extra',
        'Total time': 'Tiempo total',
        'Add user': 'Añadir usuario',
        'Edit user': 'Editar usuario',
        'Edit users': 'Editar usuarios',
        Pauses: 'Pausas',
        'Confirmation action': 'Acción de confirmación',
        'Edit Box': 'Editar caja',
        'Main Screen Type': 'Tipo de pantalla principal',
        Message: 'Mensaje',
        'With worker list': 'Con lista de trabajadores',
        'Without worker list': 'Sin lista de trabajadores',
        'Add Box': 'Añadir caja',
        "Workspaces who's people are visible in the box": 'Espacios de trabajo cuyas personas son visibles en la caja',
        Day: 'Día',
        'Choose type': 'Elegir tipo',
        Until: 'Hasta',
        'Do not count as workday': 'No contar como día laboral',
        'Time unit': 'Unidad de tiempo',
        Length: 'Longitud',
        'Edit Paid Absence': 'Editar ausencia pagada',
        'New Paid Absence': 'Nueva ausencia pagada',
        'Add new': 'Añadir nuevo',
        'Add new paid absence': 'Añadir nueva ausencia pagada',
        'User ID': 'ID de usuario',
        'User key': 'Clave de usuario',
        'Division specific settings': 'Configuraciones específicas de división',
        'Update Dimensions': 'Actualizar dimensiones',
        'Dimensions updated': 'Dimensiones actualizadas',
        'Netvisor Dimensions': 'Dimensiones de Netvisor',
        'Connection Settings': 'Configuraciones de conexión',
        'Netvisor Settings': 'Configuraciones de Netvisor',
        'Business ID': 'ID de negocio',
        'User Profile': 'Perfil de usuario',
        'Workspace does not have a worker': 'El espacio de trabajo no tiene un trabajador',
        'Registry code': 'Código de registro',
        'Contact Information': 'Información de contacto',
        Phone: 'Teléfono',
        'Start from': 'Comenzar desde',
        'User type': 'Tipo de usuario',
        'General Rights': 'Derechos generales',
        'Can register work hour from login box': 'Puede registrar horas de trabajo desde la caja de inicio de sesión',
        'Is visible on login box': 'Es visible en la caja de inicio de sesión',
        'Start of employment': 'Inicio del empleo',
        'End of employment': 'Fin del empleo',
        'Netvisor export identification': 'Identificación de exportación de Netvisor',
        'Worker identification in Netvisor export': 'Identificación del trabajador en la exportación de Netvisor',
        'Worker number': 'Número de trabajador',
        Information: 'Información',
        Rights: 'Derechos',
        Personal: 'Personal',
        'Add another number': 'Añadir otro número',
        'Add another address': 'Añadir otra dirección',
        'ID Code': 'Código de identificación',
        'TAX Number': 'Número de impuesto',
        'Date of birth': 'Fecha de nacimiento',
        Generic: 'Genérico',
        Deleted: 'Eliminado',
        Actions: 'Acciones',
        'Add Paid Absence': 'Añadir ausencia pagada',
        ':count worker is selected': ':count trabajador seleccionado',
        ':count workers are selected': ':count trabajadores seleccionados',
        'Send workers data': 'Enviar datos de los trabajadores',
        'Send email to worker': 'Enviar correo electrónico al trabajador',
        'Hide all days': 'Ocultar todos los días',
        'Show all days': 'Mostrar todos los días',
        Dimensions: 'Dimensiones',
        'Working hours are not confirmed': 'Las horas de trabajo no están confirmadas',
        'Product number': 'Número de producto',
        Hours: 'Horas',
        'Export as :type': 'Exportar como :type',
        'Collapse all rows': 'Colapsar todas las filas',
        'Expand all rows': 'Expandir todas las filas',
        All: 'Todo',
        'Confirmed hours': 'Horas confirmadas',
        'Unconfirmed hours': 'Horas no confirmadas',
        'Group by': 'Agrupar por',
        'Report options': 'Opciones de informe',
        'Total hours': 'Horas totales',
        'Total days': 'Días totales',
        'Average day': 'Día promedio',
        'Total paid absence': 'Total de ausencias pagadas',
        Bonuses: 'Bonos',
        Unconfirm: 'Desconfirmar',
        Edited: 'Editado',
        Tags: 'Etiquetas',
        Worktype: 'Tipo de trabajo',
        'Are you sure you want to :status this work log?':
          '¿Estás seguro de que quieres :status este registro de trabajo?',
        'Are you sure you want to delete this work log?':
          '¿Estás seguro de que quieres eliminar este registro de trabajo?',
        'Are you sure you want to delete :name work log?':
          '¿Estás seguro de que quieres eliminar el registro de trabajo de :name?',
        'Delete Timesheet': 'Eliminar hoja de tiempos',
        'Are you sure you want to delete :name?': '¿Estás seguro de que quieres eliminar a :name?',
        Amount: 'Cantidad',
        'Edit Bonus': 'Editar bono',
        'Are you sure you want to confirm :name work log?':
          '¿Estás seguro de que quieres confirmar el registro de trabajo de :name?',
        'Are you sure you want to unconfirm :name work log?':
          '¿Estás seguro de que quieres desconfirmar el registro de trabajo de :name?',
        'Worker Comment': 'Comentario del trabajador',
        Bonus: 'Bono',
        'Breaks Total': 'Total de pausas',
        'Add :label': 'Añadir :label',
        'No :label added': 'No se añadió :label',
        'Can register own work hour through mobile': 'Puede registrar su propia hora de trabajo a través del móvil',
        'Location tracking is required by owner': 'El seguimiento de ubicación es requerido por el propietario',
        'Can manage user work hours': 'Puede gestionar las horas de trabajo de los usuarios',
        'Edit Work type': 'Editar tipo de trabajo',
        'Work name': 'Nombre del trabajo',
        'Parent Work type': 'Tipo de trabajo superior',
        'Visma Product nr': 'Número de producto Visma',
        'Work Price': 'Precio del trabajo',
        Hour: 'Hora',
        Piece: 'Pieza',
        'Show separately on report': 'Mostrar por separado en el informe',
        'Cost Descriptions': 'Descripciones de Costo',
        'Paid Absences': 'Ausencias Remuneradas',
        ':count work items selected': ':count elementos de trabajo seleccionados',
        Files: 'Archivos',
        Save: 'Guardar',
        'Add Bonus': 'Agregar Bono',
        'Waiting activation': 'Esperando activación',
        Active: 'Activo',
        Inactive: 'Inactivo',
        'Search for user': 'Buscar usuario',
        Manager: 'Gerente',
        Worker: 'Trabajador',
        'Add manually': 'Agregar manualmente',
        'Invite user via email': 'Invitar usuario por correo electrónico',
        'Company Profile': 'Perfil de la Empresa',
        Status: 'Estado',
        'Add New': 'Agregar Nuevo',
        Filters: 'Filtros',
        Submit: 'Enviar',
        Reset: 'Restablecer',
        Workpace: 'Espacio de Trabajo',
        Name: 'Nombre',
        Type: 'Tipo',
        Period: 'Período',
        Working: 'Trabajando',
        'Not Working': 'No Trabajando',
        'Just an empty canvas': 'Solo un lienzo vacío',
        Pause: 'Pausa',
        'End time': 'Hora de Fin',
        'Start time': 'Hora de Inicio',
        'Work type': 'Tipo de Trabajo',
        Profile: 'Perfil',
        Date: 'Fecha',
        Start: 'Inicio',
        Duration: 'Duración',
        Total: 'Total',
        Confirmed: 'Confirmado',
        Salary: 'Salario',
        'Add time': 'Agregar tiempo',
        "Don't count overtime": 'No contar horas extras',
        'No files added': 'No se han agregado archivos',
        'Add files': 'Agregar archivos',
        'Start work': 'Iniciar trabajo',
        'Remove end time': 'Eliminar hora de fin',
        'Add end time': 'Agregar hora de fin',
        'Pause length': 'Duración de la pausa',
        'Pause start': 'Inicio de la pausa',
        'Work number': 'Número de trabajo',
        Comment: 'Comentario',
        ':name :old_value changed to :new_value': ':name :old_value cambiado a :new_value',
        'Birth date': 'Fecha de nacimiento',
        'Document :filename is no longer available': 'El documento :filename ya no está disponible',
        'No documents added': 'No se han agregado documentos',
        'Total size of uploaded files:': 'Tamaño total de los archivos subidos:',
        'No bonuses added for this user': 'No se han agregado bonos para este usuario',
        'No salary settings': 'No hay configuraciones de salario',
        'No paid absences added for this user': 'No se han agregado ausencias pagadas para este usuario',
        'To which workspaces can the employee register hours':
          'En qué espacios de trabajo puede el empleado registrar horas',
        '* when no workspace is selected, it is allowed to register hours to all workspaces':
          '* cuando no se selecciona ningún espacio de trabajo, se permite registrar horas en todos los espacios de trabajo',
        'Can register own work time from computer': 'Puede registrar su propio tiempo de trabajo desde el ordenador',
        '\r\nCan edit own work time': 'Puede editar su propio tiempo de trabajo',
        'Can register work hours from login box': 'Puede registrar horas de trabajo desde la caja de inicio de sesión',
        'Can register work hours through mobile': 'Puede registrar horas de trabajo a través del móvil',
        'Can generate reports': 'Puede generar informes',
        '\r\nCan edit own profile': 'Puede editar su propio perfil',
        'User has Manager Rights': 'El usuario tiene Derechos de Gerente',
        '\r\nTo which workspaces these permissions apply': 'A qué espacios de trabajo se aplican estos permisos',
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* cuando no se selecciona ningún espacio de trabajo, estos permisos se aplican a todos los espacios de trabajo',
        'Can edit preferences': 'Puede editar preferencias',
        'Can add/edit users': 'Puede añadir/editar usuarios',
        'Can generate reports for workers': 'Puede generar informes para los trabajadores',
        '\r\nCan add/edit work hours of users': 'Puede añadir/editar las horas de trabajo de los usuarios',
        'Can manage workspaces': 'Puede gestionar espacios de trabajo',
        'Can manage work types': 'Puede gestionar tipos de trabajo',
        'Can manage salary extensions': 'Puede gestionar extensiones de salario',
        'Login boxes management': 'Gestión de cajas de inicio de sesión',
        '\r\nCan confirm work hours': 'Puede confirmar horas de trabajo',
        'Add new group': 'Añadir nuevo grupo',
        'View Details': 'Ver Detalles',
        'Send worker data': 'Enviar datos del trabajador',
        'Resend activation link': 'Reenviar enlace de activación',
        Edit: 'Editar',
        Deactivate: 'Desactivar',
        Activate: 'Activar',
        Send: 'Enviar',
        'Are you sure you want to :status :name': '¿Estás seguro de que quieres :status :name?',
        'Please register by entering your email': 'Por favor, regístrese ingresando su correo electrónico',
        'Repeat password': 'Repetir contraseña',
        Password: 'Contraseña',
        'Your E-mail': 'Tu correo electrónico',
        Language: 'Idioma',
        'Sign up': 'Registrarse',
        'Number of days worked': 'Número de días trabajados',
        'People not working': 'Personas que no trabajan',
        'People working': 'Personas que trabajan',
        'Edit Workspace': 'Editar Espacio de Trabajo',
        'Delete Workspace': 'Eliminar Espacio de Trabajo',
        'I agree to': 'Estoy de acuerdo con',
        'Terms & Condition': 'Términos y Condiciones',
        about_login: 'Acerca de',
        sign_up: 'Registrarse',
        first_name: 'Nombre',
        last_name: 'Apellido',
        company_name: 'Nombre de la empresa',
        'I agree to Terms & Condition': 'Acepto los Términos y Condiciones',
        login: 'Iniciar sesión',
        register: 'Registrarse',
        welcome: 'Bienvenido a Hours',
        please_login: 'Por favor, inicie sesión ingresando su correo electrónico y contraseña',
        remember_me: 'Recuérdame',
        forgot_password: '¿Olvidaste la contraseña?',
        help: 'Ayuda',
        contact: 'Contacto',
        show_time_decimal: 'Mostrar tiempo en decimales',
        yesterday: 'Ayer',
        this_week: 'Esta semana',
        this_month: 'Este mes',
        previous_week: 'Semana anterior',
        previous_month: 'Mes anterior',
        apply: 'Aplicar',
        reset: 'Restablecer',
        cancel: 'Cancelar',
        today: 'Hoy',
        week: 'Semana',
        month: 'Mes',
        waiting_activation: 'Esperando activación',
        send_email: 'Enviar correo electrónico',
        worker_name: 'Nombre del trabajador',
        delete_worklog_msg: '¿Estás seguro de que quieres eliminar el trabajo seleccionado?',
        confirm_action: 'Confirmar',
        confirm_worklog_msg: '¿Estás seguro de que quieres confirmar los trabajos seleccionados?',
        edit_timesheet: 'Editar hoja de tiempo',
        success_delete_msg: 'Eliminado',
        success_update_msg: 'Actualizado',
        delete: 'Eliminar',
        confirm: 'Confirmar',
        add_user: 'Agregar usuario',
        add_workspace: 'Agregar espacio de trabajo',
        start_timer: 'Iniciar temporizador',
        people_working: 'Personas trabajando',
        people_not_working: 'Personas no trabajando',
        settings: 'Configuraciones',
        preferences: 'Preferencias',
        work_types: 'Tipos de trabajo',
        vacation_types: 'Tipos de ausencia',
        boxes: 'Cajas de inicio de sesión',
        logout: 'Cerrar sesión',
        general: 'General',
        overtime: 'Horas extras',
        work_rounds: 'Rondas de trabajo',
        dashboard: 'Tablero',
        timesheet: 'Hoja de tiempo',
        reports: 'Informes',
        people: 'Personas',
        workspaces: 'Espacios de trabajo',
        start_tracking: 'Comienza a registrar tiempo y a llenar las hojas vacías',
        go_ahead: 'Adelante, comienza a registrar tiempo',
        monthly_working_hours: 'Horas de trabajo mensuales',
        avg_workday_length: 'Longitud promedio del día de trabajo este mes',
        clients: 'Clientes',
        logins: 'Inicios de sesión',
        warnings: 'Advertencias',
        about: 'Acerca de',
        Opportunities: 'Oportunidades',
        use_same_preference: 'Usar las mismas preferencias para todos los espacios de trabajo',
        auto_add_pause: 'Agregar pausa automáticamente después de',
        choose_lang: 'Elige idioma',
        auto_end: 'Finalizar el día de trabajo automáticamente después de',
        show_hours: 'Mostrar horas en decimales',
        round_start: 'Redondear hora de inicio',
        round_end: 'Redondear hora de fin',
        name: 'Nombre',
        end: 'Fin',
        add_work_round: 'Agregar ronda de trabajo',
        add_overtime: 'Agregar configuración de horas extras',
        telephone: 'Teléfono',
        address: 'Dirección',
        status: 'Estado',
        role: 'Rol',
        company: 'Empresa',
        workspace: 'Espacio de trabajo',
        email: 'Correo electrónico',
        options: 'Opciones',
        filters: 'Filtros',
        working_hours: 'Horas de trabajo',
        export: 'Exportar',
      },
    },
    et: {
      translation: {
        'Holiday extra': 'Riigipüha tundide kordaja',
        'Starting month of overtime calculation of a period': 'Ületunnitöö arvestamise perioodi alguskuu',
        'Overtime multiplier': 'Ületunnitöö kordaja',
        ':worker is missing from work': { ' (:start - :end)': ':worker puudub töölt. (:start - :end)' },
        'Please wait while vour request is being processed': 'Palun oodake, töögraafikute värskendamine käib',
        'Save shift': 'Salvesta vahetus',
        'Saved shifts': 'Salvestatud vahetused',
        'Type here': 'Kirjuta siia',
        'No users found': 'Ei leitud ühtegi kasutajat',
        'Show events as full-day': 'Kuva sündmused päevapikkustena',
        'Show holidays': 'Näita pühasid',
        'No shifts added': 'Vahetusi pole lisatud',
        'Edit shift': 'Muuda vahetust',
        'is missing from work': ':name puudub töölt.',
        Copy: 'Kopeeri',
        'No presets added': 'Eelseadeid pole lisatud',
        'Add task': 'Lisa ülesanne',
        'Edit repeating task': 'Korduva ülesande muutmine',
        'Edit task': 'Muuda',
        'Add shift': 'Lisa vahetus',
        Shifts: 'Vahetused',
        'Add new task': 'Lisa uus ülesanne',
        'New task': 'Uus ülesanne',
        'Task name': 'Ülesande nimi',
        Task: 'Ülesanne',
        'Time schedules': 'Töögraafik',
        Add: 'Lisa',
        'Remove recurring event': 'Eemalda korduv sündmus',
        'This event': 'See sündmus',
        'This and following unconfirmed events': 'See ja järgmised kinnitamata sündmused',
        'All unconfirmed events': 'Kõik kinnitamata sündmused',
        Remove: 'Eemalda',
        'Add new shift': 'Lisa uus vahetus',
        'Add Schedule': 'Lisa sündmus',
        'Add paid absence': 'Lisa töökatkestus',
        Absence: 'Töökatkestus',
        'Saved presets': 'Salvestatud eelseaded',
        'New shift': 'Uus vahetus',
        'Shift name': 'Vahetuse nimetus',
        Repeating: 'Kordamine',
        'Save preset': 'Salvesta eelseade',
        Daily: 'Päevane',
        Weekly: 'Nädalane',
        Byweekly: 'Üle nädala',
        Monthly: 'Kuine',
        Yearly: 'Aastane',
        Monday: 'Esmaspäev',
        Tuesday: 'Teisipäev',
        Wednesday: 'Kolmapäev',
        Thursday: 'Neljapäev',
        Friday: 'Reede',
        Shift: 'Vahetus',
        'All day': 'Terve päev',
        'Total pauses during selected period': 'Pausid kokku valitud perioodil',
        Residue: 'Jääk',
        'Pause end': 'Pausi lõpp',
        'Ended work using fingerprint': 'Lõpetas töö sõrmejälje abil',
        'Started work using fingerprint': 'Alustas tööd sõrmejälje abil',
        'Success!': 'Tehtud!',
        'Your request has been received': {
          ' We will be calling you at the registered phone number shortly': {
            ' Please ensure that your phone is on and within reach': {
              ' Thank you for your patience': {
                '':
                  'Teie päring on saadud. Helistame teile varsti registreeritud telefoninumbril. Palun veenduge, et teie telefon on sisse lülitatud ja käeulatuses. Täname teid kannatlikkuse eest.',
              },
            },
          },
        },
        'An email has been sent to your registered email address': {
          ' Please check your inbox to ensure receipt': {
            " Don't forget to check your spam/junk folder if you can't find it": {
              ' Thank you!':
                'E-kiri on saadetud teie registreeritud e-posti aadressile. Palun kontrollige oma postkasti, et kinnitada selle kättesaamine. Kui te ei leia seda, ärge unustage kontrollida oma rämpsposti kausta. Tänan!',
            },
          },
        },
        'Account manager': 'Kontohaldur',
        'Your email address has not been confirmed': { '': 'Teie e-posti aadressi ei ole kinnitatud' },
        'Change your email': 'Muuda oma e-posti aadressi',
        'Enter your phone number': 'Sisesta oma telefoni number',
        'Contact us': 'Võta ühendust',
        "Let's talk": 'Hea meelega räägime!',
        'Schedule online meeting': 'Broneeri online demo',
        'Request callback': 'Jäta tagasihelistamise soov',
        'Price list': 'Hinnakiri',
        'Work is started with full-auto function': 'Töö alustati täisautomaatse funktsiooniga',
        'Work is ended with full-auto function': 'Töö lõpetati täisautomaatse funktsiooniga',
        'Work is started with semi-auto function': 'Tööd alustati poolautomaatse funktsiooniga',
        'Work is ended with semi-auto function': 'Töö lõpetati poolautomaatse funktsiooniga',
        'Manually added work using web': 'Käsitsi lisatud töö veebi abil',
        'Manually added work using mobile': 'Käsitsi lisatud tööd mobiili abil',
        Outside: 'Väljaspool',
        'You have no deleted workers vet': 'Teil pole veel kustutatud kasutajaid',
        'You have no inactive workers yet': 'Teil pole veel mitteaktiivseid kasutajaid',
        'Show other locations': 'Näita teisi asukohti',
        'Search address': 'Otsi aadressi',
        'Reset to current location': 'Lähtesta praegusele asukohale',
        'Must be greater than or equal to 100 meters': 'Peab olema suurem või võrdne 100 meetriga',
        'Daily allowance group': 'Päevarahade grupp',
        Invites: 'Kutse',
        'The following email addresses are already associated with an account in our system and could not be invited again': {
          '': 'Järgmised e-posti aadressid on meie süsteemis juba seotud kontoga ja neid ei saa uuesti kutsuda.',
        },
        'The other invitations have been sent successfully': {
          ' If you need further assistance, please contact our support team': {
            '': 'Ülejäänud kutsed on edukalt saadetud. Kui vajate täiendavat abi, võtke ühendust meie tugimeeskonnaga.',
          },
        },
        'Conflicting emails': 'Vastuolulised meilid',
        Text: 'Sõnum',
        Data: 'Andmed',
        'workers information': 'kasutajate andmed',
        'This email is sent by :companyName from HOURS management software':
          'Selle e-maili on saadetud ettevõte :companyName poolt kasutades HOURS tööaja arvestuse tarkvara',
        'To answer this email, please use :managerEmail': 'Selle e-kirja vastamiseks kasutage :managerEmail',
        'Please make sure that your password': 'Palun veenduge, et teie parool sisaldaks',
        'New work type': 'Uus tööliik',
        'Select logo': 'Vali pilt',
        'or drag one here': 'või lohista siia',
        'Workspace name': 'Tööruumi nimi',
        'Phone number': 'Telefoni number',
        'Do you want to change your email for verification?': 'Kas soovite oma e-posti aadressi muuta?',
        'Your email is unverified and your trial has ended': {
          ' Verify your email if you want to continue using the Hours software': {
            '':
              'Teie e-posti aadress on kinnitamata ja teie prooviperiood on lõppenud. Kui soovite jätkata Hours tarkvara kasutamist, kinnitage oma e-posti aadress.',
          },
        },
        'Resend verification': 'Saatke kinnitus email uuesti',
        Refresh: 'Värskenda',
        'Already have an account?': 'On juba konto olemas?',
        'Log in here': 'Logi sisse',
        'By signing up, you agree to our': 'Registreerudes nõustute meie',
        'terms of service': 'kasutustingimustega',
        'privacy policy': 'privaatsuspoliitikaga',
        'and to receiving marketing communication from Hours': 'ja Hoursi turundusteadete saamisega',
        'You can opt out anytime': 'Te saate igal hetkel loobuda',
        'Your email address has not been verified': { '': 'Teie e-posti aadress ei ole kinnitatud.' },
        'Check your email for the activation link or': 'Kontrollige oma e-posti või',
        'Work resumed from being idle at': 'Tööd jätkati',
        'Office door setting': 'Kontori uste seadistused',
        'Automatically stop work after': 'Lõpeta töö automaatselt peale',
        'minutes of idle time': 'minutit peale uksest väljumist',
        '* when idle time is not set, work will automatically stop after 60 minutes':
          '* kui lõpetus aega pole seadistatud, peatatakse töö automaatselt 60 minuti pärast',
        'Work was idle starting at': 'Alustati töö lõpetamist',
        None: 'Ei kasuta',
        'This email is already in use': { '': 'E-mail on juba kasutusel' },
        'RFID is already in use': { '': 'Rfid kiip on juba kasutusel' },
        'Normal workday length': 'Tööpäeva pikkus',
        'National holiday settings': 'Riigipühade seaded',
        Calendar: 'Kalender',
        'The previous working day before a national holiday will be shortened':
          'Riiklikust pühast eelmist päeva lühendatakse',
        'The following fields are missing: ': 'Täida kohustuslikud väljad',
        main: 'Peamine',
        'Visma Settings': 'Netvisori seadistused',
        'Show weekend hours separately': 'Näita nädalavahetuse tunde eraldi',
        'Show columns': 'Veerud',
        'Weekend hours': 'Nädalavahetuse tunnid',
        'User does not have work type salaries': 'Kasutajal ei ole tööliigi põhist palka',
        'Updated daily allowance': 'Päevarahad muudetud',
        'Deleted pauses': 'Kustutatud pausid',
        'Round to nearest 15 minutes': 'Ümarda lähima 15 minutini',
        'Add to login box': 'Lisa logimis terminali',
        'Work end time': 'Töö lõpu aeg',
        'Aloita lisäämällä uusi päiväraharyhmä': 'Alustamiseks lisa päevaraha grupp',
        'Your daily allowance group is empty': 'Päevarahade grupp on tühi',
        'Use in daily allowance calculation': 'Kasuta päevarahade arvutamisel',
        'View notification': 'Vaata teadet',
        'No allowance': 'Ei ole',
        "If you haven't received verification email in a few minutes, please check the spam folder": {
          '': 'Kui te pole mõne minuti jooksul kinnitusmeili saanud, kontrollige rämpsposti kausta.',
        },
        'Do nothing': 'Ära tee midagi',
        Unsubscribe: 'Loobu tellimusest',
        "If you don't want to receive notifications from Hours to your email, then you can unsubscribe": {
          '': 'Kui te ei soovi Hoursilt oma meilile märguandeid saada, saate tellimusest loobuda.',
        },
        "If you’re having trouble clicking the 'View notification' button, copy and paste the URL below into your web browser":
          'Kui teil on probleeme nupul „Vaata teadet” klõpsamisega, kopeerige ja kleepige allolev URL oma veebibrauserisse',
        'You have a new notification': 'Teile on uus teadis',
        ':name has entered :workspace at :datetime': { '': ':name sisenes :workspace kell :datetime.' },
        ':name has left :workspace at :datetime': { '': ':name lahkus : workspace kell :datetime.' },
        'Your work (:workspace - :worktype) has automatically started at :datetime': {
          '': 'Teie töö (:workspace - :worktype) algas automaatselt kell :datetime.',
        },
        'Your Hours trial will end in :trial days': { '': 'Teie prooviperiood lõpeb :trial päeva pärast.' },
        'By continuing using the Hours software after trial, you will be billed by Hours price list': {
          '': 'Kui jätkate tarkvara kasutamist pärast prooviperioodi, esitatakse teile arve Hours hinnakirja alusel.',
        },
        'Email verification': 'E-posti kinnitus',
        'Unable to verify your email': { '': 'Teie e-posti ei saa kinnitada' },
        End: 'Lõpp',
        'Your Hours': {
          'ee invoice is unpaid': {
            ' If you do not pay the bill, your account will be closed on :bill':
              'Teie Hours.ee arve on tasumata. Arve mittetasumisel suletakse teie konto :bill',
          },
        },
        Equal: 'Võrdne',
        Down: 'Alla',
        Up: 'Üles',
        'Daily allowance': 'Päevaraha',
        Post: 'Saada',
        'Group does not have a daily allowance item': 'Grupis ei ole päevaraha seadistusi',
        'Add daily allowance': 'Lisa päevaraha seadistus',
        'Daily allowances': 'Päevarahad',
        View: 'Vaata',
        'Generating Report': { ' Please wait': { '': { '': { '': 'Aruande genereerimine. Palun oota...' } } } },
        'Total:': 'Kokku:',
        'User preference updated successfully': 'Kasutaja seadistused uuendatud',
        'Work log updated successfully': 'Töö muutmine õnnestus',
        'Work paused successfully': 'Töö pandi pausile',
        'Work resumed successfully': 'Töö jätkamine õnnestus',
        'Work stopped successfully': 'Töö on peatatud',
        'Paid absence created successfully': 'Töökatkestuse lisamine õnnestus',
        'Semi-automatic (notify user before starting /ending work)':
          'Poolautomaatne (teavitage kasutajat enne töö alustamist/lõpetamist)',
        'Fully-automatic (automatically start/end work)': 'Täisautomaatne (automaatne töö alustamine/lõpetamine)',
        'User language updated successfully': 'Keel vahetatud edukalt',
        'Automatic start/end work with Mobile App': 'Automaatne töö alustamine/lõpetamine mobiiliäpist. (Beta)',
        'When entering/leaving workspace': 'Kui siseneda/väljuda tööruumist',
        'This set password link will expire 1 week from now': 'Parooli sisestamise link aegub 1 nädala pärast',
        'Notify manager': 'Teavita esimeest',
        'Work log confirmed successfully': 'Töö on edukalt kinnitatud',
        'Options updated successfully': 'Valikud on edukalt värskendatud',
        'Add a pause': 'Lisa paus',
        'The entered working time is more than 24 hours': {
          ' Are you sure you want to add it?':
            'Sisestatud tööaeg on üle 24h. Kas oled kindel, et soovid seda sisestada?',
        },
        'User registered successfully': 'Kasutaja registreerimine õnnestus',
        'Reset password link sent successfully': 'Parooli lähtestamise link edukalt saadetud',
        'Added lunch time using general preference': 'Lõuna lisatud kasutades ettevõtte "Üldiseid seadistusi"',
        'Changed pause time to': 'Paus muudetud',
        'Changed resume time to': 'Töö jätkamise aeg muudeti',
        Percentage: 'Protsent',
        added: 'lisanud',
        accepted: 'kinnitanud',
        declined: 'lükanud tagasi',
        'Congrats! :manager has :action a bonus for your great work': {
          '': 'Suurepärane! :manager on :action sinu boonuse!',
        },
        Doors: 'Uksed',
        'Merit Palk Salary type nr': 'Merit Palk impordi kood',
        'There are conflicting times for this timeframe': {
          ' Please review previous entries': {
            '': 'Antud ajavahemikus on kattuvad tööajad. Palun vaadake üle eelmised sissekanded.',
          },
        },
        'Conflicting times': 'Kattuvad tööajad',
        'Sent to Netvisor': 'Saadetud netvisorisse',
        'Sent to': 'Saadetud',
        'Rounded using general preference': 'Ümardatud kasutades ettevõtte "Üldiseid seadistusi"',
        'Rounded end time to': 'Lõpuaeg ümardatud',
        'Added using general preference': 'Lisatud kasutates ettevõtte "Üldiseid seadistusi"',
        'Updated pauses': 'Uuendatud pausid',
        'Confirmed work at': 'Töö kinnitatud',
        'Uncomfirmed work at': 'Kinnitus eemaldatud',
        'Added workspace': 'Tööruum lisatud',
        'Added work type': 'Tööliik lisatud',
        'Added address': 'Lisatud aadress',
        'Added pause': 'Lisatud paus',
        'Added comment': 'Lisatud kommentaar',
        'Added bonus': 'Lisatud boonus',
        'Added bonus comment': 'Lisatud boonuse kommentaar',
        to: '->',
        'Work edited': 'Töö muudetud',
        'Add work type extra': 'Lisa uus tööliigi lisa',
        'Ends at': 'Lõppeb',
        'Out of door at': 'Väljus uksest',
        'Resumed work at': 'Jätkas tööd',
        'Ended work at': 'Lõpetas töö',
        'Ended work using door': 'Töö lõpetati uksekaardiga',
        'Ended work using box': 'Töö lõpetati terminaliga',
        'Ended work using mobile': 'Töö lõpetati mobiilist',
        'Ended work using web': 'Töö lõpetati veebilehitsejast',
        'Rounded start time to': 'Töö algusaeg ümardati',
        'Started work using box': 'Töö alustati terminalist',
        'Started work using door': 'Töö alustati uksekaardiga',
        'Started work using mobile': 'Töö alustati mobiiliga',
        'Started work using web': 'Töö alustati veebilehitsejast',
        'Started work at': 'Töö alustati',
        'Entered door': 'Sisenes uksest',
        'Entered door at': 'Sisenes uksest',
        'Paused work at': 'Töö pandi pausile',
        'Out of door': 'Väljus uksest',
        'Congratulations! You have just set your account password': {
          ' You are now able to access the web portal': {
            '': 'Õnnitleme! Olete just määranud oma konto parooli. Nüüd saate logida sisse.',
          },
        },
        'You are all set': { '': 'Kõik on valmis.' },
        'Your email has been verified': { '': 'Teie e-post on kinnitatud.' },
        'Activation link is active within a week after creating an account': {
          ' Please resend a new activation link to your email': {
            '':
              'Aktiveerimislink on aktiivne nädala jooksul pärast konto loomist. Palun saatke oma meilile uuesti uus aktiveerimislink.',
          },
        },
        'We sent you a verification email to': 'Saatsime teile kinnitusmeili aadressile',
        'If you havent received verification email in a few minutes, please check the spam folder': {
          '': 'Kui te pole mõne minuti jooksul kinnitusmeili saanud, kontrollige rämpsposti kausta.',
        },
        'Congratulations! you have just verified your email address': {
          '': 'Super! Kinnitasite just oma e-posti aadressi.',
        },
        'You are now able to access the web portal': { '': 'Kõik on valmis. Võite nüüd sisselogida.' },
        'Invalid verification token received or the link is already expired': { '': 'Aktiveerimislink on aegunud.' },
        "If you don't see the email, please check your spam folder or contact your Organization":
          'Kui te meili ei näe, kontrollige oma rämpsposti kausta või võtke ühendust oma ettevõttega',
        'Overtime computation': 'Ületöö arvutamine',
        "Can't add pause bigger than work duration": { '': 'Töö kestusest suuremat pausi ei saa lisada.' },
        'Work duration must not overlap to other work logs': { '': 'Töö kestus ei tohi kattuda teiste töödega.' },
        "Work durations shouldn't be negative": { '': 'Töö kestus ei saa olla negatiivne.' },
        'Selected works have some users with multiple work logs on the same day':
          'Valitud töödel on mõnel kasutajal samal päeval mitu töölogi',
        'Cannot confirm not ended work': 'Töö peab enne kinnitamist olema lõpetatud',
        "Start time can't be later than end time": { '': 'Algusaeg ei tohi olla lõpuajast hilisem.' },
        'This absence request has already been declined': { '': 'See töökatkestus on juba tagasi lükatud.' },
        'Overlapping paid absences': 'Töökatkestused ei saa kattuda',
        'Cannot request with overlapping paid absences': 'Taotlus ei saa kattuda teie teiste töökatkestustega',
        'There is already ongoing unconfirmed request': 'Kinnitamata taotlus on juba pooleli',
        'Work has been deleted': 'Töö on kustutatud',
        'Can not found request': {
          ' Work log may have been deleted': { '': 'Taotlust ei leitud. Töö võib olla kustutatud.' },
        },
        'This bonus request has already been accepted': { '': 'See boonuse taotlus on juba kinnitatud.' },
        'This bonus request has already been declined': { '': 'See boonuse taotlus on juba tagasi lükatud.' },
        "Pause start can't be earlier than work start": { '': 'Pausi algus ei saa olla varasem kui töö algus.' },
        'Work resumed already': { '': 'Töö on juba jätkatud' },
        'User is currently working': { '': 'Kasutaja töötab hetkel.' },
        'Overlapping work rounds': 'Vahetused kattuvad',
        'There are some errors in exporting merit palk report': 'Merit palk aruande eksportimisel on vigu',
        'There are some errors in exporting netvisor report': 'Netvisori aruande eksportimisel on mõned vead',
        'Dimension updated unsuccessfully': 'Kulukohtade värskendamine ebaõnnestus',
        'Notification cannot be posted if deleted': 'Teatist ei saa postitada, kui see on kustutatud',
        'Error fetching locales': { '': 'Viga lokaatide toomisel.' },
        'This absence request has already been accepted': { '': 'See töökatkestus on juba vastu võetud.' },
        'Work paused already': { '': 'Töö on juba pausil' },
        "This account doesn't exist": { '': 'Seda kontot pole olemas.' },
        'Your account has been deleted': { '': 'Teie konto on kustutatud.' },
        'Please verify your email': { '': 'Palun kinnitage oma e-posti aadress.' },
        'Your account has been deactivated': { '': 'Teie konto on deaktiveeritud.' },
        'Your company account has been disabled': { '': 'Teie ettevõtte konto on suletud.' },
        'Work start should not be greater than stop time': { '': 'Töö algus ei tohi olla suurem kui lõpetamise aeg.' },
        'Work stopped already': { '': 'Töö on juba lõpetatud' },
        'Work not found': { '': 'Tööd ei leitud' },
        'You have attempted to login multiple times already': {
          ' Try resetting your password': {
            '': 'Olete proovinud juba mitu korda sisse logida. Proovige oma parool lähtestada.',
          },
        },
        'Enter your email below and we will send a password reset link to your email': {
          '': 'Sisestage oma e-posti aadress ja me saadame teie meilile parooli lähtestamise lingi',
        },
        'Token already expired': 'Aktiveerimislink on juba aegunud',
        'Token is invalid': 'Aktiveerimislink on kehtetu',
        'Token has already been used': 'Token on juba kasutatud',
        'User not found': 'Kasutajat ei leitud',
        'Password Reset Sent': 'Parooli lähtestamine saadetud',
        'You should receive the password reset email shortly once an account matching your username is found': {
          '': 'Peaksite saama parooli lähtestamise meili varsti pärast teie kasutajanimele vastava konto leidmist.',
        },
        'Invalid credentials': 'Vale kasutajanimi või parool',
        'Please try again or ': 'Palun proovige uuesti või ',
        'Merit Palk Setting': 'Merit palk seadistused',
        'API Setting': 'API seadistus',
        'API Key': 'API võti',
        'API ID': 'API ID',
        'Do not count as overtime': 'Ära arvesta ületöö arvutamisel',
        'No notifications created': 'Ei ole ühtegi teadet',
        'Start by creating notifications and post them': { '': 'Alustamiseks lisage teade' },
        'Add new notification': 'Lisa uus teade',
        'Default work type': 'Vaikimisi tööliik',
        'Automatic start/end work with mobile':
          'Alusta/lõpeta automaatselt tööd nutitelefoniga kui saabud tööpiirkonda (Beeta - testimine)',
        Radius: 'Raadius',
        'Set location': 'Määra asukoht',
        'People rate': 'Kasutaja hind',
        'Worktype rate': 'Tööliigi hind',
        'Show worker number': 'Näita kasutajate numbrit',
        'Reset Your Password': 'Taasta oma parool',
        'Back to login': 'Tagasi',
        'More details': 'Rohkem infot',
        'Showing rows: :current out of :total': 'Näitab ridu: :current / :total',
        'We use cookies to ensure you get the best experience on our site':
          'Kasutame küpsiseid, et tagada teile meie saidil parim kasutuskogemus.',
        'Start by adding new allowed pause group': 'Alustamiseks lisage lubatud pauside grupp',
        'Group does not have an allowed pause item': 'Ei ühtegi lubatud pausi lisatud',
        'Add allowed pause': 'Lisa lubatud paus',
        'Allowed pause group': 'Lubatud pauside grupp',
        'Work pause': 'Töö paus',
        'Work end': 'Töö lõpp',
        'Work start time': 'Töö algusaeg',
        'Round time': 'Ümarda aeg',
        'Lunch time': 'Lõuna',
        minutes: 'minutit',
        'before work end time to round up': 'enne töö lõppu ümarda aeg üles',
        'after work end time to round down': 'peale töö lõppu ümarda aeg alla',
        'Your allowed pause group is empty': 'Lubatud pauside grupp on tühi',
        'Update doors': 'Uuenda uksi',
        'When exiting': 'Kui väljuda uksest',
        'Edit door': 'Muuda ust',
        'Door access': 'Ukse seadistus',
        'Main door': 'Peauks',
        'must be accessed to start work': 'peab kasutama töö alustamiseks',
        'Front door': 'Esiuks',
        'Change work type when entering': 'Ukse avamisel vaheta tööliiki',
        'Work resume when entering': 'Ukse avamisel jätka tööd',
        Request: 'Taotle',
        'View Bonus': 'Vaata boonust',
        Pending: 'Ootel',
        Approved: 'Kinnitatud',
        Declined: 'Tagasi lükatud',
        'Expired activation': 'Aegunud aktiveerimine',
        'Inner Range Doors': 'Inner Range uksed',
        'Doors updated': 'Uksed uuendatud',
        'Door ID': 'Ukse ID',
        'Request Bonus': 'Küsi boonust',
        'Can accept/edit/decline requests': 'Õigus kinnitada/muuta/lükata tagasi taotlusi',
        'Switching Account': 'Vahetan kontot',
        'Past two weeks': '2 nädala palga periood',
        ':worker has requested a bonus of :bonus': ':worker on küsinud boonust :bonus',
        'has requested a paid absence': 'on küsinud töökatkestust',
        ':manager has declined your request for a bonus': { '': ':manager on lükanud sinu boonuse tagasi.' },
        'Your work (:workspace - :worktype) has automatically paused at :datetime': {
          '': 'Sinu töö (:workspace - :worktype) on automaatselt pandud pausile :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically resumed at :datetime': {
          '': 'Sinu töö (:workspace - :worktype) on automaatselt jätkatud :datetime.',
        },
        'Your work (:workspace - :worktype) has automatically ended at :datetime': {
          '': 'Sinu töö (:workspace - :worktype) on automaatselt lõpetatud :datetime.',
        },
        ':manager has :action a paid absence': { '': ':manager on :action töökatkestuse.' },
        'Congrats! :manager has :action a bonus for your greate work': {
          '': 'Jess! :manager on :action kinnitanud sinu boonuse hea töö eest.',
        },
        ":worker has requested a paid absence ':type' (:start - :end)": {
          '': ":worker on küsinud töökatkestust ':type' (:start - :end).",
        },
        ':name is working overtime with work (:workspace - :worktype) at this :datetime': {
          '': ':name teeb ületööd (:workspace -:worktype) :datetime.',
        },
        ':manager has declined your request for a paid absence': {
          '': ':manager on lükanud tagasi sinu töökatkestuse taotluse.',
        },
        'User preference': 'Kasutaja seaded',
        'Sorting order': 'Järjestus',
        'First name, Last name': 'Eesnimi, Perekonnanimi',
        'Last name, First name': 'Perekonnanimi, Eesnimi',
        'Allowed pauses': 'Lubatud pausid',
        Late: 'Hilines',
        'You have been added to Hours time tracking': 'Sind on lisatud Hours tööaja arvestuse platformi.',
        'Fetch records': 'Too InnerRange',
        'has requested a bonus of': 'on küsinud boonust',
        Posted: 'Saadetud',
        'Ready for posting': 'Valmis saatmiseks',
        Draft: 'Mustand',
        'when no user is selected, notifications will be sent to all users in the company or in the selected workspaces':
          'kui ühtegi kasutajat ei ole valitud siis teade saadetakse kõikidele ettevõtte kasutajatele või valitud tööruumi kasutajatele.',
        'Draft notification message': 'Salvesta mustandina',
        Requests: 'Taotlused',
        'No bonus requests yet': 'Ühtegi boonuse taotlust ei ole veel',
        'No paid absence requests yet': 'Ühtegi töökatkestuse taotlust ei ole veel',
        'Paid absence': 'Töökatkestus',
        'Bonus comment': 'Boonuse kommentaar',
        'Original requested amount': 'Algne küsitud summa',
        'New amount': 'Uus summa',
        'Request paid absence': 'Taotle töökatkestust',
        'Requested at': 'Taodeldud',
        'Started at': 'Alustatud',
        'Ended at': 'Lõpetatud',
        'Confirmed at': 'Kinnitatud',
        'Confirmed by': 'Kinnitanud:',
        'Work date': 'Töö kuupäev',
        'You have no rights to edit work time!': 'Sul ei ole õiguseid tööaegu muuta',
        'Notify through push notification': 'Teavita süsteemi teavituste kaudu',
        'Notify through email': 'Teavita emaili teel',
        Notifications: 'Teated',
        'Created by': 'Koostaja',
        'Posted at': 'Saadetud',
        Title: 'Pealkiri',
        Repost: 'Saada uuesti',
        'Add notification': 'Lisa teade',
        'when no workspace is selected, notifications will be sent to all users in the company':
          'kui ühtegi tööruumi pole valitud siis teade saadetakse kõikidele ettevõtte kasutajatele',
        'Company notifications': 'Ettevõtte teated',
        'Worker is working overtime': 'Kasutaja teeb ületööd',
        'Worker is asking for a bonus': 'Töötaja küsib boonust',
        'Worker is requesting for an absence': 'Kasutaja taotleb töökatkestust',
        'Worker has arrived/left from the workspace': 'Kasutaja on saabunud/lahkunud tööruumi aadressilt',
        'Manager has added/accepted/declined a bonus': 'Esimees on lisanud/nõustunud/lükanud tagasi boonuse',
        'Manager has added/accepted/declined a paid absence':
          'Esimees on lisanud/nõustunud/lükanud tagasi töökatkestuse',
        'Work paused/ended automatically through workspace preference':
          'Töö on automaatselt pandud pausile tööruumi seadistuste kaudu',
        'Start by adding a new box': { '': 'Alustamiseks lisage uus terminal' },
        Latest: 'Uuemad',
        'This week': 'See nädal',
        Older: 'Vanemad',
        'You have declined this request!': 'Sa oled selle taotluse tagasi lükanud',
        'You have already approved this request!': 'Sa oled juba selle taotluse kinnitanud',
        Accept: 'Nõustu',
        Decline: 'Keeldu',
        'Mark as read': 'Märgi loetuks',
        'Turn off receiving this status': 'Vagista seda sorti teated',
        'Delete notification': 'Kustuta teade',
        'Mark all as read': 'Märgi kõik loetuks',
        'View full feed': 'Vaata täisvoogu',
        'Clear all': 'Tühjenda kõik',
        Read: 'Loe',
        Close: 'Sulge',
        Clear: 'Tühjenda',
        'No notifications yet': 'Ühtegi teadet ei ole',
        'Turn on/off notifications': 'Lülita sisse/välja teated',
        'Weekly working hours': 'Nädala tunnid kokku',
        'Show more': 'Näita rohkem',
        'Show less': 'Näita vähem',
        'No email': 'Ilma e-mailita',
        'Complete registration by choosing a password': 'Valige parool ja viige registreerimine lõpule',
        'Your First Name': 'Sinu eesnimi',
        'Your Last Name': 'Sinu perekonnanimi',
        'Your Password': 'Sinu parool',
        'Confirm Your Password': 'Kinnita oma parool',
        "Please register by entering you're email": 'Registreerimiseks täitke järgnevad väljad',
        Location: 'Asukoht',
        'Must have at least 8 characters': 'Peab sisaldama vähemalt 8 tähemärki',
        'Must start with alphanumeric character': 'Peab algama tähtnumbrilise tähemärgiga',
        'Must have at least one lowercase character': 'Peab sisaldama vähemalt ühte väiketähte',
        'Must have at least one uppercase character': 'Peab sisaldama vähemalt ühte suurtähte',
        'Must have at least one digit': 'Peab sisaldama vähemalt ühte numbrit',
        'Passwords must match': 'Paroolid peavad kattuma',
        'Must Accept Terms and Condition': 'Peab nõustuma kasutustingimustega',
        'This field is required': 'Selle välja täitmine on kohustuslik',
        ':field is required': 'väli on kohustuslik',
        by: ',',
        'Total salary': 'Palk kokku',
        'Paid pause': 'Paus on maksuline',
        'Work type extras': 'Tööliigi lisad',
        Basic: 'Põhipalk',
        'Basic salary': 'Põhipalk',
        Week: 'Nädal',
        Rate: 'Tunnihind',
        'Worker is missing identification number':
          'Kasutajal puudub number. Palun lisa kasutaja number kasutaja profiili alt',
        'Your paid absences is empty': 'Sul ei ole veel ühtegi töökatkestust lisatud',
        'Start by adding new paid absences': 'Alustamiseks lisa uus töökatkestus',
        'Invite user': 'Saada kutse',
        'Group does not have a work round item': 'Ühtegi Vahetuste gruppi ei ole loodud',
        'Group does not have an overtime item': 'Grupis ei ole ületööde seadistusi',
        'Upload Company Logo': 'Laadi ettevõtte logo',
        'Upload Profile Image': 'Laadi profiili pilt',
        'Drag and drop your image here or': 'Lohista pilt siia või',
        'upload a photo': 'laadi pilt',
        'Single image must not exceed 5MB': 'Pildi suurus ei tohi ületada 5MB',
        'Business Area': 'Tegevusala',
        'Start by adding new work round group': 'Alustamiseks lisa uus vahetuste grupp',
        'Your work round group is empty': 'Vahetuste grupp on tühi',
        'Your overtime group is empty': 'Ületööde grupp on tühi',
        '\r\nStart by adding new overtime group': 'Alustamiseks lisa uus ületööde grupp',
        File: 'Manus',
        "This does not affect users' existing paid absences": 'Seade ei muuda kasutajate eelnevaid töökatkestusi',
        'View document': 'Vaata manust',
        'Rename document': 'Muuda manust',
        'Delete document': 'Kustuta manus',
        'File name': 'Manuse nimi',
        'File type': 'Manuse tüüp',
        'File size': 'Manuse suurus',
        'Last date modified': 'Muudetud',
        'accepts comma-separated input': 'võib eraldada komadega',
        "Press 'Enter'": 'Vajuta "Enter"',
        Emails: 'Emailid',
        'Invite users by email': 'Saada kutse',
        'Add work type': 'Lisa tööliik',
        'View work types': 'Vaata tööliike',
        'User has been deleted': 'Kasutaja on kustutatud',
        'User information cannot be viewed': 'Kasutaja informatsiooni ei saa kuvada',
        'Go back to People page': 'Mine tagasi Kasutajad lehele',
        'Something went wrong with your request': {
          ' Please try again later or refresh the page': {
            '': 'Midagi läks valesti. Palun proovi hiljem uuesti või värskenda lehte.',
          },
        },
        'Something went wrong': {
          ' Please contact administrator': {
            '': 'Midagi läks valesti. Kui viga esineb uuesti siis palun kontakteeru administraaatoriga',
          },
        },
        'The work has been updated': { ' Please confirm to apply changes': 'Kas oled kindel, et tahad tööaega muuta?' },
        'Start by adding new work type': 'Alustamiseks lisa uus tööliik',
        'Start by adding a new workina hours registration box': { '': 'Alustamiseks lisage uus logimisterminal' },
        'Account verification': 'Konto kinnitamine',
        Hello: 'Tere',
        'Please click the button below to verify your email address': { '': 'Jätkamiseks kliki allolevale nupule' },
        'Verify Email Address': 'Kinnita e-maili aadress',
        'If you did not create an account, no further action is required': {
          '': 'Kui sina ei loonud kontot siis ei ei pea sa rohkem midagi tegema',
        },
        'Best regards': 'Lugupidamisega',
        Team: 'Meeskond',
        "If you’re having trouble clicking the 'Verify Email Address' button, copy and paste the URL below into your web browser":
          'Kui sul on raskusi "Kinnita e-emaili" nupule vajutamisega siis kopeeri järgnev link veebilehitsejasse',
        'All rights reserved': 'Kõik õigused kaitstud',
        'Complete registration': 'Lõpeta registreerimine',
        from: 'ettevõttest',
        'have added you to Hours time tracking': { '': 'on lisanud sind Hours tööajaarvestuse tarkvarasse' },
        'Please complete your registration by setting up a password': {
          '': 'Palun lõpeta registreerimine klõpsates all olevale nupule',
        },
        'Set Password': 'Vali parool',
        'This set password link will expire 1 day from now': { '': 'Parooli asetamise link aegub 1 päeva pärast.' },
        'Download also Hours mobile apps': 'Laadi alla Hours mobiilirakendus',
        "If you’re having trouble clicking the 'Set Password' button, copy and paste the URL below into your web browser":
          'Kui sul on raskusi "Vali parool" nupule vajutamisega siis kopeeri järgnev link oma veebilehitsejasse',
        'Welcome to': 'Tere tulemast',
        'You are receiving this email because we received a reset password request for your account':
          'Sa oled selle e-maili saanud, sest oled palunud oma parooli taastada',
        'Reset Password': 'Taasta parool',
        'If you did not request a password reset, no further action is required': {
          '': 'Kui sa ei ole parooli taastamist tellinud siis rohkem ei pea sa midagi tegema',
        },
        'This password reset link will expire 1 day from now': { '': 'Parooli taastamise link aegub 1 päeva pärast' },
        'Your work type is empty': 'Sul ei ole veel ühtegi tööliiki',
        'Dreaming of a new box?': 'Unistad uuest terminalist?',
        'Oops! Option already exist': { '': 'Ups! Seadistused on juba olemas.' },
        'Are you sure you want to update selected work logs?': 'Oled kindel, et soovid muuta valitud töid?',
        'Round to nearest hour': 'Ümarda lähima tunnini',
        'Round to nearest half-hour': 'Ümarda lähima pooltunnini',
        'Round to nearest 10 minutes': 'Ümarda lähima 10 minutini',
        'Still having a trouble?  Please contact administrator':
          'Ikka esineb probleeme? Palun kontakteeru klienditoega.',
        'Edit group': 'Muuda gruppi',
        'Overtime group': 'Ületöö grupp',
        'Work round group': 'Vahetuse grupp',
        'Are you sure you want to remove profile image?': 'Oled kindel, et soovid eemaldada profiili pildi?',
        'Error on exporting :type': { '': 'Viga eksportimisel :type.' },
        'Do not group': 'Ära grupeeri',
        'Grouped by people': 'Gurpeeri kasutaja järgi',
        'Are you sure you want to bulk activate/deactivate?': 'Oled kindel, et soovid aktiveerida/deaktiveerida?',
        'Please try shorter time frame or less people at the same time': {
          '': 'Palun proovi lühmat ajavahemikku või vali vähem kasutajaid korraga.',
        },
        'Some selected work logs are already confirmed!': 'Mõned valitud tööd on juba kinnitatud!',
        'Round work time': 'Ümarda tööaeg',
        Processing: { '': { '': { '': 'Töötlen...' } } },
        Subject: 'Teema',
        'Number of weeks': 'Nädalat',
        'Number of months': 'Kuid',
        'Daily normal hours': 'Päevane normaalne tööaeg',
        'Weekly normal hours': 'Nädalane normaalne tööaeg',
        'Monthly normal hours': 'Kuine normaalne tööaeg',
        'Are you sure want to switch as :type?': 'Kas oled kindel, et soovid vahetada :type poolele?',
        'This action cannot be undone!': 'Seda ei saa tagasi võtta!',
        'Work type workspaces': 'Tööliigi tööruum',
        'People workspaces': 'Kasutaja tööruum',
        'Working Period': 'Töötamise periood',
        From: 'Alates',
        'Send copy': 'Saada koopia',
        'weekly overtime': 'nädala ületöö',
        'Monthly overtime': 'Kuu ületöö',
        'monthly overtime': 'kuu ületöö',
        'working hours': 'töötunnid',
        total: 'kokku',
        hours: 'tundi',
        days: 'päevi',
        'Total working days': 'Tööpäevi kokku',
        'Created at': 'Koostatud',
        Sunday: 'Pühapäev',
        Saturday: 'Laupäev',
        Map: 'Kaart',
        History: 'Ajalugu',
        Overview: 'Ülevaade',
        Company: 'Firma',
        'Average workday length': 'Keskmine tööpäeva pikkus selles kuus',
        'Total hours during selected period': 'Töötunnid kokku valitud perioodil',
        report: 'raport',
        'Work types': 'Tööliigid',
        'Paid absences': 'Töökatkestus',
        'Work rounds': 'Töö vahetus',
        'Daily overtime': 'Päevane ületöö',
        'Weekly overtime': 'Nädala ületöö',
        'Please wait while the :type is still generating': {
          '': { '': { '': 'Palun oodake kuni :type koostatakse...' } },
        },
        'Default pause length': 'Vaikimis pausi pikkus',
        'Include in overtime computation': 'Arvesta ületöö arvutamisel',
        'Total time': 'Kestvus',
        'Add user': 'Lisa kasutaja',
        'Edit user': 'Muuda kasutajat',
        'Edit users': 'Muuda kasutajaid',
        Pauses: 'Pausid',
        'Confirmation action': 'Kinnitus',
        'Edit Box': 'Muuda terminali',
        'Main Screen Type': 'Põhivaade',
        Message: 'Sõnum',
        'With worker list': 'Kasutajate listiga vaade',
        'Without worker list': 'Ilma kasutajate listita vaade',
        'Add Box': 'Lisa terminal',
        "Workspaces who's people are visible in the box": 'Tööruumid kelle kasutajad on terminalis nähtavad',
        Day: 'Päev',
        'Choose type': 'Vali tüüp',
        Until: 'Kuni',
        'Do not count as workday': 'Ära arvesta tööpäevana',
        'Time unit': 'Ajaühik',
        Length: 'Väärtus',
        'Edit Paid Absence': 'Muuda töökatkestust',
        'New Paid Absence': 'Uus töökatkestus',
        'Add new': 'Lisa uus',
        'Add new paid absence': 'Lisa uus töökatkestus',
        'User ID': 'Kasutaja ID',
        'User key': 'Kasuaja võti',
        'Division specific settings': 'Tööruumi kohased seaded',
        'Update Dimensions': 'Uuenda kulukohti',
        'Dimensions updated': 'Kulukohad uuendatud',
        'Netvisor Dimensions': 'Netvisori dimensioonid',
        'Dimension updated successfully': { '': 'Dimensioonide uuendamine õnnestus' },
        'Netvisor settings updated successfully': { '': 'Netvisori seadete uuendamine õnnestus' },
        'Connection Settings': 'Ühenduse seaded',
        'Netvisor Settings': 'Netvisori seaded',
        'Business ID': 'Registrikood',
        'User Profile': 'Kasutaja profiil',
        'Workspace does not have a worker': 'Tööruumis pole ühtegi kasutajat',
        'Registry code': 'Registrikood',
        'Contact Information': 'Kontakti info',
        Phone: 'Telefon',
        'Start from': 'Algab',
        'User type': 'Kasutaja tüüp',
        'General Rights': 'Üldised õigused',
        'Can register work hour from login box': 'Saab registreerida töötunde terminali kaudu',
        'Is visible on login box': 'On nähtav terminalis',
        'Start of employment': 'Töölevõtu alguskuupäev',
        'End of employment': 'Töölepingu lõppkuupäev',
        'Netvisor export identification': 'Netvisori identifitseerimine',
        'Worker identification in Netvisor export': 'Töötaja identifitseerimine Netvisori saatmisel',
        'Worker number': 'Töötaja number',
        Information: 'Informatsioon',
        Rights: 'Õigused',
        Personal: 'Isiklik',
        'Add another number': 'Lisa uus number',
        'Add another address': 'Lisa uus aadress',
        'ID Code': 'Isikukood',
        'TAX Number': 'Maksunumber',
        'Date of birth': 'Sünnikuupäev',
        Generic: 'Üldine',
        Deleted: 'Kustutatud',
        Actions: 'Tegevused',
        'Add Paid Absence': 'Lisa töökatkestus',
        ':count worker is selected': ':count kasutaja valitud',
        ':count workers are selected': ':count kasutajat valitud',
        'Send workers data': 'Saada kasutajate andmeid',
        'Send email to worker': 'Saada e-mail kasutajatele',
        'Hide all days': 'Peida kõik päevad',
        'Show all days': 'Näita kõiki päevi',
        Dimensions: 'Kulukohad',
        'Working hours are not confirmed': 'Töötunnid ei ole kinnitatud',
        'Product number': 'Tootenumber',
        Hours: 'Tundi',
        'Export as :type': 'Ekspordi :type',
        'Collapse all rows': 'Sulge kõik read',
        'Expand all rows': 'Näita kõiki ridu',
        All: 'Kõik',
        'Confirmed hours': 'Kinnitatud tunnid',
        'Unconfirmed hours': 'Kinnitamata tunnid',
        'Group by': 'Grupeeri',
        'Report options': 'Rapordi valikud',
        'Total hours': 'Tunnid kokku',
        'Total days': 'Tööpäevi kokku',
        'Average day': 'Keskmine tööpäev',
        'Total paid absence': 'Töökatkestusi kokku',
        Bonuses: 'Boonused',
        Unconfirm: 'Eemalda kinnitus',
        Edited: 'Muudetud',
        Tags: 'Silt',
        Worktype: 'Töötüüp',
        'Are you sure you want to :status this work log?': 'Oled kindel, et soovid :status seda tööd?',
        'Are you sure you want to delete this work log?': 'Oled kindel, et soovid kustutada antud töö?',
        'Are you sure you want to delete :name work log?': 'Oled kindel, et soovid kustutada :name töö?',
        'Delete Timesheet': 'Kustuta töö',
        'Are you sure you want to delete :name?': 'Oled kindel, et soovid kustutada :name?',
        Amount: 'Summa',
        'Edit Bonus': 'Muuda boonust',
        'Are you sure you want to confirm :name work log?': 'Oled kindel, et soovid kinnitada :name töö?',
        'Are you sure you want to unconfirm :name work log?': 'Oled kindel, et soovid eemalda kinnituse :name töölt?',
        'Worker Comment': 'Kasutaja kommentaar',
        Bonus: 'Boonus',
        'Breaks Total': 'Pausid kokku',
        'Add :label': 'Lisa :label',
        'No :label added': 'Ei ole :label lisatud',
        'Warning: Updating these settings may affect ongoing works': {
          '': 'Hoiatus: Uuendades neid seaded võid mõjutada jooksvaid töid',
        },
        'Can register own work hour through mobile': 'Saab registreerida töötunde mobiiliga',
        'Location tracking is required by owner': 'Asukohta näitamine on kohustuslik',
        'Can manage user work hours': 'Võib hallata kasutajate töötunde',
        'Edit Work type': 'Muuda tööliiki',
        'Work name': 'Töö nimi',
        'Parent Work type': 'Kõrgem tööliik',
        'Visma Product nr': 'Netvisori tootenumber',
        'Work Price': 'Töö hind',
        Hour: 'Tund',
        Piece: 'Tükk',
        'Show separately on report': 'Näita eraldi rapordil',
        'Cost Descriptions': 'Kulukoht',
        'Paid Absences': 'Töökatkestused',
        ':count work items selected': ':count tööd valitud',
        Files: 'Manused',
        Save: 'Salvesta',
        'Add Bonus': 'Lisa boonus',
        'Waiting activation': 'Ootab aktiveerimist',
        Active: 'Aktiivne',
        Inactive: 'Mitteaktiivne',
        'Search for user': 'Otsi kasutajat',
        Manager: 'Administraator',
        Worker: 'Töötaja',
        'Add manually': 'Lisa manuaalselt',
        'Invite user via email': 'Saada kutse',
        'Company Profile': 'Ettevõtte profiil',
        Status: 'Staatus',
        'Add New': 'Lisa uus',
        Filters: 'Filtrid',
        Submit: 'Salvesta',
        Reset: 'Taasta',
        Workpace: 'Töölaud',
        Name: 'Nimi',
        Type: 'Tüüp',
        Period: 'Periood',
        Working: 'Töötab',
        'Not Working': 'Ei tööta',
        'Just an empty canvas': 'Lihtsalt tühi leht',
        'Try a different date range or filters': { '': 'Proovi erinevaid kuupäevi või filtreid' },
        'Still nothing? Go ahead and track some time': { '': 'Ikka mitte midagi? Hakka pihta ja logi aegasid' },
        Pause: 'Paus',
        'End time': 'Lõpp',
        'Start time': 'Algus',
        'Work type': 'Tööliik',
        Profile: 'Profiil',
        Date: 'Kuupäev',
        Start: 'Algus',
        Duration: 'Kestvus',
        Total: 'Kokku',
        Confirmed: 'Kinnitatud',
        Salary: 'Palk',
        'Add time': 'Lisa tööaeg',
        "Don't count overtime": 'Ära arvesta ületunde',
        'No files added': 'Ei ole ühtegi dokumenti lisatud',
        'Add files': 'Lisa dokument',
        'Start work': 'Alusta tööd',
        'Remove end time': 'Eemalda lõppaeg',
        'Add end time': 'Lisa lõppaeg',
        'Pause length': 'Pausi pikkus',
        'Pause start': 'Pausi algus',
        'Work number': 'Töönumber',
        Comment: 'Kommentaar',
        ':name :old_value changed to :new_value': ':name :old_value muudetud :new_value',
        'Birth date': 'Sünnikuupäev',
        'Document :filename is no longer available': 'Dokument :filename ei ole enam saadaval',
        'No documents added': 'Ei ole ühtegi dokumenti lisatud',
        'Total size of uploaded files:': 'Laetud dokumentide suurus kokku',
        'No bonuses added for this user': 'Ei ole ühtegi boonust lisatud',
        'No salary settings': 'Palgaarvestuse seaded puuduvad',
        'No paid absences added for this user': 'Ei ole ühtegi töökatkestust lisatud',
        'To which workspaces can the employee register hours': 'Millisesse tööruumi saab kasutaja tööaegu lisada',
        '* when no workspace is selected, it is allowed to register hours to all workspaces':
          '* kui ei ole ühtegi tööruumi valitud siis saab kasutaja lisada tööaegu kõikidesse tööruumidesse',
        'Can register own work time from computer': 'Saab lisada töötunde käsitsi',
        '\r\nCan edit own work time': 'Saab muuta oma töötunde',
        'Can register work hours from login box': 'Saab lisada töötunde terminalikaudu',
        'Can register work hours through mobile': 'Saab registreerida töötunde mobiiliga',
        'Can generate reports': 'Saab genereerida raporte',
        '\r\nCan edit own profile': 'Saab muuta oma profiili',
        'User has Manager Rights': 'Kasutajal on administraatori õigused',
        '\r\nTo which workspaces these permissions apply': 'Millistesse tööruumidesse need õigused kehtivad',
        '* when no workspace is selected, these permissions apply to all workspaces':
          '* kui ei ole ühtegi tööruumi valitud siis kehtivad need õigused kõikidesse tööruumidesse',
        'Can edit preferences': 'Saab muuta eelistusi',
        'Can add/edit users': 'Saab lisada/muuta kasutajaid',
        'Can generate reports for workers': 'Saab genereerida raporte',
        '\r\nCan add/edit work hours of users': 'Õigus lisada/muuta kasutajate tööaegu',
        'Can manage workspaces': 'Õigus hallata tööruume',
        'Can manage work types': 'Õigus hallata tööliike',
        'Can manage salary extensions': 'Õigus hallata palgaarvestuse liidestusi',
        'Login boxes management': 'Õigus hallata logimisterminale',
        '\r\nCan confirm work hours': 'Õigus kinnitada tunde',
        'Add new group': 'Lisa uus grupp',
        'View Details': 'Vaata detaile',
        'Send worker data': 'Saada kasutaja andmed',
        'Resend activation link': 'Saada aktiveerimislink uuesti',
        Edit: 'Muuda',
        Deactivate: 'Deaktiveeri',
        Activate: 'Aktiveeri',
        Send: 'Saada',
        'Are you sure you want to :status :name': 'Kas oled kindel, et soovid :status :name',
        'Please register by entering your email': 'Palun sisestage oma email',
        'Repeat password': 'Korda parooli',
        Password: 'Parool',
        'Your E-mail': 'Teie e-mail',
        Language: 'Keel',
        'Sign up': 'Registreeru',
        'Number of days worked': 'Tööpäevi kokku selles kuus',
        'People not working': 'Ei tööta',
        'People working': 'Töötab',
        'Edit Workspace': 'Muuda tööruumi',
        'Delete Workspace': 'Kustuta tööruum',
        'I agree to': 'Nõustun',
        'Terms & Condition': 'Kasutustingimustega',
        about_login: 'Meist',
        sign_up: 'Registreeri',
        first_name: 'Eesnimi',
        last_name: 'Perekonnanimi',
        company_name: 'Ettevõtte nimi',
        'I allow Hours to e-mail me notifications and updates regarding notifications, raports and services':
          'Luban Hoursil saata mulle teateid e-mailile',
        'I agree to Terms & Condition': 'Nõustun kasutustingimustega',
        login: 'Sisene',
        register: 'Registreeri',
        welcome: 'Tere tulemast Hoursi',
        please_login: 'Palun logige sisse sisestades e-mail ja parool',
        remember_me: 'Mäleta mind',
        forgot_password: 'Unustasid parooli?',
        help: 'Abi',
        contact: 'Kontakt',
        show_time_decimal: 'Näita aegu sajandikena',
        yesterday: 'Eile',
        this_week: 'Jooksev nädal',
        this_month: 'Jooksev kuu',
        previous_week: 'Eelmine nädal',
        previous_month: 'Eelmine kuu',
        apply: 'Rakenda',
        reset: 'Taasta',
        cancel: 'Tühista',
        today: 'Täna',
        week: 'Nädal',
        month: 'Kuu',
        waiting_activation: 'Ootab aktiveerimist',
        send_email: 'Saada email',
        worker_name: 'Töötaja nimi',
        delete_worklog_msg: 'Oled kindel, et soovid kustutada valitud töö?',
        confirm_action: 'Kinnita',
        confirm_worklog_msg: 'Oled kindel, et soovid kinnitada valitud tööd?',
        edit_timesheet: 'Muuda tööd',
        success_delete_msg: 'Kustutatud',
        success_update_msg: 'Uuendatud',
        delete: 'Kustuta',
        confirm: 'Kinnita',
        add_user: 'Lisa kasutaja',
        add_workspace: 'Lisa tööruum',
        start_timer: 'Alusta tööd',
        people_working: 'Töötab',
        people_not_working: 'Ei tööta',
        settings: 'Seadistused',
        preferences: 'Eelistused',
        work_types: 'Tööliigid',
        vacation_types: 'Töökatkestuste liigid',
        boxes: 'Terminalid',
        logout: 'Logi välja',
        general: 'Üldine',
        overtime: 'Ületöö',
        work_rounds: 'Vahetused',
        dashboard: 'Töölaud',
        timesheet: 'Tunnileht',
        reports: 'Raportid',
        people: 'Kasutajad',
        workspaces: 'Tööruumid',
        start_tracking: 'Alusta tööd ja täida tühjad lehed',
        go_ahead: 'Hakka pihta ja logi aegasid',
        monthly_working_hours: 'Kuu tunnid',
        avg_workday_length: 'Keskmine tööpäeva pikkus selles kuus',
        clients: 'Kliendid',
        logins: 'Logimised',
        warnings: 'Hoiatused',
        about: 'Meist',
        Opportunities: 'Võimalused',
        use_same_preference: 'Kasuta samu seadeid kõikidele tööruumidele',
        auto_add_pause: 'Lisa automaatselt paus peale',
        choose_lang: 'Vali keel',
        auto_end: 'Lõpeta tööpäev automaatselt peale',
        show_hours: 'Näita tunde sjandikena',
        round_start: 'Ümarda töö algusaeg',
        round_end: 'Ümarda töö lõppaeg',
        name: 'Nimi',
        end: 'Lõpp',
        add_work_round: 'Lisa vahetus',
        add_overtime: 'Lisa ületunni seadistus',
        telephone: 'Telefon',
        address: 'Aadress',
        status: 'Staatus',
        role: 'Roll',
        company: 'Ettevõte',
        workspace: 'Tööruum',
        email: 'E-mail',
        options: 'Valikud',
        filters: 'Filtrid',
        working_hours: 'Töötunnid',
        export: 'Ekspordi',
      },
    },
  };
};
