import React from 'react';
import Button from 'shared/components/Button';
import { getTranslatedWord } from 'shared/utils/tools';
import styled from 'styled-components';

export type DialogActionsProps = {
  onContinue: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onExtra: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isProcessing: boolean;
  continueIsDisabled?: boolean;
  hasExtra?: boolean;
  hasCancel?: boolean;
  hasContinue?: boolean;
  cancelLabel?: string;
  extraLabel?: string;
  continueLabel?: string;
  role_level?: number;
  context?: 'action' | 'informational';
  variant?: string;
};

function DefaultDialogActions(props: DialogActionsProps) {
  const {
    onContinue,
    onCancel,
    onExtra,
    isProcessing,
    continueLabel,
    hasCancel,
    cancelLabel,
    hasExtra,
    extraLabel,
    hasContinue,
    continueIsDisabled,
    variant,
  } = props;

  return (
    <>
      {hasCancel && !hasContinue && (
        <CancelOnlyButton onClick={onCancel} children={getTranslatedWord(cancelLabel || '')} />
      )}
      {hasExtra && (
        <ActionButton
          variant="outline-primary outline-extra"
          onClick={onExtra}
          children={getTranslatedWord(extraLabel || '')}
        />
      )}
      {!hasCancel && hasContinue && (
        <ActionButton
          disabled={continueIsDisabled}
          isLoading={isProcessing}
          variant={variant || 'primary'}
          onClick={onContinue}
          children={getTranslatedWord(continueLabel || '')}
        />
      )}
      {hasCancel && hasContinue && (
        <>
          <ActionButton
            disabled={continueIsDisabled}
            isLoading={isProcessing}
            variant={variant || 'primary'}
            onClick={onContinue}
            children={getTranslatedWord(continueLabel || '')}
            mr="7px"
          />
          <ActionButton
            variant="secondary"
            onClick={onCancel}
            children={getTranslatedWord(cancelLabel || '')}
            mr="20px"
          />
        </>
      )}
    </>
  );
}

const ActionButton = styled(Button)<{ context?: 'action' | 'informational' }>`
  padding: 0;
  width: 140px;
  height: 56px;
`;

export const CancelOnlyButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.main};
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.gray.medium};
`;

DefaultDialogActions.defaultProps = {
  cancelLabel: 'Cancel',
  continueLabel: 'OK',
  hasCancel: true,
  hasContinue: true,
  hasExtra: false,
};

export default DefaultDialogActions;
