import React, { useEffect } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { hideNotification } from 'shared/redux/app/reducer'
import { GlobalState } from 'shared/redux/reducer'
import SnackbarDialog from '../SnackbarDialog'

const toastSelector = createSelector(
  (state: GlobalState) => state.app.dialogToast,
  e => e,
)
function ToastContainer() {
  const dispatch = useDispatch()
  const toast = useSelector(toastSelector)

  useEffect(() => {
    if (toast?.autohideTimeout) {
      setTimeout(() => dispatch(hideNotification()), toast.autohideTimeout)
    }
  }, [toast])

  return (
    toast && (
      <SnackbarDialog
        title={toast.message}
        type={toast.type}
        messageBody={toast.messageBody}
        subText={toast.subText}
        description={toast.description}
        onConfirm={onConfirm}
        onCancel={onClose}
        onClose={onClose}
        autohide={toast.autohideTimeout}
      />
    )
  )

  function onClose() {
    dispatch(hideNotification())
    if (toast?.onDismiss) {
      toast.onDismiss()
    }
  }

  function onConfirm() {
    dispatch(hideNotification())
    if (toast?.onConfirm) {
      toast.onConfirm()
    }
  }
}

export default ToastContainer
