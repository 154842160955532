import React from 'react'

const WorkSpaceIcon = ({
  fill = '#172271',
  width = '20',
  height = '21',
}: {
  fill?: string
  width?: string
  height?: string
}) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.38621 16.1552C9.22171 16.3197 9.01793 16.4395 8.79418 16.5032C8.75064 16.5154 8.71171 16.5402 8.68235 16.5746C8.65299 16.609 8.63453 16.6513 8.62933 16.6963L8.42257 18.4641C8.39955 18.6579 8.4375 18.854 8.53114 19.0251C8.62479 19.1963 8.76947 19.334 8.94505 19.4191C9.12062 19.5042 9.31836 19.5324 9.51073 19.4998C9.70309 19.4673 9.88052 19.3755 10.0183 19.2374L12.4511 16.8046C13.082 16.1638 13.5836 15.4074 13.9284 14.5768C14.1511 13.6809 14.263 12.7612 14.2618 11.838C14.2636 11.7922 14.2515 11.747 14.2271 11.7082C14.2027 11.6694 14.1672 11.6388 14.1252 11.6205C14.0832 11.6022 14.0366 11.597 13.9916 11.6056C13.9466 11.6142 13.9052 11.6361 13.8729 11.6686L9.38621 16.1552Z"
        fill="#353B5A"
      />
      <path
        d="M4.27739 11.0472L8.78773 6.52595C8.81962 6.49355 8.84112 6.45238 8.84949 6.40771C8.85786 6.36303 8.85272 6.31687 8.83474 6.27513C8.81675 6.23339 8.78673 6.19795 8.7485 6.17336C8.71028 6.14876 8.66559 6.13611 8.62014 6.13703C7.67358 6.13012 6.7296 6.23684 5.80846 6.4549C4.98561 6.81127 4.23888 7.32238 3.60885 7.96046L1.18427 10.385C1.05337 10.5163 0.964252 10.6834 0.928143 10.8652C0.892034 11.047 0.910551 11.2354 0.981358 11.4068C1.05217 11.5781 1.1721 11.7246 1.32604 11.8279C1.47998 11.9312 1.66105 11.9866 1.84643 11.9871C1.87831 11.9871 3.27823 11.8186 3.75094 11.7612C3.79454 11.7559 3.83568 11.7381 3.86944 11.71C3.9032 11.6819 3.92815 11.6447 3.9413 11.6028C4.00705 11.3932 4.12234 11.2027 4.27739 11.0472Z"
        fill="#353B5A"
      />
      <path
        d="M4.78255 14.3412C4.01291 13.5697 2.84707 13.7036 1.88343 14.6627C1.20123 15.3458 0.499903 17.5463 0.0317453 19.2732C-0.0102843 19.4282 -0.0105848 19.5915 0.0308743 19.7467C0.0723334 19.9018 0.154078 20.0433 0.267821 20.1566C0.381564 20.27 0.52326 20.3513 0.678546 20.3923C0.833832 20.4332 0.997185 20.4324 1.15205 20.3898C2.87257 19.9162 5.06854 19.2058 5.74801 18.5273C6.71074 17.5645 6.84098 16.3987 6.07044 15.6291L4.78255 14.3412Z"
        fill="#353B5A"
      />
      <path
        d="M19.8854 0.00872772C18.7487 0.23825 16.4644 0.771981 15.7558 1.48059C15.1656 2.06623 6.68318 10.574 5.40531 11.8492C5.36276 11.8918 5.33887 11.9497 5.33887 12.0099C5.33887 12.0702 5.36276 12.128 5.40531 12.1707L8.2598 15.0279C8.30248 15.0704 8.36029 15.0943 8.42055 15.0943C8.48082 15.0943 8.53863 15.0704 8.58131 15.0279L18.9445 4.66566C19.6541 3.95615 20.1878 1.67185 20.4164 0.536083C20.4304 0.46348 20.4263 0.388554 20.4045 0.317906C20.3827 0.247257 20.3439 0.183055 20.2914 0.130955C20.2389 0.0788545 20.1745 0.0404555 20.1037 0.0191409C20.0329 -0.00217366 19.9579 -0.00574975 19.8854 0.00872772Z"
        fill="#353B5A"
      />
    </svg>
  )
}

export default WorkSpaceIcon
