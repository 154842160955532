import styled from 'styled-components';

import Box from 'shared/components/Box';

import { darken } from 'polished';

export const DialogContainer = styled(Box)`
  margin: 32px 48px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main};

  .additional {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.caution};
  }

  .message {
    padding-bottom: 24px;
  }

  .overlapping-table {
    margin-left: 0px !important;
  }
`;

export const AvatarContainer = styled(Box)`
  &,
  .avatar-container {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const NameContainer = styled(Box)`
  :hover {
    text-decoration: none;
  }
`;

export const InputContainer = styled(Box)`
  display: flex;
  position: relative;
`;

export const FieldLabel = styled(Box)<{ caution?: boolean; rows?: number }>`
  display: flex;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  align-items: center;
  justify-content: center;

  width: auto;
  padding-right 8px;

  ${({ theme }) => `
    color: ${theme.colors.main};
  `}
`;

export const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
`;
