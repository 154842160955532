import { Dispatch } from 'react';
import { TableAction, TableState } from 'shared/components/DataTable/tableReducer';
import { UseQueryState } from 'shared/hooks/useQuery';

export const MANAGER = 'manager';
export const WORKER = 'worker';
export const ADMIN = 'admin';

export const MANAGER_ROLE = 5;
export const WORKER_ROLE = 6;

export type Option = {
  key?: any;
  label: any;
  value: any;
  hidden?: boolean;
  selected?: boolean;
  children?: Option[];
  parentValue?: any;
};

export type FormField = {
  id: string;
  error: string;
  onChange: (value: any, e: any) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  value: any;
  options?: Option[];
  required?: boolean;
};

export type FormErrors = {
  [key: string]: string;
};

export interface ResponseAPI<T> {
  data: T;
  message: string;
  status: boolean;
}

export type Identifiable = {
  id: string | number;
  status?: string;
};

export type FieldRendererProp = FormField &
  Identifiable & {
    index: number;
    errors: FormErrors;
    handleRemove: Function;
    key: string | number;
    length: number;
  };

export type MultiFieldProp<T extends Identifiable> = {
  errors: FormErrors;
  value: T[];
  fieldsRenderer: React.FC<FieldRendererProp>;
  schema: any;
  defaultItem: T;
  addLabel: string;
} & FormField;

export type ColumnSort = {
  column: string;
  direction: 'asc' | 'desc';
};

export interface PaginatedList<T> {
  data: any;
  total: number;
  last_page?: number;
  current_page?: number;
  total_pages?: number;
  total_work_duration?: number;
}

export interface LookupData extends Identifiable {
  name: string;
}

export type PageTableProps<T extends Identifiable> = {
  baseId?: string;
  tableState: TableState;
  tableDispatch: Dispatch<TableAction<T>>;
  tableQueryState: UseQueryState<PaginatedList<T>>;
  onRowClick?: (row: T) => void;
  onEditRow?: (row: T) => void;
  onDeleteRow?: (row: T) => void;
  onCopyRow?: (row: T) => void;
  queryHandlers?: {
    refetch(): void;
  };
};

export type SurveyObjectForm = {
  title: string;
  description: string;
  pages: SurveyPagesObject[];
};
export type SurveyPagesObject = {
  name: string;
  elements?: SurveyPageElements[];
};
export type SurveyPageElements = {
  name: string;
  type: string;
};

export type ReportResponse = {
  filename: string;
  data: object[];
};

export type PermissionId = string;
export type RouteRoleLevel = null | {
  up: null | number;
  equal: null | number;
  down: null | number;
};

export enum persona {
  'admin',
  'manager',
  'worker',
}

export interface LooseObject {
  [key: string]: any;
}
