import TakePhoto from 'shared/components/ImageTaker'
import flowRight from 'lodash/flowRight'
import withDialog from 'shared/hocs/withDialog'

const Dialog = flowRight(withDialog())(TakePhoto)

Dialog.defaultProps = {
  hasContinue: false
}

export default Dialog
