import React from 'react';

const TimeSchedulesIcon = ({
  fill = '#172271',
  width = '22',
  height = '22',
}: {
  fill?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 140 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.02899 110.046H69.0714C67.6038 105.901 66.8583 101.558 66.8583 97.1029C66.8583 91.8632 67.8855 86.7778 69.9114 81.9881C71.8673 77.3639 74.6663 73.2119 78.2308 69.6476C81.7952 66.0829 85.9471 63.2841 90.5713 61.3283C95.3611 59.3023 100.447 58.2751 105.686 58.2751C110.926 58.2751 116.011 59.3023 120.801 61.3283C121.27 61.5264 121.733 61.7359 122.192 61.9516V46.8935V21.7201C122.192 16.7335 118.149 12.6911 113.163 12.6911H103.779V6.60282C103.779 3.2785 101.085 0.583496 97.7601 0.583496H89.5826C86.2583 0.583496 83.5633 3.2785 83.5633 6.60282V12.6909H71.2045V6.60282C71.2045 3.2785 68.5095 0.583496 65.1852 0.583496H57.0072C53.6829 0.583496 50.9879 3.2785 50.9879 6.60282V12.6909H38.6292V6.60282C38.6292 3.2785 35.9342 0.583496 32.6098 0.583496H24.4318C21.1074 0.583496 18.4125 3.2785 18.4125 6.60282V12.6909H9.02899C4.04243 12.6909 0 16.7333 0 21.7199V46.8935V101.017C0 106.004 4.04243 110.046 9.02899 110.046ZM6.77174 21.7201C6.77174 20.4754 7.78434 19.4628 9.02899 19.4628H18.4124V23.1281C18.4124 26.4524 21.1072 29.1474 24.4317 29.1474H32.6097C35.934 29.1474 38.629 26.4524 38.629 23.1281V19.4628H50.9877V23.1281C50.9877 26.4524 53.6827 29.1474 57.0071 29.1474H65.1851C68.5094 29.1474 71.2044 26.4524 71.2044 23.1281V19.4628H83.5631V23.1281C83.5631 26.4524 86.2581 29.1474 89.5824 29.1474H97.7606C101.085 29.1474 103.78 26.4524 103.78 23.1281V19.4628H113.163C114.408 19.4628 115.42 20.4754 115.42 21.7201V40.1219H6.77159L6.77174 21.7201Z"
        fill={fill}
      />
      <path
        d="M140 97.103C140 84.1354 132.806 72.8495 122.192 67.0148C117.295 64.3222 111.67 62.7896 105.686 62.7896C86.7351 62.7896 71.3726 78.1522 71.3726 97.103C71.3726 101.683 72.2722 106.052 73.9002 110.046C79.0096 122.581 91.3153 131.417 105.686 131.417C124.637 131.417 140 116.054 140 97.103ZM122.192 100.113H109.766C108.846 101.368 107.361 102.183 105.686 102.183C102.894 102.183 100.631 99.9196 100.631 97.1272C100.631 95.4627 101.435 93.9867 102.677 93.0654V72.8599C102.677 71.1976 104.024 69.8502 105.686 69.8502C107.349 69.8502 108.696 71.1976 108.696 72.8599V93.0654C109.088 93.3566 109.437 93.7027 109.73 94.0931H122.192H129.929C131.591 94.0931 132.939 95.4405 132.939 97.1027C132.939 98.7651 131.591 100.112 129.929 100.112L122.192 100.113Z"
        fill={fill}
      />
    </svg>
  );
};

export default TimeSchedulesIcon;
