import React from 'react'
import { SvgIconProps } from './Home'

const SvgLogoIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={width ?? "24"}
    height={height ?? "24"}
  >
    <path
      fill={fill ?? "#172271"}
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zM5.512 12a6.488 6.488 0 1012.976 0 6.488 6.488 0 00-12.976 0z"
    ></path>
    <rect
      width="9.604"
      height="3.429"
      x="10.285"
      y="11.371"
      fill={fill ?? "#172271"}
      rx="1.714"
      transform="rotate(-45 10.285 11.37)"
    ></rect>
  </svg>
)

export default SvgLogoIcon