import React, { useContext, Fragment } from 'react';
import RmdCheckbox, { CheckboxProps } from 'react-md/lib/SelectionControls/Checkbox';
import styled, { ThemeContext } from 'styled-components';
import UncheckedCheckboxIcon from 'assets/icons/checkbox-unchecked.svg';
import SvgCheckboxCheckedIcon from 'assets/icons/CheckboxChecked';
import { compose, typography, TypographyProps } from 'styled-system';

type CheckProps = CheckboxProps &
  TypographyProps & {
    size?: 'medium' | 'small';
    error?: boolean;
    errorText?: string;
  };

const Checkbox: React.FC<CheckProps> = (props: CheckProps) => {
  const theme = useContext(ThemeContext);

  return (
    <CheckBoxWrapper>
      <StyledCheckbox
        {...props}
        checkedIcon={<SvgCheckboxCheckedIcon fill={theme.colors.green} />}
        uncheckedIcon={<img src={UncheckedCheckboxIcon} />}
      />
      {props?.error && props?.errorText && <ErrorMessage>{props?.errorText}</ErrorMessage>}
    </CheckBoxWrapper>
  );
};

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.caution};
`;

const StyledCheckbox = styled(RmdCheckbox)<CheckProps & { $size?: string }>`
  ${compose(typography)}

  height: unset;
  color: ${({ theme }) => theme.colors.gray.dark};

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.main};
    }

    .md-selection-control-toggle.md-text--secondary, {
      border: 1px solid ${({ theme }) => theme.colors.main};
    }
  }

  .md-selection-control-toggle.md-text--secondary > * {
    display: none;
  }

  .md-selection-control-toggle.md-text--disabled {
    & > img {
      display: none;
    }

    & > svg {
      opacity: 0.5;
    }
  }

  .md-icon.material-icons {
    font-size: 24px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  .md-selection-control-label {
    align-items: flex-start;
    display: flex;
  }

  .md-selection-control-toggle {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 5px;

    &.md-text--theme-secondary {
      padding: 0;
    }

    &.md-text--secondary {
      border: 1px solid ${({ theme }) => theme.colors.gray.dark};
    }

    &.md-text--disabled {
      border: 1px solid ${({ theme }) => theme.colors.gray.medium_dark};

      & + span {
        color: ${({ theme }) => theme.colors.gray.medium_dark};
      }

      &[aria-checked="true"] {
        border: none !important;
      }

      > svg {
        
        margin-left: -10px !important;
        margin-top: -10px !important;
      }
  }
`;

export default Checkbox;
