import React, { useContext } from 'react';
import SvgSmileIcon from 'assets/icons/Smile';
import SvgAlertIcon from 'assets/icons/Alert';
import SvgBellIcon from 'assets/icons/Bell';

import styled, { ThemeContext } from 'styled-components';
import Box from './Box';
import { LayoutProps, SpaceProps, TypographyProps } from 'styled-system';

type AlertComponentProps = SpaceProps &
  LayoutProps &
  TypographyProps & {
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> & any) => void;
    onLink?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    actionText?: string;
    link?: string;
    text: string;
    type: 'success' | 'danger' | 'warning' | 'info';
  };

const AlertComponent: React.FC<AlertComponentProps> = (props: AlertComponentProps) => {
  const { onClick = () => {}, onLink = () => {}, type, text, actionText, link, ...restProps } = props;
  const theme = useContext(ThemeContext);

  return (
    <AlertComponentContainer {...restProps} $type={type}>
      <AlertBox>
        <AlertIcon>
          {type === 'success' && <SvgSmileIcon fill={theme.colors.green} />}
          {type === 'danger' && <SvgAlertIcon fill={theme.colors.caution} />}
          {type === 'warning' && <SvgAlertIcon fill={theme.colors.dark_yellow} />}
          {type === 'info' && <SvgBellIcon fill={theme.colors.main} />}
        </AlertIcon>
        <AlertContent ml="16px" className="alert-text">
          {text}
          <span className="link" onClick={onLink}>
            {link}
          </span>
        </AlertContent>
      </AlertBox>
      {actionText && (
        <Box className="alert-action" onClick={onClick}>
          <span>{actionText}</span>
        </Box>
      )}
    </AlertComponentContainer>
  );
};

export const AlertComponentContainer = styled(Box)<{ $type: string }>`
  min-height: 44px;
  width: 100%;
  border-radius: 6px;
  padding: 8px 16px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  span {
    padding-left: 8px;
    color: ${({ theme }) => theme.colors.main} !important;
  }

  ${({ $type, theme }) => {
    switch ($type) {
      case 'success':
        return `
          color: ${theme.colors.green};
          background: ${theme.colors.secondaries.green};
        `;
      case 'danger':
        return `
          color: ${theme.colors.caution};
          background: ${theme.colors.secondaries.pink};
        `;
      case 'info':
        return `
          color: ${theme.colors.main};
          background: ${theme.colors.secondaries.blue};
        `;
      default:
        return `
          color: ${theme.colors.dark_yellow};
          background: ${theme.colors.yellow};
        `;
    }
  }}

  .alert-text {
    span {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }

  .alert-action {
    cursor: pointer;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.main};
  }

  ${({ theme }) => theme.mediaQueries.mediumDown} {
    display: grid;
    grid-template-columns: auto auto;
  }
`;

export const AlertBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const AlertIcon = styled(Box)`
  max-width: 24px;
  max-height: 24px;
`;

export const AlertContent = styled(Box)`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.largeDown} {
    flex-direction: column;

    span {
      padding-left: 0px !important;
    }
  }
`;

export const AlertContainer = styled(Box)<{ isPreference?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  padding: ${({ isPreference }) => (isPreference ? '8px 24px 8px 8px' : '0px')};

  ${AlertComponentContainer} {
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mediaQueries.largeDown} {
    padding: 0px;
  }
`;

export default AlertComponent;
