import React from 'react';
import DialogLayout, { DialogLayoutProps } from 'shared/components/Layout/Dialog';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { hideDialog, dialogProcessing, minimizeDialog } from 'shared/redux/app/reducer';
import useForm, { UseFormParams, FormState, FormHandlers } from 'shared/hooks/useForm';
import getDisplayName from './getDisplayName';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'shared/redux/reducer';
import { dataSelector } from 'shared/redux/selectors';

const dialogProps = [
  'dialogId',
  'dialogActionsRenderer',
  'dialogTitleRenderer',
  'title',
  'subtitle',
  'dialogContainerClassName',
  'dialogClassName',
  'cancelLabel',
  'extraLabel',
  'continueLabel',
  'hasExtra',
  'hasCancel',
  'hasContinue',
  'hasClose',
  'withActions',
  'titleClassName',
  'contentClassName',
  'footerClassName',
  'minimize',
  'context',
  'width',
  'height',
  'variant',
];
const formProps = ['initialFields', 'validator', 'customChangeHandler', 'onValid'];

export type WithDialogProps<T> = DialogLayoutProps &
  UseFormParams<T> & {
    continueIsDisabled?(form: T): boolean;
    contentStyles?: any;
  };

export type WrappedProps<T> = Pick<DialogLayoutProps, 'onCancel' | 'onContinue' | 'onClose' | 'onExtra'> & {
  formState: FormState<T>;
  formHandlers: FormHandlers;
  contentStyles: any;
};

export type DialogContentProps<T> = {
  formState: FormState<T>;
  formHandlers: FormHandlers;
};

export default () => {
  return function<T>(WrappedComponent: React.FC<WrappedProps<T>>) {
    function Dialog(props: WithDialogProps<T>) {
      const dispatch = useDispatch();
      const isProcessing = useSelector((state: GlobalState) => state.app.dialogProcessing);
      const role_level = useSelector((state: GlobalState) => state.auth.user?.role_level);
      const { isProgressing } = useSelector(dataSelector);
      const { initialFields } = props;
      const [formState, formHandlers] = useForm<T>({ ...pick(props, formProps), onValid, initialFields });
      const { title, contentStyles } = props;
      return (
        <DialogLayout
          {...pick(props, dialogProps)}
          title={title}
          onContinue={props.onContinue || onContinue}
          onCancel={props.onCancel || onCancel}
          onExtra={props.onExtra || onExtra}
          onClose={props.onClose || onCancel}
          onMinimize={props.onMinimize || onMinimize}
          isProcessing={isProcessing || !!isProgressing}
          role_level={role_level}
          continueIsDisabled={continueIsDisabled()}
        >
          <WrappedComponent
            formState={formState}
            formHandlers={formHandlers}
            onContinue={onContinue}
            onExtra={onExtra}
            onCancel={onCancel}
            onClose={onCancel}
            contentStyles={contentStyles}
            {...omit(props, dialogProps.concat(formProps))}
          />
        </DialogLayout>
      );

      function continueIsDisabled() {
        const { continueIsDisabled = () => false } = props;
        return continueIsDisabled(formState.fields);
      }

      function onValid(arg: T) {
        dispatch(dialogProcessing(true));
        props.onValid && props.onValid(arg);
      }
      function onContinue() {
        formHandlers.onValidate();
      }
      function onExtra() {
        formHandlers.onValidate();
      }
      function onCancel() {
        dispatch(hideDialog());
      }

      function onMinimize() {
        dispatch(minimizeDialog(title));
      }
    }
    Dialog.displayName = `withDialog(${getDisplayName(WrappedComponent)})`;
    return Dialog;
  };
};
