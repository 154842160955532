import CookieSvg from 'assets/icons/CookieSvg';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { createCookie, getCookie } from 'shared/utils/helpers';
import useLocaleChange from 'shared/hooks/useLocaleChange';

import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { getTranslatedWord } from 'shared/utils/tools';
import { useSelector } from 'react-redux';
import { dataSelector } from 'shared/redux/selectors';

interface IProps {
  message: string;
  url?: string;
  actions?: Array<React.FC> | React.FC;
}

const CookiePrompt = (props: IProps) => {
  const { message, url = '#', actions } = props;
  const [accepted, setAccepted] = useState(getCookie('cookie-accepted') === 'true');
  useSelector(dataSelector);

  useEffect(() => {
    if (isEmpty(getCookie('cookie-accepted'))) {
      createCookie('cookie-accepted', 'false', 36, document.location.hostname);
    }
  }, []);

  return (
    <Container className={classNames(accepted ? 'hide' : 'show')}>
      <div>
        <CookieSvg />
      </div>
      {message && (
        <p>
          {getTranslatedWord(message) + ' '}
          <a href={url} target="_blank">
            {getTranslatedWord('More details')}
          </a>
        </p>
      )}
      {actions ? (
        actions
      ) : (
        <Button variant="primary" onClick={onConfirm}>
          {getTranslatedWord('Confirm')}
        </Button>
      )}
    </Container>
  );

  function onConfirm() {
    setAccepted(true);
    createCookie('cookie-accepted', 'true', 36, document.location.hostname);
  }
};

const Container = styled.div`
  position: fixed;
  grid-template-columns: auto auto auto;
  right: 33px;
  min-height: 88px;
  min-width: 565px;
  max-width: 565px;
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: 6px;
  display: grid;
  z-index: 1000000;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: bottom 0.3s, min-width 0.1s;

  a {
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  > :not(:last-child) {
    margin-right: 16px;
  }

  p {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  &.show {
    bottom: 33px;
  }

  &.hide {
    bottom: -240px;
  }

  @media screen and (max-width: 1024px) {
    right: 0;
    min-width: 100vw;
    z-index: 10000002 !important;
    &.show {
      bottom: 0;
    }
    padding-top: 4px;
    padding-bottom: 4px;

    svg {
      width: 32px;
      height: 32px;
    }

    p {
      font-size: 14px;
    }

    button {
      font-size: 14px;
      height: 42px;
    }
  }
`;

export default CookiePrompt;
