import { genericTexts } from 'shared/utils/formats';
import { getValidationResult } from 'shared/utils/tools';
import * as yup from 'yup';
const { isRequired, isNumber } = genericTexts;

export const companyInfoSchema = yup.object().shape({
  address: yup.string().notRequired(),
  company_address: yup.string(),
  addresses: yup
    .array()
    .of(yup.string())
    .notRequired(),
  phone_numbers: yup
    .array()
    .of(yup.string())
    .notRequired(),
  activity: yup
    .string()
    .nullable(true)
    .notRequired(),
  tax_number: yup
    .string()
    .nullable(true)
    .notRequired(),
  bank_account: yup.string().notRequired(),
  telephone_number: yup
    .string()
    .nullable(true)
    .notRequired(),
  name: yup.string().required(isRequired),
  has_user: yup.boolean().notRequired(),
  phone: yup.string().notRequired(),
  email: yup
    .string()
    .email()
    .notRequired()
    .when('has_user', {
      is: true,
      then: yup
        .string()
        .email()
        .required(isRequired),
    }),
  is_email_spoof: yup.boolean().notRequired(),
  apiKey: yup
    .string()
    .nullable(true)
    .notRequired(),
  comment: yup
    .string()
    .nullable(true)
    .notRequired(),
  trial_ends_at: yup
    .date()
    .nullable(true)
    .notRequired(),
  price_admin: yup
    .string()
    .nullable(true)
    .notRequired(),
  price_user: yup
    .string()
    .nullable(true)
    .notRequired(),
});

export type CompanyInfoType = yup.InferType<typeof companyInfoSchema>;

export function getEmptyCompanyInfo(): CompanyInfoType {
  return {
    has_user: true,
    tax_number: '',
    bank_account: '',
    telephone_number: '',
    activity: '',
    name: '',
    email: '',
    address: '',
    addresses: [''],
    phone_numbers: [''],
    phone: '',
    company_address: '',
    apiKey: '',
    comment: '',
    trial_ends_at: null,
    is_email_spoof: false,
    price_admin: '',
    price_user: '',
  };
}

export default function validator(data: CompanyInfoType) {
  return getValidationResult(data, companyInfoSchema);
}
