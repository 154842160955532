import React from 'react';

const DashboardIcon = ({
  fill = '#172271',
  width = '20',
  height = '21',
}: {
  fill?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.254782 0.254807C0.0916961 0.417861 4.92499e-05 0.639023 0 0.86965V10.4488C0 10.6795 0.0916146 10.9007 0.25469 11.0638C0.417765 11.2269 0.638942 11.3185 0.869565 11.3185H7.82608C8.05671 11.3185 8.27788 11.2269 8.44096 11.0638C8.60403 10.9007 8.69565 10.6795 8.69565 10.4488V0.882695C8.69565 0.652049 8.60403 0.43085 8.44096 0.267759C8.27788 0.104668 8.05671 0.0130448 7.82608 0.0130448L0.869565 0C0.638961 4.92547e-05 0.417819 0.0917052 0.254782 0.254807Z"
        fill={fill}
      />
      <path
        d="M19.1298 0L12.1733 0.0121751C11.9427 0.0121751 11.7215 0.103799 11.5584 0.26689C11.3953 0.429981 11.3037 0.65118 11.3037 0.881825V5.23008C11.3037 5.46072 11.3953 5.68192 11.5584 5.84501C11.7215 6.0081 11.9427 6.09973 12.1733 6.09973H19.1298C19.3604 6.09973 19.5816 6.0081 19.7447 5.84501C19.9077 5.68192 19.9994 5.46072 19.9994 5.23008V0.86965C19.9994 0.639005 19.9077 0.417806 19.7447 0.254715C19.5816 0.0916236 19.3604 0 19.1298 0Z"
        fill={fill}
      />
      <path
        d="M8.44086 19.7585C8.60395 19.5955 8.6956 19.3743 8.69565 19.1437V14.7954C8.69565 14.5648 8.60403 14.3436 8.44096 14.1805C8.27788 14.0174 8.05671 13.9258 7.82608 13.9258H0.869565C0.638942 13.9258 0.417765 14.0174 0.25469 14.1805C0.0916146 14.3436 0 14.5648 0 14.7954V19.1306C0 19.3613 0.0916146 19.5825 0.25469 19.7456C0.417765 19.9087 0.638942 20.0003 0.869565 20.0003L7.82608 20.0125C8.05656 20.0126 8.27769 19.9213 8.44086 19.7585Z"
        fill={fill}
      />
      <path
        d="M19.1298 8.70898H12.1733C11.9427 8.70898 11.7215 8.80061 11.5584 8.9637C11.3953 9.12679 11.3037 9.34799 11.3037 9.57863V19.1448C11.3037 19.3754 11.3953 19.5966 11.5584 19.7597C11.7215 19.9228 11.9427 20.0144 12.1733 20.0144L19.1298 20.0023C19.3604 20.0023 19.5816 19.9106 19.7447 19.7475C19.9077 19.5845 19.9994 19.3633 19.9994 19.1326V9.57863C19.9994 9.34799 19.9077 9.12679 19.7447 8.9637C19.5816 8.80061 19.3604 8.70898 19.1298 8.70898Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
