import styled from 'styled-components';
import Box from 'shared/components/Box';
import { darken } from 'polished';

import Button from '../Button';

export const BodyContainer = styled.div`
  padding: 0;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    margin-bottom: 68px !important;
  }
`;

export const NotificationHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;

  ${({ theme }) => `
    color: ${theme.colors.black};

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-bottom: 1px solid ${theme.colors.gray.medium};
      bottom: 0;
      left: -24px;
    };
  `}
`;

export const LabelDivider = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.main};
  height: 40px;
  margin-bottom: 24px;
  padding 24px;
`;

export const NotificationContentWrapper = styled(Box)<{ isEmpty?: boolean }>`
  min-width: 320px;
  align-self: flex-start;
  z-index: 10;
  margin-right: 12px;
  width: calc(100% - 64px) !important;

  ${({ isEmpty }) => {
    if (isEmpty) {
      return `
        text-align: center !important;
        font-size: 16px;
        font-weight: 500;
        width: 100% !important;
      `;
    }
  }}

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    min-width: unset;
    width: 100%;
  }
`;

export const NotificationDate = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
  `}
`;

export const NotificationActionLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.main};
  padding-top: 4px;
`;

export const NotificationActionDeclinedLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.caution};
  padding-top: 4px;
`;

export const NotificationActionContainer = styled(Box)`
  display: flex;
  margin: 12px 0px;
`;

export const NotificationButton = styled(Button)`
  margin: 0px 4px;
  height: 40px !important;
`;

export const NotificationMessage = styled(Box)`
  z-index: unset;
  word-wrap: break-word !important;
  white-space: pre-wrap;
`;

export const NotificationComment = styled(Box)`
  z-index: unset;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
`;

export const HeaderTitle = styled(Box)`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
`;

export const IconActionWrapper = styled(Box)`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ItemIconWrapper = styled(Box)`
  min-width: 32px;
  display: block;
  align-items: center;
  justify-content: center;

  align-self: flex-start;
  margin-top: 5px;
  margin-right: 8px;
  height: 100%;

  .dot {
    color: white;
    position: relative;
    top: 0;
    right: 0;
    height: 6px;
    width: 6px;
    font-size: 6px;
    border-radius: 6px;
    margin-left: 9px;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.caution};
  }
`;
export const MoreOptionContainer = styled(Box)`
  display: block;
  justify-content: flex-end;
  width: 32px;
  z-index: 2000;
  background: transparent;
`;

export const NotificationContainer = styled(Box)`
  width: 376px;
  min-height: 162px;
  max-height: 800px;

  padding: 24px 24px 16px 24px;
  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  z-index: 20;
  right: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  text-transform: capitalize;

  animation: fadeIn ease-in-out 0.2s;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(23, 34, 113, 0.07), 0px 4px 12px rgba(23, 34, 113, 0.07);
  border-radius: 6px;

  ${({ theme }) => `
    color: ${theme.colors.gray.dark};
    border: 1px solid ${theme.colors.gray.medium};
    background: ${theme.colors.white};
  `}
`;
export const NotificationItemContainer = styled(Box)<{ $background?: string }>`
  width: 100%;
  min-height: 90px;
  margin-bottom: 1px;
  padding 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  display: flex;
  align-items: center;
  cursor: pointer;

  & > * {
    z-index: unset;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    position: absolute;
    left: -24px;
  };

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ $background, theme }) => {
    switch ($background) {
      case 'primary':
        return `
          color: ${theme.colors.main};
          background: ${theme.colors.secondaries.blue};

          &:hover {
            background: ${darken('0.02', theme.colors.secondaries.blue)};
          }
        `;
      case 'success':
        return `
          color: ${theme.colors.green};
          background: ${theme.colors.secondaries.green};

          &:hover {
            background: ${darken('0.02', theme.colors.secondaries.green)};
          }
        `;
      case 'danger':
        return `
          color: ${theme.colors.caution};
          background: ${theme.colors.secondaries.pink};

          &:hover {
            background: ${darken('0.02', theme.colors.secondaries.pink)};
          }
        `;
      case 'warning':
        return `
          color: ${theme.colors.dark_yellow};
            background: ${theme.colors.yellow};
          }

          &:hover {
            background: ${darken('0.02', theme.colors.yellow)};
          }
        `;
      default:
        return `
          color: ${theme.colors.gray.dark};
          background: ${theme.colors.gray.light};
          
          &:hover {
            background: ${darken('0.02', theme.colors.gray.light)};
          }
        `;
    }
  }}
`;
