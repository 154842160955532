import React from 'react'
import { SvgIconProps } from './Home'

const SvgLogoutMenuIcon = ({ fill, width, height }: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "19"}
    height={height ?? "17"}
    fill="none"
    viewBox="0 0 19 17"
  >
    <path
      fill={fill ?? "#353B5A"}
      d="M5.25 10.348c-.333 0-.663-.073-.964-.213a2.246 2.246 0 01-.778-.6 2.205 2.205 0 01-.465-1.844c.115-.51.406-.967.823-1.291a2.326 2.326 0 011.467-.487H10.5c.1 0 .195-.039.266-.108.07-.07.11-.164.11-.262V.74a.734.734 0 00-.22-.523.756.756 0 00-.53-.216H.75a.756.756 0 00-.53.216A.734.734 0 000 .74v15.522c0 .196.079.384.22.523.14.138.331.216.53.216h9.375c.2 0 .39-.078.53-.216a.734.734 0 00.22-.523v-5.544a.367.367 0 00-.11-.26.378.378 0 00-.265-.11H5.25z"
    ></path>
    <path
      fill={fill ?? "#353B5A"}
      d="M17.72 7.553l-.869-.684-1.007-.795-1.875-1.478a.756.756 0 00-1.103.185.732.732 0 00-.115.393v1.848h-7.5c-.298 0-.585.117-.796.325a1.1 1.1 0 000 1.568c.211.207.498.324.796.324h7.5v1.848a.731.731 0 00.426.667.759.759 0 00.793-.09l1.875-1.478 1.007-.794.87-.685a.741.741 0 00.281-.577.73.73 0 00-.281-.577h-.002z"
    ></path>
  </svg>
)

export default SvgLogoutMenuIcon
