import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Box from 'shared/components/Box';
import { darken } from 'polished';
import { Button as RButton } from 'reakit';
import {
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant as variantHelper,
} from 'styled-system';

type ButtonProps = SpaceProps &
  LayoutProps &
  TypographyProps & {
    id?: string;
    type?: 'button' | 'submit' | 'reset';
    value?: string;
    className?: string;
    children?: ReactNode;
    onClick?: any;
    disabled?: boolean;
    block?: boolean;
    expanded?: boolean;
    variant?: string;
    isLoading?: boolean;
    icon?: string | ReactNode;
    size?: 'small' | 'medium' | 'large' | 'extraLarge';
    round?: boolean;
    background?: string;
  };

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    className,
    block,
    expanded,
    disabled,
    id,
    type,
    value,
    onClick = () => {},
    isLoading,
    size,
    round,
    ...restProps
  } = props;

  const buttonClasses = classNames(className, {
    block: block,
    expanded: expanded,
  });

  const isActionButton = useMemo(() => restProps?.variant === 'action', [restProps?.variant]);

  return (
    <ButtonContainer
      id={id}
      type={type}
      className={buttonClasses}
      value={value}
      onClick={onClick}
      disabled={disabled}
      $size={size}
      $round={round}
      {...restProps}
    >
      {isActionButton && !props.isLoading && props.icon !== undefined && getIcon(props.icon, true)}
      {props.isLoading ? <div className="spinner" /> : props.children}
      {!isActionButton && !props.isLoading && props.icon !== undefined && getIcon(props.icon, false)}
    </ButtonContainer>
  );

  function getIcon(icon: string | ReactNode, isActionButton?: boolean) {
    return (
      <IconContainer $action={isActionButton}>
        {typeof icon === 'string' ? <img src={icon} className={'button__icon'} /> : props.icon}
      </IconContainer>
    );
  }
};

const ButtonContainer = styled(RButton)<ButtonProps & { $size?: string; $round?: boolean }>`
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ $round }) => ($round ? '50%' : '150px')};
  min-width: 85px;
  width: fit-content;
  padding: 12px 16px;
  text-transform: capitalize;

  ${compose(space, layout, typography)}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return 'height: 32px;';
      case 'medium':
        return 'height: 48px;';
      case 'large':
        return 'height: 56px;';
      case 'extraLarge':
        return 'height: 60px;';
      default:
        return 'height: 48px;';
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.main};
          color: ${theme.colors.white};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.very_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.almost_black};
          }

          &:disabled {
            background-color: ${theme.colors.gray.medium_dark};
          }
        `;
      case 'secondary':
        return `
          font-weight: normal;
          background-color: ${theme.colors.gray.medium};
          color: ${theme.colors.gray.dark};
          border: 0;

          &:hover {
            background-color: ${theme.colors.gray.medium_dark};
          }

          &:active {
            background-color: ${theme.colors.gray.dark_gray};
          }

          &:disabled {
            color: ${theme.colors.gray.medium_dark};
            background-color: ${theme.colors.gray.light_gray};
          }
        `;
      case 'action':
        return `
          border: 0;
          font-size: 14px;
          font-weight: normal;
          line-height: 24px;

          background-color: ${theme.colors.secondaries.blue};
          color: ${theme.colors.gray.dark};

          border-radius: 6px;
          height: 32px;
          padding: 8px 16px;
          opacity: 0.6;

          &:hover {
            background-color: ${theme.colors.hover.action};
          }

          &:active {
            background: ${theme.colors.active.action};
          }

          &:disabled {
            opacity: 0.6;
            color: ${theme.colors.gray.dark};
            background-color: ${theme.colors.secondaries.blue};
          }
        `;

      case 'anchor':
        return `
          border: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 32px;
          color: ${theme.colors.main};
          background: none;

          &:hover {
            color: ${theme.colors.hover.anchor};
            text-decoration-line: underline;
          }

          &:active {
            color: ${theme.colors.active.anchor};
          }

          &:disabled {
            color: ${theme.colors.disabled};
          }
        `;

      case 'success':
        return `
          border: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 32px;
          color: #fff;
          background: ${theme.colors.green};

          &:hover {
            text-decoration-line: underline;
          }

          &:active {
          }

          &:disabled {
            color: ${theme.colors.gray.medium_dark};
          }
        `;

      case 'warning':
        return `
          border: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 32px;
          color: ${theme.colors.dark_yellow};
          background: ${theme.colors.yellow};

          &:hover {
            text-decoration-line: underline;
          }

          &:disabled {
            color: ${theme.colors.gray.medium_dark};
          }
        `;
      default:
        return `
          background: none;
          border: 0;
        `;
    }
  }}

  &:focus {
    outline: none;
  }
`;

const IconContainer = styled.div<{ $action?: boolean }>`
  display: flex;

  ${({ $action }) => `
    ${$action ? `margin-right: 10px;` : `margin-left: 10px;`}
  `}
`;

// export default Button

type BadgeProps = {
  size?: 'small' | 'medium';
  variant?: string;
  inverted?: boolean;
  children?: React.ReactNode;
  round?: boolean;
};

export default function Badge(props: BadgeProps) {
  const { size = 'medium', variant = 'primary', inverted = false, round = false } = props;
  return (
    <BadgeContainer $size={size} $variant={variant} $inverted={inverted} $round={round}>
      {props?.children}
    </BadgeContainer>
  );
}

const BadgeContainer = styled(Box)<{ $size: string; $variant: string; $inverted: boolean; $round?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  width: max-content;
  text-transform: uppercase;
  margin-left: 5px;

  ${({ $size, theme, $round }) => {
    switch ($size) {
      case 'small': {
        if ($round) {
          return `
            border-radius: 50%;
            width: 24px;
            height: 24px;
          `;
        } else {
          return `
            min-width: 59px;
            height: 24px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            border-radius: 6px;
          `;
        }
      }
      default: {
        if ($round) {
          return `
            border-radius: 50%;
            width: 32px;
            height: 32px;
          `;
        } else {
          return `
            min-width: 59px;
            height: 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 32px;
            border-radius: 6px;
          `;
        }
      }
    }
  }}

  ${({ $variant, $inverted, theme }) => {
    switch ($variant) {
      case 'success':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.green};
            &:hover {
              background: ${darken('0.02', theme.colors.green)};
            }
          `
              : `
            color: ${theme.colors.green};
            background: ${theme.colors.secondaries.green};
            &:hover {
              background: ${darken('0.02', theme.colors.secondaries.green)};
            }
          `
          }
        `;
      case 'danger':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.caution};
            &:hover {
              background: ${darken('0.02', theme.colors.caution)};
            }
          `
              : `
            color: ${theme.colors.caution};
            background: ${theme.colors.secondaries.pink};
            &:hover {
              background: ${darken('0.02', theme.colors.secondaries.pink)};
            }
          `
          }
        `;
      case 'warning':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.dark_yellow};
            &:hover {
              background: ${darken('0.02', theme.colors.dark_yellow)};
            }
          `
              : `
            color: ${theme.colors.dark_yellow};
            background: ${theme.colors.yellow};
            &:hover {
              background: ${darken('0.02', theme.colors.yellow)};
            }
          `
          }
        `;
      case 'dark':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.gray.dark};
            &:hover {
              background: ${darken('0.02', theme.colors.gray.dark)};
            }
          `
              : `
            color: ${theme.colors.gray.dark};
            background: ${theme.colors.gray.medium};
            &:hover {
              background: ${darken('0.02', theme.colors.gray.medium)};
            }
          `
          }
        `;

      case 'darker':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.gray.dark};
            &:hover {
              background: ${darken('0.02', theme.colors.main)};
            }
          `
              : `
            color: ${theme.colors.white};
            background: ${theme.colors.main};
            &:hover {
              background: ${darken('0.02', theme.colors.main)};
            }
          `
          }
        `;

      case 'light':
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.main};
            font-weight: bolder;
            background: ${theme.colors.white};
            &:hover {
              background: ${darken('0.02', theme.colors.white)};
            }
          `
              : `
            color: ${theme.colors.main};
            font-weight: bolder;
            background: ${theme.colors.white};
            &:hover {
              background: ${darken('0.02', theme.colors.white)};
            }
          `
          }
        `;

      default:
        return `
          ${
            $inverted
              ? `
            color: ${theme.colors.white};
            background: ${theme.colors.main};
            &:hover {
              background: ${darken('0.02', theme.colors.main)};
            }
          `
              : `
            color: ${theme.colors.main};
            background: ${theme.colors.secondaries.blue};
            &:hover {
              background: ${darken('0.02', theme.colors.secondaries.blue)};
            }
          `
          }
        `;
    }
  }}
`;
