import React from 'react';
import { flowRight } from 'lodash';

import withDrawer from 'shared/hocs/withDrawer';

import { ConnectionSettingsType } from 'pages/VismaSettings/model/connectionSettings';

import Box from 'shared/components/Box';
import TextField from 'shared/components/TextField';

import { ResponseAPI } from 'shared/types';

import useQuery from 'shared/hooks/useQuery';

import { ConnectionSettingsProps } from './types';
import { DrawerContainer } from './styledComponents';
import { getTranslatedWord } from 'shared/utils/tools';

const ConnectionSettings = ({
  formState: { fields, errors },
  formHandlers: { onElementChange, onSetForm },
}: ConnectionSettingsProps) => {
  const [queryState, queryHandler] = useQuery<null | ResponseAPI<ConnectionSettingsType>>(
    {
      url: `admin/netvisor/settings`,
    },
    {
      initialData: null,
      onFetchSuccess: handleFetchSuccess,
    },
  );

  if (queryState?.loading)
    return (
      <Box padding="32px 48px">
        <div className="spinner spinner-settings" />
      </Box>
    );

  return (
    <DrawerContainer>
      <TextField
        id="url"
        label="Url"
        labelId="visma-connection-settings-url"
        onChange={onElementChange}
        errorText={getTranslatedWord(errors.url)}
        value={fields?.url}
        placeholder="Business ID"
        marginBottom="32px"
        caution={Boolean(errors.url)}
        error={Boolean(errors.url)}
      />
      <TextField
        id="partnerID"
        label="Partner ID"
        labelId="visma-connection-settings-partnerID"
        onChange={onElementChange}
        errorText={getTranslatedWord(errors.partnerID)}
        value={fields?.partnerID}
        placeholder="User ID"
        marginBottom="32px"
        caution={Boolean(errors.partnerID)}
        error={Boolean(errors.partnerID)}
      />
      <TextField
        id="partnerKey"
        label="Partner key"
        labelId="visma-connection-settings-partnerKey"
        onChange={onElementChange}
        errorText={getTranslatedWord(errors.partnerKey)}
        value={fields?.partnerKey}
        placeholder="User key"
        marginBottom="32px"
        caution={Boolean(errors.partnerKey)}
        error={Boolean(errors.partnerKey)}
      />
    </DrawerContainer>
  );

  function handleFetchSuccess(response: null | ResponseAPI<ConnectionSettingsType>) {
    if (response?.data) onSetForm(response?.data);
  }
};

const ConnectionSettingsDrawer = flowRight(withDrawer())(ConnectionSettings);

ConnectionSettingsDrawer.defaultProps = {
  title: 'Connection Settings',
};

export default ConnectionSettingsDrawer;
