import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { flatMap } from 'lodash';
import moment from 'moment';

import axios from 'shared/utils/axios';

import { Company, CompanySettings, OvertimeGroup } from 'shared/types/api';
import { GlobalState } from 'shared/redux/reducer';

import {
  // Missing Works
  _fetchMissingWorks,
  fetchMissingWorks,
  // Division
  _fetchDivision,
  fetchDivisions,
  // Work types
  _fetchWorkTypes,
  fetchWorkTypes,
  // People
  _fetchPeople,
  fetchPeople,
  _fetchAllPeople,
  fetchAllPeople,
  // Login boxes
  _fetchLoginBoxes,
  fetchLoginBoxes,
  // Preferences
  _fetchOvertimeGroups,
  fetchOvertimeGroups,
  _fetchWorkshiftGroups,
  fetchWorkshiftGroups,
  _fetchAllowedPauseGroups,
  fetchAllowedPauseGroups,
  _fetchDailyAllowanceGroups,
  fetchDailyAllowanceGroups,
  _fetchCompensationGroups,
  fetchCompensationGroups,
  // Vacation types
  _fetchVacationTypes,
  fetchVacationTypes,
  // Inner range
  _fetchInnerRangeDoors,
  fetchInnerRangeDoors,
  _fetchInnerRangeSettings,
  fetchInnerRangeSettings,
  // Geofence
  _fetchGeofenceGateways,
  fetchGeofenceGateways,
  // Netvisor
  _fetchNetvisorDimensions,
  fetchNetvisorDimensions,
  _fetchNetvisorSettings,
  fetchNetvisorSettings,
  // Notifications
  _fetchNotifications,
  fetchNotifications,
  // Permissions
  _fetchPermissions,
  fetchPermissions,
  // Tranlations
  _fetchTranslations,
  fetchTranslations,
  // Company settings
  setCompanySettings,
  fetchCompanySettings,
  setHolidays,
  fetchHolidays,
} from 'shared/redux/data/reducer';

//=================================
// Fetch Missing Works
//=================================
function* FetchMissingWorks({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/companies/${payload.id}/workers/works/missing`,
      method: 'GET',
    });

    yield put(_fetchMissingWorks(data));
  } catch (err) {}
}

//=================================
// Fetch Divisions
//=================================
function* FetchDivisions({ payload }: PayloadAction<{ data: Company; rights: number[] | string[] }>) {
  const { data: company, rights } = payload;
  try {
    const { data } = yield call(axios.request, {
      url: `/companies/${company.id}/divisions`,
      method: 'GET',
      params: {
        is_notDeleted: true,
      },
    });

    yield put(_fetchDivision({ data, rights }));
  } catch (error) {}
}

//=================================
// Fetch Work Types
//=================================
function* FetchWorkTypes({ payload }: PayloadAction<any>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/companies/${payload?.company_id}/work-types`,
      method: 'GET',
      params: payload,
    });

    yield put(_fetchWorkTypes(data));
  } catch (err) {}
}

//=================================
// Fetch People
//=================================
function* FetchPeople({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/companies/${payload.id}/workers`,
      method: 'GET',
    });

    yield put(_fetchPeople(data));
  } catch (err) {}
}

function* FetchAllPeople({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/companies/${payload.id}/workers/all`,
      method: 'GET',
    });

    yield put(_fetchAllPeople(data));
  } catch (err) {}
}

//=================================
// Fetch Login Boxes
//=================================
function* FetchLoginBoxes({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/box`,
      method: 'GET',
      params: {
        company_id: payload.id,
      },
    });

    yield put(_fetchLoginBoxes(data));
  } catch (error) {}
}

//=================================
// Fetch Overtime Groups
//=================================
function* FetchOvertimeGroups({ payload }: PayloadAction<Company>) {
  try {
    const data: OvertimeGroup[] = yield call(axios.request, {
      url: `/companies/${payload.id}/overtimes/groups`,
      method: 'GET',
    });

    yield put(_fetchOvertimeGroups(data));
  } catch (err) {}
}

//=================================
// Fetch Workshift Groups
//=================================
function* FetchWorkshiftGroups({ payload }: PayloadAction<Company>) {
  try {
    const data: OvertimeGroup[] = yield call(axios.request, {
      url: `/companies/${payload.id}/work-rounds/groups`,
      method: 'GET',
    });

    yield put(_fetchWorkshiftGroups(data));
  } catch (err) {}
}

//=================================
// Fetch Allowed Pause Groups
//=================================
function* FetchAllowedPauseGroups({ payload }: PayloadAction<Company>) {
  try {
    const data: OvertimeGroup[] = yield call(axios.request, {
      url: `/companies/${payload.id}/allowed-pauses/groups`,
      method: 'GET',
    });

    yield put(_fetchAllowedPauseGroups(data));
  } catch (err) {}
}

//=================================
// Fetch Daily Allowance Groups
//=================================
function* FetchDailyAllowanceGroups({ payload }: PayloadAction<Company>) {
  try {
    const data: OvertimeGroup[] = yield call(axios.request, {
      url: `/companies/${payload.id}/daily-allowance/groups`,
      method: 'GET',
    });

    yield put(_fetchDailyAllowanceGroups(data));
  } catch (err) {}
}

//=================================
// Fetch Compensation Groups
//=================================
function* FetchCompensationGroups({ payload }: PayloadAction<Company>) {
  try {
    const data: OvertimeGroup[] = yield call(axios.request, {
      url: `/companies/${payload.id}/compensation/groups`,
      method: 'GET',
    });

    yield put(_fetchCompensationGroups(data));
  } catch (err) {}
}

//=================================
// Fetch Vacation Types
//=================================
function* FetchVactionTypes({ payload }: PayloadAction<Company[]>) {
  try {
    const { data } = yield call(axios.request, {
      url: `companies/work-leaves/types`,
      method: 'POST',
      data: {
        company_ids: flatMap(payload, company => Number(company?.id)),
      },
    });

    yield put(_fetchVacationTypes(data));
  } catch (err) {}
}

//=================================
// Fetch Inner Range Doors
//=================================
function* FetchInnerRangeDoors({ payload }: PayloadAction<Company>) {
  try {
    const {
      data: { data },
    } = yield call(axios.request, {
      url: `/inner-range/companies/${payload.id}/doors`,
      method: 'GET',
      params: {
        timestamp: moment().unix() * 1000,
      },
    });

    yield put(_fetchInnerRangeDoors(data));
  } catch (error) {}
}

//=================================
// Fetch Inner Range Settings
//=================================
function* FetchInnerRangeSettings({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/inner-range/companies/${payload.id}/settings`,
    });

    yield put(_fetchInnerRangeSettings(data));
  } catch (error) {}
}

//=================================
// Fetch Geofence Gateway
//=================================
function* FetchGeofenceGateways({ payload }: PayloadAction<Company>) {
  try {
    const {
      data: { data },
    } = yield call(axios.request, {
      url: `/geofence-api/companies/${payload.id}/gateways`,
      method: 'GET',
      params: {
        timestamp: moment().unix() * 1000,
      },
    });

    yield put(_fetchGeofenceGateways(data));
  } catch (error) {}
}

//=================================
// Fetch Netvisor Dimensions
//=================================
function* FetchNetvisorDimensions({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/netvisor/companies/${payload.id}/dimension`,
    });

    yield put(_fetchNetvisorDimensions(data));
  } catch (error) {}
}

//=================================
// Fetch Netvisor Settings
//=================================
function* FetchNetvisorSettings({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/netvisor/companies/${payload.id}/settings`,
    });

    yield put(_fetchNetvisorSettings(data));
  } catch (error) {}
}

//=================================
// Fetch Notifications
//=================================
function* FetchNotifications() {
  try {
    const { data } = yield call(axios.request, {
      url: `/notifications`,
    });

    yield put(_fetchNotifications(data));
  } catch (error) {}
}

//=================================
// Fetch Permissions
//=================================
function* FetchPermissions({ payload }: PayloadAction<Company>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/roles/permissions`,
    });

    yield put(_fetchPermissions(data));
  } catch (error) {}
}

//=================================
// Fetch Translations
//=================================
function* FetchTranslations() {
  try {
    const { data } = yield call(axios.request, {
      url: `/onesky/translations`,
      method: 'GET',
    });
    const { data: locales } = yield call(axios.request, {
      url: '/onesky/locale',
      method: 'GET',
    });

    yield put(_fetchTranslations({ ...data, locales }));
  } catch (err) {}
}

//=================================
// Fetch Company Settings
//=================================
function* FetchCompanySettings({ payload }: PayloadAction<Company>) {
  try {
    const data: CompanySettings = yield call(axios.request, {
      url: `/companies/${payload?.id}/settings`,
      method: 'GET',
    });

    let companySettings = {
      ...data,
      auto_end_workday: String(data?.auto_end_workday) === 'true',
      show_time_decimal: String(data?.show_time_decimal) === 'true',
      apply_all_workspace: String(data?.apply_all_workspace) === 'true',
      auto_add_pause_time: String(data?.auto_add_pause_time) === 'true',
    };

    yield put(setCompanySettings(companySettings));
  } catch (err) {}
}

//=================================
// Fetch Holidays
//=================================
function* FetchHolidays({ payload }: PayloadAction<{ code?: string }>) {
  try {
    const { data } = yield call(axios.request, {
      url: `/holidays/${payload.code}`,
      method: 'GET',
    });

    yield put(setHolidays(data));
  } catch (err) {}
}

export function* switchAccount() {
  const old: GlobalState = yield select(state => state);
}

export default function* rootSaga() {
  yield takeLatest(fetchMissingWorks.type, FetchMissingWorks);
  yield takeLatest(fetchDivisions.type, FetchDivisions);
  yield takeLatest(fetchWorkTypes.type, FetchWorkTypes);
  yield takeLatest(fetchPeople.type, FetchPeople);
  yield takeLatest(fetchAllPeople.type, FetchAllPeople);
  yield takeLatest(fetchLoginBoxes.type, FetchLoginBoxes);
  yield takeLatest(fetchOvertimeGroups.type, FetchOvertimeGroups);
  yield takeLatest(fetchWorkshiftGroups.type, FetchWorkshiftGroups);
  yield takeLatest(fetchAllowedPauseGroups.type, FetchAllowedPauseGroups);
  yield takeLatest(fetchDailyAllowanceGroups.type, FetchDailyAllowanceGroups);
  yield takeLatest(fetchCompensationGroups.type, FetchCompensationGroups);
  yield takeLatest(fetchVacationTypes.type, FetchVactionTypes);
  yield takeLatest(fetchInnerRangeDoors.type, FetchInnerRangeDoors);
  yield takeLatest(fetchInnerRangeSettings.type, FetchInnerRangeSettings);
  yield takeLatest(fetchGeofenceGateways.type, FetchGeofenceGateways);
  yield takeLatest(fetchNetvisorDimensions.type, FetchNetvisorDimensions);
  yield takeLatest(fetchNetvisorSettings.type, FetchNetvisorSettings);
  yield takeLatest(fetchNotifications.type, FetchNotifications);
  yield takeLatest(fetchPermissions.type, FetchPermissions);
  yield takeLatest(fetchTranslations.type, FetchTranslations);
  yield takeLatest(fetchCompanySettings.type, FetchCompanySettings);
  yield takeLatest(fetchHolidays.type, FetchHolidays);
}
