import React, { ReactNode } from 'react';
import moment from 'moment';

import flowRight from 'lodash/flowRight';

import withDialog from 'shared/hocs/withDialog';
import DataTable from 'shared/components/DataTable';
import { ColumnType, columnTyping } from '../DataTable/Column';

import { getTranslatedWord, serverUploadPath } from 'shared/utils/tools';
import { dateFormat, formatTimeByTimezone } from 'shared/utils/formats';

import {
  FieldLabel,
  AvatarContainer,
  InputContainer,
  NameContainer,
  IconContainer,
  DialogContainer,
} from './styledComponent';
import Avatar from '../DataTable/Avatar';

import DesktopIcon from 'assets/icons/desktop.svg';
import TagIcon from 'assets/icons/tag.svg';
import MobileIcon from 'assets/icons/mobile.svg';
import DoorIcon from 'assets/icons/door.svg';
import FingerprintIcon from 'assets/icons/fingerprint.svg';
import BeaconIcon from 'assets/icons/beacon.svg';
import QrCodeIcon from 'assets/icons/qrcode.svg';

type OverlappingDialogProps = {
  message?: string | ReactNode;
  overlapping: Array<any>;
  worker?: any;
  isAbsence?: boolean;
};

const Overlapping = (props: OverlappingDialogProps) => {
  const { message, overlapping, worker, isAbsence = false } = props;

  const ICONS = {
    web: DesktopIcon,
    box: TagIcon,
    mobile: MobileIcon,
    Mobile: MobileIcon,
    door: DoorIcon,
    fingerprint: FingerprintIcon,
    beacon: BeaconIcon,
    qr_code: QrCodeIcon,
  } as { [key: string]: any };

  const baseColumns: ColumnType[] = [
    {
      title: getTranslatedWord('Name', 'worker_name'),
      accessor: 'name',
      sortable: false,
      sortKey: 'worker_name',
      type: 'function' as columnTyping,
      adjusted: true,
      bodyProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      width: '300px',
      fn: (row, index) => (
        <AvatarContainer display="flex" alignItems="center">
          <Avatar
            name={worker?.worker_name}
            imageUrl={serverUploadPath(worker?.worker_thumbnail) + '?cache_id=' + worker?.photo_cache}
          />
          <NameContainer ml="16px" fontWeight="500" fontSize="16px" lineHeight="32px">
            {worker?.worker_name}
          </NameContainer>
        </AvatarContainer>
      ),
    },
    {
      title: getTranslatedWord('Date'),
      accessor: 'work_date',
      sortable: false,
      sortKey: 'work_date',
      type: 'function' as columnTyping,
      adjusted: true,
      bodyProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      conditionalRendering: row => !isAbsence,
      fn: (row, index) => {
        if (row?.work_date?.match(/[0-9]{2}-[0-9]{2}/g))
          return (
            <span>
              {formatTimeByTimezone(row?.start_utc, row?.timezone_area).format('DD')}-
              {formatTimeByTimezone(row?.end_utc, row?.timezone_area).format(dateFormat)}
            </span>
          );
        return <span>{formatTimeByTimezone(row?.start_utc, row?.timezone_area).format(dateFormat)}</span>;
      },
    },
    {
      sortable: false,
      sortKey: 'start_utc',
      type: 'function' as columnTyping,
      accessor: 'start_utc',
      title: getTranslatedWord('Start', 'start'),
      bodyProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      headProps: {
        pl: '16px;',
      },
      fn: row => {
        return (
          <InputContainer className="md-input-container">
            {isAbsence && <FieldLabel>{moment(row?.started_at).format('DD.MM.yyyy')}</FieldLabel>}
            {!isAbsence && row?.start_utc && (
              <>
                <FieldLabel>{formatTimeByTimezone(row?.start_utc, row?.timezone_area).format('HH:mm')}</FieldLabel>
                <IconContainer>
                  <i className={'timepicker-icon'}>
                    <img
                      src={
                        ICONS[
                          (row?.start_device &&
                            typeof row?.start_device === 'string' &&
                            row?.start_device.toLowerCase()) ||
                            'web'
                        ]
                      }
                    />
                  </i>
                </IconContainer>
              </>
            )}
          </InputContainer>
        );
      },
    },
    {
      sortable: false,
      sortKey: 'end_utc',
      accessor: 'end_utc',
      type: 'function' as columnTyping,
      title: getTranslatedWord('End', 'end'),
      bodyProps: {
        style: {
          whiteSpace: 'nowrap',
        },
      },
      headProps: {
        pl: '16px;',
      },
      fn: row => {
        return (
          <InputContainer className="md-input-container">
            {isAbsence && <FieldLabel>{moment(row?.ended_at).format('DD.MM.yyyy')}</FieldLabel>}
            {!isAbsence && row?.end_utc && (
              <>
                <FieldLabel>{formatTimeByTimezone(row?.end_utc, row?.timezone_area).format('HH:mm')}</FieldLabel>
                <IconContainer>
                  <i className={'timepicker-icon'}>
                    <img
                      src={
                        ICONS[
                          (row?.start_device &&
                            typeof row?.start_device === 'string' &&
                            row?.start_device.toLowerCase()) ||
                            'web'
                        ]
                      }
                    />
                  </i>
                </IconContainer>
              </>
            )}
          </InputContainer>
        );
      },
    },
  ];

  return (
    <DialogContainer>
      {message && typeof message === 'string' ? <div className="message">{message}</div> : message}
      <DataTable
        rows={overlapping}
        columns={baseColumns}
        className="overlapping-table"
        isSelectable={false}
        showEmptyTable={false}
        loading={false}
        hideTableHeader
        paddingBottom="0px"
        mobilePaddingBottom="0px"
      />
    </DialogContainer>
  );
};

const OverlappingDialog = flowRight(withDialog())(Overlapping);

OverlappingDialog.defaultProps = {
  titleClassName: 'dialog__title--no-capitalize',
  width: '600px',
  height: 'auto',
};

export default OverlappingDialog;
