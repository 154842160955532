import { all, fork } from 'redux-saga/effects';
import auth from './auth/effects';
import app from './app/effects';
import company from './company/effects';
import data from './data/effects';
import schedule from './schedule/effects';

function* rootSaga() {
  yield all([auth, app, company, data, schedule].map(fork));
}

export default rootSaga;
