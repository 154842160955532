import React, { useMemo } from 'react';
import flowRight from 'lodash/flowRight';
import withDrawer, { WrappedProps } from 'shared/hocs/withDrawer';

import styled from 'styled-components';
import Box from 'shared/components/Box';
import { UserNotification } from 'shared/types/api';
import { useSelector } from 'react-redux';
import { dataSelector } from 'shared/redux/selectors';

import NotificationBody from 'shared/components/Notification';

type NotificationProps = WrappedProps<any> & {
  // items?: UserNotification[]
};

function Notification(props: NotificationProps) {
  const {
    notifications: { list },
  } = useSelector(dataSelector);

  const notifications = useMemo(() => {
    return list?.filter((item: any) => {
      return item?.type !== 'App\\Notifications\\WorkUpdate';
    }) as UserNotification[];
  }, [list]);

  return (
    <DrawerContainer>
      <NotificationBody items={notifications} />
    </DrawerContainer>
  );
}

const NotificationDrawer = flowRight(withDrawer())(Notification);

const DrawerContainer = styled(Box)`
  &,
  .drawer-toolbar {
    padding: 0 16px;

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      padding: 0 !important;
      margin-bottom: 16px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
`;

NotificationDrawer.defaultProps = {
  labelId: 'notification',
};

export default NotificationDrawer;
