import React, { useState, useEffect, useContext, createContext } from 'react'
import { breakpoints } from 'theme'

export type iBreakpoint = 'mobile' | 'tablet' | 'tablet-landscape' | 'desktop'
const breakpointValues = breakpoints.map(parseFloat)

const BreakpointContext = createContext<iBreakpoint | undefined>(undefined)

function getRootFontSize() {
  // Returns a number
  return parseFloat(
    // @ts-ignore
    getComputedStyle(document.documentElement).fontSize
  )
}

function convertEm(value: number) {
  return value * getRootFontSize()
}

function getBreakpoint(size: number, sizes: number[]): iBreakpoint {
  if (size <= sizes[0]) {
    return 'mobile'
  }

  if (size <= sizes[1]) {
    return 'tablet'
  }

  if (size <= sizes[2]) {
    return 'tablet-landscape'
  }

  return 'desktop'
}

function BreakpointProvider({ children }: { children: React.ReactNode }) {
  const [breakpoint, setBreakpoint] = useState<iBreakpoint | undefined>(
    'desktop'
  )

  useEffect(() => {
    const initialSize = window.document.documentElement.clientWidth
    const sizes = breakpointValues.map(convertEm)
    setBreakpoint(getBreakpoint(initialSize, sizes))
  }, [])

  useEffect(() => {
    function listener() {
      const size = document.documentElement.clientWidth
      const sizes = breakpointValues.map(convertEm)
      setBreakpoint(getBreakpoint(size, sizes))
    }

    // @todo - orest - Add debounce
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  })

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  )
}

function useBreakpoint(): iBreakpoint {
  const breakpoint = useContext(BreakpointContext)

  if (breakpoint === undefined) {
    throw new Error(
      `useBreakpoint must be used within a <BreakpointProvider />`
    )
  }

  return breakpoint as iBreakpoint
}

function useAtBreakpoints(target: iBreakpoint[]): boolean {
  const breakpoint = useBreakpoint()

  return target.indexOf(breakpoint) !== -1
}

export { BreakpointProvider, useAtBreakpoints, useBreakpoint }
