import { createSelector } from '@reduxjs/toolkit';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { SVGIcon } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from 'shared/components/Button';
import NavBar from 'shared/components/Layout/NavBar';
import withAuth from 'shared/hocs/withAuth';
import { useBreakpoint } from 'shared/hooks/useBreakpoint';
import useWindowSize from 'shared/hooks/useWindowSize';
import { changeMedia, showMinimizeDialog } from 'shared/redux/app/reducer';
import { GlobalState } from 'shared/redux/reducer';
import styled from 'styled-components';
import Box from '../Box';
import AdminNavBar from './AdminNavBar';
import MobileNavBar from './MobileNavBar';
import AdminMobileNavBar from './MobileNavBar/AdminMobileNavBar';

const pageSelector = createSelector(
  (state: GlobalState) => state.app.dialogToast,
  (state: GlobalState) => state.app.dialog,
  (state: GlobalState) => state.app.drawer,
  (state: GlobalState) => state.app.temporaryClosedDialogs,
  (state: GlobalState) => get(state.auth, 'user.role', ''),
  (state: GlobalState) => state.app.minimizeDialogs,
  (state: GlobalState) => state.app.bottomNavBar.isShown,
  (toast, dialog, drawer, temporaryClosedDialogs, role, minimizeDialogs, isShown) => ({
    toast,
    dialog,
    drawer,
    hasTemporaryClosed: temporaryClosedDialogs.length > 0,
    minimizeDialogs,
    role,
    isShown,
  }),
);

type PageProps = {
  children: ReactNode;
  hasNavigatin?: boolean;
  requireAuth?: boolean;
  className?: string;
  pageId?: string;
  hasNavigation?: boolean;
  pageTitle?: string;
  requiredRoles?: string[];
};

function Page(props: PageProps) {
  const { children, hasNavigation, pageId, className, requireAuth } = props;
  const breakpoint = useBreakpoint();
  const size = useWindowSize();
  const appData = useSelector(pageSelector);
  const dispatch = useDispatch();
  const { dialog, drawer, role, minimizeDialogs = [], isShown } = appData;

  const location = useLocation();
  const isAdminRoute = location.pathname.includes('/admin');
  const isPdfViewer = location.pathname.includes('/report-pdf-viewer');
  const isErrorPage = location.pathname.includes('/error');

  useEffect(() => {
    const windowWidth = get(size, 'width');
    if (windowWidth && windowWidth >= 600 && windowWidth <= 1366) dispatch(changeMedia('tablet'));
    else if (windowWidth && windowWidth >= 1 && windowWidth <= 599) dispatch(changeMedia('mobile'));
    else dispatch(changeMedia('desktop'));
  }, [size]);

  const isDesktop = useMemo(() => {
    return breakpoint === 'desktop';
  }, [breakpoint]);

  const pageWithNavBarComponent = useMemo(() => {
    if (!hasNavigation) {
      return null;
    }
    if (isDesktop) {
      if (isAdminRoute) {
        return <AdminNavBar />;
      } else {
        return <NavBar />;
      }
    } else {
      if (isAdminRoute) {
        return <AdminMobileNavBar />;
      } else {
        if (isShown) {
          return <MobileNavBar />;
        }
        return null;
      }
    }
  }, [isShown, isAdminRoute, isDesktop]);

  const theme = `theme-${role.toLowerCase()}`;
  const skipFooter = ['challenges', 'survey'].includes(String(pageId));

  let DrawerComponent;
  if (drawer && drawer.component) {
    ({ component: DrawerComponent } = drawer);
  }

  let DialogComponent;
  if (dialog && dialog.component) {
    ({ component: DialogComponent } = dialog);
  }

  useEffect(() => {
    document.title = props?.pageTitle ? `HOURS - ${props?.pageTitle}` : 'HOURS';
  }, [props?.pageTitle]);

  return (
    <>
      {DialogComponent && dialog && <DialogComponent {...dialog.props} theme={theme} />}
      {DrawerComponent && drawer && <DrawerComponent {...drawer.props} theme={theme} />}
      <MainLayout className={`page page-${pageId} ${theme} ${className}`}>
        {pageWithNavBarComponent}
        <Wrapper id="wrapper" isLanding={!hasNavigation || pageId == 'schedules'}>
          {children}
        </Wrapper>
        <div className="minimize-dialogs">
          {[...minimizeDialogs].reverse().map((each: any, index: number) => (
            <Button key={index} onClick={() => dispatch(showMinimizeDialog(each.minimizeName))} variant="primary">
              {each.minimizeName}
              <SVGIcon aria-label="maximize">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M3 3h18v2H3z" />
              </SVGIcon>
            </Button>
          ))}
        </div>
      </MainLayout>
    </>
  );
}

const MainLayout = styled.main``;

const Wrapper = styled(Box)<{ isLanding?: boolean; width?: string }>`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.label};
    border-radius: 50px;
  }

  ${({ isLanding }) =>
    !isLanding &&
    `
    margin: 0px auto;
    max-width: 2560px;
  `}
`;

const EnhancedPage = flowRight(withAuth)(Page);

Page.defaultProps = {
  hasNavigation: true,
  pageId: '',
  requiredRoles: [],
};

EnhancedPage.defaultProps = Page.defaultProps;

export default EnhancedPage;
