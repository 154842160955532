import React from 'react'
import { SvgIconProps } from './Home'

type SvgNotificationProps = {
  hasUnread?: boolean
}

const SvgNotificationIcon = ({ fill, width, height, hasUnread }: SvgIconProps & SvgNotificationProps) =>
  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "48"}
      height={height ?? "48"}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill={fill ?? "#000"}
        d="M32.15 28.313c-.338 0-.662-.139-.902-.385a1.332 1.332 0 01-.373-.928v-4.254a7.096 7.096 0 00-1.494-4.58 6.73 6.73 0 00-4.031-2.477v-1.814a.888.888 0 00-.249-.619A.838.838 0 0024.5 13a.838.838 0 00-.601.256.888.888 0 00-.249.619v1.814a6.73 6.73 0 00-4.03 2.476 7.096 7.096 0 00-1.495 4.58V27c0 .348-.134.682-.373.928-.24.246-.564.384-.902.384a.838.838 0 00-.601.257.888.888 0 00-.249.619c0 .232.09.454.249.618.16.164.376.256.601.256h15.3a.838.838 0 00.601-.256.888.888 0 00.249-.619.888.888 0 00-.249-.618.838.838 0 00-.601-.256zM26.4 31.375h-3.8a.209.209 0 00-.142.056.221.221 0 00-.07.138 2.22 2.22 0 00.609 1.79c.399.41.94.641 1.503.641.564 0 1.104-.23 1.503-.64a2.22 2.22 0 00.608-1.79.22.22 0 00-.069-.14.207.207 0 00-.141-.055z"
      ></path>
      {hasUnread && (<circle cx="35" cy="11" r="4" fill="#C52929"></circle>)}
    </svg>
  )

export default SvgNotificationIcon
