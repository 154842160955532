import { useHistory } from 'react-router-dom';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import store from 'shared/redux/store';
import { unauthorize } from 'shared/redux/auth/reducer';
import { addToast, ToastType, showDialog, hideAllDialogs, hideAllDrawers } from 'shared/redux/app/reducer';
import { getResponseMessage, getTranslatedWord } from 'shared/utils/tools';
import ConfirmDialog from 'shared/components/ConfirmDialog';

const baseURL = '/api';
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  function(config: AxiosRequestConfig) {
    /* XSRF for safari */
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    config.headers['X-XSRF-TOKEN'] = store.getState().auth.csrf;
    return config;
  },
  (err: Error) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error: AxiosError) {
    const { response } = error;
    switch (response?.status) {
      // FIXME: Workaround fix
      case 501:
      case 504:
        return Promise.reject(error);

      case 405:
      case 429:
        store.dispatch(
          showDialog({
            component: ConfirmDialog,
            props: {
              hasCancel: false,
              title: 'Oops!',
              variant: 'danger',
              message: getTranslatedWord(
                'Something went wrong with your request. Please try again later or refresh the page.',
              ),
              additional_message: getTranslatedWord('Still having a trouble?  Please contact administrator'),
              onValid: () => {
                store.dispatch(hideAllDialogs());
              },
            },
          }),
        );
        return Promise.reject(error);

      case 500:
      case 502:
        store.dispatch(
          showDialog({
            component: ConfirmDialog,
            props: {
              hasCancel: false,
              title: 'Oops!',
              variant: 'danger',
              message: getTranslatedWord('Something went wrong. Please contact administrator.'),
              onValid: () => {
                store.dispatch(hideAllDialogs());
              },
            },
          }),
        );
        return Promise.reject(error);

      case 400:
        const notification: ToastType = {
          message: getResponseMessage(response),
          type: 'error',
        };
        store.dispatch(addToast(notification));
        return Promise.reject(error);

      case 401:
        store.dispatch(unauthorize());

      default:
        if (response && response.status && ![500, 502, 429, 200, 401].includes(response.status)) {
          store.dispatch(hideAllDrawers());

          const history = useHistory();
          history.push(`/error/${response.status}`, {
            message: typeof response?.data?.message === 'string' ? response?.data?.message : 'Something went wrong',
            redirectTo: history.location.pathname || '',
          });
        }

        return Promise.reject(error);
    }
  },
);

export default axiosInstance;
