import React, { useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { pick } from 'lodash';

import PaidAbsenceRequestForm from 'pages/People/components/Drawers/PaidAbsenceRequestForm';
import { PaidAbsenceType } from 'pages/People/model/paid-absence';

import { getNotificationType, getTranslatedWord, isWithin24Hours, isWithinThisWeek } from 'shared/utils/tools';
import { UserNotification } from 'shared/types/api';
import useMutation from 'shared/hooks/useMutation';
import { fetchNotifications, _setNotifications } from 'shared/redux/data/reducer';
import { userSelector } from 'shared/redux/selectors';
import { setNotificationSettings } from 'shared/redux/auth/reducer';
import { drawerProcessing, hideAllDialogs, hideDrawer, showDialog, showDrawer } from 'shared/redux/app/reducer';
import { AddBonusType } from 'shared/components/Tables/Timesheet/model/bonus';
import { validator as BonusValidator } from 'shared/components/Tables/Timesheet/model/bonus';

import BonusRequestDrawer from '../Tables/Timesheet/Drawers/AddBonusDrawer/BonusRequestDrawer';

import LatestNotifications from './LatestNotifications';
import ThisWeekNotifications from './ThisWeekNotifications';
import OlderNotifications from './OlderNotifications';

import { NotificationComponentProps } from './propsType';
import {
  BodyContainer,
  NotificationItemContainer,
  NotificationContentWrapper,
  NotificationMessage,
} from './styledComponents';
import OverlappingDialog from '../OverlappingDialog';

const NotificationBody = ({ items, dropDownRef, setIsOpen }: NotificationComponentProps) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { auth } = useSelector(userSelector);

  const latest = useMemo(() => {
    if (items === undefined || items === null) {
      return [];
    }
    return items
      .filter((item: UserNotification) => {
        return isWithin24Hours(item?.created_at);
      })
      .sort((a: any, b: any) => {
        return a.created_at - b.created_at;
      });
  }, [items]);

  const thisWeek = useMemo(() => {
    if (items === undefined || items === null) {
      return [];
    }
    return items
      .filter((item: UserNotification) => {
        return !isWithin24Hours(item?.created_at) && isWithinThisWeek(item?.created_at);
      })
      .sort((a: any, b: any) => {
        return a.created_at - b.created_at;
      });
  }, [items]);

  const older = useMemo(() => {
    if (items === undefined || items === null) {
      return [];
    }
    return items
      .filter((item: UserNotification) => {
        return !isWithin24Hours(item?.created_at) && !isWithinThisWeek(item?.created_at);
      })
      .sort((a: any, b: any) => {
        return a.created_at - b.created_at;
      });
  }, [items]);

  const [, onUpdate] = useMutation({
    method: 'POST',
    showMessage: false,
  });

  const [, onUpdateUserSetting] = useMutation({
    url: `/users/${auth?.id}/notifications/settings`,
    method: 'POST',
    showMessage: true,
  });

  const handleOnAction = (item: UserNotification, action: string) => {
    if (item.type === 'App\\Notifications\\BonusRequest') {
      if (action !== 'edit') {
        return onUpdate({
          url: `/works/${item.data.work_id}/bonus/accept`,
          data: {
            status: action === 'accept' ? true : false,
            timestamp: moment().unix() * 1000,
            id: item.data.request_id,
            notification_id: item.id,
          },
          message: getTranslatedWord('Bonus request updated successfully'),
          onSuccess() {
            dispatch(fetchNotifications());
          },
          showMessage: true,
        });
      } else {
        setIsOpen && setIsOpen(false);
        return dispatch(
          showDrawer({
            component: BonusRequestDrawer,
            props: {
              title: getTranslatedWord('Edit Bonus'),
              labelId: 'notifications-edit-bonus-drawer',
              workId: item.data.work_id,
              origBonus: item.data.bonus,
              continueLabel: getTranslatedWord('Edit Bonus'),
              onValid: (formData: AddBonusType) => {
                const allData = {
                  ...formData,
                  timestamp: moment().unix() * 1000,
                  notification_id: item.id,
                };
                onUpdate({
                  method: 'POST',
                  url: `works/${item.data.work_id}/bonus/request`,
                  data: allData,
                  message: getTranslatedWord('Bonus request updated successfully'),
                  onSuccess() {
                    dispatch(drawerProcessing(false));
                    dispatch(hideDrawer());
                    dispatch(fetchNotifications());
                  },
                  showMessage: true,
                });
              },
              validator: BonusValidator,
            },
          }),
        );
      }
    }
    if (item.type === 'App\\Notifications\\AbsenceRequest') {
      if (action !== 'edit') {
        return onUpdate({
          url: `/users/work-leaves/accept`,
          data: {
            status: action === 'accept' ? true : false,
            id: item.data.request_id,
            notification_id: item.id,
          },
          message: getTranslatedWord('Paid absence request updated successfully'),
          onSuccess() {
            dispatch(fetchNotifications());
          },
          showMessage: true,
        });
      } else {
        setIsOpen && setIsOpen(false);
        return dispatch(
          showDrawer({
            component: PaidAbsenceRequestForm,
            props: {
              title: getTranslatedWord('Edit Request'),
              labelId: 'notifications-edit-request-drawer',
              requestId: item.data.request_id,
              continueLabel: getTranslatedWord('Edit Request'),
              onValid: (data: PaidAbsenceType) => {
                const allData = {
                  ...pick(data, ['type_id', 'comment', 'user_id']),
                  id: item.data.request_id,
                  started_at: data?.started_at ? moment(data?.started_at).unix() * 1000 : moment().unix() * 1000,
                  ended_at: data?.ended_at ? moment(data?.ended_at).unix() * 1000 : moment().unix() * 1000,
                  notification_id: item.id,
                };
                onUpdate({
                  method: 'POST',
                  url: `users/work-leaves/request`,
                  data: allData,
                  message: getTranslatedWord('Paid absence request updated successfully'),
                  onSuccess() {
                    dispatch(drawerProcessing(false));
                    dispatch(hideDrawer());
                    dispatch(fetchNotifications());
                  },
                  onError: (err: any) => {
                    if (err?.type == 'overlapping') {
                      const overlapping = Object.entries(err.overlapping).map((data: any) => data[1]);
                      const worker = {
                        worker_name: err?.worker_name || '',
                        worker_thumbnail: err?.worker_thumbnail || '',
                      };

                      dispatch(
                        showDialog({
                          component: OverlappingDialog,
                          props: {
                            title: getTranslatedWord('Conflicting dates'),
                            message: getTranslatedWord(
                              'There are conflicting date for this timeframe. Please review previous entries.',
                            ),
                            variant: 'primary',
                            overlapping,
                            worker,
                            hasCancel: false,
                            isAbsence: true,
                            continueLabel: getTranslatedWord('Close'),
                            onContinue: () => {
                              dispatch(drawerProcessing(false));
                              dispatch(hideAllDialogs());
                            },
                          },
                        }),
                      );
                    } else {
                      dispatch(drawerProcessing(false));
                    }
                  },
                  showMessage: true,
                });
              },
            },
          }),
        );
      }
    }
  };

  const handleReadNotification = (item: UserNotification) => {
    onUpdate({
      url: `notifications/${item.id}/read`,
      onSuccess(response: any) {
        dispatch(_setNotifications(response?.data));
      },
    });
  };

  const handleOnClick = (item: UserNotification) => {
    return handleReadNotification(item);
  };

  const handleTurnOffStatus = (item: UserNotification) => {
    const type = getNotificationType(item?.type);
    const settings = {
      ...JSON.parse(auth?.notification_settings || '{}'),
      [type]: false,
    };

    onUpdateUserSetting({
      data: {
        options: JSON.stringify(settings),
      },
      onSuccess(response: any) {
        dispatch(setNotificationSettings(response?.data));
      },
    });
  };

  const handleDeleteNotification = (item: UserNotification) => {
    onUpdate({
      url: `notifications/${item.id}/delete`,
      showMessage: true,
      message: getTranslatedWord('Notification deleted successfully'),
      onSuccess(response: any) {
        dispatch(_setNotifications(response?.data));
      },
    });
  };

  const actions = [
    {
      label: getTranslatedWord('Mark as read'),
      onClick: handleReadNotification,
    },
    {
      label: getTranslatedWord('Turn off receiving this status'),
      onClick: handleTurnOffStatus,
    },
    {
      label: getTranslatedWord('Delete notification'),
      onClick: handleDeleteNotification,
      color: 'red',
    },
  ] as Object[];

  return (
    <ThemeProvider theme={theme}>
      <BodyContainer>
        {items && items?.length <= 0 && (
          <NotificationItemContainer>
            <NotificationContentWrapper isEmpty={true}>
              <NotificationMessage>{getTranslatedWord('No notifications yet')}</NotificationMessage>
            </NotificationContentWrapper>
          </NotificationItemContainer>
        )}
        <LatestNotifications
          isVisible={latest && latest.length > 0}
          notificationData={latest}
          actions={actions}
          handleOnAction={handleOnAction}
          handleOnClick={handleOnClick}
          dropDownRef={dropDownRef}
        />
        <ThisWeekNotifications
          isVisible={thisWeek && thisWeek.length > 0}
          notificationData={thisWeek}
          actions={actions}
          handleOnAction={handleOnAction}
          handleOnClick={handleOnClick}
          dropDownRef={dropDownRef}
        />
        <OlderNotifications
          isVisible={older && older.length > 0}
          notificationData={older}
          actions={actions}
          handleOnAction={handleOnAction}
          handleOnClick={handleOnClick}
          dropDownRef={dropDownRef}
        />
      </BodyContainer>
    </ThemeProvider>
  );
};

export default NotificationBody;
