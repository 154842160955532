import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SidebarState {
  isSidebarVisible: boolean
  opened: string
  active: string
}

const initialState: SidebarState = {
  isSidebarVisible: true,
  opened: '',
  active: '',
}

const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarVisible = !state.isSidebarVisible
    },
    setOpenedList(state, { payload }: PayloadAction<string>) {
      state.opened = payload
    },
    setActive(state, { payload }: PayloadAction<string>) {
      state.active = payload
    },
  },
})

export const { toggleSidebar, setOpenedList, setActive } = sidebar.actions

export default sidebar.reducer
