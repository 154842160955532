import React, { ReactNode } from 'react';
import { Drawer } from 'react-md';
import { useSelector } from 'react-redux';

import DefaultTitle, { DrawerTitleProps } from './Title';
import DefaultActions, { DrawerActionsProps } from './DefaultActions';

import pick from 'lodash/pick';
import styled from 'styled-components';
import SvgCloseIcon from 'assets/icons/Close';

import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Badge from 'shared/components/Badge';

import { GlobalState } from 'shared/redux/reducer';
import { FormHandlers } from 'shared/hooks/useForm';

export type DrawerLayoutProps = {
  children: ReactNode;
  dialogTitleRenderer?: React.FC<DrawerTitleProps>;
  dialogActionsRenderer?: React.FC<DrawerActionsProps> | null;
  dialogClassName?: string;
  dialogClass?: string;
  dialogId?: string;
  labelId?: string;
  theme: string;
  visible: boolean;
  dialogContainerClassName: string;
  titleClassName: string;
  footerClassName: string;
  formHandlers: FormHandlers;
  closeOnValid?: boolean;
} & DrawerActionsProps &
  DrawerTitleProps;

const defaultProps = {
  dialogTitleRenderer: DefaultTitle,
  dialogActionsRenderer: DefaultActions,
  dialogClassName: '',
  titleClassName: '',
  contentClassName: '',
  footerClassName: '',
  dialogContainerClassName: '',
  dialogId: '',
  labelId: '',
  theme: 'theme-admin',
  autopadContent: false,
};

function DrawerLayout(props: DrawerLayoutProps) {
  const {
    children,
    dialogTitleRenderer: DialogTitle,
    dialogActionsRenderer: DrawerActions,
    dialogClassName,
    dialogContainerClassName,
    dialogId,
    labelId,
    titleClassName,
    footerClassName,
    title,
    visible,
    $noBottom = false,
    $withBottomMargin = true,
  } = props;

  const { time_elapse, total_elapse_time } = useSelector((state: GlobalState) => state.work);

  return (
    <DrawerContainer
      visible={visible}
      portal
      overlay
      type={Drawer.DrawerTypes.TEMPORARY}
      onVisibilityChange={handleVisibility}
      position="right"
      id={`${dialogId}_drawer`}
      renderNode={document.body}
      className={`dialog__container ${dialogContainerClassName}`}
      overlayClassName="drawer-default-overlay"
    >
      <DrawerToolbar className="drawer-toolbar" $withBottomMargin={$withBottomMargin}>
        <div className="drawer-toolbar-title-container">
          <div id={labelId} className="drawer-toolbar-title">
            {title}
          </div>
          {total_elapse_time > 0 && (
            <Badge>{`${time_elapse.hours}:${time_elapse.minutes}:${time_elapse.seconds}`}</Badge>
          )}
        </div>
        <Button
          id="drawer-close-button"
          type="button"
          isLoading={false}
          icon={<SvgCloseIcon />}
          onClick={props?.onCancel}
        />
      </DrawerToolbar>
      <DrawerContent $noBottom={$noBottom} {...pick(props, ['hasContinue', 'hasCancel'])}>
        <div className="drawer-content-container">{children}</div>
        {DrawerActions ? (
          <DrawerActions
            {...pick(props, [
              'formHandlers',
              'onContinue',
              'onCancel',
              'onExtra',
              'isProcessing',
              'role_level',
              'continueLabel',
              'cancelLabel',
              'extraLabel',
              'hasContinue',
              'hasCancel',
              'hasExtra',
              'continueIsDisabled',
              'context',
              'disableDefaultAction',
            ])}
          />
        ) : null}
      </DrawerContent>
    </DrawerContainer>
  );

  function handleVisibility(show: boolean = false) {
    if ([false].includes(show) && props?.onCancel) {
      props.onCancel();
    }
  }
}

const DrawerContainer = styled(Drawer)`
  z-index: 1900005;
  ${({ theme }) => theme.mediaQueries.largeDown} {
    margin-bottom: 90px;
  }
  height: 100%;
`;

const DrawerToolbar = styled(Box)<{ $withBottomMargin: boolean }>`
  padding: 28px 28px 28px 48px;
  width: 100%;
  height: 88px;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme, $withBottomMargin }) =>
    $withBottomMargin &&
    `
    border-bottom: 1px solid ${theme?.colors?.gray?.medium};
  `}

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    transition: all 0.2s ease;

    &:hover {
      background: ${({ theme }) => theme?.colors?.gray?.light_gray};
    }

    & > * {
      margin: 0;
      padding: 0;
    }
  }

  .drawer-toolbar-title-container {
    display: flex;
  }

  .drawer-toolbar-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-right: 10px;
  }

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    padding: 0 16px;
    max-height: 56px;
    min-height: 56px;

    .drawer-toolbar-title {
      font-size: 18px !important;
      line-height: 16px !important;
    }
  }
`;

const DrawerContent = styled(Box)<{ hasContinue?: boolean; hasCancel?: boolean; $noBottom?: boolean }>`
  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // padding-bottom: 100px;

  .drawer-content-container {
    height: ${({ hasContinue, hasCancel }) => (hasContinue || hasCancel ? 'calc(100% - 68px)' : '100vh')};

    overflow-y: auto;
    min-width: 472px;
    max-width: 472px;
    // margin-bottom: 64px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.label};
      border-radius: 50px;
    }

    & > div:first-of-type {
      padding-bottom: ${({ $noBottom }) => ($noBottom ? '24px' : '248px')};
    }

    ${({ theme }) => theme?.mediaQueries?.largeDown} {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: unset;

      & > *:first-child {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &:has(.PaidAbsenceDrawer) {
      > div {
        padding-bottom: 110px;
      }
    }

    &:has(.ShiftDrawer) {
      > div {
        padding-bottom: 70px;
      }
    }

    &:has(.TaskDrawer) {
      > div {
        padding-bottom: 70px;
      }
    }
  }
`;

DrawerLayout.defaultProps = defaultProps;

export default DrawerLayout;
