import styled from 'styled-components';
import Box from 'shared/components/Box';

export const BonusDrawerContainer = styled(Box)`
  &,
  .drawer-toolbar {
    padding: 32px 48px;
  }

  & > *:first-child {
    margin-top: 12px;
  }
`;
