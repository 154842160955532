import React, { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { StyledTableColumn, StyledTableColumnLoader } from './index';
import {
  LoadingSkeleton,
  StyledTableRow,
  StyledTableColumnWorkTypeLoader,
  StyledTableRowWorkTypeLoader,
} from './styledComponents';

import Avatar from 'shared/components/DataTable/Avatar';
import { ColumnType } from './Column';
import { Identifiable } from 'shared/types';
import Checkbox from '../Checkbox';

// WorkTypeTable
import { ColumnType as ColumnTypeWorkType } from 'pages/WorkType/components/types';

export const useHandleData = (tableData: any[], queryUrl: string = '', query: any, showMore?: boolean): any[] => {
  const [data, setData] = useState<any[]>(tableData || []);
  const page = query.page;

  const _identifier = JSON.stringify({ queryUrl, query: omit(query, ['page', 'per_page']) });
  const [identifier, setIdentifier] = useState<string>(_identifier);

  useEffect(() => {
    if (_identifier !== identifier) {
      setData(tableData || []);
      setIdentifier(_identifier);
    } else {
      if (page !== 1) {
        setData(d => [...d, ...tableData]);
      } else {
        setData(tableData || []);
      }
    }
  }, [tableData]);

  if (showMore) return data;
  return tableData || [];
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function loadingState<T extends Identifiable>(
  columns: ColumnType<T>[],
  rows: number,
  isLocalSelectable?: boolean,
  withAvatar = false,
) {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((r, ri) => {
          return (
            <StyledTableRow key={`row-loading-${ri}`}>
              {isLocalSelectable && (
                <StyledTableColumn adjusted={false} className="md-table-checkbox">
                  <Checkbox
                    className="custom-table-checkbox"
                    name={`table-${ri}-header`}
                    id={`table-${ri}-header`}
                    size="small"
                  />
                </StyledTableColumn>
              )}
              {columns.map((c: ColumnType<T>, ci) => {
                return (
                  c.title && (
                    <StyledTableColumnLoader key={`row-loading-${ri}-column-${ci}`}>
                      {columns.length > 1 && ci === 0 && (
                        <div className="avatar-container">
                          {withAvatar && (
                            <div className="avatar">
                              <Avatar />
                            </div>
                          )}
                          <div className="line">
                            <LoadingSkeleton $width={getRandomInt(65, 100)} />
                          </div>
                        </div>
                      )}
                      {columns.length > 1 && ci !== 0 && (
                        <div className="line">
                          <LoadingSkeleton $width={getRandomInt(65, 100)} />
                        </div>
                      )}
                    </StyledTableColumnLoader>
                  )
                );
              })}
            </StyledTableRow>
          );
        })}
    </>
  );
}

export function loadingState2<T extends Identifiable>(
  columns: ColumnType<T>[],
  rows: number,
  isSelectable: boolean,
  withAvatar = false,
) {
  return Array(rows ? 10 : rows)
    .fill(0)
    .map((r, ri) => {
      return (
        <StyledTableRow key={`row-loading-${ri}`}>
          {isSelectable && (
            <StyledTableColumn adjusted={false} className="md-table-checkbox">
              <Checkbox
                className="custom-table-checkbox"
                name={`table-${ri}-header`}
                id={`table-${ri}-header`}
                size="small"
              />
            </StyledTableColumn>
          )}
          {columns.map((c: ColumnType<T>, ci) => {
            return (
              c.title && (
                <StyledTableColumnLoader key={`row-loading-${ri}-column-${ci}`}>
                  {columns.length > 1 && ci === 0 && (
                    <div className="avatar-container">
                      {withAvatar && (
                        <div className="avatar">
                          <Avatar />
                        </div>
                      )}
                      <div className="line">
                        <LoadingSkeleton $width={getRandomInt(65, 100)} />
                      </div>
                    </div>
                  )}
                  {columns.length > 1 && ci !== 0 && (
                    <div className="line">
                      <LoadingSkeleton $width={getRandomInt(65, 100)} />
                    </div>
                  )}
                </StyledTableColumnLoader>
              )
            );
          })}
        </StyledTableRow>
      );
    });
}

export function loadingStateWorkType<T extends Identifiable>(columns: ColumnTypeWorkType<T>[], rows: number) {
  return Array(rows ? 10 : rows)
    .fill(0)
    .map((r, ri) => {
      return (
        <StyledTableRowWorkTypeLoader
          selected={false}
          key={`row-loading-${ri}`}
          className="is-parent"
          hidden={false}
          paddingIncrement={56}
        >
          {columns.map((column: ColumnTypeWorkType, ci: any) => {
            return (
              <StyledTableColumnWorkTypeLoader key={`row-loading-${ri}-column-${ci}`} paddingIncrement={24}>
                {columns.length > 1 && ci === 0 && (
                  <div className="avatar-container">
                    <div className="line">
                      <LoadingSkeleton $width={getRandomInt(65, 100)} />
                    </div>
                  </div>
                )}
                {columns.length > 1 && ci !== 0 && (
                  <div className="line">
                    <LoadingSkeleton $width={getRandomInt(65, 100)} />
                  </div>
                )}
              </StyledTableColumnWorkTypeLoader>
            );
          })}
        </StyledTableRowWorkTypeLoader>
      );
    });
}

export function loadingHistoryState(items: number, rows: number) {
  return Array(items ?? 4)
    .fill(0)
    .map((r, ri) => {
      const data = Array(rows ?? 3)
        .fill(0)
        .map((r, ri) => {
          return (
            <li>
              <div className="line">
                <LoadingSkeleton $width={getRandomInt(45, 100)} />
              </div>
            </li>
          );
        });

      return (
        <div className="container">
          {ri === 0 && <hr />}
          <div className="line">
            <LoadingSkeleton $width={getRandomInt(40, 65)} />
          </div>
          <ul>{data}</ul>
          <div className="date">
            <div className="line">
              <LoadingSkeleton $width={getRandomInt(25, 55)} />
            </div>
          </div>
          <hr />
        </div>
      );
    });
}
