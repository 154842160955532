import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CallbacksStateType = {
  refetch: any;
};

type SetRefetchCallbackPayload = {
  refetchCallback: any;
};

const initialState: CallbacksStateType = {
  refetch: null,
};

const callbacks = createSlice({
  name: 'callbacks',
  initialState,
  reducers: {
    setRefetchCallback: (state: CallbacksStateType, action: PayloadAction<SetRefetchCallbackPayload>) => {
      state.refetch = action.payload;
    },
  },
});

export const { setRefetchCallback } = callbacks.actions;
export default callbacks.reducer;
